import useAllClientData from "@/api/clients/admin/getAllClientData";
import { AgePie } from "@/routes/Main/Reporting/components/AgePie";
import { ChartCard } from "@/routes/Main/Reporting/components/ChartCard";
import { ChartContainer } from "@/routes/Main/Reporting/components/ChartContainer";
import { EthnicityPie } from "@/routes/Main/Reporting/components/EthnicityPie";
import { GenderPie } from "@/routes/Main/Reporting/components/GenderPie";
import { NoChartsVisibleCard } from "@/routes/Main/Reporting/components/NoChartsVisibleCard";
import { MainReportingControls } from "@/routes/Main/Reporting/components/controls/MainReportingControls";
import { getChartColors } from "@/routes/Main/Reporting/utils/ChartConfigs";
import { AgeRange, Ethnicity, Gender } from "@/util/constants";
import { downloadImg } from "@/util/fileUtils";
import { useRef, useState } from "react";

export const CustomReport = ({ ...props }) => {
    const clientsQuery = useAllClientData();
    const refs = {
        age: useRef(),
        // income : useRef(),
        gender: useRef(),
        ethnicity: useRef(),
        goals: useRef(),
        characteristics: useRef(),
    };

    const [show, setShow] = useState({
        demographics: {
            age: true,
            // income: true,
            gender: true,
            ethnicity: true,
            goals: false,
            characteristics: false,
        },
        usage: {},
    });

    const [mainState, setMainState] = useState({
        datasetColors: getChartColors(10),
        // titleColor :'black',
        // legendColor,
        backgroundColor: "transparent",
        showAttachedLegend: false,
        attachedLegendPos: "top",
        attachedLegendFontSize: 15,
        showAttachedLabel: true,
        attachedLabelFontSize: 12,
        titleFontSize: 25,
        legendPos: "right",
        legendFontSize: 20,
        legendDotSize: 15,
        chartTitleAlignment: "center",
    });
    const [mainFilters, setMainFilters] = useState({});
    function toggleShow(chartToToggle) {
        setShow({ ...show, [chartToToggle]: !show[chartToToggle] });
    }
    function handleDownloadClicked(chart, chartTitle) {
        console.log("clickedchart", refs[chart]);
        const fileName = chartTitle || "chart.png";
        downloadImg(fileName, refs[chart]?.current?.toBase64Image?.());
    }
    function downloadAll() {
        Object.keys(refs).forEach((key) => {
            downloadImg(`${key}.png`, refs[key].current.toBase64Image());
        });
    }
    function noChartsVisible() {
        return (
            Object.values(show.demographics).every((v) => !v) &&
            Object.values(show.usage).every((v) => !v)
        );
    }
    return (
        <div className="flex flex-col gap-5 pb-5">
            <MainReportingControls
                state={mainState}
                setState={setMainState}
                visibility={show}
                setVisibility={setShow}
            />
            {noChartsVisible() && <NoChartsVisibleCard />}
            {show.demographics.age && (
                <ChartCard
                    chartType="pie"
                    datasetEnum={AgeRange}
                    chartName={"Age Distribution"}
                    handleDownloadClicked={() =>
                        handleDownloadClicked("age", "Age Distribution")
                    }
                    renderFn={(config) => (
                        <ChartContainer>
                            <AgePie
                                chartRef={refs.age}
                                clients={clientsQuery.data}
                                config={config}
                            />
                        </ChartContainer>
                    )}
                />
            )}
            {/* {show.demographics.income && (
                <ChartCard
                    chartType='bar'
                    datasetEnum={IncomeSources}
                    chartName={'Income Sources'}
                    handleDownloadClicked={() => handleDownloadClicked('income','Income Sources')}
                    renderFn={(config) => (
                        <ChartContainer>
                            <IncomeSourceChart chartRef={refs.income} clients={clientsQuery.data} config={config}/>
                        </ChartContainer>
                    )}
                    />
            )} */}
            {show.demographics.gender && (
                <ChartCard
                    chartType="pie"
                    datasetEnum={Gender}
                    chartName={"Gender Distribution"}
                    handleDownloadClicked={() =>
                        handleDownloadClicked("gender", "Gender Distribution")
                    }
                    renderFn={(config) => (
                        <ChartContainer>
                            <GenderPie
                                chartRef={refs.gender}
                                clients={clientsQuery.data}
                                config={config}
                            />
                        </ChartContainer>
                    )}
                />
            )}
            {show.demographics.ethnicity && (
                <ChartCard
                    chartType="pie"
                    datasetEnum={Ethnicity}
                    chartName={"Ethnicity Distribution"}
                    handleDownloadClicked={() =>
                        handleDownloadClicked(
                            "ethnicity",
                            "Ethnicity Distribution"
                        )
                    }
                    renderFn={(config) => (
                        <ChartContainer>
                            <EthnicityPie
                                chartRef={refs.ethnicity}
                                clients={clientsQuery.data}
                                config={config}
                            />
                        </ChartContainer>
                    )}
                />
            )}
            {/* {show.demographics.goals && (
                <ChartCard
                    chartType='bar'
                    chartName={'Client Goals'}
                    handleDownloadClicked={() => handleDownloadClicked('goals')}>
                    <ChartContainer>
                        <GoalsChart chartRef={refs.goals} clients={clientsQuery.data}/>
                    </ChartContainer>
                
            )}
            {show.demographics.characteristics && (
                <ChartCard
                    chartType='bar'
                    chartName={'Client Characteristics'}
                    handleDownloadClicked={() => handleDownloadClicked('characteristics')}>
                    <ChartContainer>
                        <CharacteristicsChart chartRef={refs.characteristics} clients={clientsQuery.data}/>
                    </ChartContainer>
                
            )} */}
        </div>
    );
};
