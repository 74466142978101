import { MILLISEC } from "@/util/constants";

export function dateFilterFn(row, columnId, {from,to,...rest}) {
    // console.log('hi', typeof from, typeof to);
    // console.log(rest);
    const value = row.getValue(columnId);
    if (!value) return false;
    if (from && from > value) return false;
    if (to && to < value) return false;
    return true;
}

export function maxDateFilterFn(row, columnId, maxDate) {
    let value = row.getValue(columnId);
    // console.log('value', value, 'maxDate', maxDate);
    // console.log(value - maxDate );
    if (!value) return true;
    if (maxDate && maxDate < value) return false;
    return true;
}

export function minDateFilterFn(row, columnId, minDate) {
    if (!minDate) return true;
    let value = row.getValue(columnId);
    if (!value) return false;
    if (minDate && minDate > value) return false;
    return true;
}

// export function simplifiedStringFilterFn(row, columnId, filterVal) {
//     if (!filterVal) return true
//     const value = row.getValue(columnId)
//     if ()
// }