import { useEffect } from "react";
import { useRHF } from "../../../hooks/useRHF";
import { useYupValidationResolver } from "../../../hooks/useYupValidationResolver";
import { enumToOptions } from "../../../lib/utils";
import { primaryDemographics } from "../../../util/constants";
import { ComposedForm } from "../../../wrappers/ui/form/ComposedForm";
import { ComposedFormRadio } from "../../../wrappers/ui/form/ComposedFormRadio";
import { ComposedFormText } from "../../../wrappers/ui/form/ComposedFormText";
import Yup from "../../../wrappers/yup";
import { FormSection } from "../../ui/FormSection";

const validationSchema = Yup.object({
    serviceTypes: Yup.object({
        employment: Yup.boolean().required("required"),
        housing: Yup.boolean().required("required"),
        healthAndMentalHealth: Yup.boolean().required("required"),
        disability: Yup.boolean().required("required"),
        domesticViolence: Yup.boolean().required("required"),
        fosterCare: Yup.boolean().required("required"),
        homelessness: Yup.boolean().required("required"),
        legalServices: Yup.boolean().required("required"),
        prisonerReentry: Yup.boolean().required("required"),
        substanceAbuse: Yup.boolean().required("required"),
        childcareServices: Yup.boolean().required("required"),
        veterans: Yup.boolean().required("required"),
    }).minOneTrue(),
    otherServices: Yup.string().notRequired(),
    primaryDemographic: Yup.mixed().oneOfEnum(primaryDemographics),
    agencyMission: Yup.string(),
    otherServiceLimits: Yup.string(),
});

export const AgencyServiceType = ({
    enumsQuery,
    sectionIndex,
    agencyData,
    hasPrev,
}) => {
    const resolver = useYupValidationResolver(validationSchema);
    const form = useRHF({
        resolver,
        defaultValues: validationSchema.getDefault(),
    });
    const { register, handleSubmit, formState, setValue, reset, control } =
        form;
    // const formStatus = getFormSectionStatus({submitted: hasPrev, isDirty: formState.isDirty, isValid: formState.isValid})
    function onSubmit(data: any) {}
    function loadData() {
        if (!agencyData) return;
        reset(agencyData);
    }
    useEffect(loadData, [agencyData]);
    return (
        <FormSection {...{ sectionIndex }} title={"Service Type"}>
            <ComposedForm form={form} onSubmit={handleSubmit(onSubmit)}>
                <ComposedFormRadio
                    control={control}
                    name={"primaryDemographic"}
                    label="Primary Service Group"
                    options={enumToOptions(primaryDemographics)}
                />
                <ComposedFormText
                    small
                    placeholder=""
                    label="Other Services"
                    name={"otherServices"}
                    control={control}
                />
                <ComposedFormText
                    small
                    placeholder=""
                    label="Agency Mission"
                    name={"agencyMission"}
                    control={control}
                />
                <ComposedFormText
                    small
                    placeholder=""
                    label="Service Type Notes"
                    name={"otherServiceLimits"}
                    control={control}
                />
            </ComposedForm>
        </FormSection>
    );
};
