import { Button } from "@/components/ui/button"
import { Separator } from "@/components/ui/separator"
import { cn } from "@/lib/utils"
import { DemographicsReportData } from "@/routes/Main/Reporting/Presets/DemographicsReport/useDemographicsReportData"
import { blue, orange } from "@/util/constants"
import { LiveNumber } from "@/wrappers/ui/LiveNumber"
import { Users } from "lucide-react"
import { useState } from "react"
import { useCountUp } from "react-countup"


type DemoStatsProps = {
    data: DemographicsReportData
}

export const DemoStats = ({data}: DemoStatsProps) => {
    const {
        shelterCount,
        frequentShelterUse,
        newcomerCount,
        isRefugee,
        hasStudentVisa,
        indigenousCount,
        hasStatusCard
    } = data

    return (
        <div className="flex flex-col gap-2 min-h-fit h-full justify-stretch">
            <StatCard>
                <NumberContainer>
                    <LiveNumber className="font-extrabold text-3xl " startOnMount percent end={(frequentShelterUse /  shelterCount * 100)} />    
                </NumberContainer>
                <StatDescription>of clients who stay at a shelter do so <strong className="font-extrabold">frequently</strong></StatDescription>
            </StatCard>
            <StatCard style={{backgroundColor: orange}}>
                <NumberContainer>
                    <LiveNumber className="font-extrabold text-3xl " startOnMount percent end={(hasStatusCard /  indigenousCount * 100)} />    
                </NumberContainer>
                <StatDescription>of indigenous clients have <strong className="font-extrabold">status</strong></StatDescription>
            </StatCard>
            <StatCard style={{backgroundColor: blue}}>
                <NumberContainer>
                    <LiveNumber className="font-extrabold text-3xl " startOnMount percent end={(isRefugee /  newcomerCount * 100)} />    
                </NumberContainer>
                <StatDescription>of newcomer clients are <strong className="font-extrabold">refugees</strong></StatDescription>
                <Separator className="col-span-2"/>
                <NumberContainer>
                    <LiveNumber className="font-extrabold text-3xl " startOnMount percent end={(hasStudentVisa /  newcomerCount * 100)} />    
                </NumberContainer>
                <StatDescription>have a <strong className="font-extrabold">student visa card</strong></StatDescription>
            </StatCard>
        </div>
)
}

const NumberContainer = (props) => <div className="max-w-fit inline-flex" {...props}/>
const StatCard = ({className='', ...props}) => <div className={cn("grid flex-grow gap-x-2 gap-y-4 justify-start items-center grid-cols-[6.5rem,1fr] w-full bg-slate-600  p-6 rounded-xl text-white", className)} {...props}/>
const StatDescription = ({children, className='', ...props}) => <div className={cn("font-extralight text-2xl 2xl:text-3xl", className)} {...props}>{children}</div>