import {
    FormControl,
    FormDescription,
    FormField,
    FormHeader,
    FormItem,
    FormLabel,
} from "@/components/ui/form";
import { cn } from "@/lib/utils";
import { Control } from "react-hook-form";
import { RadioGroup, RadioGroupItem } from "../../../components/ui/radio-group";

type ComposedFormRadioProps = {
    options: any[];
    description?: string | null;
    label?: string;
    name: string;
    control: Control;
    containerClassName?: string;
    valueFn?: (opt: any) => any;
    labelFn?: (opt: any) => any;
    subComponentFn?: (option: any) => JSX.Element;
};

export const ComposedFormRadio = ({
    control,
    name,
    label = `${name}`,
    options,
    description = null,
    containerClassName,
    valueFn = (option) => option?.id || option?.value,
    labelFn = (option) => option?.Name || option?.label,
    subComponentFn = (option) => null,
}: ComposedFormRadioProps) => {
    return (
        <FormField
            control={control}
            name={name}
            render={({ field }) => (
                <FormItem className="py-3">
                    {label && <FormHeader label={label} />}
                    <FormControl>
                        <RadioGroup
                            onValueChange={field.onChange}
                            defaultValue={getDefaultValue(field, valueFn)}
                            className={cn(
                                "flex flex-col ml-3",
                                containerClassName
                            )}
                        >
                            {options?.map?.((option) => (
                                <FormItem key={valueFn(option)}>
                                    <div>
                                        <FormLabel
                                            className={
                                                "flex w-fit items-center hover:cursor-pointer space-x-2 "
                                            }
                                        >
                                            <FormControl>
                                                <RadioGroupItem
                                                    // id={option.value}
                                                    value={valueFn(option)}
                                                />
                                            </FormControl>
                                            <FormLabel className="hover:cursor-pointer w-fit">
                                                {labelFn(option) || option}
                                            </FormLabel>
                                        </FormLabel>
                                        {subComponentFn(option)}
                                    </div>
                                </FormItem>
                            ))}
                        </RadioGroup>
                    </FormControl>
                    {description && (
                        <FormDescription>{description}</FormDescription>
                    )}
                </FormItem>
            )}
        />
    );
};

function getDefaultValue(field, valueFn) {
    const v = valueFn(field);
    return Array.isArray(v) ? v?.[0] : v;
}
