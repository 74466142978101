import { cn } from "@/lib/utils"
import { Form } from "../../../components/ui/form"
import { CustomUseFormReturn } from "../../../hooks/useRHF"
import { checkFormForAutofill } from "../../../util/formUtils"
import { cva } from "class-variance-authority"

const ComposedFormVariants = cva(
    'grid py-4',
    {    variants: {
            spacing: {
                none: "",
                small: "gap-2",
                medium: "gap-4",
                large: "gap-8",
            },
        },
        defaultVariants: {
            spacing: "none",
        },
    }
)

interface ComposedFormProps {
    form: CustomUseFormReturn
    onSubmit: (data: any) => void,
    className?: string,
    children?: React.ReactNode,
    spacing?: 'none' | 'small' | 'medium' | 'large'
}

export const ComposedForm = ({form, className, onSubmit, spacing, ...props}: ComposedFormProps) => {
    const {formState, handleSubmit} = form
    return (
        <Form {...form}>
            <form
                className={cn(ComposedFormVariants({spacing}), className)}
                onSubmit={e => checkFormForAutofill(formState, handleSubmit(onSubmit),e)}>
                {props.children}
            </form>
        </Form>
    )
}