import { cn } from '@/lib/utils';
import { cva } from 'class-variance-authority';
import React from 'react';

type FieldGroupProps = {
    title?: string | undefined;
    children?: React.ReactNode | undefined;
    error?: string | undefined;
    className?: string | undefined;
    labelPosition?:'left' | 'center' | 'right' | undefined;
    labelClassName?: string | undefined;
    variant?:{
        variant?: 'small' | 'medium' | 'large' | undefined
        width?: 'fit' | 'wide' | undefined
    } | undefined;
};

const FieldGroupVariants = cva(
    'grid gap-2 rounded-lg border p-4 relative shadow-md',
    {
        variants: {
            variant: {
                large: '',
                medium: 'w-[240px]',
                small: 'w-[15rem]'
            },
            width: {
                custom: '',
                fit: 'w-fit',
                wide: 'w-full'
            }
        },
        defaultVariants: {
            variant: 'small',
            width: 'wide'
        }
    }
)

export const FieldGroup: React.FC<FieldGroupProps> = ({ title = '', children, error, className, variant, labelPosition='left', labelClassName, ...props }: FieldGroupProps) => {
    return children && (
        <fieldset className={cn(FieldGroupVariants(variant), error && 'border-error', className)}>
            {title && (
                <legend className={cn(
                    "-ml-1 px-1 text-sm font-medium w-fit bg-background leading-none", 
                    error && 'text-error-header',
                    labelPosition === 'left' && 'absolute left-2 -translate-y-1/2 top-0', 
                    labelPosition === 'center' && 'absolute left-1/2 -translate-x-1/2 -translate-y-1/2 top-0 origin-center', 
                    labelPosition === 'right' && 'absolute right-2 -translate-y-1/2 top-0', 
                    labelClassName
                    )} >
                    {title}
                    {error && (
                        <>
                            <div className='h-3 border-[0.5px] border-error-dark  self-center mx-2' />
                            <span>{error}</span>
                        </>
                    )}
                </legend>
            )}
            {children}
        </fieldset>
    );
};
