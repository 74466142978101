import { RadialProgress } from "@/components/progress/RadialProgress";
import { RankCell } from "@/components/tables/cells/RankCell";
import { zeroNaN } from "@/util/miscUtils";
import { createColumnHelper, filterFns } from "@tanstack/react-table";


const columnHelper = createColumnHelper();


const stringFilter = filterFns.includesString;
const numRangeFilter = filterFns.inNumberRange
const multiSelectFilter = filterFns.arrIncludesSome
const strictStringFilter = filterFns.includesString.autoRemove


export const BranchUsageRatingsCols = [
columnHelper.accessor((row) => row.name, {
    id: "Name",
    size: 100,
    filterFn: stringFilter,
    cell: ({row, getValue}) => (
    <RankCell index={row.index + 1}>
      <span className="text-md">{getValue()}</span>
    </RankCell> 
    ),
  }),
columnHelper.accessor((row) => row.activeCount, {
    id: "Active users",
    size: 100,
    filterFn: stringFilter,
    cell: ({row}) => <Activity row={row.original} ratio={row.getValue('Percent active')}/>,
    header: (info) => <span>Activity</span>,
  }),
columnHelper.accessor((row) => (zeroNaN(row.activeCount / (row.activeCount + row.inactiveCount)) * 100).toFixed(1) , {
    id: "Percent active",
    meta: {hiddenCol: true},
    filterFn: stringFilter,
    cell: (info) => <PercentActivity ratio={info.getValue()} />,
    header: (info) => <></>,
  }),
]
// grid-cols-[repeat(auto-fit,minmax(50px,1fr))]
const Activity = ({row, ratio}) => (
  <div className="flex items-center gap-10 justify-between">
    <div className="grid grid-cols-[fit-content(30px),fit-content(30px)]  gap-x-2 text-sm font-medium">
      <p className="w-fit text-[#FD5B41]">{row.activeCount}</p>
      <p className="w-fit ml-2 whitespace-nowrap">Active numbers</p>

      <p className="w-fit text-muted-foreground">{row.inactiveCount}</p>
      <p className="w-fit ml-2 whitespace-nowrap">Inactive numbers</p>
    </div>
    <PercentActivity ratio={ratio}/>
  </div>
)

export const PercentActivity = ({ratio}) => {
  return (
    <>
      {/* <div className="relative">
        <div className="radial-progress z-[1] text-orange" style={{"--value": ratio, '--size': '4rem'}}>
        <span className="text-primary">{ratio}%</span>
        </div>
        <div className="absolute left-0 top-0">
          <div className="text-muted radial-progress" style={{"--value": 100, '--size': '4rem'}}></div>
        </div>
      </div> */}
      <RadialProgress
        ratio={ratio / 100}
        className={'h-[4rem] text-orange'}
      >
        <span className="text-orange">{ratio}%</span>
      </RadialProgress>
    </>
  );
};
// const ActiveUsers = ({activeCount}) => (
    
// )

