import useAllBranches from "@/api/branches/getAllBranches";
import { useAllEnums } from "@/api/enum/getAllEnums";
import useMyUserData from "@/api/user/getMyUserData";
import { useMyAuthorizedAgencies } from "@/hooks/state/useMyAuthorizedAgencies";
import { useMyAuthorizedBranches } from "@/hooks/state/useMyAuthorizedBranches";
import { useObjectSearchParams } from "@/hooks/state/useObjectSearchParams";
import { useSyncYupValidationResolver } from "@/hooks/useYupValidationResolver";
import { AgencyGAOReportContent } from "@/routes/Main/Reporting/Presets/GoalsAndOutcomesReport/reportContent/AgencyGAOReportContent";
import { BranchGAOReportContent } from "@/routes/Main/Reporting/Presets/GoalsAndOutcomesReport/reportContent/BranchGAOReportContent";
import { NationalGAOReportContent } from "@/routes/Main/Reporting/Presets/GoalsAndOutcomesReport/reportContent/NationalGAOReportContent";
import { useGAOReportData } from "@/routes/Main/Reporting/Presets/GoalsAndOutcomesReport/useGAOReportData";
import { UsageReportSettings } from "@/routes/Main/Reporting/Presets/UsageReport/UsageReportSettings";
import { AgencyReport } from "@/routes/Main/Reporting/Presets/UsageReport/components/AgencyReport";
import { BranchReport } from "@/routes/Main/Reporting/Presets/UsageReport/components/BranchReport";
import { NationalReport } from "@/routes/Main/Reporting/Presets/UsageReport/components/NationalReport";
import Yup from "@/wrappers/yup";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";

export type UsageReportConfig = {
    view?: UsageReportView;
    branch?: any;
    agency?: any;
    scope?: {
        active?: boolean;
        inactive?: boolean;
    };
    separateBranches?: boolean;
    separateAgencies?: boolean;
};

export type UsageReportView = "national" | "branch" | "agency";

const settingsSchema = Yup.object({
    view: Yup.string().oneOf(["national", "branch", "agency"]).required(),
    branch: Yup.object({
        id: Yup.string().required(),
        name: Yup.string().required(),
    }).when(["view"], {
        is: "branch",
        then: (schema) => schema.required("oops"),
        otherwise: (schema) => schema.nullable(),
    }),
    agency: Yup.object({
        id: Yup.string().required(),
        name: Yup.string().required(),
    }).when(["view"], {
        is: "agency",
        then: (schema) => schema.required("oops"),
        otherwise: (schema) => schema.nullable(),
    }),
    separateBranches: Yup.boolean().when(["view"], {
        is: (view) => view === "national",
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(),
    }),
    separateAgencies: Yup.boolean().when(["view", "separateBranches"], {
        is: (view, separateBranches) =>
            (view === "national" && separateBranches) || view === "branch",
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(),
    }),
});

export const GoalsAndOutcomeReport = () => {
    const settingsContainerRef = useRef(null);
    const [config, setConfig] = useObjectSearchParams();
    const [activeStep, setActiveStep] = useState(1);
    const [configValid, setConfigValid] = useState(false);
    const [shouldGenerateReport, setShouldGenerateReport] = useState(false);
    const resolver = useSyncYupValidationResolver(settingsSchema);

    const myUserDataQuery = useMyUserData();
    const allBranchesQuery = useAllBranches();
    const enumsQuery = useAllEnums();

    const branchesQuery = useMyAuthorizedBranches({
        myUserDataQuery,
        allBranchesQuery,
    });
    const agenciesQuery = useMyAuthorizedAgencies({
        myUserDataQuery,
        allBranchesQuery,
    });

    useLayoutEffect(validateConfig, [config]);
    const errors = useMemo(getConfigErrors, [config]);
    const reportData = useGAOReportData({
        config: config,
        branchesQuery,
        agenciesQuery,
        enumsQuery,
        myUserDataQuery,
        enabled: configValid,
    });

    function getConfigErrors() {
        return resolver(config).errors;
    }

    function validateConfig() {
        const { view, branch, agency, scope } = config;
        const { errors } = resolver(config);
        setShouldGenerateReport(false);
        if (Object.keys(errors).length > 0) setConfigValid(false);

        if (errors.view) return setActiveStep(1);
        if (view === "national") {
            if (errors.separateBranches) return setActiveStep(2);
            if (errors.separateAgencies) return setActiveStep(3);
            setActiveStep(3);
            setConfigValid(true);
        }
        if (view === "branch") {
            if (errors["branch.id"] || errors["branch.Name"])
                return setActiveStep(2);
            if (errors.separateAgencies) return setActiveStep(3);
            setActiveStep(3);
            setConfigValid(true);
        }
        if (view === "agency") {
            if (errors["agency.id"] || errors["agency.Name"])
                return setActiveStep(2);
            setActiveStep(2);
            setConfigValid(true);
        }
    }

    function scrollToTop() {
        (settingsContainerRef?.current as HTMLDivElement).scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
        });
    }
    useEffect(() => {
        if (shouldGenerateReport) return;
        scrollToTop();
    }, [shouldGenerateReport]);
    console.log("config valid", configValid);
    console.log("shouldgen", shouldGenerateReport);
    return (
        <div>
            <div className="flex flex-col gap-4">
                <div ref={settingsContainerRef}>
                    <UsageReportSettings
                        {...{
                            myUserData: myUserDataQuery.data,
                            config: config as UsageReportConfig,
                            setConfig: setConfig as (
                                prev: UsageReportConfig
                            ) => UsageReportConfig,
                            errors,
                            setConfigValid,
                            configValid,
                            activeStep,
                            setActiveStep,
                            branches: branchesQuery.data || [],
                            agencies: agenciesQuery.data || [],
                            shouldGenerateReport,
                            setShouldGenerateReport,
                        }}
                    />
                </div>

                <AnimatePresence presenceAffectsLayout mode="wait">
                    {shouldGenerateReport && (
                        <motion.div
                            transition={{ duration: 0.7 }}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                        >
                            {
                                {
                                    national: (
                                        <NationalReport
                                            NationalReportComponent={
                                                NationalGAOReportContent
                                            }
                                            AgencyReportComponent={
                                                AgencyGAOReportContent
                                            }
                                            BranchReportComponent={
                                                BranchGAOReportContent
                                            }
                                            {...reportData}
                                            config={config}
                                        />
                                    ),
                                    branch: (
                                        <BranchReport
                                            AgencyReportComponent={
                                                AgencyGAOReportContent
                                            }
                                            BranchReportComponent={
                                                BranchGAOReportContent
                                            }
                                            {...reportData}
                                            config={config}
                                        />
                                    ),
                                    agency: (
                                        <AgencyReport
                                            AgencyReportComponent={
                                                AgencyGAOReportContent
                                            }
                                            {...reportData}
                                            config={config}
                                        />
                                    ),
                                }[config.view]
                            }
                        </motion.div>
                    )}
                </AnimatePresence>
                {/* { shouldGenerateReport && (
                <>
                    {config.view === 'national'}
                    

                </>
            )} */}
            </div>
        </div>
    );
};
