import { cn } from '@/lib/utils';
import { rangeArray } from '@/util/miscUtils';
import React from 'react';

export const ListSkeleton = ({numRows=8, className, ...props}) => {
    return rangeArray(0,numRows - 1).map( i =>(
        <div key={i} className={cn('skeleton w-full h-12',className)}>
        </div>
    )
    );
};
