import { postPromise } from "@/api/promises";
import { ROUTE_IDS } from "@/api/routeIDs";
import { composeFormData, convertObjFieldsToString } from "@/util/apiUtils";
import { useMutation } from "@tanstack/react-query";

type SendSignupInviteParams = {
    email: string,
    usertype: string,
    nodeids: string[],
    
}

function sendSignupInvite(params: SendSignupInviteParams) {
    return postPromise(import.meta.env.VITE_API_BASE_URL, convertObjFieldsToString({...params, type: ROUTE_IDS.SEND_SIGNUP_INVITE}), {json: false})
}

export const useSendSignupInvite = ({onSuccess, ...props}) => {
    const mutation = useMutation({
        mutationKey: ['sendSignupInvite'],
        mutationFn: sendSignupInvite,
        onSuccess: (data: any, req) => {
            if (data?.status?.includes?.('not')) throw new Error(data.status)
            onSuccess && onSuccess(data, req)
        },
        ...props
    })
    return mutation
}