import { Button } from '@/components/ui/button';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { cn } from '@/lib/utils';
import { motion } from 'framer-motion';
import { Edit, GripHorizontal, ListOrdered, Minus, Plus } from 'lucide-react';
import { useState } from 'react';

export const SettingsManagementGuide = () => {
    const [showGuide, setShowGuide] = useState(false);
    const toggleGuideVisibility = () => setShowGuide(!showGuide);
    return (
        <div>
            <Button 
                className={cn(
                    'shadow-md w-40',
                    showGuide && 'border-b-background rounded-b-none z-10 -mb-0.5 shadow-none')} 
                onClick={toggleGuideVisibility}
                >
                {showGuide ? (
                        <>Hide guide <Minus className='ml-2 h-4 w-4' /></>
                    ) : (
                        <>Show guide <Plus className='ml-2 h-4 w-4' /></>
                    )}
            </Button>
            <motion.div
                layout='position'
                variants={guideVariants}
                initial={false}
                animate={showGuide ? 'open' : 'closed'}
                className={cn(
                    'border  flex-shrink-0 max-w-fit bg-background rounded-md rounded-tl-none shadow-md overflow-hidden',
                )}
                >
                    <motion.div layout='position'>
                        <Tabs defaultValue='editing' className='w-[30rem] px-4 pb-4 pt-1'>
                            <TabsList className='w-full'>
                                <TabsTrigger className='flex-grow text-md tracking-wide' value='editing'>
                                    <Edit className='w-5 h-5 mr-2'/>Editing Entries
                                </TabsTrigger>
                                <TabsTrigger className='flex-grow text-md tracking-wide' value='reordering'>
                                    <GripHorizontal className='w-5 h-5 mr-2' />Reordering Entries
                                </TabsTrigger>
                            </TabsList>
                            <TabsContent value='editing' className='bg-background border rounded-md mt-2 !px-2 !py-0'>
                                <GuideRow index={1}>
                                    Click the <Edit className='inline w-4 h-4 mb-0.5'/> button on a FAQ entry to start editing it
                                </GuideRow>
                                <GuideRow index={2}>
                                    Change the name of the FAQ entry by typing in the input field
                                </GuideRow>
                            </TabsContent>
                            <TabsContent value='reordering' className='bg-background border rounded-md mt-2 !px-2 !py-0'>
                                <GuideRow index={1}>
                                    Click and drag the <GripHorizontal className='inline w-5 h-5 mb-0.5'/> icon to reorder the FAQ entries
                                </GuideRow>
                                <GuideRow index={2}>
                                    Click the "Save New Order <ListOrdered className='inline'/>" button to save the entries in the new order
                                </GuideRow>
                            </TabsContent>
                        </Tabs>
                    </motion.div>
            </motion.div>
        </div>
    );
};



const guideVariants = {
    open: {width: 'auto', height:'auto'},
    closed: { width: 0, height: 0}
}
const GuideRow = ({index, children}) => (
    <p className='mb-2 last:mb-0 flex'>
        <span className='font-semibold w-6 flex-shrink-0'>{index}.</span> <span>{children}</span>
    </p>
)