import { getAgenciesInBranch, getAgenciesInBranchQueryOptions } from "@/api/agencies/getAgenciesInBranch"
import useAllBranches from "@/api/branches/getAllBranches"
import useMyUserData from "@/api/user/getMyUserData"
import { useQueries } from "@tanstack/react-query"
import { useCallback, useEffect, useRef, useState } from "react"

export const useMyAuthorizedBranches = ({allBranchesQuery, myUserDataQuery}) => {
    const fallbackDataRef = useRef([])
    const {isAgencyUser} = myUserDataQuery.data || {}
    const propsPending = (!isAgencyUser && allBranchesQuery.isPending) || myUserDataQuery.isPending
    const filteredBranches = isAgencyUser
        ? []
        : allBranchesQuery.data.filter(b => myUserDataQuery.data?.nodeids?.includes(b.id) || myUserDataQuery.data?.isAdmin)
    const composedBranchesQuery = useQueries({
        queries: propsPending
            ? fallbackDataRef.current
            : filteredBranches
                .map(b => getAgenciesInBranchQueryOptions(b.id)), 
        combine: useCallback((queries) => {
            const agenciesPending = queries.some(q => q.isPending)
            if (agenciesPending) return {data: fallbackDataRef.current, isPending: true}
            const branches = filteredBranches.map((b, idx) => ({
                ...b,
                agencies: queries[idx]?.data,
            }))
            return {data: branches, isPending: false}
        },[propsPending, allBranchesQuery.data, myUserDataQuery.data])
    })

    return composedBranchesQuery
}
