import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { rangeArray } from '@/util/miscUtils';
import React from 'react';

export const HelpSettingsEntriesSkeleton = ({numSkeletons=8}) => {
    return (
        <>
            <div className="w-full flex justify-center">
                <div className="loading text-primary loading-infinity loading-xl self-center"/>
            </div>
            {rangeArray(1,numSkeletons).map( (i) =>
                <div key={i} className='h-20  mt-2 flex items-center justify-between p-4 border rounded-md bg-background overflow-hidden'>
                    <div>
                        <div className='font-medium skeleton h-4 w-32'/>
                        <div className="text-sm block hover:underline underline-offset-2 hover:cursor-pointer text-muted-foreground skeleton w-24 h-3 mt-2"/>
                    </div>
                    <div>
                        <div className='flex gap-4'>
                            <Button variant='skeleton'size='icon'/>
                            <Button variant='skeleton'size='icon'/>
                            <Separator orientation='vertical' className='h-10'/>
                            <Button variant='skeleton'size='icon'/>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
};
