import { RadialProgress } from "@/components/progress/RadialProgress";
import { RankCell } from "@/components/tables/cells/RankCell";
import { zeroNaN } from "@/util/miscUtils";
import { createColumnHelper, filterFns } from "@tanstack/react-table";

const columnHelper = createColumnHelper();

const stringFilter = filterFns.includesString;
const numRangeFilter = filterFns.inNumberRange;
const multiSelectFilter = filterFns.arrIncludesSome;
const strictStringFilter = filterFns.includesString.autoRemove;

export const BranchDemographicRatingsCols = [
    columnHelper.accessor((row) => row.Name, {
        id: "Name",
        size: 100,
        filterFn: stringFilter,
        cell: ({ row, getValue }) => (
            <RankCell index={row.index + 1}>
                <span className="text-md">{getValue()}</span>
            </RankCell>
        ),
    }),
    columnHelper.accessor((row) => row.withProfileCount, {
        id: "Profiles",
        size: 100,
        filterFn: stringFilter,
        cell: ({ row }) => (
            <Activity
                row={row.original}
                ratio={row.getValue("Percent Completion")}
            />
        ),
        header: (info) => <span>Activity</span>,
    }),
    columnHelper.accessor(
        (row) =>
            (
                zeroNaN(
                    row.withProfileCount /
                        (row.withProfileCount + row.withoutProfileCount)
                ) * 100
            ).toFixed(1),
        {
            id: "Percent Completion",
            meta: { hiddenCol: true },
            filterFn: stringFilter,
            cell: (info) => <PercentActivity ratio={info.getValue()} />,
            header: (info) => <></>,
        }
    ),
];

const Activity = ({ row, ratio }) => (
    <div className="flex items-center gap-10 justify-between">
        <div className="grid grid-cols-[fit-content(30px),fit-content(30px)]  gap-x-2 text-sm font-medium">
            <p className="w-fit text-[#FD5B41]">{row.withProfileCount}</p>
            <p className="w-fit ml-2 whitespace-nowrap">Client profiles</p>

            <p className="w-fit text-[#8E9AAE]">{row.withoutProfileCount}</p>
            <p className="w-fit ml-2 whitespace-nowrap">
                Numbers without profiles
            </p>
        </div>
        <PercentActivity ratio={ratio} />
    </div>
);

export const PercentActivity = ({ ratio }) => {
    return (
        <>
            <RadialProgress
                ratio={ratio / 100}
                className={"h-[4rem] text-orange"}
            >
                <span className="text-primary">{ratio}%</span>
            </RadialProgress>
        </>
    );
};
// const ActiveUsers = ({withProfileCount}) => (

// )
