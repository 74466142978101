export const barOptions = ({
    animations=true,
    stackedX = false,
    stackedY = false,
    showTitle = false,
    title,
    showLegend = true,
    alignTitle = "center",
    titleFontSize,
    legendPos,
    legendDotSize = 20,
    titlePos,
    backgroundColor,
    showAttachedLabel = 12,
    attachedLabelFontSize,
    ...additionalConfig
}) => ({
    maintainAspectRatio: false,
    responsive: true,
    barPercentage: 0.9,
    categoryPercentage: 0.9,
    minBarLength: 5,
    layout: {
        padding: {
            top: 30,
        },
    },
    animations: animations,
    plugins: {
        title: {
            display: showTitle,
            text: title,
            align: alignTitle,
            position: titlePos,
            font: {
                size: titleFontSize === "" ? 20 : titleFontSize,
            },
            color: "black",
            fullSize: true,
        },
        legend: {
            display: showLegend,
            // fullSize:true,
            position: legendPos,
            align: "center",
            labels: {
                font: {
                    size: 20,
                },
                boxWidth: legendDotSize,
                boxHeight: legendDotSize,
                // borderRadius:100,
                // useBorderRadius: true,tjs rela
                usePointStyle: true,
                pointStyle: "rectRounded",
            },
        },
        customCanvasBackgroundColor: {
            color: backgroundColor,
        },
        datalabels: {
            anchor: "end",
            labels: {
                value: {
                    display: showAttachedLabel,
                    align: function (ctx) {
                        // console.log('align',ctx.chart.scale);
                        return "top";
                    },
                    color: function (ctx) {
                        return "white";
                    },
                    backgroundColor: function (ctx) {
                        var color = ctx.dataset.backgroundColor;
                        return color;
                    },
                    formatter: function (value, ctx) {
                        // console.log('formatter', value,ctx);
                        return value;
                    },
                    font: {
                        weight: "bolder",
                        size: attachedLabelFontSize,
                    },
                    // borderColor: 'white',
                    borderWidth: 2,
                    borderRadius: 4,
                    padding: 6,
                    offset: 2,
                },
            },
        },
    },
    scales: {
        x: {
            display: false,
            stacked: stackedX,
            // grid: {
            //     display: false,
            //     drawBorder: false,
            // },
            // ticks: {
            //     font: {
            //         size: 20
            //     }
            // }
        },
        y: {
            stacked: stackedY,
            grid: {
                // display: false,
                drawBorder: false,
            },
            ticks: {
                font: {
                    size: 18,
                },
            },
        },
    },
    ...additionalConfig,
});

export const demoReportBarOptions = (additionalConfig = {}) => {
    return barOptions({
        showTitle: false,
        showLegend: false,
        alignTitle: "center",
        legendPos: "bottom",
        backgroundColor: "transparent",
        legendDotSize: 15,
        legendFontSize: "12",
        displayTooltip: false,
        showAttachedLegend: false,
        showAttachedLabel: true,
        borderRadius: 5,
        spacing: 0,
        // cutout: '80%',
        hoverOffset: 0,
        layout: {
            padding: {
                left: 15,
                right: 10,
                top: 40,
            },
        },
        ...additionalConfig,
    });
};
