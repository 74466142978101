import { Auth, loginAction } from '@/routes/Auth';
import { ForgotPassword } from '@/routes/Auth/ForgotPassword';
import { Login } from '@/routes/Auth/Login';
import { ResetPassword } from '@/routes/Auth/ResetPassword';
import { Signup, SignupLoader } from '@/routes/Auth/Signup';
import { NoCode } from '@/routes/Auth/Signup/NoCode';
import { ErrorFallback } from '@/routes/ErrorFallback';
import HowTo from '@/routes/HowTo';
import { Admin, adminRouteLoader } from '@/routes/Main/Admin';
import { AllAgencies, AllAgenciesLoader } from '@/routes/Main/Admin/AllAgencies';
import { AllBranches, AllBranchesLoader } from '@/routes/Main/Admin/AllBranches';
import { AllClients, AllClientsLoader } from '@/routes/Main/Admin/AllClients';
import { HelpSettings } from '@/routes/Main/Admin/HelpSettings';
import { Users, UsersLoader } from '@/routes/Main/Admin/Users';
import { Agencies, AgenciesLoader } from '@/routes/Main/Agencies';
import { AgencyDetails, AgencyDetailsLoader } from '@/routes/Main/Agencies/AgencyDetails';
import { AgencyMailboxes, AgencyMailboxesLoader } from '@/routes/Main/Agencies/AgencyMailboxes';
import { AllPhoneNumbers, allPhoneNumbersLoader } from '@/routes/Main/AllPhoneNumbers';
import { Branches, BranchesLoader } from '@/routes/Main/Branches';
import { BranchDetails, BranchDetailsLoader } from '@/routes/Main/Branches/BranchDetails';
import { Clients, ClientsLoader } from '@/routes/Main/Clients';
import { ClientDetails, ClientDetailsLoader } from '@/routes/Main/Clients/ClientDetails';
import { Dashboard } from '@/routes/Main/Dashboard';
import { Help } from '@/routes/Main/Help';
import { Hierarchy } from '@/routes/Main/Hierarchy';
import { PhoneNumbers } from '@/routes/Main/PhoneNumbers';
import { Reporting, ReportingLoader } from '@/routes/Main/Reporting';
import { CustomReport } from '@/routes/Main/Reporting/CustomReport';
import { Presets } from '@/routes/Main/Reporting/Presets';
import { DemographicsReport } from '@/routes/Main/Reporting/Presets/DemographicsReport';
import { GoalsAndOutcomeReport } from '@/routes/Main/Reporting/Presets/GoalsAndOutcomesReport';
import { ThirtyDayInactivity } from '@/routes/Main/Reporting/Presets/ThirtyDayInactivity/ThirtyDayInactivity';
import { UsageReport } from '@/routes/Main/Reporting/Presets/UsageReport';
import { Testing } from '@/routes/Main/Testing';
import { Unauthorized } from '@/routes/Main/Unauthorized';
import { MainRoute, MainRouteLoader } from '@/routes/MainRoute';
import { NotFound } from '@/routes/NotFound';
import { Navigate, Outlet, createBrowserRouter } from 'react-router-dom';

export const router = (queryClient) => createBrowserRouter([
  {
    path: '/',
    element: <MainRoute />,
    loader: MainRouteLoader(queryClient),
    id: 'main',
    errorElement: <NotFound/>,
    // ErrorBoundary: (e) => <div>{'error!'}</div>,
    // errorElement: <div>Error!!!</div>,
    children: [
      {
        element: <Dashboard/>,
        index: true,
      },
      {
        path: '/unauthorized',
        element: <Unauthorized/>,
      },
      // –––––––––––––––  ADMIN ––––––––––––––– 
      {
        path: 'admin',
        element: <Admin />,
        loader: adminRouteLoader(queryClient),
        children: [
          {
            path: 'branch',
            element: <AllBranches />,
            // children:
            loader: AllBranchesLoader(queryClient),
          },
          {
            path: 'agency',
            element: <AllAgencies />,
            // children:
            loader: AllAgenciesLoader(queryClient),
          },
          {
            path: 'client',
            element: <AllClients />,
            // children:
            loader: AllClientsLoader(queryClient),
          },
          {
            path: 'phonenumber',
            element: <AllPhoneNumbers />,
            loader: allPhoneNumbersLoader(queryClient),
          },      
          {
            path: 'users',
            element: <Users />,
            loader: UsersLoader(queryClient)
          },
          {
            path: 'help-settings',
            element: <HelpSettings />,
          },
        ]
      },
      // –––––––––––––––  NON ADMIN ––––––––––––––– 
      {
        path: 'users',
        element: <Users />,
        // children:
        loader: UsersLoader,
      },
      {
        path: 'branch',
        element: <Branches />,
        // children:
        loader: BranchesLoader(queryClient),
      },
      {
        path: 'branch/:branchID',
        element: <BranchDetails />,
        // children:
        loader: BranchDetailsLoader(queryClient),
      },
      {
        path: 'agency',
        element: <Agencies />,
        loader: AgenciesLoader(queryClient),
      },
      {
        path: 'agency/:branchID/:agencyID',
        element: <AgencyDetails />,
        // action
        loader: AgencyDetailsLoader(queryClient),
      },
      {
        path: 'agency/:agencyID/mailboxes',
        element: <AgencyMailboxes />,
        // action
        loader: AgencyMailboxesLoader(queryClient),
      },
      {
        path: 'phonenumber',
        element: <PhoneNumbers />,
        // children:
        // loader:,
      },
      {
        path: 'hierarchy',
        element: <Hierarchy />,
        // children:
        // loader:,
      },
      {
        path: 'client',
        element: <Clients />,
        loader: ClientsLoader(queryClient),
      },
      {
        path: 'client/:branchID/:agencyID/:objectID',
        element: <ClientDetails />,
        loader: ClientDetailsLoader(queryClient),
      },
      // –––––––––––––––  Reporting ––––––––––––––– 
      {
        path: 'reporting',
        element: <Reporting />,
        loader: ReportingLoader(queryClient),
        children: [
          {
            path: 'presets',
            element: <Presets/>,
            children: [
              {
                path: '30dayinactivity',
                element: <ThirtyDayInactivity />,
                // children:
                // loader:,
              },
              {
                path: 'usage',
                element: <UsageReport />,
                children: [
                  
                ]
                // loader:,
              },
              {
                path: 'demographics',
                element: <DemographicsReport />,
                children: [
                  
                ]
                // loader:,
              },
              {
                path: 'goalsandoutcomes',
                element: <GoalsAndOutcomeReport />,
                children: [
                  
                ]
                // loader:,
              },
            ]
          },
          {
            path: 'customreport',
            element: <CustomReport />,
            // children:
            // loader:,
          },
        ]
        // loader:,
      },
      {
        path: 'testing',
        element: <Testing />,
        // children:
        // loader:,
      },
      {
        path: '/help',
        element: <Help />
      }
    ]
  },

  // –––––––––––––––  AUTH ––––––––––––––– 
  {
    path: '/auth',
    element: <Auth />,
    // action: loginAction(queryClient),
    // children:
    // loader: Auth.loader,
    children: [
      {
        path: 'login',
        index: true,
        element: <Login />,
      },
      {
        path: 'forgot-password',
        element: <ForgotPassword />,
        // children:
        // loader: Auth.loader,
      },
      {
        path: 'reset-password',
        element: <ResetPassword />,
        // children:
        // loader: Auth.loader,
      },
      {
        path: 'signup',
        element: <Signup />,
        loader: SignupLoader(queryClient),
      },
      {
        path: 'no-signup-code',
        element: <NoCode />
      },
      {
        path:'*',
        element: <Navigate to='/auth/login' />,
      },
    ]
  },
  {
    path: '/how-to',
    element: <HowTo />
  },
  {
    path:'/debug',
    element:<Testing/>
  },
  {
    path: '*',
    element: <NotFound />,
  },
    
    
],{
       
  })