import { postPromise } from "@/api/promises";
import { ROUTE_IDS } from "@/api/routeIDs";
import { composeFormData } from "@/util/apiUtils";
import { JetDBEnums } from "@/util/constants";
import { queryOptions, useQuery } from "@tanstack/react-query";

const getAllEnums = () =>
    // new Promise(r => r(mockEnums));
    postPromise(
        import.meta.env.VITE_API_BASE_URL,
        composeFormData({}, ROUTE_IDS.GET_ALL_ENUMS)
    );

export const useAllEnums = () => {
    const query = useQuery<JetDBEnums, Error>(getAllEnumsQueryOptions);
    return query;
};
export const getAllEnumsQueryOptions = queryOptions({
    queryKey: ["enum", ROUTE_IDS.GET_ALL_ENUMS],
    queryFn: getAllEnums,
    select,
});

function select(data: JetDBEnums) {
    const clone = structuredClone(data);
    clone.ageRange.find((e) => e.Name === "46 to 44").Name = "26 to 44";
    clone.clientGoals.find((e) => e.Name === "Other").Name = "Other";
    return clone;
}
