import { refreshToken } from '@/api/auth/refreshToken';
import { useAllEnums } from '@/api/enum/getAllEnums';
import useAllNumbers from '@/api/numbers/getAllNumbers';
import { getPromise } from '@/api/promises';
import { getMyUserDataQueryOptions } from '@/api/user/getMyUserData';
import { ClientForm } from '@/components/forms/ClientForm';
import { Button } from '@/components/ui/button';
import { useToast } from '@/components/ui/use-toast';
import { ClientDetails } from '@/routes/Main/Clients/ClientDetails';
import { getSubscriberMetrics } from '@/util/chartUtils';
import { MILLISEC } from '@/util/constants';
import { getCookie, setCookie } from '@/util/cookieUtils';
import { useQueryClient } from '@tanstack/react-query';
import React, { useMemo, useTransition } from 'react';

const VIEWS = {
    ONE: 'one',
    TWO: 'two',
    THREE: 'three',
    FOUR: 'four',
}

const getInbox = (objectid) => {
    return getPromise('https://192.168.1.22:8443/vmrest/mailbox/folders/inbox?userobjectid=42213863-2c8b-4eb5-a17b-1ec0e1884eaf', {}, {
        auth: {
            username: 'dmcleod-admin',
            password: 'P@ssw0rd!'
        },
    },false)
}
const getAllInboxes = (objectids) => {
    const promises = []
    for (const id of objectids) {
        promises.push(getInbox(id))
    }
}

export const Testing = ({...props}) => {
    const client = useQueryClient()
    const [viewToShow, setViewToShow] = React.useState(VIEWS.ONE)

    // const subscriberQuery = useAllNumbers()
    // const subscriberMetrics = useMemo(() => {
    //     return getSubscriberMetrics(subscriberQuery.data)
    // },[])
    // const numbersQuery = useAllNumbers()
    // const enumsQuery = useAllEnums()
    // const [showError, setShowError] = React.useState(null)
    // function test() {
    //     getAllInboxes(numbersQuery.data.map(n => n.Name))
    // }
    // const {successToast} = useToast()

    // async function refresh() {
    //     const r = await refreshToken()
    //     successToast('Refreshed')
    //     console.log('refresh results', r);
    // }
    // async function getmydata() {
    //     client.invalidateQueries({queryKey: ['user','me']})
    //     const m = await client.ensureQueryData(getMyUserDataQueryOptions)
    //     successToast('Got Data')
    //     console.log('my data', m);

    // }
    // async function setTokenToExpiredToken() {
    //     setCookie('token', 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjEwMDQiLCJ0eXBlIjoicmVmcmVzaCIsImlhdCI6MTcxOTE5NDg0NCwiaXNzIjoiY3ZtYXBpLmpldHNvbi53b3JrcyIsIm5iZiI6MTcxOTE5NDg0NCwiZXhwIjoxNzE5MjA5MjQ0fQ==.+rmj/ybqrZ97FaWNHOIY5U1pyGi6Y80TpLLudA5yZUY=',MILLISEC.MIN_15)
    // }
    return (
        <div className='w-full'>
        {/* <div className="flex flex-col gap-4 max-w-[40rem]">
            <p className='whitespace-pre-wrap break-words' >Token: {getCookie('token')}</p>
            <p className='whitespace-pre-wrap break-words'>refresh: {getCookie('refreshToken')}</p>
        </div> */}
        {/* <div className="flex gap-4">
            <Button variant='outline' onClick={refresh}>Refresh</Button>
            <Button variant='outline' onClick={getmydata}>Get My Data</Button>
            <Button variant='outline' onClick={setTokenToExpiredToken}>Set Token to Expired</Button>
        </div> */}
        <div className="bg-base container">
            <ClientDetails/>
        </div>
        testing
            {/* <ChartCard
                chartType='pie'
                datasetEnum={USAGE}
                chartName='Subscriber Activity'
                renderFn={(chartRef, config) => 
                    <ChartContainer>
                        <SubscriberActivityPie

                            subscribers={subscriberQuery.data}
                            chartRef={chartRef}
                            config={config}
                        />
                    </ChartContainer>
                }
            /> */}
            <div className="flex gap-2">
                {/* <Button variant='outline' className={cn('px-4 py-2 rounded-xl mt-2', numbersQuery.isPending && 'bg-destructive')} onClick={test}>test</Button> */}
                {/* <Button variant='destructive' className='px-4 py-2 rounded-xl  text-white mt-2' onClick={throwTestError}>error</Button> */}
                <button className='px-4 py-2 rounded-xl text-white mt-2' onClick={() => setViewToShow(VIEWS.THREE)}>Three</button>
                <button className='px-4 py-2 rounded-xl text-white mt-2' onClick={() => setViewToShow(VIEWS.FOUR)}>Four</button>
            {/* <Calendar numberOfMonths={2} mode='range' selected={date} onSelect={setDate} />  */}
             </div>
            {/* <div className="grid w-full px-10 xl:px-20 mt-10">
                {{
                    one: <ClientForm />,
                }[viewToShow]}
            </div> */}
        </div>
    );
};
