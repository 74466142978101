import { getManualPieConfig } from '@/routes/Main/Reporting/utils/ChartConfigs';
import React, { useMemo } from 'react';
import { Doughnut, Pie } from 'react-chartjs-2';
import { UsageReportData } from '../useUsageReportData';
import { customDataLabels, doughnutOptions } from '../../../utils/doughnutOptions';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { commaNumber } from '../../../../../../util/textUtils';
import { Chart } from 'chart.js';
import CountUp from 'react-countup';
import { PieChartSkeleton } from '@/components/skeletons/PieChartSkeleton';

type UsageMessagesPieProps = {
    data: UsageReportData,
};
export const UsageMessagesPie = React.forwardRef<Chart<"doughnut", any, unknown>, UsageMessagesPieProps>(({ data, ...props }, ref) => {
    const { totalRead, totalUnread } = data;
    const config = useMemo(() => {
        return getManualPieConfig(
            [totalRead, totalUnread],
            ['#FD5B41', '#8E9AAE'],
            ['Listened to', 'Not listened to',],
        );
    }, [data]);
    const options = useMemo(() => {
        return doughnutOptions({
            animations: !props?.isExporting,
            showTitle: false,
            showLegend: false,
            alignTitle: 'center',
            legendPos: 'bottom',
            backgroundColor: 'transparent',
            legendDotSize: '20',
            legendFontSize: '16',
            displayTooltip: false,
            showAttachedLegend: false,
            showAttachedLabel: false,
            displayTailedAttachedLabel: true,
            tailedAttachedLabelFontSize: 16,
            tailedAttachedLabelStrokeWidth: 2,
            tailedAttachedLabelYOffset: 25,
            tailedAttachedLabelXOffset: 15,
            borderRadius: 0,
            spacing: 0,
            cutout: '80%',
            hoverOffset: 0,
            layout: { padding: {
                left: 15,
                right: 10
            } },
        } as any);
    }, []);
    return config ? (
        <div className='w-full min-h-fit max-h-none'>
            <span className='font-semibold text-xl'>Message activity</span>
            <div className='w-[99%] h-auto min-h-[20rem] relative'>
                <Doughnut
                    ref={ref}
                    options={options as any}
                    plugins={[ChartDataLabels, customDataLabels]}
                    {...config}
                />
                <div className="absolute flex flex-col items-center left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                    <CountUp className='font-semibold' start={0} end={totalRead + totalUnread} separator=','/>
                    <span className='font-light mt-1'>Total Messages</span>
                </div>
            </div>
            <div className='flex flex-col gap-1 justify-center'>
                <div className="flex items-center gap-2">
                    <div className="w-4 h-4 rounded-md bg-[#FD5B41]"></div>
                    <span className='font-medium'>
                        Listened to
                    </span>
                </div>
                <div className="flex items-center gap-2">
                    <div className="w-4 h-4 rounded-md bg-[#8E9AAE]"></div>
                    <span className='font-medium'>
                        Not listened to
                    </span>
                </div>
            </div>
        </div>
    ) : (
        <PieChartSkeleton/>
    );
});

UsageMessagesPie.displayName = 'UsageUserCountPie';