import { Table } from "@/components/tables/Table";
import { clientWithoutProfileCols } from "@/components/tables/columnHelpers/clientWithoutProfileCols";
import { SimpleCard } from "@/components/ui/SimpleCard";
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "@/components/ui/accordion";
import { cn } from "@/lib/utils";
import { AgeDoughnut } from "@/routes/Main/Reporting/Presets/DemographicsReport/components/charts/AgeDoughnut";
import { DemoStats } from "@/routes/Main/Reporting/Presets/DemographicsReport/components/charts/DemoStats";
import { GenderPie } from "@/routes/Main/Reporting/Presets/DemographicsReport/components/charts/GenderPie";
import { MiscCharacteristicsChart } from "@/routes/Main/Reporting/Presets/DemographicsReport/components/charts/MiscCharacteristicsChart";
import { DemographicAgencyRankings } from "@/routes/Main/Reporting/Presets/DemographicsReport/components/tables/DemographicAgencyRankings";
import { DemographicBranchRankings } from "@/routes/Main/Reporting/Presets/DemographicsReport/components/tables/DemographicBranchRankings";
import { DemographicsReportData } from "@/routes/Main/Reporting/Presets/DemographicsReport/useDemographicsReportData";
import { UsageReportConfig } from "@/routes/Main/Reporting/Presets/UsageReport";
import { CancelExportButton } from "@/routes/Main/Reporting/components/CancelExportButton";
import { ExportButton } from "@/routes/Main/Reporting/components/ExportButton";
import { A4_CONTENT_WIDTH, A4_MARGIN, pdfItemPadding } from "@/util/constants";
import {
    addDefaultPDFTitlePage,
    addTableToPDF,
    contrainDimensionsToA4,
    elementToCanvasAndURL,
    getNewPDFWithDefaultConf,
} from "@/util/fileUtils";
import { JCheckbox } from "@/wrappers/ui/JCheckbox";
import { useEffect, useRef, useState } from "react";

type NationalReportContentProps = {
    data: DemographicsReportData;
    isPending?: boolean;
    config: UsageReportConfig;
    isExporting?: boolean;
    onExportFinish?: () => void;
};

export const NationalReportContent = (props: NationalReportContentProps) => {
    const {
        data,
        isPending,
        isExporting: controlledIsExporting,
        onExportFinish,
        config,
    } = props || {};
    const [hideUnknown, setHideUnknown] = useState(false);
    const [isExporting, setIsExporting] = useState(false);
    const pdfRef = useRef(null);

    const branchRankingsTableRef = useRef(null);
    const agencyRankingsTableRef = useRef(null);
    const withProfilesFilledInRef = useRef(null);
    const withoutProfilesFilledInRef = useRef(null);

    const statsRef = useRef(null);
    const ageRangeChartRef = useRef(null);
    const genderChartRef = useRef(null);
    const miscCharacteristicsChartRef = useRef(null);

    useEffect(() => {
        setIsExporting(controlledIsExporting);
    }, [controlledIsExporting]);

    useEffect(() => {
        isExporting && exportReport();
    }, [isExporting]);

    async function exportReport() {
        const pdf = getNewPDFWithDefaultConf();
        pdfRef.current = pdf;
        addDefaultPDFTitlePage(pdf, "Goals & Outcomes", "Nation-Wide");
        // Get Images
        const promises = [];
        promises.push(elementToCanvasAndURL(statsRef.current));
        promises.push(elementToCanvasAndURL(ageRangeChartRef.current));
        promises.push(elementToCanvasAndURL(genderChartRef.current));
        promises.push(
            elementToCanvasAndURL(miscCharacteristicsChartRef.current)
        );
        let [[sCanv, sURL], [aCanv, aURL], [gCanv, gURL], [mCanv, mURL]] =
            await Promise.all(promises);
        // Miscellaneous Characteristics
        const { width: mW, height: mH } =
            miscCharacteristicsChartRef.current.getBoundingClientRect();
        const [mFW, mFH] = contrainDimensionsToA4(
            mW,
            mH,
            A4_CONTENT_WIDTH,
            400
        );
        pdf.addImage(mURL, "png", A4_MARGIN, 220, mFW, mFH);
        const yAfterMisc = 220 + mFH + pdfItemPadding;
        // Stats
        const { width: sW, height: sH } =
            statsRef.current.getBoundingClientRect();
        const [sFW, sFH] = contrainDimensionsToA4(
            sW,
            sH,
            A4_CONTENT_WIDTH,
            A4_CONTENT_WIDTH / 3 - pdfItemPadding
        );
        pdf.addImage(
            sURL,
            "png",
            A4_MARGIN,
            yAfterMisc + pdfItemPadding,
            sFW,
            sFH
        );
        const yAfterStats = yAfterMisc + sFH + pdfItemPadding;
        // Age Range
        const { width: aW, height: aH } =
            ageRangeChartRef.current.getBoundingClientRect();
        const [aFW, aFH] = contrainDimensionsToA4(
            aW,
            aH,
            A4_CONTENT_WIDTH / 3 - pdfItemPadding,
            undefined
        );
        pdf.addImage(
            aURL,
            "png",
            A4_MARGIN + sFW + pdfItemPadding,
            yAfterMisc + pdfItemPadding,
            aFW,
            aFH
        );
        // Gender
        const { width: gW, height: gH } =
            genderChartRef.current.getBoundingClientRect();
        const [gFW, gFH] = contrainDimensionsToA4(
            gW,
            gH,
            A4_CONTENT_WIDTH / 3 - pdfItemPadding,
            undefined
        );
        pdf.addImage(
            gURL,
            "png",
            A4_MARGIN + sFW + +aFW + pdfItemPadding,
            yAfterMisc + pdfItemPadding,
            gFW,
            gFH
        );

        // Images Cleanup
        sCanv.remove();
        sCanv = null;
        aCanv.remove();
        aCanv = null;
        gCanv.remove();
        gCanv = null;
        mCanv.remove();
        mCanv = null;

        // Tables
        await addTableToPDF(
            pdf,
            withProfilesFilledInRef.current,
            "Clients With Profiles Filled In"
        );
        await addTableToPDF(
            pdf,
            withoutProfilesFilledInRef.current,
            "Clients Without Profiles Filled In"
        );
        await addTableToPDF(
            pdf,
            branchRankingsTableRef.current,
            "Branches Ranked by Filled-In Profiles Ratio"
        );
        await addTableToPDF(
            pdf,
            agencyRankingsTableRef.current,
            "Agencies Ranked by Filled-In Profiles Ratio"
        );

        pdf.save(`demographics-report-nation-wide.pdf`);
        pdfRef.current = null;
        setIsExporting(false);
        onExportFinish();
    }
    return (
        <div className="flex flex-col w-full">
            <div className="flex gap-2">
            <ExportButton {...{isExporting, setIsExporting,isPending}}>Export report</ExportButton>
            <CancelExportButton isExporting={isExporting} onClick={() => setIsExporting(false)}/>
                <JCheckbox
                    className="mb-8 bg-background w-fit py-[0.6rem]"
                    value={hideUnknown}
                    handleChange={setHideUnknown}
                    label="Hide unknown values?"
                />
            </div>
            {/* <span className="font-semibold text-xl mb-2">National usage report</span> */}
            <div
                className={cn(
                    "flex flex-col mb-6 gap-4",
                    isPending && "skeletonify"
                )}
            >
                <div className="flex gap-4  min-h-fit">
                    <SimpleCard
                        ref={statsRef}
                        className="flex-1 skelechild min-h-fit max-h-none flex flex-col"
                    >
                        <span className="font-semibold text-xl">
                            Profile Stats
                        </span>
                        <DemoStats data={data} />
                    </SimpleCard>
                    <div className="flex-1 flex gap-[inherit] min-h-full">
                        <SimpleCard
                            ref={ageRangeChartRef}
                            className="skelechild flex-grow h-full"
                        >
                            <AgeDoughnut hideUnknown={hideUnknown} {...props} />
                        </SimpleCard>
                        <SimpleCard
                            ref={genderChartRef}
                            className="skelechild flex-grow h-full"
                        >
                            <GenderPie hideUnknown={hideUnknown} {...props} />
                        </SimpleCard>
                    </div>
                </div>
                <SimpleCard
                    ref={miscCharacteristicsChartRef}
                    className="skelechild"
                >
                    <MiscCharacteristicsChart
                        hideUnknown={hideUnknown}
                        data={data}
                    />
                </SimpleCard>

                {/* GRAPHS */}
                <div className="flex flex-1  w-full gap-4">
                    <SimpleCard className="skelechild  h-full flex-1">
                        <DemographicBranchRankings
                            tableRef={branchRankingsTableRef}
                            pdfRef={pdfRef}
                            branches={data.branches}
                        >
                            <span className="font-semibold text-xl">
                                Top branches by filled-in profiles ratio
                            </span>
                        </DemographicBranchRankings>
                    </SimpleCard>
                    <SimpleCard className="skelechild flex-1 h-full">
                        <DemographicAgencyRankings
                            tableRef={agencyRankingsTableRef}
                            pdfRef={pdfRef}
                            agencies={data.agencies}
                        >
                            <span className="font-semibold text-xl">
                                Top agencies by filled-in profiles ratio
                            </span>
                        </DemographicAgencyRankings>
                    </SimpleCard>
                </div>
                <SimpleCard className="skelechild">
                    <Accordion
                        type="single"
                        collapsible
                        value={isExporting ? "0" : undefined}
                    >
                        <AccordionItem
                            title="Active Clients"
                            value="0"
                            className="border-b-0"
                        >
                            <AccordionTrigger className="font-medium text-md py-0 leading-none">
                                Clients with profiles filled in
                            </AccordionTrigger>
                            <AccordionContent className="pt-2">
                                <Table
                                    tableFnRef={withProfilesFilledInRef}
                                    controlledPDF
                                    pdfRef={pdfRef}
                                    showColumnVisibility={false}
                                    showExportButton={false}
                                    showFilters={false}
                                    columnHelper={clientWithoutProfileCols}
                                    data={data.withProfile}
                                />
                            </AccordionContent>
                        </AccordionItem>
                    </Accordion>
                </SimpleCard>
                <SimpleCard className="skelechild">
                    <Accordion
                        type="single"
                        collapsible
                        value={isExporting ? "0" : undefined}
                    >
                        <AccordionItem
                            title="Active Clients"
                            value="0"
                            className="border-b-0"
                        >
                            <AccordionTrigger className="font-medium text-md py-0 leading-none">
                                Clients without profiles filled in
                            </AccordionTrigger>
                            <AccordionContent className="pt-2">
                                <Table
                                    tableFnRef={withoutProfilesFilledInRef}
                                    controlledPDF
                                    pdfRef={pdfRef}
                                    showColumnVisibility={false}
                                    showExportButton={false}
                                    showFilters={false}
                                    columnHelper={clientWithoutProfileCols}
                                    data={data.withoutProfile}
                                />
                            </AccordionContent>
                        </AccordionItem>
                    </Accordion>
                </SimpleCard>
            </div>
        </div>
    );
};
