import { postPromise } from "@/api/promises";
import { ROUTE_IDS } from "@/api/routeIDs";
import { composeFormData } from "@/util/apiUtils";
import { MILLISEC } from "@/util/constants";
import { condenseDuplicateNumbers } from "@/util/queryUtils";
import { queryOptions, useQuery } from "@tanstack/react-query";

export const getAllNumbers = async ({ queryKey }) => {
    const { _key } = queryKey;
    const data = await postPromise(
        import.meta.env.VITE_API_BASE_URL,
        composeFormData({}, ROUTE_IDS.GET_ALL_NUMBERS)
    );

    const ret = condenseDuplicateNumbers(data)
    return ret;
};

export default function useAllNumbers(props) {
    const query = useQuery<any[], Error>({
        ...getAllNumbersQueryOptions,
        ...props,
    });
    return query;
}
export const getAllNumbersQueryOptions = queryOptions({
    queryKey: ["numbers", "all"],
    staleTime: Infinity,
    gcTime: MILLISEC.MIN_15,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    queryFn: getAllNumbers,
});
