import { Input } from '@/components/ui/input';
import { useDebounce } from '@/hooks/useDebounce';
import { cn } from '@/lib/utils';
import { Search } from 'lucide-react';
import React, { useEffect } from 'react';


export const TextSearchBar = ({
  onChange, 
  value: controlledValue, 
  debounce=true, 
  placeholder="Search...",
  className='',
  ...props
}) => {
    const [value, setValue] = React.useState(controlledValue || '');
    useEffect(() => {
      setValue(controlledValue)
    },[controlledValue])
    function handleChange(e) {setValue(e.target.value)}
    useDebounce(() => onChange(value), [value], 200)


    return (
        <div className="relative ml-auto flex-1 md:grow-0 overflow-visible">
          <div className='absolute overflow-visible left-2.5 top-1/2 -translate-y-1/2'>
            <SearchIcon className=" h-4 w-4 text-muted-foreground" />
          </div>
            <Input
              // type="search"
              value={value}
              onChange={handleChange}
              placeholder={placeholder}
              className={cn("w-full roundedLg bg-background pl-8 md:w-[200px] lg:w-[336px]", className)}
            />
          </div>
    );
};
const SearchIcon = (props) => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 10a7 7 0 1 0 14 0a7 7 0 1 0-14 0m18 11l-6-6"/></svg>
) 
