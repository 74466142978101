import { MiniCard } from '@/components/cards/MiniCard';
import { Checkbox } from '@/components/ui/checkbox';
import { Input } from '@/components/ui/input';
import { Separator } from '@/components/ui/separator';
import { SubcontrolContainer } from '@/routes/Main/Reporting/components/controls/subcontrols/SubcontrolContainer';
import { SubcontrolTitle } from '@/routes/Main/Reporting/components/controls/subcontrols/SubcontrolTitle';
import { VanishingControls } from '@/routes/Main/Reporting/components/controls/subcontrols/VanishingControls';
import React from 'react';

export const AttachedDataControls = ({showAttachedLabel, attachedLabelFontSize, handleChange, ...props}) => {
    return (
            <MiniCard className={'flex-row shadow-none h-16 w-1/2'} active={showAttachedLabel}>
                <div className="items-center flex space-x-2">
                    <Checkbox
                        defaultChecked={showAttachedLabel}
                        value={showAttachedLabel}
                        onCheckedChange={(v) => handleChange('showAttachedLabel',   v)}
                        className={'w-4 h-4 stroke-[2]  data-[state=checked]:bg-candy data-[state=checked]:text-candy-foreground'}
                    />
                    <span className='text-lg font-medium'>Attached Values</span>
                </div>
                <VanishingControls disabled={!showAttachedLabel}>
                    <Separator orientation='vertical'/>
                    <SubcontrolContainer>
                        <SubcontrolTitle>Font</SubcontrolTitle>
                        <Input
                            className='w-10 text-center pr-1 pl-1 py-1 h-8 text-xs'
                            type='number'
                            min={0}
                            max={90}
                            disabled={!showAttachedLabel}
                            value={attachedLabelFontSize}
                            onChange={(e) => handleChange('attachedLabelFontSize',   e.target.value)}/>
                    </SubcontrolContainer>
            </VanishingControls>
        </MiniCard>
    );
};


