import { Button } from "@/components/ui/button";
import { Popover, PopoverContent, PopoverDescription, PopoverHeader, PopoverTitle, PopoverTrigger } from "@/components/ui/popover";
import { Select } from "@/wrappers/ui/Select";
import { useState } from "react";

export const FillInAgencyProfilePopover = ({agenciesQuery, onSubmit, children}) => {
    const [agency, setAgency] = useState(null)
    return (
        <Popover >
            <PopoverTrigger asChild>
                {children}
            </PopoverTrigger>
            <PopoverContent className="w-80 flex flex-col gap-4" side="bottom" align="start">
                <PopoverHeader>
                    <PopoverTitle>Add or Edit Agency Profile</PopoverTitle>
                    <PopoverDescription>Begin by selecting an agency</PopoverDescription>
                </PopoverHeader>
                <div className="mt-4 flex flex-col gap-1">
                    <span className="text-sm font-semibold leading-tight">Target agency</span>
                    <Select
                        showSearch
                        buttonClassName="w-[18rem]"
                        popupClassName="w-[18rem]"
                        options={agenciesQuery.data}
                        handleChange={setAgency}
                        value={agency}
                        renderFn={agency => `${agency.branchName} ${agency?.Name}`}
                        inForm={false}
                        label='Agency'
                    />              
                </div>
                <Button 
                    onClick={() => onSubmit(agency)}
                    variant="default" className="gap-4 ">
                    Begin agency profile 
                </Button>
            </PopoverContent>
        </Popover>
    )
}