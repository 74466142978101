import { ScrollArea } from '@/components/ui/scroll-area';
import { useScrollContainerRef } from '@/context/ScrollContainerContext';
import React from 'react';


export const MainScrollContainer = ({children}) => {
    const scrollContainerRef = useScrollContainerRef()
    return (
        <ScrollArea ref={scrollContainerRef} onScroll={() => {console.log('scrolling container')}} className='h-screen '>
            {children}
        </ScrollArea>
    );
};

