import {
    FormControl,
    FormDescription,
    FormField,
    FormHeader,
    FormItem,
} from "@/components/ui/form";
import { Input, InputProps } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import { cva } from "class-variance-authority";
import React, { forwardRef, useRef } from "react";
import { Toggle } from "../../../components/ui/toggle";
import { camelCaseToWords } from "../../../util/textUtils";

export const TextInputVariants = cva(
    "flex relative border pointer-events-none rounded-md focus-within:ring-ring focus-within:ring-offset-2 focus-within:ring-2 focus-visible:outline-none border-input ring-offset-background",
    {
        variants: {
            variant: {
                large: "",
                medium: "w-full",
                small: "w-[15rem]",
            },
            header: {
                form: '',
                display: ''
            }
        },
        defaultVariants: {
            variant: "small",
        },
    }
);

export type InputVariant = "small" | "medium" | "large";

type ComposedFormTextProps = InputProps & {
    control: any;
    name: string;
    small?: boolean;
    label?: string;
    placeholder?: string;
    description?: string;
    variant?: InputVariant;
};

export const ComposedFormText = forwardRef<
    HTMLInputElement,
    ComposedFormTextProps
>(
    (
        {
            control,
            name,
            small = false,
            label = `${name}`,
            placeholder = `Enter your${camelCaseToWords(label, false)}`,
            description = null,
            variant = "small" as InputVariant,
            className = "",
            type,
            ...props
        },
        ref
    ) => {
        const containerRef = useRef();
        const [showPassword, setShowPassword] = React.useState(false);

        function toggleShow() {
            const inputEl = containerRef.current?.children?.[0];
            if (inputEl !== null) {
                const isPassword = inputEl.type === "password";
                inputEl.type = isPassword ? "text" : "password";
                setShowPassword(isPassword);
            }
        }
        return (
            <FormField
                control={control}
                name={name}
                render={({ field }) => (
                    <FormItem className="py-3">
                        <FormHeader label={label} />
                        <FormControl className="flex pointer-events-none ">
                            <div
                                ref={containerRef}
                                className={cn(
                                    TextInputVariants({ variant }),
                                    className
                                )}
                            >
                                <Input
                                    type={type}
                                    className={cn(
                                        "pointer-events-auto border-none focus-visible:ring-transparent focus-visible:outline-none focus-visible:ring-offset-0"
                                    )}
                                    placeholder={placeholder}
                                    {...field}
                                    value={field.value || ""}
                                    {...props}
                                />
                                {type === "password" && (
                                    <Toggle
                                        value={"true"}
                                        tabIndex={-1}
                                        className={cn(
                                            "text-secondary-foreground bg-secondary !absolute top-0 bottom-0 right-0 z-[1] pointer-events-auto swap data-[state=off]:swap-active",
                                            !showPassword && "swap-active"
                                        )}
                                        data-state={showPassword ? "on" : "off"}
                                        onClick={toggleShow}
                                    >
                                        <svg
                                            className="swap-off"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 512 512"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fill="none"
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="32"
                                                d="M255.66 112c-77.94 0-157.89 45.11-220.83 135.33a16 16 0 0 0-.27 17.77C82.92 340.8 161.8 400 255.66 400c92.84 0 173.34-59.38 221.79-135.25a16.14 16.14 0 0 0 0-17.47C428.89 172.28 347.8 112 255.66 112"
                                            />
                                            <circle
                                                cx="256"
                                                cy="256"
                                                r="80"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeMiterlimit="10"
                                                strokeWidth="32"
                                            />
                                        </svg>
                                        <svg
                                            className="swap-on"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 512 512"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fill="currentColor"
                                                d="M432 448a15.92 15.92 0 0 1-11.31-4.69l-352-352a16 16 0 0 1 22.62-22.62l352 352A16 16 0 0 1 432 448m-176.34-64c-41.49 0-81.5-12.28-118.92-36.5c-34.07-22-64.74-53.51-88.7-91v-.08c19.94-28.57 41.78-52.73 65.24-72.21a2 2 0 0 0 .14-2.94L93.5 161.38a2 2 0 0 0-2.71-.12c-24.92 21-48.05 46.76-69.08 76.92a31.92 31.92 0 0 0-.64 35.54c26.41 41.33 60.4 76.14 98.28 100.65C162 402 207.9 416 255.66 416a239.1 239.1 0 0 0 75.8-12.58a2 2 0 0 0 .77-3.31l-21.58-21.58a4 4 0 0 0-3.83-1a204.8 204.8 0 0 1-51.16 6.47m235.18-145.4c-26.46-40.92-60.79-75.68-99.27-100.53C349 110.55 302 96 255.66 96a227.3 227.3 0 0 0-74.89 12.83a2 2 0 0 0-.75 3.31l21.55 21.55a4 4 0 0 0 3.88 1a192.8 192.8 0 0 1 50.21-6.69c40.69 0 80.58 12.43 118.55 37c34.71 22.4 65.74 53.88 89.76 91a.13.13 0 0 1 0 .16a310.7 310.7 0 0 1-64.12 72.73a2 2 0 0 0-.15 2.95l19.9 19.89a2 2 0 0 0 2.7.13a343.5 343.5 0 0 0 68.64-78.48a32.2 32.2 0 0 0-.1-34.78"
                                            />
                                            <path
                                                fill="currentColor"
                                                d="M256 160a96 96 0 0 0-21.37 2.4a2 2 0 0 0-1 3.38l112.59 112.56a2 2 0 0 0 3.38-1A96 96 0 0 0 256 160m-90.22 73.66a2 2 0 0 0-3.38 1a96 96 0 0 0 115 115a2 2 0 0 0 1-3.38Z"
                                            />
                                        </svg>
                                    </Toggle>
                                )}
                            </div>
                        </FormControl>
                        {description && (
                            <FormDescription className="!mt-1">
                                {description}
                            </FormDescription>
                        )}
                    </FormItem>
                )}
            />
        );
    }
);
ComposedFormText.displayName = "ComposedFormText";
