import { useEffect } from "react";
import { useRHF } from "../../../hooks/useRHF";
import { useYupValidationResolver } from "../../../hooks/useYupValidationResolver";
import { enumToOptions } from "../../../lib/utils";
import { AgencyStatus as Status } from "../../../util/constants";
import { ComposedForm } from "../../../wrappers/ui/form/ComposedForm";
import { ComposedFormDatepicker } from "../../../wrappers/ui/form/ComposedFormDatepicker";
import { ComposedFormSelect } from "../../../wrappers/ui/form/ComposedFormSelect";
import Yup from "../../../wrappers/yup";
import { FormSection } from "../../ui/FormSection";
import { FieldGroup } from "../formComponents/FieldGroup";

const validationSchema = Yup.object({
    agencyStartTimestamp: Yup.date().required("required"),
    agencyEndTimestamp: Yup.date().required("required"),
    status: Yup.mixed().oneOfEnum(Status).required("required"),
});
export const AgencyStatus = ({
    enumsQuery,
    sectionIndex,
    agencyData,
    hasPrev,
}) => {
    const resolver = useYupValidationResolver(validationSchema);
    const form = useRHF({
        resolver,
        defaultValues: validationSchema.getDefault(),
    });
    const { register, handleSubmit, formState, setValue, reset, control } =
        form;
    // const formStatus = getFormSectionStatus({submitted: hasPrev, isDirty: formState.isDirty, isValid: formState.isValid})
    useEffect(loadData, [agencyData]);
    function loadData() {
        if (!agencyData) return;
        reset(agencyData);
    }
    function onSubmit(data: any) {}
    return (
        <FormSection {...{ sectionIndex }} title={"Agency Status"}>
            <ComposedForm form={form} onSubmit={handleSubmit(onSubmit)}>
                <FieldGroup>
                    <div className="flex gap-2">
                        <ComposedFormDatepicker
                            control={control}
                            name={"agencyStartTimestamp"}
                            placeholder="Start"
                            label="Agency Start Date"
                            small
                        />
                        <ComposedFormDatepicker
                            control={control}
                            name={"agencyEndTimestamp"}
                            placeholder="End"
                            label="Agency End Date"
                            small
                        />
                    </div>
                </FieldGroup>
                <ComposedFormSelect
                    control={control}
                    name={"status"}
                    label="Agency Status"
                    valueFn={(value) => value?.value}
                    renderFn={(value) => value?.label}
                    options={enumToOptions(Status)}
                />
            </ComposedForm>
        </FormSection>
    );
};
