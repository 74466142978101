import { Button } from "@/components/ui/button"
import { Separator } from "@/components/ui/separator"
import { cn } from "@/lib/utils"
import { DemographicsReportData } from "@/routes/Main/Reporting/Presets/DemographicsReport/useDemographicsReportData"
import { GAOReportData } from "@/routes/Main/Reporting/Presets/GoalsAndOutcomesReport/useGAOReportData"
import { blue, orange, yesGreen } from "@/util/constants"
import { LiveNumber } from "@/wrappers/ui/LiveNumber"
import { Users } from "lucide-react"
import { useState } from "react"
import { useCountUp } from "react-countup"


type GaoStatsProps = {
    data: GAOReportData
}
// Statistics for goals only draw from client profiles with at least one goal set
// Statistics for outcomes only draw from client profiles with at least one outcome set unless otherwise stated
export const GaoStats = ({data}: GaoStatsProps) => {
    const {
        avgAccomplishedAmount,
        avgGoalAmount,
        // avgKnownOutcomeAmount,
        avgOutcomeAmountWithoutGoal,
        avgFailedGoals,
        totalGoalCount,
        totalGoalsAccomplished,
        totalGoalsSetAndAccomplished,
        totalFailedGoals,
        goalWithoutOutcomeCount,
        totalKnownOutcomes,
        totalGoalsAccomplishedWithoutGoal,
        hasGoalsButNoOutcomes,
        avgGoalsSetAndAccomplished,
        avgGoalsWithoutOutcome,
        
        
    } = data

    return (
        <div className="grid grid-cols-2 gap-y-2 auto-rows-max gap-x-2 min-h-fit">
            <StatDescription className="placese">On average,</StatDescription>
            <StatDescription>In total,</StatDescription>

            <StatCard style={{backgroundColor: blue}}>
                <StatDescription className="col-span-2">
                    Clients <Emphasis>set</Emphasis> 
                    <NumberContainer>
                        <LiveNumber className="font-extrabold text-3xl "  decimals={1} end={avgGoalAmount} />
                    </NumberContainer>
                    goals.
                </StatDescription>
            </StatCard>
            <StatCard style={{backgroundColor: blue}}>
                <NumberContainer className="ml-0">
                    <LiveNumber className="font-extrabold text-3xl"  end={totalGoalCount} />
                </NumberContainer>
                <StatDescription className="col-span-2">goals have been <Emphasis>set.</Emphasis></StatDescription>
            </StatCard>
            <StatCard style={{backgroundColor: yesGreen}} className="block">
                <div className="inline-flex gap-2">
                    <StatDescription>
                        Clients<Emphasis className="ml-2">accomplish</Emphasis>
                        <NumberContainer>
                            <LiveNumber className="font-extrabold text-3xl " startOnMount decimals={1} end={avgGoalsSetAndAccomplished} />    
                        </NumberContainer>
                        of the goals they set and 
                        <NumberContainer className='mx-2'>
                            <LiveNumber className="font-extrabold text-3xl " startOnMount decimals={1} end={avgOutcomeAmountWithoutGoal} />    
                        </NumberContainer>
                        that they didn't set.
                    </StatDescription>
                </div>
            </StatCard>
            <StatCard style={{backgroundColor: yesGreen}}>
                    <StatDescription>
                    <NumberContainer className="ml-0">
                        <LiveNumber className="font-extrabold text-3xl " startOnMount end={totalGoalsSetAndAccomplished} />    
                    </NumberContainer>
                        of them have been <Emphasis>accomplished,</Emphasis> along with
                    <NumberContainer>
                        <LiveNumber className="mx-2 font-extrabold text-3xl " startOnMount end={totalGoalsAccomplishedWithoutGoal} />    
                    </NumberContainer>
                        that weren't set in the first place
                    </StatDescription>
                </StatCard>
            <StatCard>
                <StatDescription>
                    Clients have 
                    <NumberContainer>
                        <LiveNumber className="font-extrabold text-3xl " startOnMount decimals={1}  end={avgGoalsWithoutOutcome} />    
                    </NumberContainer>
                    goals <Emphasis>pending</Emphasis> an outcome.
                </StatDescription>
            </StatCard>
            <StatCard> 
                <StatDescription>
                    <NumberContainer className="ml-0">
                        <LiveNumber className="font-extrabold leading-none text-3xl" startOnMount  end={goalWithoutOutcomeCount} />    
                    </NumberContainer>
                    goals are <Emphasis>pending</Emphasis> an outcome.
                </StatDescription>
            </StatCard>
        </div>
)
}

const NumberContainer = ({className='',...props}) => <div className={cn("mx-2 max-w-fit inline-flex",className)} {...props}/>
const StatCard = ({className='', ...props}) => <div className={cn("flex items-center gap-x-2 w-full bg-slate-600  p-6 rounded-xl text-white", className)} {...props}/>
const StatDescription = ({children, className='', ...props}) => <div className={cn("font-extralight text-2xl 2xl:text-3xl", className)} {...props}>{children}</div>

const Emphasis = ({className='',...props}) =>  (
    <strong className={cn("font-extrabold text-3xl", className)} {...props}/>
)