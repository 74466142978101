import useAllNumbers from "@/api/numbers/getAllNumbers";
import { useNumbersInAgencies } from "@/api/numbers/getNumbersInAgency";
import { BasicJetDB, JetDBEnums } from "@/util/constants";
import { reverseObjectEntries, subObject } from "@/util/miscUtils";
import { UseQueryResult } from "@tanstack/react-query";
import { useEffect, useState } from "react";

export type DemographicsReportAgency = Omit<
    GAOReportData,
    "agencies" | "branches"
> & {
    name: string;
    id: number;
    branchname: string;
    branchid: string;
};
export type DemographicsReportBranch = Omit<GAOReportData, "branches"> & {
    name: string;
    id: number;
};
type demoData = {
    [key: string]: number;
}[];

const goalOutcomeKeyPairs = {
    Employment: "foundEmployment",
    Housing: "gotHousing",
    "Social Services": "accessedSocialServices",
    Healthcare: "usedforHealthCare",
    "Increase Financial Benefits": "increasedFinancialBenefits",
    "Safe Communications": "achievedSafeCommunication",
    "Communication with Family and Friends":
        "achievedCommunicationwithFriendsandFamily",
    // 'Community Connections': 'maintainedCommunityConnections',
    "Legal/Justice System": "accessedLegalJusticeSystem",
    "Other": "usedforOtherReasons",
};

type goalOutcomeData = {
    [goalName: string]: {
        hasGoal: boolean;
        Achieved: number;
        NotAchieved: number;
        NoData: number;
    };
};

export type GAOReportData = {
    withGoals?: any[];
    withoutGoals?: any[];
    withGoalsOrOutcomes: any[];
    totalCount: number;
    clientGoals: {
        [key: string]: goalOutcomeData;
    };
    financialResource: {
        [key: string]: number;
    };
    housingOutcome: {
        [key: string]: number;
    };
    outcomesWithoutGoal: number;

    withGoalsCount: number;
    withoutGoalsCount: number;
    goalWithoutOutcomeCount: number;
    withoutProfilesCount: number;
    failedAllGoalsCount: number;
    hasGoalsButNoOutcomes: number;

    totalGoalCount: number;
    totalGoalsAccomplished: number;
    totalKnownOutcomes: number;
    totalGoalsAccomplishedWithoutGoal: number;
    totalFailedGoals: number;
    totalGoalsSetAndAccomplished: number;

    avgGoalsWithoutOutcome: number;
    avgGoalsSetAndAccomplished: number;
    avgFailedGoals: number;
    avgGoalAmount: number;
    avgAccomplishedAmount: number; // Only change if user has an outcome
    avgKnownOutcomeAmount: number; // Only change if user has an outcome
    avgOutcomeAmountWithoutGoal: number; // Only change if user has an outcome

    agencies?: DemographicsReportAgency[];
    branches?: DemographicsReportBranch[];
};

const defaultValues = (enumsObj) => {
    const reversedKeys = reverseObjectEntries(goalOutcomeKeyPairs);
    return {
        withGoalsOrOutcomes: [],
        withoutGoalsOrOutcomes: [],
        totalCount: 0,

        clientGoals: Object.fromEntries(
            Object.values(enumsObj.clientGoals).map((k) => [
                k.Name,
                { hasGoal: 0, Yes: 0, No: 0, Unknown: 0 },
            ])
        ),
        housingOutcome: getStartingEntriesFromEnum(enumsObj.housingOutcome),
        financialResource: getStartingEntriesFromEnum(
            enumsObj.financialResource
        ),

        hasGoal: 0,
        hasOutcome: 0,
        failedAllGoals: 0,
        hasGoalsButNoOutcomes: 0,

        hasAccomplishedGoal: 0,
        failedGoalsArr: [],
        goalCountArr: [],
        goalAccomplishedAmountArr: [],
        knownOutcomeAmountArr: [],
        goalAccomplishedWithoutGoal: [],
        goalsSetAndAccomplished: [],
        goalWithoutOutcomeArr: [],
    };
};

const enumKeys = ["housingOutcome", "financialResource"];

type GAOReportDataHookProps = {
    config: any;
    includeNoData?: boolean;
    myUserDataQuery: any;
    enumsQuery: UseQueryResult<JetDBEnums>;
    branchesQuery: UseQueryResult<any[], Error>;
    agenciesQuery: UseQueryResult<any[], Error>;
    numbersQuery: UseQueryResult<any[], Error>;
    enabled: any;
};

type GAOReportDataHook = ({
    config,
    myUserDataQuery,
    enumsQuery,
    branchesQuery,
    agenciesQuery,
    numbersQuery,
    enabled,
    includeNoData,
}: GAOReportDataHookProps) => {
    data: Partial<GAOReportData>;
    isPending: boolean;
};
export const useGAOReportData: GAOReportDataHook = ({
    config,
    myUserDataQuery,
    branchesQuery,
    agenciesQuery,
    enabled,
    enumsQuery,
    includeNoData = true,
}) => {
    const isAdmin = myUserDataQuery.data?.isAdmin;
    const [data, setData] = useState<Partial<GAOReportData>>({
        branches: [],
        agencies: [],
    });

    const adminNumbersQuery = useAllNumbers({ enabled: isAdmin });
    const numbersQuery = useNumbersInAgencies(
        agenciesQuery.data
        // TODO:DONT FORGET TO UNCOMMENT
        // !isAdmin
    );

    const isPending =
        branchesQuery.isPending ||
        agenciesQuery.isPending ||
        (isAdmin ? adminNumbersQuery.isPending : numbersQuery.isPending) ||
        enumsQuery.isPending;
    useEffect(calculateData, [
        config,
        branchesQuery,
        agenciesQuery,
        adminNumbersQuery.data,
        numbersQuery.data,
        enabled,
    ]);
    useEffect(() => {
        // console.log('data',data)
    }, [data]);

    function calculateData() {
        const start = performance.now();
        if (isPending || !enabled) return;
        const { view, branch, agency } = config;
        const numbersSrc = isAdmin ? adminNumbersQuery.data : numbersQuery.data;
        let filteredNumbers = [];
        if (view === "national") {
            filteredNumbers = numbersSrc;
        }
        if (view === "branch") {
            filteredNumbers = numbersSrc.filter(
                (n) => n.branchid === String(branch.id)
            );
        }
        if (view === "agency") {
            filteredNumbers = numbersSrc.filter(
                (n) => n.agencyid === String(agency?.id)
            );
        }
        const enumsSubObj = subObject(enumsQuery.data, [
            ...enumKeys,
            "clientGoals",
        ]);
        const total = {
            ...defaultValues(enumsSubObj),
            agencies: {},
            branches: {},
        };

        // Begin client loop
        for (const client of filteredNumbers) {
            // const {ageRange=['noData'], gender=['noData'], identity=['noData'], currentHousing=['noData'], miscCharacteristics=['noData']} = client.clientdata || {}
            const numFieldsFilled =
                Object.values(client.clientdata || {}).length - 2;
            const hasGoals = client.clientdata?.clientGoals?.length > 0;

            let goalsAchieved = 0;

            let numGoalCount = 0;
            let numKnownOutcomes = 0;
            let numFailedGoals = 0;
            let numGoalsAccomplished = 0;
            let numGoalsAccomplishedWithoutGoal = 0;
            const clientOutcomeObj = Object.fromEntries(
                Object.entries(goalOutcomeKeyPairs).reduce((acc, [k, v]) => {
                    const o = client.clientdata?.[v]?.[0] || "Unknown";
                    const hasG = client.clientdata?.clientGoals?.includes(k);
                    if (hasG) numGoalCount++;
                    switch (o) {
                        case "Yes":
                            goalsAchieved++;
                            numKnownOutcomes++;
                            if (hasG) numGoalsAccomplished++;
                            else numGoalsAccomplishedWithoutGoal++;
                            break;
                        case "No":
                            numFailedGoals++;
                            numKnownOutcomes++;
                            break;
                        case "Unknown":
                            break;
                    }

                    const res = [
                        k,
                        {
                            hasGoal: hasG,
                            outcome: o,
                        },
                    ];
                    acc.push(res);
                    return acc;
                }, [])
            );
            const hasOutcome = numKnownOutcomes > 0;
            const hasGoalsButNoOutcomes = numGoalCount > 0 && !hasOutcome;
            const hasAchievedAGoal = numGoalsAccomplished > 0;
            const failedAllGoals =
                numFailedGoals === numGoalCount && numFailedGoals > 0;

            const mutateList = [total];
            if (view === "national") {
                if (!total.branches[client.branchid]) {
                    total.branches[client.branchid] = {
                        Name: client.branchname,
                        id: client.branchid,
                        agencies: {},
                        ...defaultValues(enumsQuery.data),
                    };
                }
                if (
                    !total.branches[client.branchid].agencies[client.agencyid]
                ) {
                    total.branches[client.branchid].agencies[client.agencyid] =
                        {
                            Name: client.agencyName,
                            id: client.agencyid,
                            branchName: client.branchname,
                            branchid: client.branchid,
                            ...defaultValues(enumsQuery.data),
                        };
                }

                mutateList.push(total.branches[client.branchid]);
                mutateList.push(
                    total.branches[client.branchid].agencies[client.agencyid]
                );
            }
            if (view === "branch" || view === "national") {
                if (!total.agencies[client.agencyid]) {
                    total.agencies[client.agencyid] = {
                        Name: client.agencyname,
                        id: client.agencyid,
                        branchname: client.branchname,
                        branchid: client.branchid,
                        agencyRankInBranch: 0,
                        ...defaultValues(enumsQuery.data),
                    };
                }
                mutateList.push(total.agencies[client.agencyid]);
            }
            for (const subscriber of mutateList) {
                // Calculate Sums
                if (hasGoals) {
                    subscriber.goalCountArr.push(numGoalCount);
                }
                if (hasOutcome) {
                    subscriber.failedGoalsArr.push(numFailedGoals);
                    subscriber.goalAccomplishedAmountArr.push(
                        numGoalsAccomplished
                    );
                    subscriber.knownOutcomeAmountArr.push(numKnownOutcomes);
                    subscriber.goalAccomplishedWithoutGoal.push(
                        numGoalsAccomplishedWithoutGoal
                    );
                }

                hasGoalsButNoOutcomes && subscriber.hasGoalsButNoOutcomes++;
                hasAchievedAGoal && subscriber.hasAccomplishedGoal++;
                failedAllGoals && subscriber.failedAllGoals++;
                hasGoals && subscriber.hasGoal++;
                hasOutcome && subscriber.hasOutcome++;

                for (const [k, v] of Object.entries(clientOutcomeObj)) {
                    const { hasGoal, outcome } = v;
                    subscriber.clientGoals[k][outcome]++;
                    hasGoal && subscriber.clientGoals[k].hasGoal++;
                    hasGoal &&
                        outcome === "Yes" &&
                        subscriber.goalsSetAndAccomplished.push(client);
                    hasGoal &&
                        !(outcome === "Yes") &&
                        subscriber.goalWithoutOutcomeArr.push(client);
                }
                if (hasOutcome || hasGoals) {
                    subscriber?.withGoalsOrOutcomes?.push?.(client);
                    // Only relevent for housing and financial resource outcomes
                    // Fill in enum data
                    for (const key of enumKeys) {
                        for (const e of client.clientdata[key] || []) {
                            e == null
                                ? subscriber[key].Unknown++
                                : subscriber[key][e]++;
                        }
                    }
                } else {
                    subscriber?.withoutGoalsOrOutcomes?.push?.(client);
                }
            }
        }

        // Turn objects into arrays
        total.branches = Object.values(total.branches);
        total.agencies = Object.values(total.agencies);
        for (const branch of total.branches) {
            branch.agencies = Object.values(branch.agencies);
        }

        // Compute aggregates
        for (const branch of total.branches) {
            computeAggregates(branch);
            for (const agency of branch.agencies) {
                computeAggregates(agency);
            }
        }
        for (const agency of total.agencies) {
            computeAggregates(agency);
        }
        computeAggregates(total);
        setData(total);
    }
    return {
        data,
        isPending,
    };
};

function computeAggregates(obj) {
    obj.totalFailedGoals = obj.failedGoalsArr.reduce((a, c) => a + c, 0);
    obj.totalGoalCount = obj.goalCountArr.reduce((a, c) => a + c, 0);
    obj.totalGoalsAccomplished = obj.goalAccomplishedAmountArr.reduce(
        (a, c) => a + c,
        0
    );
    obj.totalKnownOutcomes = obj.knownOutcomeAmountArr.reduce(
        (a, c) => a + c,
        0
    );
    obj.totalGoalsAccomplishedWithoutGoal =
        obj.goalAccomplishedWithoutGoal.reduce((a, c) => a + c, 0);
    obj.totalGoalsSetAndAccomplished = obj.goalsSetAndAccomplished.length;
    obj.totalGoalsWithoutOutcome = obj.goalWithoutOutcomeArr.reduce(
        (a, c) => a + c,
        0
    );

    obj.avgGoalsWithoutOutcome = obj.totalGoalsWithoutOutcome / obj.hasGoal;
    obj.avgGoalsSetAndAccomplished =
        obj.totalGoalsSetAndAccomplished / obj.hasGoal;
    obj.avgFailedGoals = obj.totalFailedGoals / obj.hasOutcome;
    obj.avgGoalAmount = obj.totalGoalCount / obj.hasGoal;
    obj.avgAccomplishedAmount = obj.totalGoalsAccomplished / obj.hasOutcome;
    obj.avgKnownOutcomeAmount = obj.totalKnownOutcomes / obj.hasOutcome;
    obj.avgOutcomeAmountWithoutGoal =
        obj.totalGoalsAccomplishedWithoutGoal / obj.hasOutcome;
    // cleanupArrays(obj)
}

function getStartingEntriesFromEnum(enumArr: BasicJetDB[]) {
    return {
        Unknown: 0,
        ...Object.fromEntries(enumArr.map((e) => [e.Name, 0])),
    };
}
