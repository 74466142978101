import { getMyUserDataQueryOptions } from '@/api/user/getMyUserData';
import React from 'react';
import { Outlet, redirect } from 'react-router-dom';


export const Admin = Outlet;

export const adminRouteLoader = (queryClient) => async () => {
    try {
        const myUserData = await queryClient.fetchQuery({
            queryKey: getMyUserDataQueryOptions.queryKey
        })
        const authorized = myUserData && Number(myUserData?.adminLevel) > 9
        if (!authorized) {
            console.log('user is not an admin');
            throw new Error('User is not authorized as an admin')
        } 
        return null;
    } catch (error) {
        return redirect("/");
    }

}

