import { Button, ButtonProps } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { cva, VariantProps } from 'class-variance-authority';
import { ArrowLeft } from 'lucide-react';
import React from 'react';

const BackButtonVariants = cva(
    'group gap-2 font-medium items-center text-md text-muted-foreground inline-flex',
    {
        variants: {
            size: {
                lg: 'text-md h-7 !py-1 px-4 large',
                md: 'text-sm h-6 !py-0.5 px-3 default',
                sm: 'text-xs h-5 !py-0 px-0 small'
            }
        },
        defaultVariants: {
            size: 'sm'
        }
    }
)

type BackButtonProps = Omit<ButtonProps,'variant'> & {
    variant?: VariantProps<typeof BackButtonVariants>['size']
}

export const BackButton = ({onClick, className, variant, children, ...props}: BackButtonProps) => {
    return (
        <Button
            variant='ghost'
            className={cn(BackButtonVariants({size: variant}), className)} 
            {...props}
            >
                <ArrowLeft className='w-5 group-[.default]:w-4.5 group-[.default]:h-4.5 group-[.large]:h-6 group-[.large]:w-6 group-[.small]:h-3.5 group-[.small]:w-3.5'/>
                {children}
        </Button>
    );
};
