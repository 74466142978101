import useModAgency from '@/api/agencies/modAgency';
import { AgencyInfo } from '@/components/forms/AgencyForm/AgencyInfo';
import { AgencyServiceType } from '@/components/forms/AgencyForm/AgencyServiceType';
import { AgencyStatus } from '@/components/forms/AgencyForm/AgencyStatus';
import { ContactInfo } from '@/components/forms/AgencyForm/ContactInfo';
import { ErrorSection } from '@/components/forms/ClientForm';
import { Accordion } from '@/components/ui/accordion';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { useRHF } from '@/hooks/useRHF';
import { useYupValidationResolver } from '@/hooks/useYupValidationResolver';
import { cn } from '@/lib/utils';
import { parseBooleans, parseServerEnums } from '@/util/queryUtils';
import { useQueryResultToasts } from '@/wrappers/toasts';
import { ComposedForm } from '@/wrappers/ui/form/ComposedForm';
import Yup from '@/wrappers/yup';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';



const fieldNameSections = {
    isActive: '1',
    startDate: '1',
    endDate: '1',
    displayName: '1',
    address: '1',
    address2: '1',
    city: '1',
    serviceTypes: '1',
    otherServices: '1',
    agencyNotes: '1',
    agencyPassword: '1',

    mainPhone: '2',
    contactName: '2',
    contactEmail: '2',
    contactPhone: '2',
    contactRole: '2',
    execContactName: '2',
    execContactEmail: '2',
    execContactPhone: '2',
    execContactRole: '2',
};

const validationSchema = Yup.object({
    agencyid: Yup.string().required('Error: could not find agency ID'),
    isActive: Yup.boolean().default(false),
    startDate: Yup.date().required('Provide a start date'),
    endDate: Yup.date().notRequired(),

    displayName: Yup.string(),
    
    address: Yup.string().notRequired(),
    address2: Yup.string().notRequired(),
    mainPhone: Yup.string().notRequired(),
    city: Yup.string().capitalizeEachFirstLetter().notRequired(),

    otherServices: Yup.string().notRequired(),
    agencyNotes: Yup.string().notRequired(),
    agencyPassword: Yup.string().notRequired(),

    contactName: Yup.string().notRequired(),
    contactEmail: Yup.string().notRequired(),
    contactPhone: Yup.string().notRequired(),
    contactRole: Yup.string().notRequired(),

    execContactName: Yup.string().notRequired(),
    execContactEmail: Yup.string().notRequired(),
    execContactPhone: Yup.string().notRequired(),
    execContactRole: Yup.string().notRequired(),
    
    serviceTypes: Yup.array().notRequired(),
})

export const AgencyForm = ({numbersQuery, enumsQuery, agencyQuery,  ...props}) => {
    const {agencyID} = useParams()
    const {serviceTypes} = enumsQuery.data || {}
    const {data: agencyData} = agencyQuery
    const {Name, branchName} = agencyData || {}
    console.log(agencyData);

    const [openSections, setOpenSections] = useState([])

    const numbersWithProfiles = useMemo(() => {
        if (!numbersQuery.data) return
        return numbersQuery.data.filter(n => Object.values(n.clientdata || {})?.length > 2)
    },[numbersQuery.data])

    const resolver = useYupValidationResolver(validationSchema,{serviceTypes})
    const form = useRHF({resolver, defaultValues: validationSchema.getDefault()})
    const {handleSubmit, getValues,formState, reset, control} = form
    const isActive = form.watch('isActive')

    const queryResultToasts = useQueryResultToasts()
    const agencyMutation = useModAgency({...queryResultToasts(
        'Agency Updated',
        'Error Updating Agency'
    )})

    useEffect(loadData, [agencyQuery.data, serviceTypes, agencyID])

    function loadData() {
        const agencyData = agencyQuery.data
        agencyID && form.setValue('agencyid', agencyID)
        if (!agencyData || !serviceTypes) return 
        const parsedBooleans = parseBooleans(agencyData)
        const parsedEnums = parseServerEnums(parsedBooleans, [serviceTypes], ['serviceTypes'])  
        reset(validationSchema.cast({
            ...parsedEnums,
            agencyid: agencyID,
        },{stripUnknown: true, assert: false, context: {...enumsQuery.data}}))
        // reset(fakeData)
    }
    const hasPrev = agencyQuery?.data?.modifiedTimestamp

    function onSubmit(data) {
        agencyMutation.mutate(data)
    }
    return (
        <ComposedForm {...{form,onSubmit}} className="flex gap-4">
            <div className="bg-background rounded-xl px-4 border flex-4">
                <Accordion type='multiple' value={openSections} onValueChange={setOpenSections}>
                    <AgencyInfo form={form} enumsQuery={enumsQuery} agencyData={agencyQuery.data} hasPrev={hasPrev} sectionIndex={1}/>
                    <ContactInfo form={form} agencyData={agencyQuery.data} sectionIndex={2}/>
                </Accordion>
            </div>
            <div className="bg-background flex-1 rounded-xl border sticky top-5  h-fit min-w-[20rem]">
                <div className="p-4 flex flex-col">
                        <p className="place-self-center leading-tight text-lg font-medium">
                            {branchName} {Name}
                        </p>
                        <Badge 
                            variant={'outline'} 
                            className={cn(
                                "w-fit place-self-center bg-primary text-primary-foreground", 
                                !isActive && 'bg-muted text-muted-foreground'
                                )}>
                                {isActive ? 'Active' : 'Inactive'}
                        </Badge>
                        <Separator className="my-3 h-0.5"/>
                    <div className="w-full grid gap-y-1 grid-cols-2 whitespace-nowrap">

                        <span className="text-muted-foreground border-b">
                            Numbers in agency:
                        </span>
                        <span className="text-end border-b">
                            {numbersQuery.data?.length}
                        </span>

                        <span className="text-muted-foreground border-b">
                            Filled-in client profiles:
                        </span>
                        <span className="text-end border-b">{numbersWithProfiles?.length}</span>
                            
                    </div>
                    <div className="pt-[3rem] pb-2">  
                        <ErrorSection {...{form, openSections, setOpenSections, fieldNameSections}}/>
                    </div>
                    <Button type="submit" variant="default" className="mt-auto w-full" disabled={Object.keys(form?.formState?.errors)?.length > 1}>
                        Submit agency profile
                    </Button>
                </div>
            </div>
        </ComposedForm>
    );
};
