import { Button } from '@/components/ui/button';
import { Plus } from 'lucide-react';
import { Link } from 'react-router-dom';

export const LinkedClientCell = ({row, ...props}) => {
    const len = Object.values(row.clientdata || {}).length
    const isLinked = Object.values(row.clientdata || {}).length > 2
    const {firstName='', lastName=''} = row?.clientdata || {}
    return  isLinked ? (
        <Link to={`/client/${row.branchid}/${row.agencyid}/${row.Name}`} >
            <Button size='xs' variant='link' className='flex gap-0.5'>
                {firstName || lastName ? (
                    <span>
                        {firstName} {lastName}
                    </span>
                ) : (
                    <span>Unnamed client</span>
                )}
            </Button>
        </Link>
    ) : (
        <Link to={`/client/${row.branchid}/${row.agencyid}/${row.Name}`} >
        <Button size='xs' className='flex gap-0.5'>
            Add 
            <Plus className='w-4 h-4'/>
        </Button>
    </Link>
    )
};
