import useAllNumbers from "@/api/numbers/getAllNumbers";
import { useNumbersInAgencies } from "@/api/numbers/getNumbersInAgency";
import { BasicJetDB, JetDBEnums } from "@/util/constants";
import { subObject } from "@/util/miscUtils";
import { UseQueryResult } from "@tanstack/react-query";
import { useEffect, useState } from "react";

export type DemographicsReportAgency = Omit<
    DemographicsReportData,
    "agencies" | "branches"
> & {
    name: string;
    id: number;
    branchname: string;
    branchid: string;
};
export type DemographicsReportBranch = Omit<
    DemographicsReportData,
    "branches"
> & {
    name: string;
    id: number;
};
type demoData = {
    [key: string]: number;
}[];

export type DemographicsReportData = {
    ageRange?: demoData;
    gender?: demoData;
    currentHousing?: demoData;
    identity?: demoData;
    miscCharacteristics?: demoData;
    withProfile?: any[];
    withoutProfile?: any[];
    totalCount: number;

    frequentShelterUse: number;
    hasStatusCard: number;
    hasStudentVisa: number;
    isRefugee: number;

    indigenousCount: number;
    shelterCount: number;
    newcomerCount: number;

    withProfileCount: number;
    withoutProfileCount: number;
    avgNumFieldsFilled: number[];
    agencies?: DemographicsReportAgency[];
    branches?: DemographicsReportBranch[];
};

const defaultValues = (enumsObj) => ({
    ageRange: getStartingEntriesFromEnum(enumsObj.ageRange),
    gender: getStartingEntriesFromEnum(enumsObj.gender),
    currentHousing: getStartingEntriesFromEnum(enumsObj.currentHousing),
    identity: getStartingEntriesFromEnum(enumsObj.identity),
    miscCharacteristics: getStartingEntriesFromEnum(
        enumsObj.miscCharacteristics
    ),

    frequentShelterUse: 0,
    hasStatusCard: 0,
    hasStudentVisa: 0,
    isRefugee: 0,

    indigenousCount: 0,
    shelterCount: 0,
    newcomerCount: 0,

    numFieldsFilledArr: [],
    withProfile: [],
    withoutProfile: [],
});

const booleanKeys = [
    "frequentShelterUse",
    "statusCardCount",
    "hasStudentVisa",
    "isRefugee",
];

const enumKeys = [
    "ageRange",
    "gender",
    "currentHousing",
    "identity",
    "miscCharacteristics",
    // "foundEmployment",
    // "gotHousing",
    // "accessedSocialServices",
    // "usedforHealthCare",
    // "increasedFinancialBenefits",
    // "achievedSafeCommunication",
    // "achievedCommunicationwithFriendsandFamily",
    // "accessedLegalJusticeSystem",
    // "maintainedCommunityConnections",
    // "usedforOtherReasons",
    // "financialResource",
    // "housingOutcome"
];

type DemographicsReportDataHookProps = {
    config: any;
    includeNoData?: boolean;
    myUserDataQuery: any;
    enumsQuery: UseQueryResult<JetDBEnums>;
    branchesQuery: UseQueryResult<any[]>;
    agenciesQuery: UseQueryResult<any[]>;
    enabled: any;
};

type DemographicsReportDataHook = ({
    config,
    myUserDataQuery,
    enumsQuery,
    branchesQuery,
    agenciesQuery,
    enabled,
    includeNoData,
}: DemographicsReportDataHookProps) => {
    data: DemographicsReportData;
    isPending: boolean;
};
export const useDemographicsReportData: DemographicsReportDataHook = ({
    config,
    myUserDataQuery,
    branchesQuery,
    agenciesQuery,
    enabled,
    enumsQuery,
    includeNoData = true,
}) => {
    const isAdmin = myUserDataQuery.data?.isAdmin;
    const [data, setData] = useState<DemographicsReportData>({
        branches: [],
        agencies: [],
    });

    const adminNumbersQuery = useAllNumbers({ enabled: isAdmin });
    const numbersQuery = useNumbersInAgencies(
        agenciesQuery.data
        // TODO:DONT FORGET TO UNCOMMENT
        // !isAdmin
    );

    const isPending =
        branchesQuery.isPending ||
        agenciesQuery.isPending ||
        (isAdmin ? adminNumbersQuery.isPending : numbersQuery.isPending) ||
        enumsQuery.isPending;
    // console.log('ispending', isPending);
    useEffect(calculateData, [
        config,
        branchesQuery,
        agenciesQuery,
        adminNumbersQuery.data,
        numbersQuery.data,
        enabled,
    ]);
    useEffect(() => {
        // console.log('data',data)
    }, [data]);

    function calculateData() {
        const start = performance.now();
        if (isPending || !enabled) return;
        const { view, branch, agency } = config;
        const numbersSrc = isAdmin ? adminNumbersQuery.data : numbersQuery.data;
        let filteredNumbers = [];
        let segregated = [];
        if (view === "national") {
            filteredNumbers = numbersSrc;
        }
        if (view === "branch") {
            filteredNumbers = numbersSrc.filter(
                (n) => n.branchid === String(branch.id)
            );
        }
        if (view === "agency") {
            filteredNumbers = numbersSrc.filter(
                (n) => n.agencyid === String(agency?.id)
            );
        }
        segregated = segregateClientsByProfilePresence(filteredNumbers);
        const enumsSubObj = subObject(enumsQuery.data, enumKeys);
        // const enumNamesObj = enumsSubObj.map(e => e.Name)
        // const parsedNumbers =  filteredNumbers.map(n => parseServerEnums(n, enumsSubObj, enumKeys))
        // console.log('parsedNumbers',parsedNumbers);

        const total = {
            ...defaultValues(enumsSubObj),
            agencies: {},
            branches: {},
        };

        for (const client of filteredNumbers) {
            // const {ageRange=['noData'], gender=['noData'], identity=['noData'], currentHousing=['noData'], miscCharacteristics=['noData']} = client.clientdata || {}
            const numFieldsFilled =
                Object.values(client.clientdata || {}).length - 2;
            const hasProfile = numFieldsFilled > 0;
            const isIndigenous =
                client.clientdata?.miscCharacteristics?.includes?.(
                    "Indigenous"
                ) || false;
            const staysAtShelter =
                client.clientdata?.currentHousing?.includes?.("Shelter");
            const isNewcomer =
                client.clientdata?.identity?.includes?.("Newcomer");
            const usesShelterFrequently = client.clientdata?.frequentShelterUse;
            const hasStatusCard = client.clientdata?.statusCardCount;
            const hasStudentVisa = client.clientdata?.hasStudentVisa;
            const isRefugee = client.clientdata?.isRefugee;

            const mutateList = [total];
            if (view === "national") {
                if (!total.branches[client.branchid]) {
                    total.branches[client.branchid] = {
                        Name: client.branchname,
                        id: client.branchid,
                        agencies: {},
                        ...defaultValues(enumsQuery.data),
                    };
                }
                if (
                    !total.branches[client.branchid].agencies[client.agencyid]
                ) {
                    total.branches[client.branchid].agencies[client.agencyid] =
                        {
                            Name: client.agencyName,
                            id: client.agencyid,
                            branchName: client.branchname,
                            branchid: client.branchid,
                            ...defaultValues(enumsQuery.data),
                        };
                }
                // total.branches[client.branchid][hasProfile ? 'withProfile' : 'withoutProfile'].push(client)
                // total.branches[client.branchid].agencies[client.agencyid][hasProfile ? 'withProfile' : 'withoutProfile'].push(client)

                mutateList.push(total.branches[client.branchid]);
                mutateList.push(
                    total.branches[client.branchid].agencies[client.agencyid]
                );

                // mergeSum([branches[client.branchid], curr])
                // mergeSum([branches[client.branchid].agencies[client.agencyid],curr])
            }
            if (view === "branch" || view === "national") {
                if (!total.agencies[client.agencyid]) {
                    total.agencies[client.agencyid] = {
                        Name: client.agencyname,
                        id: client.agencyid,
                        branchname: client.branchname,
                        branchid: client.branchid,
                        agencyRankInBranch: 0,
                        ...defaultValues(enumsQuery.data),
                    };
                }
                // total.agencies[client.agencyid][hasProfile ? 'withProfile' : 'withoutProfile'].push(client)
                mutateList.push(total.agencies[client.agencyid]);
            }
            for (const subscriber of mutateList) {
                if (hasProfile) {
                    subscriber?.withProfile?.push?.(client);
                    // Fill in enum data
                    for (const key of enumKeys) {
                        for (const e of client.clientdata[key] || []) {
                            e == null
                                ? subscriber[key].Unknown++
                                : subscriber[key][e]++;
                        }
                    }
                    // Record number of fields filled
                    subscriber.numFieldsFilledArr.push(numFieldsFilled);

                    // Record misc counts
                    isIndigenous && subscriber.indigenousCount++;
                    isIndigenous && hasStatusCard && subscriber.hasStatusCard++;

                    staysAtShelter && subscriber.shelterCount++;
                    staysAtShelter &&
                        usesShelterFrequently &&
                        subscriber.frequentShelterUse++;

                    isNewcomer && subscriber.newcomerCount++;

                    isNewcomer && hasStudentVisa && subscriber.hasStudentVisa++;
                    isNewcomer && isRefugee && subscriber.isRefugee++;
                } else {
                    subscriber?.withoutProfile?.push?.(client);
                }
            }
        }

        // const agenciesSortedByActiveUsers = Object.entries(branches).sort(compareActiveUsersEntries)
        // const agenciesSortedByProfileAmounts = Object.values(total.agencies).map(countProfilePresence).sort(compareProfilePresence)
        // const branchesWithAgenciesSortedByActiveUsers = Object.values(total.branches).map((b: any) => {
        //     const sortedAgencies = Object.values(b.agencies).map(countProfilePresence).sort(compareProfilePresence)
        //     return {
        //             ...b,
        //             agencies: sortedAgencies
        //         }
        // }).map(countProfilePresence).sort(compareProfilePresence)
        // console.log('sortedAencies', agenciesSortedByActiveUsers);
        // console.log('sortedBranches', branchesWithAgenciesSortedByActiveUsers);
        // sortedByActiveUsers.forEach((b: any, i) => {
        //     branches[b.id].branchRank = i + 1
        // })
        // sortedByActiveUsersAgencies.forEach((a: any, i) => {
        //     agencies[a.id].agencyRankInBranch = i + 1
        //     agencies[a.id].agencyRankTotal = i + 1
        // })

        // Turn objects into arrays
        total.branches = Object.values(total.branches);
        total.agencies = Object.values(total.agencies);
        for (const branch of total.branches) {
            branch.agencies = Object.values(branch.agencies);
        }

        const { agencies, branches } = total;

        // Compute aggregates
        for (const branch of total.branches) {
            computeAggregates(branch);
            for (const agency of branch.agencies) {
                computeAggregates(agency);
            }
        }
        for (const agency of total.agencies) {
            computeAggregates(agency);
        }
        computeAggregates(total);
        // console.log('completed demographics calculations in', performance.now() - start, 'ms');
        setData(total);
    }
    // console.log('data',data);
    return {
        data,
        isPending,
    };
};

function computeAggregates(obj) {
    const { withProfile = [], withoutProfile = [], numFieldsFilledArr } = obj;
    obj.totalCount = withProfile.length + withoutProfile.length;
    obj.withProfileCount = withProfile.length;
    obj.withoutProfileCount = withoutProfile.length;
    obj.avgNumFieldsFilled =
        numFieldsFilledArr.reduce((a, c) => a + c, 0) / obj.totalCount;
}

function segregateClientsByProfilePresence(numArr) {
    return numArr.reduce(
        (acc, num) => {
            const hasProfile = Object.values(num.clientdata || {}).length > 2;
            hasProfile ? acc[0].push(num) : acc[1].push(num);
            return acc;
        },
        [[], []]
    );
}

function getStartingEntriesFromEnum(enumArr: BasicJetDB[]) {
    return {
        Unknown: 0,
        ...Object.fromEntries(enumArr.map((e) => [e.Name, 0])),
    };
}
