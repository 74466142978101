import { A4_CONTENT_HEIGHT, A4_CONTENT_WIDTH, A4_HEIGHT, A4_MARGIN, A4_WIDTH, themeStorageKey } from "@/util/constants";
import { toCanvas, toPixelData, toPng } from "html-to-image";
import jsPDF from "jspdf";

export function downloadCSV(fileName, headers, rows) {
    console.log(rows);
    const preamble = "data:text/csv;charset=utf-8,";
    const csvContent =
        headers + "\n" + rows.map((cell) => cell.join(",")).join("\n");
    const encodedUri = encodeURI(preamble + csvContent + "\n\n" + csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", fileName + ".csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export function downloadImg(fileName, img) {
    const a = document.createElement("a"); //Create <a>
    a.setAttribute("href", img); //Image Base64 Goes here
    a.setAttribute("download", fileName); //File name Here
    // a.href = "data:image/png;base64," + img; //Image Base64 Goes here
    // a.download = "fileName" + 'png'; //File name Here
    a.click(); //Downloaded file
}

const excludeFromExportRender: (node: Element) => boolean = (node) => {
    return node.getAttribute("data-no-export") === "true" ? true : false;
};

export function downloadCanvas(canvas, fileName) {
    downloadImg(fileName, canvas?.toBase64Image?.());
}

export function formatImageDimToFillA4(w, h, margin = 20) {
    const factor = Math.max(w / (A4_WIDTH - margin * 2), h / (A4_HEIGHT - margin * 2));
    return [w / factor, h / factor];
}

export function imageDimToA4Size(w, h) {
    return [w / A4_WIDTH, h / A4_HEIGHT];
}

// export function downloadElAsImage(
//     el,
//     fileName = "img",
//     imageSuffix = "png",
//     backgroundColor
// ) {
//     return new Promise((r) => {

//         html2canvas(el, {
//             backgroundColor: backgroundColor || "transparent",
//             ignoreElements: excludeFromExportRender,

//             // foreignObjectRendering: true
//         }).then((canvEl) => {
//             canvEl.toBlob(
//                 (data) => {
//                     const url = URL.createObjectURL(data);
//                     downloadImg(fileName + "." + imageSuffix, url);
//                     URL.revokeObjectURL(url);
//                 },
//                 `image/${imageSuffix}`,
//                 0.8
//             );
//         });
//         r(undefined);
//     });
// }

//semibold: 600,
//normal: 400,

export function addDefaultPDFTitlePage(pdf: jsPDF, title, subject) {
    // SETUP
    // pdf.addPage();
    // pdf.addFont('Helvetica', '')
    // pdf.addFileToVFS

    // CVMLOGO
    // 1219 x 426
    pdf.addImage("cvm.png", "png", 101, 18, 250, 83, "cvm", "FAST");
    // TITLE
    pdf.setFontSize(24);
    pdf.setFont("helvetica", "normal");
    pdf.setTextColor("#808000");
    pdf.text(title, 410, 54);
    // SUBJECT
    pdf.setFontSize(18);
    pdf.setTextColor("#A12B39");
    pdf.text(subject, 101, 161);

    // TIMESTAMP
    pdf.setFontSize(12);
    pdf.setFont("helvetica", "normal");
    pdf.setTextColor("black");
    pdf.text(
        `Generated on ${new Date().toLocaleString("en-CA", {
            dateStyle: "full",
            timeStyle: "short",
        })}`,
        101,
        194
    );
    // SEPARATOR
    pdf.setLineWidth(2)
    pdf.line(20,204,A4_WIDTH - A4_MARGIN,204)
    return pdf;
}

export async function getImageElFromURL(url, width, height) {
    return new Promise((res, rej) => {
        const img = new Image();
        img.onload = () => res(img);
        img.src = url;
    });
}

export function getNewPDFWithDefaultConf() {
    return new jsPDF({
        putOnlyUsedFonts: true,
        unit: "px",
        format: "a4",
        compress: true,
        hotfixes: ["px_scaling"],
    });
}

export async function elementToCanvasAndURL(el) {
    return new Promise((r, _) => {
        // let temptImg = new Image()
        const theme = localStorage.getItem(themeStorageKey) || 'light'
        const backgroundColor = theme === 'light' ? 'white' : 'black'
        console.log('backgroundColor', backgroundColor);
        console.log('theme', theme);
        toCanvas(el, {backgroundColor: backgroundColor, quality: 0.8, cacheBust: true}).then((url) => {
            const fakeEl = {
                remove: () => {
                    url = null
                    url?.remove?.()
                }
            }
            // for (let i = 0; i < nonClamped.length; i += 4) {
            //     //check if the pixels in each array are the same
            //     if (nonClamped[i] !== url[i] || nonClamped[i + 1] !== url[i + 1] || nonClamped[i + 2] !== url[i + 2] || nonClamped[i + 3] !== url[i + 3]) {
            //         throw new Error(' clamped at ' + i);
            //     } 
            // }
            // console.log('nonclamped',nonClamped);
            // console.log('pixeldata', nonClamped instanceof Uint8Array, url);
            // console.log('windowwidth', window.innerWidth, window.innerHeight, );
            // const {
            //     top,
            //     left,
            //     width,
            //     height,
            // } = el.getBoundingClientRect();
            // console.log('diff',offsetWidth, width, offsetHeight, height);
            // const rgbaData = {
            //     data: url,
            //     width: offsetWidth,
            //     height: offsetHeight,
            // }
            r([fakeEl, url]);
            // console.log("Time to get turn element into png", performance.now() - start);
        })
    });
}

type ConstrainDimensionsToA4Props = {
    w: number;
    h: number;
    constraint: "width" | "height";
};
export function contrainDimensionsToA4(w, h, maxW=A4_CONTENT_HEIGHT * 2,maxh=A4_CONTENT_HEIGHT * 2,) {
    const factor = Math.max(w / maxW, h / maxh);
    return [w / factor, h / factor];
}


export function addTableToPDF(pdf, table, tableTitle) {
    return new Promise(async (res, rej) => {
        const tableStartPageIndex = pdf.getNumberOfPages() + 1
        pdf.setPage(tableStartPageIndex - 1)
        await table?.exportToPDF?.()
        pdf.setPage(tableStartPageIndex)
        pdf.setFontSize(20)
        pdf.text(tableTitle, A4_WIDTH / 2, A4_MARGIN * 2,{align: 'center'})
        res(null)
    })
}


export function downloadElementAsPng(element) {
    toPng(element).then(png => {
        const link = document.createElement('a');
        link.download = 'chart.png';
        link.href = png
        link.click();
    })
}

export function downloadRemoteFile(url, fileName) {
    const a = document.createElement('a');
    a.href = url
    a.download = fileName
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)

}