import { cn } from '@/lib/utils';
import React from 'react';
import {motion } from 'framer-motion';
import { nullNum } from '@/util/miscUtils';
import { CircleIcon } from 'lucide-react';

export const RadialProgress = ({className, ratio, activeClass, size, children, ...props}) => {
    return (
        <div className={cn('overflow-visible relative flex  w-[4rem] items-center justify-center', className)} {...props}>
            <div className="absolute -rotate-90  overflow-visible w-full h-full z-[1] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ">
                <Circle ratio={ratio}  className=' w-full h-full text-orange stroke-[2px] '/>
            </div>
            <div className="absolute -rotate-90 w-full h-full  overflow-visible top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ">
                <Circle ratio={1.0} className='w-full h-full text-muted stroke-[2px]'/>
            </div>
            {children}
        </div>
    );
};


const Circle = ({ratio, ...props}) => {
    const safeRatio = Math.min(Math.max(ratio, 0.01), 1);
    return (
    <motion.svg
            {...props}
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
        <motion.circle
            strokeDasharray={'180 360'}
            initial={false}
            animate={{pathLength: nullNum(safeRatio + 0.01)}}
            fill='none'
            stroke='currentColor'
            cx="12"
            cy="12"
            r="11"
        />
        </motion.svg>
    )
}