import { cn } from '@/lib/utils';
import { scrollInfo, useMotionTemplate, useMotionValue, useMotionValueEvent, useScroll, motion } from 'framer-motion';
import { ChevronUp } from 'lucide-react';
import React, { useState } from 'react';


type BackToTopProps =  {
    className?: string
    element?: JSX.Element
}

export const BackToTop = ({className, element, ...props}: BackToTopProps) => {
    const [visible, setVisible] = useState(false)
    const {scrollYProgress, scrollY} = useScroll()
    useMotionValueEvent(scrollY,'change',checkForVisibility)

    function checkForVisibility(ys) {
        setVisible(ys > 900)
    }
    function scrollToTop() {
        if (element) {       
        } else {
            window.scrollTo({top: 0, behavior: 'smooth'})
        }
    }
    return (
        <div>
            <motion.div  
                variants={variants}
                initial={false}
                animate={visible ? 'visible' : 'invisible'}
                className={cn('fixed top-8 z-[5]',className)}>
                <div className="flex py-3 px-4 shadow-md items-center justify-center bg-white rounded-full hover:cursor-pointer" onClick={scrollToTop}>
                    <span className='text-sm pr-2 '>Back to top</span>
                    <ChevronUp/>
                </div>
            </motion.div>

        </div>
    );
};



const variants = {
    visible: {
        right:8,
        opacity: 100
    },
    invisible: {
        right: 0,
        opacity: 0
    }
}