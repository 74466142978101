import { cn } from "@/lib/utils"

export const PinCheckingCell = ({pinned=false, doublePinned, rightScrollRef, className, children, hidden, ...props}) => {
  const zIndexClass = doublePinned ? 'z-[3]' : 'z-[1]'
  let shadowClass = ''
  if (pinned === 'top') shadowClass += 'before:fade-column ' 
  if (doublePinned && pinned === 'left') shadowClass += 'before:fade-column before:flex-none before:h-fit  '
  if (doublePinned && pinned === 'right') shadowClass += 'after:fade-column '

  if (pinned === 'right') shadowClass += 'before:fade-left '
  if (pinned === 'left') shadowClass += 'after:fade-right '
  if (hidden) return null
  return (
    <td 
      className={cn(
          " first:pl-4 last:pr-4 px-2 py-1 align-middle",
          className,
          !!pinned && `${shadowClass} ${zIndexClass}`,
          pinned === 'right' && 'sticky top-0 right-0 opacity-95',
          pinned === 'left' && 'sticky top-0 z-[1] left-0 opacity-95',
          pinned === 'top' && 'bg-background sticky top-0 opacity-95 z-[2]',
        )}
      {...props}
      >{children}
    </td>
  )
}
//   {/* <td className={`px-4 py-3 align-middle ${className}  `} {...props}>{children}</td>
// ) : pinned === 'right' ? (
//   <th className={`px-4 py-3 align-middle sticky top-0 right-0 opacity-95 ${zIndexClass} ${shadowClass}
//     ${className}`} {...props}>
//     {children}
//   </th>
// ) : pinned === 'left' ? (
//   <th className={`px-4 py-3 align-middle sticky top-0 z-[1] left-0 opacity-95 ${shadowClass} ${className} ${zIndexClass}`} {...props}>{children}</th>
// ) : pinned === 'top' ? (
//   <th className={`px-4 py-3 align-middle sticky top-0 opacity-95 z-[2] ${className} `} {...props}>{children}</th>
// ) : (
//   <td className={`px-4 py-3 align-middle ${className} `} {...props}>{children}</td>
// ) */}