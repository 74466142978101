import { postPromise } from "@/api/promises";
import { ROUTE_IDS } from "@/api/routeIDs";
import { composeFormData } from "@/util/apiUtils";
import { useMutation, useQueryClient } from "@tanstack/react-query"

export const modBranch = (params) => 
    postPromise(import.meta.env.VITE_API_BASE_URL, composeFormData(params, ROUTE_IDS.MOD_BRANCH))

export default function useModBranch(props) {
    const {onSuccess, ...otherProps } = props || {}
    const client = useQueryClient();
    const mutation = useMutation({
        mutationKey: [ROUTE_IDS.MOD_BRANCH],
        mutationFn: modBranch,
        onSuccess: (data:any, req) => {
            if (data?.status?.includes?.('not')) throw new Error(data.status)
            client.invalidateQueries({queryKey: [ROUTE_IDS.GET_ALL_REGIONS]})
            onSuccess && onSuccess(data, req)
        },
        ...otherProps
    });
    return mutation;
}
