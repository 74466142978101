import { Check, ChevronsUpDown } from "lucide-react";
import * as React from "react";

import { Button } from "@/components/ui/button";
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/components/ui/command";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { FormControl } from "@/components/ui/form";
import { capitalizeFirst } from "../../util/textUtils";
import { cva } from "class-variance-authority";
import { preventDefault, stopPropagation } from "../../util/miscUtils";


export const SelectVariants = cva(
    "w-[200px] justify-between",
    { 
      variants: {
        variant: {
          large: '',
          medium: "w-full",
          small: "w-[15rem]",
          xs: "w-[10rem]",
        }
      },
      defaultVariants: {
        variant: "small"
      }
    }
  )

interface ObjectOption extends Object {
    id?: string,
    [key: string]: any
} 
type Option = string | number | ObjectOption;

interface SelectProps {
    options: Option[];
    value: Option | string | number;
    open?: boolean | undefined;
    handleChange: (option: Option) => void;
    name?: string;
    showSearch?: boolean;
    closeOnPointerOut?: boolean;
    closeOnItemSelect?: boolean;
    ignoreBlur?: boolean;
    openOnFocus?: boolean;
    inForm?: boolean;
    variant?: "small" | "medium" | "large" | "xs";
    placeholder?: string;
    searchLabel?: string;
    emptyMessage?: string;
    popupClassName?: string;
    buttonClassName?: string;
    disabled?: boolean;
    popoverContentProps?: React.ComponentPropsWithoutRef<typeof PopoverContent>;
    side?: "left" | "top" | "right" | "bottom",
    valueIsID?: boolean;
    valueFn?: (option: Option) => string | number;
    renderFn?: (option: Option) => string | React.ReactNode;
    displayFn?: (option: Option) => string | React.ReactNode;
    IDFn?: (option: Option) => string | number;
}

export const Select = React.forwardRef<
    React.ElementRef<typeof Button>,
    SelectProps
>(({
    options,
    value,
    open: controlledOpen,
    handleChange,
    name = "",
    showSearch = false,
    closeOnPointerOut = true,
    closeOnItemSelect = true,
    ignoreBlur = false,
    openOnFocus = false,
    inForm = true,
    disabled,
    variant,
    side='bottom',
    popupClassName='',
    buttonClassName='',
    popoverContentProps,
    placeholder = `Select`,
    searchLabel = `Search`,
    emptyMessage = `No results found.`,
    valueIsID = false,
    valueFn = (opt) => (opt as ObjectOption)?.id,
    renderFn = valueFn,
    displayFn= renderFn,
    IDFn = (opt) => valueFn(opt),
    ...props
}, ref) => {
    const [isOpen, setIsOpen] = React.useState(false);

    function onOptClick(opt: Option) {
        handleChange(opt);
        closeOnItemSelect && setIsOpen(false);
    }

    function close() {
        setIsOpen(false);
    }

    function open() {
        setIsOpen(true);
    }

    const displayValue: Option = React.useMemo(() => {
        if (!value) return null;
        return valueIsID 
            ? options.find((opt) => String(IDFn(opt)) === String(value)) 
            : value as Option;
    },[value, options, valueIsID])
    return (
        <Popover open={controlledOpen || isOpen} onOpenChange={setIsOpen}>
            <PopoverTrigger asChild>
                {inForm ? (
                    <FormControl>
                        <Button
                            disabled={disabled}
                            onFocus={openOnFocus ? open : undefined}
                            variant="outline"
                            type="button"
                            role="combobox"
                            className={cn(SelectVariants({variant}), !value && "text-muted-foreground", buttonClassName)}
                        >
                            {value ? displayFn(displayValue) : placeholder}
                            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                        </Button>
                    </FormControl>
                ) : (
                        <Button
                            disabled={disabled}
                            onFocus={openOnFocus ? open : undefined}
                            variant="outline"
                            type="button"
                            role="combobox"
                            className={cn(
                                SelectVariants({variant}),
                                !value && "text-muted-foreground",
                                buttonClassName
                            )}
                        >
                            {displayValue ? renderFn(displayValue) : placeholder}
                            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                        </Button>
                )}
            </PopoverTrigger>
            <PopoverContent align="start" onBlur={ignoreBlur ? stopPropagation : undefined} className={cn("w-[200px] p-0", popupClassName)} side={side} onPointerLeave={closeOnPointerOut ? close : undefined} {...popoverContentProps}>
                <Command>
                    { showSearch &&
                        <CommandInput hide={!showSearch} placeholder={searchLabel} />
                    }
                    <CommandList>
                        <CommandEmpty>{emptyMessage}</CommandEmpty>
                        <CommandGroup>
                            {options?.map?.((opt) => (
                                <CommandItem
                                    tabIndex={0}
                                    // value={valueFn(opt) || ''} 
                                    key={IDFn(opt)}
                                    onSelect={() => onOptClick(opt)}
                                >
                                    <Check
                                        className={cn(
                                            "mr-2 h-4 w-4",
                                            displayValue && IDFn(opt) === IDFn(displayValue)
                                            ? "opacity-100"
                                            : "opacity-0"
                                        )}
                                    />
                                    {renderFn(opt)}
                                </CommandItem>
                            ))}
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    );
});

Select.displayName = "Select";