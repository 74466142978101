import { postPromise } from "@/api/promises";
import { ROUTE_IDS } from "@/api/routeIDs";
import { composeFormData } from "@/util/apiUtils";
import { invalidateQueryKeys } from "@/util/queryUtils";
import { useMutation, useQueryClient } from "@tanstack/react-query"

export const flagAgency = (params) => 
    postPromise(import.meta.env.VITE_API_BASE_URL, composeFormData(params, ROUTE_IDS.FLAG_AGENCY))

export default function useFlagAgency(props) {
    const {onSuccess, ...otherProps } = props || {}
    const client = useQueryClient();
    const mutation = useMutation({
        mutationKey: [ROUTE_IDS.FLAG_AGENCY],
        mutationFn: flagAgency,
        onSuccess: (data, req) => {
            console.log('id',req?.agencyid)
            invalidateQueryKeys(client, [
                [ROUTE_IDS.GET_AGENCIES_IN_REGION],
                [ROUTE_IDS.GET_AGENCY, {agencyID: req?.agencyid}]
            ]).then(() => {
                onSuccess && onSuccess(data, req)
            })            
        },
        ...otherProps
    });
    return mutation;
}
