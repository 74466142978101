import useMyUserData from '@/api/user/getMyUserData';
import { AdminThirtyDayInactivity } from '@/routes/Main/Reporting/Presets/ThirtyDayInactivity/AdminThirtyDayInactivity';
import { BMThirtyDayInactivity } from '@/routes/Main/Reporting/Presets/ThirtyDayInactivity/BMThirtyDayInactivity';

export const ThirtyDayInactivity = ({...props}) => {
    const meQuery = useMyUserData()
    return (
        <div className="bg-background rounded-2xl px-6 py-4">
        {    Number(meQuery.data?.adminLevel) > 10 ? (
            <AdminThirtyDayInactivity />
        ) : (
            <BMThirtyDayInactivity meQuery={meQuery}/>
        )}
        </div>
    )
};
