import { Moon, Sun } from "lucide-react"

import { Button } from "@/components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { cn } from "@/lib/utils"
import { getSystemTheme, useTheme } from "@/components/theme/theme-provider"

export function ModeToggle() {
  const { theme, setTheme } = useTheme()
    function toggleTheme() {
        let newTheme
        switch (theme) {
            case 'light':
                newTheme = 'dark'
                break
            case 'dark':
                newTheme = 'light'
                break
            case 'system':
                newTheme = getSystemTheme() === 'dark' ? 'light' : 'dark'
                break
            default:
                newTheme = 'dark'
                break
        }
        console.log(theme,newTheme);
        setTheme(newTheme)
        console.log('swapping from', theme);
    }
  return (
    <Button onClick={toggleTheme}>
        <div className={cn("swap ", theme === 'light' && 'swap-active')}>
            <Sun className="swap-off h-[1.2rem] w-[1.2rem]" />
            <Moon className="swap-on absolute h-[1.2rem] w-[1.2rem]" />
        </div>
    </Button>
    // <DropdownMenu>
    //   <DropdownMenuTrigger asChild>
    //     <Button variant="outline" size="icon">
    //       <Sun className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" />
    //       <Moon className="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" />
    //       <span className="sr-only">Toggle theme</span>
    //     </Button>
    //   </DropdownMenuTrigger>
    //   <DropdownMenuContent align="end">
    //     <DropdownMenuItem onClick={() => setTheme("light")}>
    //       Light
    //     </DropdownMenuItem>
    //     <DropdownMenuItem onClick={() => setTheme("dark")}>
    //       Dark
    //     </DropdownMenuItem>
    //     <DropdownMenuItem onClick={() => setTheme("system")}>
    //       System
    //     </DropdownMenuItem>
    //   </DropdownMenuContent>
    // </DropdownMenu>
  )
}
