import { FormControl, FormDescription, FormField, FormHeader, FormItem } from '@/components/ui/form';
import { cva } from 'class-variance-authority';
import { addYears, format, subYears } from 'date-fns';
import { CalendarIcon } from 'lucide-react';
import React from 'react';
import { Button } from '../../../components/ui/button';
import { Calendar } from '../../../components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '../../../components/ui/popover';
import { cn } from '../../../lib/utils';


const defaultMax = addYears(Date.now(),1)
const defaultMin = subYears(Date.now(),20)

const DatePickerVariants = cva(
  "flex flex-col items-between py-3 ",
  { 
    variants: {
      variant: {
        large: 'w-full',
        medium: "w-[240px]",
        small: "w-[15rem]"
      }
    },
    defaultVariants: {
      variant: "small"
    }
  }
)

export const ComposedFormDatepicker = ({
    control,
    name, 
    variant=null,
    label=name,
    placeholder='Pick a date',
    maxDate=defaultMax,
    minDate=defaultMin,
    description=null,
    showDropdownMonth=true,
    showDropdownYear=true,
    closeOnPointerOut=false,
    ...props
  }) => {
    const [open, setOpen] = React.useState(false)


    return (
        <FormField
        control={control}
        name={name}
        render={({ field }) => (
          <FormItem className={cn(DatePickerVariants({variant}))}>
            <FormHeader label={label}/>
            <Popover onOpenChange={(o) => {console.log('auto',o); setOpen(o)}} open={open}>
              <PopoverTrigger asChild>
                <FormControl>
                  <Button
                    ref={field.ref}
                    variant={"outline"}
                    className={cn(
                      "w-full pl-3 text-left font-normal pt-2",
                      !field.value && "text-muted-foreground"
                    )}
                  >
                    {field.value ? (
                      format(field.value, "PPP")
                    ) : (
                      <span>{placeholder}</span>
                    )}
                    <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                  </Button>
                </FormControl>
              </PopoverTrigger>
              <PopoverContent 
                closeOnPointerOut={false} 
                className="w-auto p-0" 
                align="start" 
                onPointerLeave={() => closeOnPointerOut && setOpen(false)}>
                <Calendar
                  mode="single"
                  showMonthDropdown={showDropdownMonth}
                  showYearDropdown={showDropdownYear}
                  selected={field.value}
                  onSelect={(e) => {
                    field.onChange(e)
                    setOpen(false)
                  }}
                  minDate={minDate}
                  maxDate={maxDate}
                  disabled={(date) =>
                    (date < minDate) || (date > maxDate)
                  }
                  autoFocus
                />
              </PopoverContent>
            </Popover>
            <FormDescription>
              {description}
            </FormDescription>
            {/* <FormMessage /> */}
          </FormItem>
        )}
      />
    );
};
