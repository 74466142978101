import useAllNumbers from "@/api/numbers/getAllNumbers";
import { selectNumbersWithClientData } from "@/api/selectors/selectUnassignedNumbers";
import useMyUserData from "@/api/user/getMyUserData";
import { PageHeader } from "@/components/misc/PageHeader";
import { Table } from "@/components/tables/Table";
import { allClientFieldsCols } from "@/components/tables/columnHelpers/allClientFieldsCols";
import { clientsColumnHelper } from "@/components/tables/columnHelpers/clientsColumnHelper";
import { Button } from "@/components/ui/button";
import { useState } from "react";

export const AllClients = ({ ...props }) => {
    const [showAllFields, setShowAllFields] = useState(false);
    const myDataQuery = useMyUserData();
    const clientsQuery = useAllNumbers({
        select: selectNumbersWithClientData
    });
    return (
        <div className="h-full flex flex-col">
            <PageHeader title={"All Clients"}></PageHeader>
            <Table
                retrieveSearchParamFilters
                cellClassName={showAllFields ? "!py-0 !px-0" : ''}
                exportFileName="all_clients"
                data={clientsQuery.data}
                columnHelper={showAllFields ? allClientFieldsCols : clientsColumnHelper}
                maxHeightPixels={'100%'}
            >
                <Button onClick={() => setShowAllFields(prev => !showAllFields)}>
                    {showAllFields ? "Show Compact View" : "Show All Fields"}
                </Button>
            </Table>
        </div>
    );
};

export const AllClientsLoader = (queryClient) => async () => {
    return { };
};
