import { useSendPasswordResetEmail } from '@/api/auth/sendResetPasswordEmail';
import { ErrorAlert } from '@/components/blockalerts/ErrorAlert';
import { BackButton } from '@/components/buttons/BackButton';
import { Spacer } from '@/components/misc/Spacer';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { useRHF } from '@/hooks/useRHF';
import { useYupValidationResolver } from '@/hooks/useYupValidationResolver';
import { useQueryResultToasts } from '@/wrappers/toasts';
import { ComposedForm } from '@/wrappers/ui/form/ComposedForm';
import { ComposedFormText } from '@/wrappers/ui/form/ComposedFormText';
import Yup from '@/wrappers/yup';
import { Link, useNavigate } from 'react-router-dom';

const validationSchema = Yup.object({
    email: Yup.string().email().required('Enter an email').default(''),
})
export const ForgotPassword = ({ ...props}) => {
    const nav = useNavigate()
    const resolver = useYupValidationResolver(validationSchema);
    const form
        = useRHF({resolver, defaultValues: validationSchema.getDefault(), mode: 'onBlur'});
    const queryResultToasts = useQueryResultToasts()
    const sendEmailMutation = useSendPasswordResetEmail(queryResultToasts(
        "Email Sent",
        "Error sending email"
    ));
    
    function onSubmit(fields) {
        sendEmailMutation.mutate(fields);
    }

    const hasSubmitted = sendEmailMutation.isSuccess
    
    return (
        <Card className='max-w-full lg:w-[35rem]'>
            {hasSubmitted && (
                <>
                    <CardHeader>
                        <CardTitle>Please check your email</CardTitle>
                        <CardDescription className='max-w-[80%]'>
                            If we've found an eligible account associated with the email <span className='text-secondary-foreground'>{form.watch('email')}</span>,  we will have sent a set of reset instructions to that email. 
                        </CardDescription>
                    </CardHeader>
                    <CardContent>
                        <CardDescription className='space-y-2 mt-12'>
                            <p>Don't see the email? check your spam folder. </p>
                            <p>If you're still having trouble logging in, <a href='mailto:support@lnhs.ca' className='text-secondary-foreground ml-1'>Contact Support</a></p>
                        </CardDescription>
                    </CardContent>
                </>
            )}
            {!hasSubmitted && (
                <>
                    <CardHeader>
                        <CardTitle>Forgot Your Password?</CardTitle>
                        <CardDescription className='whitespace-pre-wrap break-words'>
                            We'll send reset instructions to the email address you would like to reset the password for
                        </CardDescription>
                        {sendEmailMutation.isError && (
                            <ErrorAlert className={'!mt-4'} message={'There was an problem sending the reset email'}/>
                        )}
                    </CardHeader>
                    <ComposedForm {...{form, onSubmit}}>
                        <CardContent>
                                <ComposedFormText
                                    variant='large'
                                    control={form.control}
                                    name='email'
                                    label='Email'
                                    // placeholder='Enter your email'
                                    />
                        </CardContent>
                        <CardFooter className='block'>
                            <Button type='submit' variant='default' className='w-full'>Send reset link</Button>
                            <Link to={'/auth/login'}>
                                <BackButton className='mt-2' variant='sm'>Return to login</BackButton>
                            </Link>
                        </CardFooter>

                    </ComposedForm>
                </>

            )}
        </Card>
    );
};
