import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Link } from 'react-router-dom';

export const NoCode = () => {
    return (
        <Card>
            <CardHeader>
                <CardTitle>Something went wrong</CardTitle>
                <CardDescription>We couldn't find an invite code in your URL</CardDescription>
            </CardHeader>
            <CardContent>
                    <p>Try clicking on the invite link in the email we sent you again.</p>
                    <CardDescription>
                        <p>If you're still having trouble logging in, <a href='mailto:support@lnhs.ca' className='text-secondary-foreground ml-1 hover:underline'>Contact Support</a></p>
                    </CardDescription>
            </CardContent>
        </Card>
    );
};
