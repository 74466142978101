import { PieChartSkeleton } from '@/components/skeletons/PieChartSkeleton';
import { ResponsivePie } from '@/components/tables/charts/ResponsivePie';
import { getPieConfig } from '@/routes/Main/Reporting/utils/ChartConfigs';
import { Gender } from '@/util/constants';
import { useMemo } from 'react';
import { doughnutOptions } from '../utils/doughnutOptions';

export const GenderPie = ({clients,chartRef,config: extraConf, ...props}) => {
    const {datasetColors} = extraConf || {}

    const config = useMemo(() => getPieConfig(Gender, clients, c=>c.gender, datasetColors),[clients])

    return config ? (
        <ResponsivePie
            ref={chartRef}
            datasetColors={datasetColors}
            options={doughnutOptions(extraConf)}
            {...config}/>
    ) : <PieChartSkeleton/>;
};
