import { Button } from '@/components/ui/button';
import { Dialog, DialogBody, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { useRHF } from '@/hooks/useRHF';
import { useYupValidationResolver } from '@/hooks/useYupValidationResolver';
import { capitalizeFirst } from '@/util/textUtils';
import { ComposedForm } from '@/wrappers/ui/form/ComposedForm';
import { ComposedFormSelect } from '@/wrappers/ui/form/ComposedFormSelect';
import { ComposedFormText } from '@/wrappers/ui/form/ComposedFormText';
import { ComposedFormMultiSelect } from '@/wrappers/ui/form/ComposedMultiSelect';
import { ShowWhenFieldSibling } from '@/wrappers/ui/form/ShowWhenFieldSibling';
import Yup from '@/wrappers/yup';
import React from 'react';

const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('required').trim().default(''),
    usertype: Yup.string().required('required').default('agency').lowercase(),
    nodeids: Yup.array().required('required').default([]).when('usertype',{
        is: 'admin',
        then: (schema) => schema.notRequired(),
        otherwise:(schema) => schema.min(1, 'Select at least one location')
    })
})

export const InviteUserDialog = ({
    onSubmit,
    branchesQuery,
    agenciesQuery,
    open,
    onOpenChange,
    loading,
    creatableUserTypes

}) => {
    const resolver = useYupValidationResolver(validationSchema);
    const form = useRHF({resolver, defaultValues: validationSchema.getDefault(), mode: 'onBlur', });

    function handleClose() {
        form.reset({...validationSchema.getDefault()});
    }
    console.log(agenciesQuery.data?.map((agency) => agency.id)) ;
    return (
        <Dialog {...{open, onOpenChange}}>
                <DialogContent onCloseAutoFocus={handleClose}>
                    <ComposedForm {...{form, onSubmit}}>
                        <DialogHeader>
                            <DialogTitle>Invite User</DialogTitle>
                            <DialogDescription>Configure permissions for a new user and send an invite for them to fill in their login information</DialogDescription>
                        </DialogHeader>
                        <DialogBody>
                            <ComposedFormSelect
                                buttonClassName='w-full'
                                variant={'large'}
                                control={form.control}
                                name='usertype'
                                label='What Kind of User?'
                                options={creatableUserTypes}
                                onChange={() => form.setValue('nodeids', [])}
                                IDFn={(opt) => opt} 
                                renderFn={(opt) => capitalizeFirst(opt)}/>
                            <div className='pt-6'>
                                <ShowWhenFieldSibling 
                                    noAnimation
                                    sibling='usertype'
                                    is={'agency'}
                                >
                                    <ComposedFormMultiSelect
                                        buttonClassName='w-full'
                                        popupClassName='w-96'
                                        showSearch
                                        valueIsID
                                        control={form.control} 
                                        searchLabel='Search Agencies'                                
                                        name="nodeids" 
                                        label="Authorized Agencies" 
                                        placeholder='Select agencies'
                                        options={agenciesQuery?.data}
                                        renderFn={(agency) => `${agency.branchName} ${agency.Name}`}
                                        IDFn={(agency) => agency.id}
                                        selectedFn={(agency, sel) =>  sel.includes(agency.id)}
                                        />
                                </ShowWhenFieldSibling>
                                <ShowWhenFieldSibling 
                                    noAnimation
                                    sibling='usertype'
                                    is={'branch'}
                                >
                                    <ComposedFormMultiSelect 
                                        buttonClassName='w-full'
                                        showSearch
                                        valueIsID
                                        control={form.control} 
                                        name="nodeids" 
                                        searchLabel='Search Branches'
                                        label="Authorized Branches" 
                                        placeholder='Select branches'
                                        options={branchesQuery?.data}
                                        renderFn={(branch) => branch.Name}
                                        IDFn={(branch) => branch.id}
                                        selectedFn={(branch, sel) => sel.includes(branch.id)}
                                        />
                                </ShowWhenFieldSibling>
                            </div>
                            <ComposedFormText
                                variant='medium'
                                label="Email"
                                name="email"
                                placeholder='Enter the email the invite will be sent to'
                                control={form.control}
                            />
                        </DialogBody>
                        <DialogFooter>
                            <DialogClose asChild>
                                <Button type="reset">Cancel</Button>
                            </DialogClose>
                            <Button variant={'default'} type="submit">Send Invite</Button>
                        </DialogFooter>
                    </ComposedForm>
                </DialogContent>

        </Dialog>
    );
};
