import { Checkbox } from "@/components/ui/checkbox";
import { ComposedFormCheckbox } from "@/wrappers/ui/form/ComposedFormCheckbox";
import { ComposedFormTextarea } from "@/wrappers/ui/form/ComposedFormTextarea";
import { ShowWhenFieldSibling } from "@/wrappers/ui/form/ShowWhenFieldSibling";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { AgeRange, BasicJetDB, Gender } from "../../../util/constants";
import { ComposedFormCheckboxGroup } from "../../../wrappers/ui/form/ComposedFormCheckboxGroup";
import { ComposedFormRadio } from "../../../wrappers/ui/form/ComposedFormRadio";
import Yup from "../../../wrappers/yup";
import { FormSection } from "../../ui/FormSection";
import { FormControl, FormField, FormItem, FormLabel } from "../../ui/form";

export type DemographicsData = {
    ageRange: AgeRange;
    gender: Gender;
    // ethnicity: Ethnicity,
    // race: Set<Race>,
    currentHousing?: BasicJetDB[];
    identity: BasicJetDB[];
    frequentShelterUse: boolean;
    hasStatusCard: boolean;
    hasStudentVisa: boolean;
    isRefugee: boolean;
    miscCharacteristics: BasicJetDB[];
    indigenousType: BasicJetDB[];
    housingOutcome: BasicJetDB[];
    financialResource: BasicJetDB[];

    otherGoals: string;
    otherGoalsOutcome: string;
    otherGender: string;
    otherCurrentHousing: string;
    otherIdentity: string;
    otherMiscCharacteristics: string;
};

export const Demographics = ({
    form,
    enumsQuery,
    clientData,
    sectionIndex,
    ...props
}) => {
    const params = useParams();
    const { branchID, agencyID, objectID: objectid } = params;
    const {
        data: {
            gender,
            ageRange,
            identity,
            currentHousing,
            miscCharacteristics,
        } = {},
    } = enumsQuery
    
    const { setValue, formState, reset, control } = form;

    const newcomerID = useMemo(
        () => identity?.find?.((i) => i.Name === "Newcomer")?.id,
        [identity]
    );
    const shelterID = useMemo(
        () => currentHousing?.find?.((i) => i.Name === "Shelter")?.id,
        [currentHousing]
    );

    // console.log('demo gformstate',getValues());

    return (
        <FormSection sectionIndex={sectionIndex} title={"Client Demographics"}>
            <ComposedFormRadio
                control={control}
                name="ageRange"
                label="Age range"
                options={ageRange}
            />

            <ComposedFormRadio
                control={control}
                name={"gender"}
                label="Gender"
                options={gender}
                subComponentFn={(entry) =>
                    ({
                        Other: (
                            <ShowWhenFieldSibling
                                sibling="gender"
                                includes={entry.id}
                                mode="wait"
                            >
                                <div className="ml-6 pt-3 pb-1">
                                <ComposedFormTextarea
                                    hideLabel
                                    label=''
                                    control={control}
                                    name="otherGender"
                                    placeholder="Describe gender"
                                />
                                </div>
                            </ShowWhenFieldSibling>
                        ),
                    }[entry.Name || entry.label])
                }
            />

            <ComposedFormCheckboxGroup
                control={control}
                name={"currentHousing"}
                label="Current housing"
                entries={currentHousing}
                subComponentFn={(entry) =>
                    entry.Name === "Shelter" && (
                        <ShowWhenFieldSibling
                            sibling="currentHousing"
                            includes={shelterID}
                            onConditionFail={() =>
                                setValue("frequentShelterUse", false)
                            }
                        >
                            <div className="ml-3">
                                <ComposedFormRadio
                                    control={control}
                                    name="frequentShelterUse"
                                    label={null}
                                    options={[
                                        { value: true, label: "Often" },
                                        { value: false, label: "Seldom" },
                                    ]}
                                />
                            </div>
                        </ShowWhenFieldSibling>
                    )
                }
            />
            <ComposedFormCheckboxGroup
                control={control}
                name={"identity"}
                label="Identity"
                entries={identity}
                subComponentFn={(entry) =>
                    ({
                        Indigenous: (
                            <ShowWhenFieldSibling
                                sibling="identity"
                                includes={entry.id}
                                mode="wait"
                                onConditionFail={() => {
                                    setValue("hasStatusCard", false);
                                }}
                            >
                                <div className="ml-6 pt-3 pb-1">
                                    <ComposedFormCheckbox
                                        oneLine
                                        control={control}
                                        name="hasStatusCard"
                                        label="Has status card"
                                    />
                                </div>
                            </ShowWhenFieldSibling>
                        ),
                        Newcomer: (
                            <ShowWhenFieldSibling
                                sibling={"identity"}
                                includes={newcomerID}
                                onConditionFail={() => {
                                    setValue("hasStudentVisa", false);
                                    setValue("isRefugee", false);
                                }}
                            >
                                <div className="ml-6 pt-2 flex flex-col gap-2">
                                    <FormField
                                        control={control}
                                        name={"hasStudentVisa"}
                                        render={({ field }) => (
                                            <FormItem className=" flex space-y-0 items-center gap-2 w-fit">
                                                <FormLabel className="flex flex-row gap-2 hover:cursor-pointer">
                                                    <FormControl>
                                                        <Checkbox
                                                            checked={
                                                                field.value
                                                            }
                                                            onCheckedChange={
                                                                field.onChange
                                                            }
                                                        />
                                                    </FormControl>
                                                    <FormLabel className="pointer-events-none">
                                                        Has student visa
                                                    </FormLabel>
                                                </FormLabel>
                                            </FormItem>
                                        )}
                                    />
                                    <FormField
                                        control={control}
                                        name={"isRefugee"}
                                        render={({ field }) => (
                                            <FormItem className="flex space-y-0 items-center gap-2 w-fit">
                                                <FormLabel className="flex flex-row gap-2 hover:cursor-pointer">
                                                    <FormControl>
                                                        <Checkbox
                                                            checked={
                                                                field.value
                                                            }
                                                            onCheckedChange={
                                                                field.onChange
                                                            }
                                                        />
                                                    </FormControl>
                                                    <FormLabel className="pointer-events-none">
                                                        Is refugee
                                                    </FormLabel>
                                                </FormLabel>
                                            </FormItem>
                                        )}
                                    />
                                </div>
                            </ShowWhenFieldSibling>
                        ),
                        Other: (
                            <ShowWhenFieldSibling
                                sibling="identity"
                                includes={entry.id}
                            >
                                <div className="ml-6 pt-3 pb-1">
                                    <ComposedFormTextarea
                                        hideLabel
                                        className="pt-0"
                                        control={control}
                                        name="otherIdentity"
                                        label=""
                                        placeholder="Describe other component of identity"
                                    />
                                </div>
                            </ShowWhenFieldSibling>
                        ),
                    }[entry.Name])
                }
            />

            <ComposedFormCheckboxGroup
                control={control}
                name="miscCharacteristics"
                label="Personal challenges"
                placeholder="Describe other personal challenges"
                entries={miscCharacteristics}
                subComponentFn={(entry) =>
                    ({
                        Other: (
                            <ShowWhenFieldSibling
                                sibling="miscCharacteristics"
                                includes={entry.id}
                                mode="wait"
                            >
                                <div className="ml-6 pt-3 pb-1">
                                    <ComposedFormTextarea
                                        hideLabel
                                        className="pt-0"
                                        control={control}
                                        name="otherMiscCharacteristics"
                                        label=""
                                        placeholder="Describe other personal challenges"
                                    />
                                </div>
                            </ShowWhenFieldSibling>
                        ),
                    }[entry.Name || entry.label])
                }
            />
        </FormSection>
    );
};
