import { AgencyCell } from "@/components/tables/cells/AgencyCell";
import { BranchCell } from "@/components/tables/cells/BranchCell";
import { LinkedClientCell } from "@/components/tables/cells/LinkedClientCell";
import { noRed, yesGreen } from "@/util/constants";
import { formatPhoneNumber } from "@/util/textUtils";
import { createColumnHelper, filterFns } from "@tanstack/react-table";
import { X } from "lucide-react";

const columnHelper = createColumnHelper();

const stringFilter = filterFns.includesString;
const numRangeFilter = filterFns.inNumberRange;
const multiSelectFilter = filterFns.arrIncludesSome;
const strictStringFilter = filterFns.includesString.autoRemove;

export const goalsAndOutcomesCols = [
    columnHelper.accessor((row) => row.alias, {
        id: "Phone Number",
        size: 10,
        filterFn: stringFilter,
        cell: (info) => (
            <span className="whitespace-nowrap  text-md font-semibold">
                {formatPhoneNumber(info.getValue())}
            </span>
        ),
        header: (info) => <span>Phone Number</span>,
    }),
    columnHelper.accessor((row) => row.branchname, {
        id: "Branch",
        size: 100,
        filterFn: stringFilter,
        cell: ({ row: { original: row } }) => (
            <BranchCell name={row.branchname} id={row.branchid} />
        ),
        header: (info) => <span>Branch</span>,
    }),
    columnHelper.accessor((row) => row.agencyname, {
        id: "Agency",
        size: 100,
        filterFn: stringFilter,
        cell: ({ row: { original: row } }) => (
            <AgencyCell
                name={row.agencyname}
                id={row.agencyid}
                branchid={row.branchid}
            />
        ),
        header: (info) => <span>Agency</span>,
    }),
    columnHelper.accessor(
        (row) =>
            `${row.clientdata?.firstName || ""} ${
                row.clientdata?.lastName || ""
            }`,
        {
            id: "Client",
            size: 10,
            filterFn: stringFilter,
            cell: ({ row: { original } }) => (
                <LinkedClientCell row={original} />
            ),
            header: (info) => <span>Linked Client</span>,
        }
    ),
    columnHelper.display({
        id: "Employment",
        size: 10,
        filterFn: stringFilter,
        cell: ({ getValue, row: { original } }) => (
            <GoalOutcomeCell
                row={original}
                goalOutcomeKeyPair={goalOutcomeKeyPairs["Employment"]}
            />
        ),
        header: (info) => <span>Employment</span>,
    }),
    columnHelper.display({
        id: "Housing",
        size: 10,
        filterFn: stringFilter,
        cell: ({ getValue, column, row: { original } }) => (
            <GoalOutcomeCell
                row={original}
                goalOutcomeKeyPair={goalOutcomeKeyPairs[column.id]}
            />
        ),
        header: (info) => <span>Housing</span>,
    }),
    columnHelper.display({
        id: "Social Services",
        size: 10,
        filterFn: stringFilter,
        cell: ({ getValue, column, row: { original } }) => (
            <GoalOutcomeCell
                row={original}
                goalOutcomeKeyPair={goalOutcomeKeyPairs[column.id]}
            />
        ),
        header: (info) => <span>Social Services</span>,
    }),
    columnHelper.display({
        id: "Healthcare",
        size: 10,
        filterFn: stringFilter,
        cell: ({ getValue, column, row: { original } }) => (
            <GoalOutcomeCell
                row={original}
                goalOutcomeKeyPair={goalOutcomeKeyPairs[column.id]}
            />
        ),
        header: (info) => <span>Healthcare</span>,
    }),
    columnHelper.display({
        id: "Increase Financial Benefits",
        size: 10,
        filterFn: stringFilter,
        cell: ({ getValue, column, row: { original } }) => (
            <GoalOutcomeCell
                row={original}
                goalOutcomeKeyPair={goalOutcomeKeyPairs[column.id]}
            />
        ),
        header: (info) => <span>Increase Financial Benefits</span>,
    }),
    columnHelper.display({
        id: "Safe Communications",
        size: 10,
        filterFn: stringFilter,
        cell: ({ getValue, column, row: { original } }) => (
            <GoalOutcomeCell
                row={original}
                goalOutcomeKeyPair={goalOutcomeKeyPairs[column.id]}
            />
        ),
        header: (info) => <span>Safe Communications</span>,
    }),
    columnHelper.display({
        id: "Communication with Family and Friends",
        size: 10,
        filterFn: stringFilter,
        cell: ({ getValue, column, row: { original } }) => (
            <GoalOutcomeCell
                row={original}
                goalOutcomeKeyPair={goalOutcomeKeyPairs[column.id]}
            />
        ),
        header: (info) => <span>Communication with Family and Friends</span>,
    }),
    columnHelper.display({
        id: "Legal/Justice System",
        size: 10,
        filterFn: stringFilter,
        cell: ({ getValue, column, row: { original } }) => (
            <GoalOutcomeCell
                row={original}
                goalOutcomeKeyPair={goalOutcomeKeyPairs[column.id]}
            />
        ),
        header: (info) => <span>Legal/Justice System</span>,
    }),
    columnHelper.display({
        id: "Other",
        size: 10,
        filterFn: stringFilter,
        cell: ({ getValue, column, row: { original } }) => (
            <GoalOutcomeCell
                row={original}
                goalOutcomeKeyPair={goalOutcomeKeyPairs[column.id]}
            />
        ),
        header: (info) => <span>Other</span>,
    }),
];

const GoalOutcomeCell = ({ row, goalOutcomeKeyPair }) => {
    const [goal, outcome] = goalOutcomeKeyPair;
    const hasGoal = row.clientdata?.clientGoals?.includes?.(goal);
    const outcomeStr = row.clientdata?.[outcome]?.[0];
    const goalAccomplished = outcomeStr === "Yes";
    // console.log(row.clientdata, row.clientdata?.firstName);
    // console.log("goalacc", goalAccomplished, outcomeStr, goal, outcome);
    if (goalAccomplished)
        return <TargetWithArrowIcon style={{ color: yesGreen }} />;
    if (outcomeStr === "No")
        return (
            <div className="relative max-w-fit min-w-6">
                {hasGoal && <TargetIcon />}
                <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                    <X
                        style={{ color: noRed }}
                        className="w-8 h-8 stroke-[4px]"
                    />
                </div>
            </div>
        );
    if (hasGoal) return <TargetIcon />;
    return <></>;
};
const TargetIcon = (props) => (
    <svg
        {...props}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
        >
            <path d="M11 12a1 1 0 1 0 2 0a1 1 0 1 0-2 0" />
            <path d="M7 12a5 5 0 1 0 10 0a5 5 0 1 0-10 0" />
            <path d="M3 12a9 9 0 1 0 18 0a9 9 0 1 0-18 0" />
        </g>
    </svg>
);
const TargetWithArrowIcon = (props) => (
    <svg
        {...props}
        width="24"
        height="24"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill="currentColor"
            d="M17.603 7.519a7.999 7.999 0 1 1-5.117-5.126L11.439 3.44q-.086.086-.158.185a6.5 6.5 0 1 0 5.088 5.098a1.5 1.5 0 0 0 .192-.162zM14.898 9q.1.486.1 1A5 5 0 1 1 11 5.1v1.546A3.5 3.5 0 1 0 13.353 9zm-4.9 2.5a1.5 1.5 0 0 0 1.45-1.887L13.03 8.03l.03-.03h2.44a.5.5 0 0 0 .354-.146l2-2A.5.5 0 0 0 17.5 5H15V2.5a.5.5 0 0 0-.853-.354l-2 2A.5.5 0 0 0 12 4.5v2.44l-.03.03l-1.582 1.581a1.5 1.5 0 0 0-1.89 1.45a1.5 1.5 0 0 0 1.5 1.5"
        />
    </svg>
);

const goalOutcomeKeyPairs = {
    Employment: ["Employment", "foundEmployment"],
    Housing: ["Housing", "gotHousing"],
    "Social Services": ["Social Services", "accessedSocialServices"],
    Healthcare: ["Healthcare", "usedforHealthCare"],
    "Increase Financial Benefits": [
        "Increase Financial Benefits",
        "increasedFinancialBenefits",
    ],
    "Safe Communications": ["Safe Communications", "achievedSafeCommunication"],
    "Communication with Family and Friends": [
        "Communication with Family and Friends",
        "achievedCommunicationwithFriendsandFamily",
    ],
    "Legal/Justice System": [
        "Legal/Justice System",
        "accessedLegalJusticeSystem",
    ],
    Other: ["Other", "usedforOtherReasons"],
};
