import { Button } from '@/components/ui/button';
import { UploadIcon } from 'lucide-react';
import React from 'react';

export const ExportButton = ({isPending, isExporting, setIsExporting, children='Export to PDF', ...props}) => {
    return (
        <Button
            disabled={isPending || isExporting}
            className="skelechild flex gap-4 text-md justify-between items-center bg-green-600 hover:bg-green-600/80 hover:text-white text-white w-fit"
            onClick={() => setIsExporting(true)}
        >
            {children}
            { isExporting ? (
                <div className="loading loading-spinner"/>
            ) : (
                <UploadIcon className="-mr-1 w-5" />
            )}
        </Button>
    );
};
