import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { downloadRemoteFile } from "@/util/fileUtils";
import { Download } from "lucide-react";

export const HelpEntry = ({ item, previewClicked, downloadClicked }) => {
    function handleDownloadClicked(e) {
        console.log("here");
        downloadRemoteFile(item.url, item.title);
    }
    return (
        <div className={cn("select-none relative mt-2 !overflow-hidden")}>
            <div className="flex items-center justify-between p-4 border rounded-md bg-background overflow-hidden h-18">
                <div>
                    <span className="font-medium">{item.title}</span>
                    <span
                        className="text-sm block hover:underline underline-offset-2 hover:cursor-pointer text-muted-foreground"
                        onClick={() => previewClicked(item)}
                    >
                        Show preview
                    </span>
                </div>

                <div className="ml-auto flex gap-2 items-center self-start">
                    <Button
                        variant="default"
                        size="default"
                        onClick={handleDownloadClicked}
                        className="flex "
                    >
                        Download File <Download className="ml-2 w-5 h-5" />
                    </Button>
                </div>
            </div>
        </div>
    );
};

export const HelpEntries = ({ items, previewClicked, downloadClicked }) =>
    items.map((item) => (
        <HelpEntry
            key={item.id}
            {...{ item, previewClicked, downloadClicked }}
        />
    ));
