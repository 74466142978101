import { Spacer } from '@/components/misc/Spacer';
import { cn } from '@/lib/utils';
import { AlertCircle } from 'lucide-react';
import React from 'react';


export const ErrorAlert = ({message, title='Error', className=undefined, ...props}) => {
    return message ? (
        <div className={cn('rounded-md border border-destructive  p-3 grid grid-cols-[1rem_auto] items-center gap-x-3',className)}>
            <AlertCircle className='text-destructive h-4 w-4' />
            <span className='text-destructive font-medium '>{title}</span>
            <Spacer/>
            <p className=" w-full text-sm h-[1.1rem] text-destructive font-light">{message}</p>
            <div className="col-span-2 [&>*:first-child]:mt-4">
                {props.children}
            </div>
        </div>
    ) : (
        <>
            {props.children}
        </>
    )
};
