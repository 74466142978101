import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from '@/components/ui/alert-dialog';
import { cn } from '@/lib/utils';
import { useAlert } from '@/wrappers/alerts/useAlert';
import { cva } from 'class-variance-authority';

const globalAlertVariants = cva(
    'group', {
    variants: {
        variant: {
            default: "group bg-background",
            destructive:  
            "destructive group border-destructive",
            error:
            "group error border-error-dark",
            success: 
            "group success border-success-dark",
            warning: 
            "warning  border-warning-dark"
        },
    },
    defaultVariants: {
        variant: 'default'
    },
})

export const GlobalAlert = ({ ...props}) => {
    const {isOpen, close, alertProps, confirm, cancel,} = useAlert()
    const {title,cancelText,confirmText,message,showCancel,showConfirm, status} = alertProps

    // console.log('status', status);
    // console.log(globalAlertVariants({variant: status}));
    function handleBlur(e) {
        // console.log('ghandling', e.relatedTarget == null);
        e.relatedTarget == null && close()
    }
    function handleConfirm(e) {
        e.preventDefault()
        confirm()
    }
    function handleCancel(e) {
        e.preventDefault()
        cancel()
    }
    return (
        <AlertDialog open={isOpen} onOpenChange={close}>
            <AlertDialogContent  onBlur={handleBlur} className={cn(globalAlertVariants({variant: 'default'}))}>
                <AlertDialogHeader>
                    {status === 'warning' && (
                            <WarningIcon className='w-16 h-16 text-warning self-center'/>

                    )}
                        {/* <>
                        </> */}
                    <AlertDialogTitle className='group-[.warning]:text-warning-header'>{title}</AlertDialogTitle>
                    <AlertDialogDescription>
                        {typeof message === 'string' ? message : message()}
                    </AlertDialogDescription>
                </AlertDialogHeader>
                { (showCancel || showConfirm) && (
                    <AlertDialogFooter>
                        {showCancel && <AlertDialogCancel className='' onClick={handleCancel}>{cancelText}</AlertDialogCancel>}
                        {showConfirm && <AlertDialogAction className='bg-yellow-700' onClick={handleConfirm}>{confirmText}</AlertDialogAction>}
                    </AlertDialogFooter>
                )}
            </AlertDialogContent>
        </AlertDialog>
    );
};


const WarningIcon = (props) => (
    <svg {...props} width="30" height="30" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M236.8 188.09L149.35 36.22a24.76 24.76 0 0 0-42.7 0L19.2 188.09a23.51 23.51 0 0 0 0 23.72A24.35 24.35 0 0 0 40.55 224h174.9a24.35 24.35 0 0 0 21.33-12.19a23.51 23.51 0 0 0 .02-23.72m-13.87 15.71a8.5 8.5 0 0 1-7.48 4.2H40.55a8.5 8.5 0 0 1-7.48-4.2a7.59 7.59 0 0 1 0-7.72l87.45-151.87a8.75 8.75 0 0 1 15 0l87.45 151.87a7.59 7.59 0 0 1-.04 7.72M120 144v-40a8 8 0 0 1 16 0v40a8 8 0 0 1-16 0m20 36a12 12 0 1 1-12-12a12 12 0 0 1 12 12"/></svg>
)