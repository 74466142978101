import { Button } from "@/components/ui/button"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { SwatchesPicker } from "react-color"

export const ColorPickerPopover = ({color, label, onChange}) => {
    return (
           <Popover >
                <PopoverTrigger asChild>
                    <Button
                        size={'xs'}
                        className='flex gap-2 text-sm pr-2 pl-3 items-center'
                    >
                        <span >
                            {label}
                        </span>
                        <svg className='w-4 h-4'  viewBox='0 0 24 24' style={{fill:color}}>
                            <circle cx={12} cy={12} r={12} />
                        </svg>
                    </Button>
                </PopoverTrigger>
                <PopoverContent side='top'  className=' w-fit'>
                    <SwatchesPicker height={'100%'} width={'520px'}  className='!h-fit' color={color} onChange={(v) => onChange('backgroundColor',v.hex)}/>
                </PopoverContent>
            </Popover>
    )
}