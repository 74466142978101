import useAllBranches from "@/api/branches/getAllBranches";
import useMyUserData, {
    getMyUserDataQueryOptions,
} from "@/api/user/getMyUserData";
import { PageHeader } from "@/components/misc/PageHeader";
import { Table } from "@/components/tables/Table";
import { clientsColumnHelper } from "@/components/tables/columnHelpers/clientsColumnHelper";
import { useAuthorizedPhoneNumbers } from "@/hooks/state/useAuthorizedPhoneNumbers";
import { useMyAuthorizedAgencies } from "@/hooks/state/useMyAuthorizedAgencies";

export const Clients = ({ ...props }) => {
    const myUserDataQuery = useMyUserData();
    const allBranchesQuery = useAllBranches();
    const authorizedAgenciesQuery = useMyAuthorizedAgencies({
        myUserDataQuery,
        allBranchesQuery,
    });
    const authorizedPhoneNumbers = useAuthorizedPhoneNumbers({
        agenciesQuery: authorizedAgenciesQuery,
    });
    return (
        <div className="h-full flex flex-col">
            <PageHeader title={"Clients"}></PageHeader>
            <Table
                retrieveSearchParamFilters
                exportFileName="clients"
                showLoading={authorizedPhoneNumbers.isPending}
                data={authorizedPhoneNumbers.data}
                columnHelper={clientsColumnHelper}
                maxHeightPixels={"100%"}
            />
        </div>
    );
};

export const ClientsLoader = (queryClient) => async () => {
    const myData = await queryClient.ensureQueryData(getMyUserDataQueryOptions);
    // const enumData = await queryClient.ensureQueryData(getAllEnumsQueryOptions);
    // const agencyPromises = myData.branches.map((branchID) =>
    //     queryClient.ensureQueryData(getClientsInBranchQueryOptions(branchID))
    // );
    // console.log(myData);
    // const clientsData = await Promise.all(agencyPromises);
    return {};
};
