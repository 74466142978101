import useAllNumbers from '@/api/numbers/getAllNumbers';
import { Table } from '@/components/tables/Table';
import { phoneNumbersColumnHelper } from '@/components/tables/columnHelpers/phoneNumbersColumnHelper';
import { MILLISEC } from '@/util/constants';
import { selectThirtyDayInactive } from '@/util/queryUtils';
import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';


export const Sitewide = ({visibleIDs, ...props}) => {
    const allNumbersQuery = useAllNumbers({
        select: selectThirtyDayInactive
    })
    const tableData = useMemo(() => {
        if (!visibleIDs) return allNumbersQuery.data
        
    },[allNumbersQuery.data,visibleIDs])
    return (
        <Table
            exportFileName="phone_numbers"
            pdfName={'Phone Numbers'}
            data={allNumbersQuery.data}
            showLoading={allNumbersQuery.isPending}
            columnHelper={phoneNumbersColumnHelper}/>
    );
};

