import { cn } from "@/lib/utils";
import { cva } from "class-variance-authority";

const iconVariants = cva("rounded-full p-2   flex justify-center items-center  border-white border", {
    variants: {
        variant: {
            info: "text-info [&>svg]:fill-info [&>svg]:stroke-white bg-info-background/30 shadow-even",
            error: "<text-err></text-err> [&>svg]:fill-error [&>svg]:stroke-white bg-error-background/30 shadow-even ",
            warning:
                "text-warning [&>svg]:fill-warning [&>svg]:stroke-white bg-warning-background/70 shadow-even",
        },
        size: {
            lg: "large w-15 h-15",
            md: "medium w-10 h-10",
            sm: "small w-8 h-8",
        },
    },
    defaultVariants: {
        variant: "info",
        size: "md",
    },
});

export const BlockAlertIcon = ({ variant }) => {
    let I = Info;
    if (variant?.variant === "warning") I = Warning;
    if (variant?.variant === "error") I = Alert;

    return (
        <div className="rounded-full w-fit justify-self-center bg-white border-white border ">
            <div className={cn(iconVariants(variant))}>
                <I className="w-full h-full" />
            </div>
        </div>
    );
};

const Info = (props) =>
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path  d="M11 17h2v-6h-2zm1-8q.425 0 .713-.288T13 8t-.288-.712T12 7t-.712.288T11 8t.288.713T12 9m0 13q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22"/></svg>
const Warning = (props) => 
    <svg {...props} width="24" height="24" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg"><path d="M236.8 188.09L149.35 36.22a24.76 24.76 0 0 0-42.7 0L19.2 188.09a23.51 23.51 0 0 0 0 23.72A24.35 24.35 0 0 0 40.55 224h174.9a24.35 24.35 0 0 0 21.33-12.19a23.51 23.51 0 0 0 .02-23.72M120 104a8 8 0 0 1 16 0v40a8 8 0 0 1-16 0Zm8 88a12 12 0 1 1 12-12a12 12 0 0 1-12 12"/></svg>

const Alert = (props) => 
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M12 17q.425 0 .713-.288T13 16t-.288-.712T12 15t-.712.288T11 16t.288.713T12 17m-1-4h2V7h-2zm1 9q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22"/></svg>