import { ToggleButton } from "@/components/buttons/ToggleButton";
import { TableRowSkeletons } from "@/components/skeletons/TableRowSkeletons";
import { expandColumnHelper } from "@/components/tables/cells/ExpandIndicatorCell";
import { ColumnVisibilityDropdown } from "@/components/tables/helpers/ColumnVisibilityDropdown";
import { Filters } from "@/components/tables/helpers/Filters";
import { PinCheckingCell } from "@/components/tables/helpers/PinCheckingCell";
import { RowSection } from "@/components/tables/helpers/RowSection";
import { SortControls } from "@/components/tables/helpers/SortControls";
import { TableProgress } from "@/components/tables/helpers/TableProgress";
import { Pagination } from "@/components/tables/helpers/pagination/Pagination";
import {
    fuzzyFilter,
    getDetachedChildrenFilteredRowModel,
} from "@/components/tables/helpers/tableUtils";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import { useObjectSearchParams } from "@/hooks/state/useObjectSearchParams";
import { useTableExportHelper } from "@/hooks/useTableExportHelper";
import { cn } from "@/lib/utils";
import { A4_MARGIN } from "@/util/constants";
import { getCookie, setCookie } from "@/util/cookieUtils";
import { isIterable, throttle } from "@/util/miscUtils";
import { Select } from "@/wrappers/ui/Select";
import { TextSearchBar } from "@/wrappers/ui/TextSearchBar";
import {
    ColumnHelper,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    getFacetedMinMaxValues,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { isNumber } from "lodash";
import {
    InfoIcon,
    SortAsc,
    SortDesc,
    TrashIcon,
    UploadIcon,
} from "lucide-react";
import mergeRefs from "merge-refs";
import React, {
    RefObject,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from "react";
import { useLocation } from "react-router-dom";

const DEFAULT_PAGE_SIZE = 10;

type TableProps = {
    retrieveSearchParamFilters?: boolean;
    showLoading?: boolean;
    debug?: boolean;
    extraFilters?: any[];
    className?: string;
    headerClassName?: string;
    rowClassName?: string;
    cellClassName?: string;
    meta?: any;
    state?: any;
    tableProps?: any;
    data?: any;
    showExportButton?: boolean;
    exportFileName?: string;
    pdfName?: string;
    exportHelper?: any;
    detachChildrenOnParentFilter?: boolean;
    initialState?: any;
    initialPageSize?: number;
    columnHelper: ColumnHelper<any>[];
    scrollShadows?: boolean;
    showSearchBar?: boolean;
    showRowCount?: boolean;
    hasPinnedRows?: boolean;
    resetFiltersOnFiltersHide?: boolean;
    renderSubComponent?: any;
    initialSelectionDataIDs?: any[];
    showSortButtons?: boolean;
    showFilters?: boolean;
    showPagination?: boolean;
    showColumnVisibility?: boolean;
    hoverShadow?: boolean;
    handleSelectionChange?: any;
    rowAccordion?: boolean;
    maxHeightPixels?: number | string;
    controlsClassName?: string;
    oneRowControls?: boolean;
    extraRows?: any[];
    initialExpand?: boolean;
    canExpand?: boolean;
    disabled?: boolean;
    pdfRef?: any;
    controlledPDF?: boolean;
    subrowFn?: any;
    children?: any;
    tableRef?: any;
    tableFnRef?: any;
    tableElRef?: RefObject<HTMLTableElement>;
};

export const Table = ({
    retrieveSearchParamFilters = false,
    showLoading = false,
    debug = false,
    extraFilters = [],
    className = "",
    headerClassName = "",
    rowClassName = "",
    cellClassName = "",
    meta,
    state,
    tableProps,
    data,
    showExportButton = true,
    exportFileName = "export",
    pdfName,
    exportHelper: controlledExportHelper,
    detachChildrenOnParentFilter = false,
    initialState,
    initialPageSize = null,
    columnHelper,
    scrollShadows = true,
    showSearchBar = true,
    showRowCount = true,
    hasPinnedRows = false,
    resetFiltersOnFiltersHide = true,
    renderSubComponent = () => <></>,
    initialSelectionDataIDs,
    showSortButtons = false,
    showFilters = true,
    showPagination = true,
    showColumnVisibility,
    hoverShadow = false,
    handleSelectionChange = () => {},
    rowAccordion = false,
    maxHeightPixels = 480,
    controlsClassName = "shadow-sm",
    oneRowControls = false,
    extraRows = [],
    initialExpand = false,
    canExpand = false,
    disabled = false,
    pdfRef,
    controlledPDF = false,
    subrowFn = (row) => row?.subnodes || [],
    children,
    tableRef,
    tableFnRef,
    tableElRef: controlledTableElRef,
}: TableProps) => {
    const tBodyRef = useRef(null);
    const [searchParams, setSearchParams] = useObjectSearchParams();
    const lastExtraFilters = useRef(extraFilters);
    const scrollContainerRef = React.useRef(null);
    const rightShadowRef = React.useRef(null);
    const tableElRef = React.useRef<HTMLTableElement>(null);
    const elementToExportRef = useRef(null);
    const mergedTableRef = mergeRefs(
        controlledTableElRef,
        tableElRef
    ) as RefObject<HTMLTableElement>;
    const resolvePDFExportRef = useRef(null);
    const location = useLocation();
    const [columnFilters, setColumnFilters] = useState(
        initialState?.columnFilters || []
    );
    const [globalFilter, setGlobalFilter] = useState("");
    const [rowSelection, setRowSelection] = useState({});
    const [expanded, setExpanded] = useState([]);
    const [allowFilter, setAllowFilter] = useState(false);
    const [allowSort, setAllowSort] = useState(false);
    const [pageState, setPageState] = useState({
        pageIndex: Number(getCookie(`${location.pathname}_tablePage`)) || 0,
        pageSize:
            Number(
                initialPageSize ||
                    getCookie(`${location.pathname}_tablePageSize`)
            ) || DEFAULT_PAGE_SIZE,
    });

    function onExpandedChange(update) {
        setExpanded(update);
    }
    function onRowSelectionChange(update) {
        setRowSelection(update);
    }
    function onPaginationChange(update) {
        const newState = update(pageState);
        setPageState(update);
        setCookie(`${location.pathname}_tablePageSize`, newState.pageSize);
    }

    const disabledChildren = useMemo(() => {
        return disabled && !!children
            ? isIterable(children)
                ? children.map((child) =>
                      React.cloneElement(child, { disabled, key: child.key })
                  )
                : React.cloneElement(children, { disabled })
            : children;
    }, [disabled, children]);

    const table = useReactTable<any>({
        columns: [
            ...(canExpand ? expandColumnHelper : []),
            ...columnHelper,
        ] as ColumnHelper<any>[],
        state: {
            columnFilters,
            globalFilter,
            rowSelection,
            pagination: pageState,
            expanded,
            ...state,
        },
        initialState,
        meta,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        debugAll: debug,
        data: data || [],
        autoResetPageIndex: false,
        paginateExpandedRows: false,
        onPaginationChange,
        enableSubRowSelection: true,
        getSubRows: canExpand && subrowFn,
        getRowCanExpand: (row) => row.subRows?.length > 0,
        onExpandedChange: onExpandedChange,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getExpandedRowModel: getExpandedRowModel(),
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: detachChildrenOnParentFilter
            ? getDetachedChildrenFilteredRowModel()
            : getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        onRowSelectionChange,
        ...tableProps,
    });
    const headers = table.getFlatHeaders();
    const rows = table.getRowModel().rows;

    // Export Helper
    const defaultExportHelper =
        useTableExportHelper({
            pageState,
            setPageState,
            exportFileName,
            pdfName,
            table,
            tableElRef: elementToExportRef,
            pdfSetupFn: controlledPDF ? () => pdfRef.current : undefined,
            onPDFExportFinish: controlledPDF
                ? () => {
                      resolvePDFExportRef.current();
                  }
                : undefined,
            skipAlert: controlledPDF,
            skipToast: controlledPDF,
            pdfExportSaveFn: controlledPDF ? () => {} : undefined,
            topMargin: controlledPDF ? A4_MARGIN : 0,
        }) || {};
    const exportHelper = controlledExportHelper || defaultExportHelper;
    const {
        exportOptions,
        cancelExport,
        exportDataClicked,
        getExportProgress,
        handleExport,
        handleToggleExportGroupByColumn,
        setAllPagesOption,
        setGroupByColumn,
        shouldExport,
        setShouldExport
    } = exportHelper;
    // Expose export to PDF
    useImperativeHandle(tableFnRef, () => {
        return {
            async exportToPDF() {
                return new Promise((resolve) => {
                    setAllPagesOption(true);
                    exportDataClicked("pdf");
                    resolvePDFExportRef.current = resolve;
                });
            },
        };
    });

    // Set scroll shadow listeners
    useEffect(() => {
        if (tableRef) tableRef.current = table;
        const scrollContainer = scrollContainerRef?.current;
        const { scrollLeft, scrollWidth, clientWidth } = scrollContainer || {};
        if (scrollLeft < scrollWidth - clientWidth - 5)
            mergedTableRef?.current?.classList.add("fade-left-active");
        if (scrollContainer) {
            scrollContainer.addEventListener("scroll", setShadows, {
                passive: true,
            });
        }
        return scrollShadows
            ? () => {
                  if (scrollContainer) {
                      scrollContainer.removeEventListener("scroll", setShadows);
                  }
              }
            : () => {};
    }, [mergedTableRef, data, scrollContainerRef]);
    // Set SearchParam filters
    useEffect(() => {
        if (!searchParams || Object.keys(searchParams).length < 1 || !retrieveSearchParamFilters) {
            return;
        }
        console.log("searchParams", searchParams)
        const searchFilters = Object.entries(searchParams)?.map(
            ([key, value]) => ({
                id: key,
                value: String(value),
            })
        );
        console.log('setting to searchfilters', searchFilters);
        setColumnFilters(searchFilters);
        
    }, [searchParams]);
    // console.log('fffilter',table.getAllColumns().map(c => c.getFilterValue()));
    // Set extra filters
    useEffect(() => {
        // Add extra filters to the table
        for (const filter of extraFilters)
            table.getColumn(filter.id).setFilterValue(filter.value);
        // Remove filters that are no longer in the extra filters
        for (const filter of lastExtraFilters.current) {
            if (!extraFilters.find((f) => f.id === filter.id))
                table.getColumn(filter.id).setFilterValue(null);
        }
        lastExtraFilters.current = extraFilters;
    }, [extraFilters]);

    // Set initial row selection
    useEffect(() => {
        if (initialSelectionDataIDs?.length > 0) {
            rows.forEach((row) => {
                if (initialSelectionDataIDs.includes(row.original.id)) {
                    row.toggleSelected(true);
                    row.pin("top");
                }
            });
        }
        if (initialExpand && !table.getIsAllRowsExpanded())
            table.toggleAllRowsExpanded();
    }, []);

    useEffect(() => {
        handleSelectionChange?.(
            table.getSelectedRowModel().flatRows.map((r) => {
                r.original.rowID = r.id;
                return r.original;
            })
        );
    }, [rowSelection]);

    useEffect(() => {
        const maxPageIndex = Math.max(table?.getPageCount() - 1, 0);
        if (maxPageIndex < pageState.pageIndex) {
            setPageState({ ...pageState, pageIndex: maxPageIndex });
        }
    }, [columnFilters]);

    useEffect(() => {handleExport()}, [
        shouldExport,
        pageState.pageIndex,
        exportOptions.groupByColumnValueIndex,
        ]
    );

    // Set initial page size based on container height
    useEffect(() => {
        if (!elementToExportRef.current || !tBodyRef.current) return;
        const containerHeight = elementToExportRef.current?.clientHeight;
        if (!containerHeight || !(containerHeight > 0) || initialPageSize != null) 
            return;
        const children = Array.from(tBodyRef.current.children)
        const largestRowHeight = 
            Math.max(
                ...children?.map((el) =>
                    el.getBoundingClientRect().height
                )
            )
        setPageState((prev) => ({
            ...prev,
            pageSize:  Math.max(0,Math.floor(containerHeight / largestRowHeight) - 1),
        }))
    },[showLoading, data])

    const hasClearableFilters = useMemo(() => {
        const extraFilterIDs = extraFilters.map((f) => f.id);
        const hasClearableColFilters =
            table
                .getAllColumns()
                .filter((c) => !extraFilterIDs.includes(c.id))
                .map((c) => c.getFilterValue())
                .filter((v) => !!v).length > 0;
        const hasClearableGlobalFilter = globalFilter?.length > 0;
        return hasClearableColFilters || hasClearableGlobalFilter;
    }, [globalFilter, columnFilters, extraFilters]);

    const toggleFilters = () => {
        setAllowFilter((prev) => !prev);
        if (!resetFiltersOnFiltersHide) return;
        if (allowFilter) setColumnFilters(extraFilters);
    };
    const SubComponent = ({ row }) => {
        return <>{renderSubComponent({ row })}</>;
    };
    function clearFilters() {
        setColumnFilters(extraFilters);
        setGlobalFilter("");
        setSearchParams({});
    }
    const setShadows = throttle(function (event) {
        const { scrollLeft, scrollTop, scrollWidth, clientWidth } =
            event.target || {};
        const t = mergedTableRef.current;
        scrollTop > 10
            ? t.classList.add("fade-column-active")
            : t.classList.remove("fade-column-active");
        scrollLeft > 10
            ? t.classList.add("fade-right-active")
            : t.classList.remove("fade-right-active");
        scrollLeft > scrollWidth - clientWidth - 5 && t
            ? t.classList.remove("fade-left-active")
            : t.classList.add("fade-left-active");
    }, 40);

    // console.log('filters', table.getAllColumns().map(c => [c.id,c.getFilterValue()]));
    return (
        <div className={`w-full @container flex flex-col h-full  ${className}`}>
            <div
                className={`flex mb-3 rounded-2xl w-full 
        ${
            oneRowControls
                ? "flex-row flex-wrap ga px-4 py-3 justify-between"
                : "items-start flex-col gap-2 "
        }
        `}
            >
                <div className="gap-y-3 w-full gap-x-50 lg:gap-x-12 items-start flex justify-between flex-wrap-reverse ">
                    <div className="flex items-end justify-between gap-2 table-element mt-3">
                        {showSearchBar && (
                            <div className="flex relative overflow-visible pt-1">
                                <span className="absolute  -top-3.5 right-0 text-xs text-muted-foreground">
                                    showing{" "}
                                    {table.getFilteredRowModel().rows.length} of{" "}
                                    {table.getPreFilteredRowModel().rows.length}
                                </span>
                                <TextSearchBar
                                    disabled={disabled}
                                    value={globalFilter}
                                    placeholder="Search all columns..."
                                    onChange={setGlobalFilter}
                                    iconClassName="text-accent w-6 h-6 place-self-center"
                                />
                            </div>
                        )}
                        {showFilters && (
                            <ToggleButton
                                toggled={allowFilter}
                                onToggle={toggleFilters}
                                disabled={disabled}
                            >
                                Filters
                            </ToggleButton>
                        )}
                        {hasClearableFilters && (
                            <Button
                                disabled={disabled}
                                onClick={clearFilters}
                                className="border-error text-error-content"
                            >
                                Clear Filters
                                <TrashIcon className="w-4 h-4 ml-1" />
                            </Button>
                        )}
                        {showColumnVisibility && (
                            <ColumnVisibilityDropdown
                                visibilityModified={
                                    !table.getIsAllColumnsVisible()
                                }
                                columns={table
                                    .getAllColumns()
                                    .filter(
                                        (col) =>
                                            !(col.columnDef?.meta as any)
                                                ?.hiddenCol && col.getCanHide()
                                    )}
                            />
                        )}
                        {children && (
                            <div className="flex items-start gap-2 table-element max-h-full">
                                {disabled ? disabledChildren : children}
                            </div>
                        )}
                    </div>

                    {showExportButton && (
                        <div>
                            {shouldExport ? (
                                <Button
                                    onClick={cancelExport}
                                    className=" flex text-md justify-between items-center bg-error-background text-error-content w-36"
                                >
                                    Cancel Export
                                </Button>
                            ) : (
                                <DropdownMenu>
                                    <DropdownMenuTrigger asChild>
                                        <Button className="flex text-md justify-between items-center bg-green-600 hover:bg-green-500 text-white w-36">
                                            Export
                                            <UploadIcon className=" ml-1 -mr-1 w-5" />
                                        </Button>
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent className="">
                                        <DropdownMenuLabel className="flex justify-between items-center gap-2">
                                            <span className="text-sm font-medium">
                                                Export all pages
                                            </span>
                                            <Checkbox
                                                checked={exportOptions.allPages}
                                                onCheckedChange={
                                                    setAllPagesOption
                                                }
                                            />
                                        </DropdownMenuLabel>
                                        <DropdownMenuSeparator />
                                        <DropdownMenuLabel className="flex flex-col items-start gap-2">
                                            <div className="flex gap-2">
                                                <span className="text-sm font-medium">
                                                    Group-by column
                                                </span>
                                                <Checkbox
                                                    checked={
                                                        exportOptions.showGroupByColumn
                                                    }
                                                    onCheckedChange={
                                                        handleToggleExportGroupByColumn
                                                    }
                                                />
                                            </div>
                                            {exportOptions.showGroupByColumn && (
                                                <Select
                                                    placeholder="Select column"
                                                    side="left"
                                                    inForm={false}
                                                    openOnFocus={false}
                                                    value={
                                                        exportOptions.groupByColumn
                                                    }
                                                    handleChange={
                                                        setGroupByColumn
                                                    }
                                                    options={table
                                                        .getAllColumns()
                                                        .map((col) => ({
                                                            label: col.columnDef
                                                                .id,
                                                            value: col.id,
                                                        }))}
                                                    renderFn={(option) =>
                                                        option.label
                                                    }
                                                    IDFn={(option) =>
                                                        option?.value
                                                    }
                                                    buttonClassName="w-34 px-2"
                                                />
                                            )}
                                        </DropdownMenuLabel>
                                        <DropdownMenuSeparator />
                                        <DropdownMenuGroup>
                                            <DropdownMenuItem
                                                onSelect={() =>
                                                    exportDataClicked("csv")
                                                }
                                            >
                                                As CSV
                                            </DropdownMenuItem>
                                            <DropdownMenuItem
                                                onSelect={() =>
                                                    exportDataClicked("pdf")
                                                }
                                            >
                                                As PDF
                                            </DropdownMenuItem>
                                            <DropdownMenuItem
                                                onSelect={() =>
                                                    exportDataClicked("png")
                                                }
                                            >
                                                As PNG
                                            </DropdownMenuItem>
                                            <DropdownMenuItem
                                                onSelect={() =>
                                                    exportDataClicked("jpg")
                                                }
                                            >
                                                As JPG
                                            </DropdownMenuItem>
                                        </DropdownMenuGroup>
                                    </DropdownMenuContent>
                                </DropdownMenu>
                            )}
                        </div>
                    )}
                </div>

                {allowFilter && (
                    <div className="flex items-start ">
                        <Filters
                            disabled={disabled}
                            headers={headers}
                            table={table}
                        />
                    </div>
                )}

                {allowSort && (
                    <div className="flex items-start ">
                        <SortControls
                            disabled={disabled}
                            headers={headers}
                            table={table}
                        />
                    </div>
                )}
            </div>
            <div className={cn("w-full h-full")}>
                {shouldExport && exportOptions.allPages && (
                    <TableProgress progress={getExportProgress()} />
                )}
                <div
                    className={cn(
                        "h-full",
                        shouldExport &&
                            exportOptions.allPages &&
                            `opacity-20 skeleton pointer-events-none select-none`
                    )}
                >
                    <div
                        ref={elementToExportRef}
                        className="w-full h-full rounded-md border bg-background shadow-all-small shadowed-table "
                    >
                        <ScrollArea
                            style={{
                                "--max-height": !!shouldExport
                                    ? "auto"
                                    : isNumber(maxHeightPixels)
                                        ? `${maxHeightPixels}px`
                                        : maxHeightPixels,
                            }}
                            className={cn(
                                "rounded-xl  grid grid-rows-[minmax(auto,var(--max-height))]"
                            )}
                            ref={scrollContainerRef}
                        >
                            <ScrollBar
                                ref={rightShadowRef}
                                orientation="horizontal"
                            />
                            <table
                                ref={mergedTableRef}
                                className={`table show-empty-cells w-full static h-full`}
                            >
                                <thead className="w-full  z-[1] h-12  text-left align-middle border-b">
                                    <tr className=" h-full w-full border-gray-200 hover:bg-muted/50">
                                        {headers.map((header) => (
                                            <PinCheckingCell
                                                hidden={
                                                    header.column.columnDef
                                                        ?.meta?.hiddenCol
                                                }
                                                key={header.id}
                                                rightScrollRef={rightShadowRef}
                                                colSpan={header.colSpan}
                                                doublePinned={
                                                    header.column.getIsPinned() !=
                                                    null
                                                }
                                                pinned={
                                                    header.column.getIsPinned() ||
                                                    "top"
                                                }
                                                style={{
                                                    width: header.getSize(),
                                                }}
                                                className={cn(
                                                    "whitespace-pre-wrap top-0 items-end text-subtext font-normal text-md",
                                                    cellClassName
                                                )}
                                            >
                                                {header.isPlaceholder ? null : (
                                                    <>
                                                        <div
                                                            key={
                                                                header.id +
                                                                "flexrowcontainer"
                                                            }
                                                            {...{
                                                                className: cn(
                                                                    "select-none flex items-end leading-tight",
                                                                    header.column.getCanSort() &&
                                                                        "hover:cursor-pointer",
                                                                    headerClassName
                                                                ),
                                                                onClick:
                                                                    disabled
                                                                        ? null
                                                                        : header.column.getToggleSortingHandler(),
                                                            }}
                                                        >
                                                            {flexRender(
                                                                header.column
                                                                    .columnDef
                                                                    .header,
                                                                header.getContext()
                                                            )}
                                                            {header.column.getIsVisible() &&
                                                                header.column.getCanSort() &&
                                                                {
                                                                    asc: (
                                                                        <span className="relative text-[#ff6000]">
                                                                            <SortAsc className="absolute left-0 bottom-0" />
                                                                            {/* <AscendingIcon className='absolute left-0 bottom-0'/> */}
                                                                        </span>
                                                                    ),
                                                                    desc: (
                                                                        <span className="relative flex-nowrap text-[#4700ff]">
                                                                            <SortDesc className="absolute left-0 bottom-0" />
                                                                            {/* <DescendingIcon className='absolute left-0 bottom-0'/> */}
                                                                        </span>
                                                                    ),
                                                                }[
                                                                    String(
                                                                        header.column.getIsSorted()
                                                                    )
                                                                ]}
                                                        </div>
                                                    </>
                                                )}
                                            </PinCheckingCell>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody
                                    ref={tBodyRef}
                                    className={cn(
                                        "relative min-h-[10rem] h-full  [&_tr:last]:border-0"
                                    )}
                                >
                                    {data && !showLoading ? (
                                        <>
                                            {...extraRows}
                                            {hasPinnedRows && (
                                                <>
                                                    <RowSection
                                                        {...{
                                                            disabled,
                                                            rowAccordion,
                                                            rows: table.getTopRows(),
                                                            hoverShadow,
                                                            rowClassName,
                                                            cellClassName,
                                                            SubComponent,
                                                        }}
                                                    />
                                                    <RowSection
                                                        {...{
                                                            disabled,
                                                            rowAccordion,
                                                            rows: table.getCenterRows(),
                                                            hoverShadow,
                                                            rowClassName,
                                                            cellClassName,
                                                            SubComponent,
                                                        }}
                                                    />
                                                    <RowSection
                                                        {...{
                                                            disabled,
                                                            rowAccordion,
                                                            rows: table.getBottomRows(),
                                                            hoverShadow,
                                                            rowClassName,
                                                            cellClassName,
                                                            SubComponent,
                                                        }}
                                                    />
                                                </>
                                            )}
                                            {!hasPinnedRows && (
                                                <RowSection
                                                    {...{
                                                        disabled,
                                                        rowAccordion,
                                                        rows,
                                                        hoverShadow,
                                                        rowClassName,
                                                        cellClassName,
                                                        SubComponent,
                                                    }}
                                                />
                                            )}
                                            {table.getFilteredRowModel()?.rows
                                                .length === 0 && (
                                                <tr className="justify-center h-20">
                                                    <td
                                                        className="text-muted-foreground text-center "
                                                        colSpan={headers.length}
                                                    >
                                                        <div className="flex gap-2 w-full justify-center">
                                                            <InfoIcon className="w-6 h-6 mr-1" />
                                                            No rows found
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </>
                                    ) : (
                                        <TableRowSkeletons />
                                    )}
                                </tbody>
                            </table>
                        </ScrollArea>
                    </div>
                </div>
            </div>
            {showPagination && <Pagination location={location} table={table} />}
        </div>
    );
};
