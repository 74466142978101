import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    SubTitle,
    Title,
    Tooltip,
} from 'chart.js';
import { doughnutOptions } from './doughnutOptions';
import { rangeArray } from '@/util/miscUtils';

const customCanvasBackgroundColorPlugin = {
    id: 'customCanvasBackgroundColor',
    beforeDraw: (chart, args, options) => {
      const {ctx} = chart;
      ctx.save();
      ctx.globalCompositeOperation = 'destination-over';
      ctx.fillStyle = options.color || '#99ffff';
      ctx.fillRect(0, 0, chart.width, chart.height);
      ctx.restore();
    }
  };

ChartJS.register(
    ArcElement,
    BarElement,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    SubTitle,
    Tooltip,
    Legend,
    customCanvasBackgroundColorPlugin
)

export const legendSpacePlugin = (xPadding, yPadding) => ({
    id: "legendMargin",
    beforeInit: function (chart) {
      const fitValue = chart.legend.fit;
      chart.legend.fit = function fit() {
        fitValue.bind(chart.legend)();
        this.width += xPadding
        this.height += yPadding
        return this;
      };
    }
  });


export const DEFAULT_PIE_SIZE = {

}

export const PIE_SIZE = {
    sm: {
        boxHeight : 8,
        boxWidth : 8,
        size : 12   ,
    },        
    md: {
        boxHeight : 11,
        boxWidth : 11,
        size : 14   ,
    },        
    lg: {
        boxHeight : 13,
        boxWidth : 13,
        size : 16,
    },        
    xl: {
        boxHeight : 15,
        boxWidth : 15,
        size : 20,
    },        
}
export function setChartLegendSize(chart, size) {
    chart.options.plugins.legend.labels.boxWidth = size.boxWidth
    chart.options.plugins.legend.labels.boxHeight = size.boxHeight
    chart.options.plugins.legend.labels.font.size = size.size
    chart.update()
}

export const multiDoughnutOptions = {
    ...doughnutOptions,    
}

const unknownColor = '#3B484B'

export const chartColors = [
    '#09B7A8',
    '#3897B7',
    '#FE625F',
    '#F0C817',
    '#FE625F',
    '#FCA359',
    'hsl(0, 73%, 75%)',
    'hsl(65, 50%, 75%)',
    'hsl(92, 60%, 77%)',
    'hsl(249, 40%, 75%)',
    'hsl(170, 50%, 79%)',
    'hsl(217, 50%, 77%)',
    'hsl(32, 50%, 72%)',
    'hsl(290, 70%, 75%)',
    '#005F60',
    '#38812F',
    '#004B95',
    '#3C3D99',
    '#3C3D99',
    '#C46100',
    '#470000',
]
// ChartJS.overrides.pie.transitions.resize.duration = 10
export function getChartColors(count, hideUnknown=false) {
    const colors = chartColors.slice(0, count - 1)
    if (hideUnknown) return colors
    return [colors, unknownColor].flat()
}

export function getEnumCounts(enumObj, items, acessorFn=()=>null, multipleValues=false) {
    const entries = Object.entries(enumObj)
    const data = Object.fromEntries(entries.map(([key,_]) => [key,0]))
    const inverseEnum = Object.fromEntries(entries.map(([key,value]) => [value,key]))
    for (const item of items) {
        if (multipleValues) {
            for (const val of acessorFn(item)) {
                data[inverseEnum[val]]++
            }
            continue
        } else {
            data[inverseEnum[acessorFn(item)]] ++
        }
    }
    return Object.values(data) 
}

export function getPieConfig(enumObj, items, acessorFn=()=>null, colors) {
    if (!items) return 
    const values = Object.values(enumObj)
    const data = getEnumCounts(enumObj, items, acessorFn)
    // console.log('colors', colors);
    return ({
        data: {
            labels: values,
            datasets: [{
                backgroundColor: colors,
                borderColor: colors,
                data: data,
            }]
        }
    })
}

export function getManualPieConfig(data, colors, labels) {
    if (!data) return null
    return ({
        data: {
            labels: labels,
            datasets: [{
                backgroundColor: colors,
                borderColor: colors,
                data: data,
                // options: {
                //         transitions: {
                //             resize: {
                //                 animation: {
                //                     duration: 0
                //                 }
                //             },
                //             active: {
                //                 animation: {
                //                     duration: 1000,
                //                 }
                //             }
                //         },
                // }
            }]
        },
    })
}

export function getBarConfig(enumObj, items, acessorFn, multipleValues=false) {
    if (!items) return 
    const values = Object.values(enumObj)
    const colors = getChartColors(values.length)
    const counts = getEnumCounts(enumObj, items, acessorFn, multipleValues)
    return ({
            data: {
                labels: [''],
                datasets: Object.values(counts).map((count,idx) => ({
                    label: values[idx],
                    backgroundColor: colors[idx],
                    borderColor: colors[idx],
                    data: [count],
                }
        ))
        }
    })
}

export function getManualBarConfig(data, colors, labels) {
    if (!data) return null
    return ({
        data: {
            labels: [''],
            datasets: data.map((d, idx) => ({
                label: labels[idx],
                backgroundColor: colors[idx],
                borderColor: colors[idx],
                data: [d],
            }))
        }
    })
}

export function getManualStackedBarConfig(datasets, xColors, yColors, xLabels, yLabels) {
    if (!datasets) return null
    const firstLength = datasets[0].length
    if (!datasets.every(d => d.length === firstLength)) throw new Error('Datasets must be all the same length')
        console.log('datasets',datasets, yLabels);
    console.log('dees',datasets);
    return ({
        data: {
            labels: xLabels,
            datasets: datasets.map((d, idx) => ({
                label: yLabels[idx],
                backgroundColor: yColors[idx],
                borderColor: yColors[idx],
                data: d,
            }))
        }
    })
}


function stackedBorderColorFormatter(xColors) {
    return function(ctx) {
        // console.log('colors', xColors[ctx.dataIndex]);
        return xColors[ctx.dataIndex]
    }
}