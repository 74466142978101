import { FormDescription, FormField, FormHeader, FormItem } from '@/components/ui/form';
import { Control, useController } from 'react-hook-form';
import { capitalizeFirst } from '../../../util/textUtils';
import { MultiSelect, MultiSelectProps } from '../../MultiSelect';

export type ComposedFormMultiSelectProps = MultiSelectProps & {
    control: Control,
    name: string,
    label?: string,
    description?: string | null,
    open?: boolean | undefined,
    placeholder?: string,
    disabled?: boolean,
    defaultValue?: any,
    renderFn?: (opt:any)=>string,
    valueFn?: (opt:any)=>any,
    valueIsID?: boolean,
}


export const ComposedFormMultiSelect = ({
    control,
    name, 
    label=`${name}`,
    description=null,
    open,
    placeholder=`Select ${capitalizeFirst(name)}`,
    searchLabel=`Search ${capitalizeFirst(name)}`,
    emptyMessage=`No ${capitalizeFirst(name)} Found.`,
    showSearch=false,
    options,
    disabled:controlledDisabled=false,
    defaultValue=null,
    displayFn,
    renderFn=(opt)=>opt?.Name,
    valueFn=(opt)=>opt,
    IDFn=valueFn,
    ...props}: ComposedFormMultiSelectProps) => {
    const {field} = useController({control, name, defaultValue, disabled: controlledDisabled})
    const { onBlur, onChange, ref, value, disabled} = field;

    return (
        <FormField
            control={control}
            name={name}
            render={({field}) => (
                <FormItem>
                    <FormHeader label={label}/>
                    <MultiSelect
                        options={options} 
                        {...{
                            selected: field.value,
                            ref,
                            displayFn,
                            placeholder,
                            searchLabel,
                            emptyMessage,
                            onChange: (opt) => {
                                field.onChange(field)
                                onChange && onChange(opt)
                            },
                            renderFn,
                            showSearch,
                            valueFn,
                            IDFn,
                            open,
                            ...props
                        }}
                        {...field}
                    />
                    {description && <FormDescription>{description}</FormDescription>}
                </FormItem>
            )}
        />
    );
};

 