import { FormDescription, FormField, FormHeader, FormItem } from '@/components/ui/form';
import { Select, SelectVariants } from '@/wrappers/ui/Select';
import { VariantProps } from 'class-variance-authority';
import { SelectProps } from 'react-day-picker';


type ComposedFormSelectProps =  SelectProps & {
    options: any[];
    description?: string | null;
    label?: string;
    name: string;
    control: any;
    open?: boolean | undefined;
    placeholder?: string;
    searchLabel?: string;
    emptyMessage?: string;
    showSearch?: boolean;
    disabled?: boolean;
    defaultValue?: any;
    renderFn?: (opt:any)=>string;
    valueFn?: (opt:any)=>any;
    IDFn?: (opt:any)=>any;
    onChange,
    variant?: VariantProps<typeof SelectVariants>['variant'];
    buttonClassName?: string;
}

export const ComposedFormSelect = ({
    control,
    name, 
    label=`${name}`,
    description=null,
    open,
    placeholder=`Select`,
    searchLabel=`Search`,
    emptyMessage=`No results found.`,
    showSearch=false,
    options,
    disabled:controlledDisabled=false,
    defaultValue=null,
    renderFn=(opt)=>opt?.Name || opt?.label,
    valueFn=(opt)=>opt,
    IDFn=valueFn=(opt)=>opt?.id || opt?.value,
    onChange,
    ...props}: ComposedFormSelectProps) => {
        
    
    return (
        <FormField
            control={control}
            name={name}
            render={({field}) => (
                <FormItem>
                    <FormHeader label={label}/>
                    <Select
                        options={options} 
                        {...{
                            placeholder,
                            searchLabel,
                            emptyMessage,
                            handleChange: (opt) => {
                                field.onChange(opt)
                                onChange && onChange(opt)                    
                            },
                            value: field.value,
                            renderFn,
                            showSearch,
                            valueFn,
                            IDFn,
                            open,
                        }}
                        {...props}
                        {...field}
                    />
                    {description && <FormDescription>{description}</FormDescription>}
                </FormItem>
            )}
        />
    );
};

 