import { UsageReportConfig } from '@/routes/Main/Reporting/Presets/UsageReport';
import { UsageReportData } from '@/routes/Main/Reporting/Presets/UsageReport/useUsageReportData';
import { ReportLoader } from '@/routes/Main/Reporting/components/ReportLoader';
import { useEffect, useRef } from 'react';

type AgencyReportProps = {
    data: UsageReportData,
    isPending?: boolean,
    config: UsageReportConfig,
    AgencyReportComponent: (any) => JSX.Element,
}
export const AgencyReport = (props: AgencyReportProps) => {
    const containerRef = useRef(null)
    const {config, isPending, data, AgencyReportComponent, ...rest} = props || {}

    useEffect(scrollToContainer, [isPending,containerRef.current])    
    function scrollToContainer() {
        if (containerRef.current && !isPending) {
             containerRef.current.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
        }
    }
    return (
        <div ref={containerRef}>
            <ReportLoader isPending={isPending}/>
            <AgencyReportComponent agency={{...config.agency,...data}} {...props}/>
            {/* <AgencyReportContent {...props}/> */}
        </div>
    );
};
