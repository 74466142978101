import { postPromise } from "@/api/promises";
import { convertObjFieldsToString } from "@/util/apiUtils";
import { MICROSERVICE_URL } from "@/util/constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const reorderFAQs = async (params) => {
    return postPromise(
        `${MICROSERVICE_URL}/faq/reorder`,
        convertObjFieldsToString(params),
        {json: true}
    );
};

export const useReorderFAQs = ({ onSuccess, ...otherProps }) => {
    const client = useQueryClient();
    const mutation = useMutation({
        mutationKey: ["faq", "reorder"],
        mutationFn: reorderFAQs,
        onSuccess: (data, req) => {
            client.invalidateQueries({ queryKey: ["faq"] }).then(() => {
                onSuccess && onSuccess(data, req);
            });
        },
        ...otherProps,
    });
    return mutation;
};
