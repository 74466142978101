import { SplashIcon } from '@/components/misc/icons';
import { Button } from '@/components/ui/button';
import { DashboardIcon } from '@/routes/Main/components/Sidebar/Sidebar';
import { Undo2 } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';

export const Unauthorized = ({...props}) => {
    const nav = useNavigate()
    function goBack() {
        nav(-2)
    }
    return (
        <div className='h-screen w-full flex items-center gap-36'>
            <img width={400} height={341} src='unauthorized.png' />
            <div className="flex flex-col items-center max-w-[40rem] text-center relative">
                <SplashIcon className='text-blue-300 absolute w-[65rem] text-primary opacity-20 z-[-1] center-abs'/>
                <h1 className='text-7xl font-light'>Unauthorized</h1>
                <span className='mt-8 text-xl'>You aren&apos;t authorized to access this page. This is likely because the resource you&apos;re trying to access is a part of a branch your account isn&apos;t linked to.</span>
                <div className="flex gap-6 mt-9">
                    <Link to={-1} draggable={false}>
                        <Button  className='flex justify-between px-7 py-7 w-[12rem] text-xl' onClick={goBack}>
                            Go back
                            <Undo2 className='w-7 h-7'/>
                        </Button>
                    </Link>
                    <Link to='/' draggable={false}>
                        <Button variant='default' className='flex justify-between px-7 py-7 w-[15rem] text-lg'>
                            Go to dashboard
                            <DashboardIcon className='w-8 h08'/>
                        </Button>
                    </Link>
                </div>
            </div>
        
        </div>
    );
};


