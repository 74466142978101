import { SignupWithInviteParams, useSignupWithInvite } from '@/api/auth/signupWithInvite';
import { BackButton } from '@/components/buttons/BackButton';
import { TooSoonButton } from '@/components/buttons/TooSoonButton';
import { FieldRow } from '@/components/forms/formComponents/FieldRow';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { useObjectSearchParams } from '@/hooks/state/useObjectSearchParams';
import { useRHF } from '@/hooks/useRHF';
import { useYupValidationResolver } from '@/hooks/useYupValidationResolver';
import { useQueryResultToasts } from '@/wrappers/toasts';
import { ComposedForm } from '@/wrappers/ui/form/ComposedForm';
import { ComposedFormText } from '@/wrappers/ui/form/ComposedFormText';
import Yup from '@/wrappers/yup';
import { QueryClient } from '@tanstack/react-query';
import { Mail } from 'lucide-react';
import { useEffect } from 'react';
import { Link, redirect, useNavigate } from 'react-router-dom';

const validationSchema = Yup.object().shape({
    invitecode: Yup.string().required('Enter the code').default(''),
    firstname: Yup.string().required('Enter your first name').default(''),
    lastname: Yup.string().required('Enter your last name').default(''),
    email: Yup.string().email().required('Enter an email').default(''),
    password: Yup.string().min(6).required('Enter a password').default(''),
    confirmPassword: Yup.string().min(6, 'password must be at least 6 characters').required('Please re-enter your password').default('').equalTo(Yup.ref('password'),'Passwords must match'),
})

const submissionSchema = Yup.object().shape({
    invitecode: Yup.string().required().nonNullable().required(),
    firstname: Yup.string().required(),
    lastname: Yup.string().required(),
    email: Yup.string().email().required(),
    password: Yup.string().min(6).required(),
}).required()

export const Signup = () => {
    const [searchParams,_] = useObjectSearchParams()
    const nav = useNavigate()
    const {invite, email} = searchParams

    const queryResultToasts = useQueryResultToasts();
    const signupMutation = useSignupWithInvite(queryResultToasts(
        "Signup Success",
        "Error Signing Up",
        handleSignupSuccess
    ))

    const resolver = useYupValidationResolver(validationSchema);
    const form = useRHF({resolver, defaultValues: validationSchema.getDefault(), mode: 'onBlur'});

    useEffect(() => {
        loadQueryParams()
    }, [])

    function loadQueryParams() {
        form.setValue('email', email)
        form.setValue('invitecode', invite)
    }

    function handleSignup(fields) {
        console.log('here1');
        const params = submissionSchema.cast(fields, {stripUnknown: true}) as SignupWithInviteParams
        signupMutation.mutate(params)
        console.log('here2');
    }

    function handleSignupSuccess() {
        nav('/auth/login')
    }
    
    return (
        <div>
            <Card className='max-w-full lg:w-[35rem]' >
            <CardHeader>
                <CardTitle>Sign Up for CVM</CardTitle>
                <CardDescription>
                    Welcome to the CVM Data System. Please enter the credentials you would like for your login.
                </CardDescription>
            </CardHeader>
                <ComposedForm {...{form, onSubmit: handleSignup}}>
                    <CardContent>
                        <FieldRow variant={{variant: 'large', width: 'wide'}} className='bg-red-600'>
                            <ComposedFormText
                                control={form.control}
                                name='firstname'
                                label='First name'
                                placeholder='John'
                                />
                            <ComposedFormText
                                control={form.control}
                                name='lastname'
                                label='Last name'
                                placeholder='Smith'
                                />
                        </FieldRow>
                        <ComposedFormText
                            control={form.control}
                            name='email'
                            label='Email'
                            placeholder='johnsmith@example.com'
                            className='w-full'
                            />
                        <ComposedFormText
                            control={form.control}
                            name='password'
                            type='password'
                            label='Password'
                            placeholder='Enter your new password'   
                            className='w-full'
                            />
                        <ComposedFormText
                            control={form.control}
                            name='confirmPassword'
                            type='password'
                            label='Confirm Password'
                            placeholder='Confirm your new password'
                            className='w-full'
                            />
                    </CardContent>
                    <CardFooter className='block'>
                        <Button disabled={!form.formState.isValid} variant={'default'} className='w-full text-lg font-normal' type='submit'>
                            {signupMutation.isPending 
                                ? <div className="loading loading-infinity loading-lg"/> 
                                : 'Sign up'
                            }
                        </Button>
                        <Link to={'/auth/login'}>
                            <BackButton className='mt-1'>
                                Back to login
                            </BackButton>
                        </Link>
                    </CardFooter>
                </ComposedForm>
            </Card>
        </div>
    );
};


export const SignupLoader = (queryClient: QueryClient) => async ({request}) => {
    const url = new URL(request.url)
    console.log("request", request)
    console.log("url", url)
    const code = url.searchParams.get('invite')

    if (!code) {
        console.log('no code');
        return redirect('/auth/no-signup-code')
    }
    
    console.log('code', code);
    return {}
}