import { Spacer } from "@/components/misc/Spacer";
import { Button } from "@/components/ui/button";
import { Popover, PopoverContent, PopoverDescription, PopoverHeader, PopoverTitle, PopoverTrigger } from "@/components/ui/popover";
import { useRHF } from "@/hooks/useRHF";
import { useYupValidationResolver } from "@/hooks/useYupValidationResolver";
import { ComposedForm } from "@/wrappers/ui/form/ComposedForm";
import { ComposedFormText } from "@/wrappers/ui/form/ComposedFormText";
import { FormError } from "@/wrappers/ui/form/FormError";
import { FormErrorProvider } from "@/wrappers/ui/form/FormErrorProvider";
import Yup from "@/wrappers/yup";

const validationSchema = Yup.object({
    atLeastOne: Yup.boolean().test(
    'atLeastOne',
    'At least one field must be filled in',
    (_,ctx) => {
        const {number, clientName} = ctx.parent
        // ctx.parent
        const oneNotNull = number != null || clientName != null
        const oneNotEmpty = number !== '' || clientName !== ''
        return oneNotEmpty && oneNotNull
    }   
),
    number: Yup.string().default(''),
    clientName: Yup.string().default('')
})

export const FindCVMNumberPopover = ({onSubmit, children}) => {
    const resolver = useYupValidationResolver(validationSchema);
    const form
        = useRHF({resolver, defaultValues: validationSchema.getDefault(), mode: 'onBlur'});
    const {control} = form;

    return (
        <Popover >
            <PopoverTrigger asChild>
                {children}
            </PopoverTrigger>
            <PopoverContent className="w-80 flex flex-col gap-3" side="bottom" align="start">
                <ComposedForm form={form} onSubmit={onSubmit} className="pb-0 pt-0">
                    <PopoverHeader>
                        <PopoverTitle>Find a CVM Number</PopoverTitle>
                        <PopoverDescription>Search CVM numbers by digits or by the linked client's name</PopoverDescription>
                        <FormErrorProvider field={'atLeastOne'}>
                            {FormError}
                        </FormErrorProvider>
                    </PopoverHeader>
                    <Spacer className="mt-4"/>
                    <ComposedFormText
                        {...{
                            control,
                            name: 'clientName',
                            label: 'Client name',
                            placeholder: 'Client name',
                            variant:'large'
                        }}
                    />
                    <ComposedFormText
                        {...{
                            control,
                            name: 'number',
                            label: 'Number',
                            placeholder: 'Client name',
                            variant:'large'
                        }}
                    />
                    <Button 
                        type="submit"
                        variant="default" className="w-fit place-self-end">
                        Search for CVM Number
                    </Button>
                </ComposedForm>
            </PopoverContent>
        </Popover>
    )
}