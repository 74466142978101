import { cn } from '@/lib/utils';
import React from 'react';

type PageDescriptionProps = React.HTMLAttributes<HTMLDivElement> & {
    className?: string;
}

export const PageDescription= ({className=undefined, ...props}: PageDescriptionProps) => 
    <div className={cn('text-muted-foreground text-md mb-6 -mt-3',className)} {...props}/>

