import { refreshToken } from '@/api/auth/refreshToken';
import { ROUTE_IDS } from "@/api/routeIDs";
import { deleteAllCookies, getCookie, setCookie } from '@/util/cookieUtils';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

import Swal from 'sweetalert2';


const api = axios.create({});



const refreshExpiredTokenClosure = () => {
  let isCalled = false;
  let runningPromise = undefined;
  console.log('inside refreshing token closure. is already called:', isCalled);

  return () => {
    if (!getCookie('refreshToken')) return Promise.reject()
    if (isCalled) {
      console.log('refresh has been called already. returning promise');
      return runningPromise;
    } else {
      console.log('refresh has not been called yet. calling refresh');
      isCalled = true;
      runningPromise = refreshToken();
      return runningPromise;
    }
  };
};

export const refreshExpiredToken = refreshExpiredTokenClosure();

api.interceptors.request.use(
  async (request) => {
    const abortController = new AbortController();
    request.signal = abortController.signal;
    let baseURL = '';
    request.url = baseURL + request.url;
    let accessToken = getCookie('token');
    console.log('meta',request.meta?.skipToken);
    // We have an access token
    request.headers.Accept = '*/*';
    if (accessToken != null) {
      if (!request.meta?.skipToken) {
        request.headers.Authorization = `Bearer ${accessToken}`
      } else {
        console.log('skipping token in interceptor');
      }
    // No access token present
    } else {
      // Request does not require token
      if (request.meta?.skipToken) {
        console.log('skipping token in interceptor');
        request.headers = {
          Accept: '*/*',
        };
      // No token present for request that requires it
      } else {
        const refreshToken = getCookie('refreshToken');
        if (refreshToken != null) {
          console.log('refreshing token');
          try {
            const data = await refreshExpiredToken();
            const {token} = data.data || {};
            if (token != null) {
              const decodedToken = jwtDecode(token)
              setCookie('token', token, decodedToken.exp);
              request.headers = {
                Authorization: `Bearer ${token}`,
                Accept: '*/*',
              };
            } else {
              abortController.abort()
              return request
            }
          } catch (error) {
            // Could not get a new access token. Take back to login page
            console.log('could not refresh token', error);
            deleteAllCookies();
            abortController.abort()
            return request
          }          
          // refreshToken();
        } else {
          // Not authorized. Take back to login page
          abortController.abort()
          return request
        }

      }
    }

    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  async (response) => {
    const JetDBStatus = response.data?.status;
    if (JetDBStatus?.includes('not')) {
      console.log('JetDB Error');
      const error = new Error(JetDBStatus)
      error.rzesponse = response;
      response.status = 400;
      return Promise.reject(error);
    }
    // console.log('RESPONSE', response);
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const status = error?.response?.status;
    const endpoint = originalRequest?.data?.['type'] || originalRequest?.data?.get('type');
    if (endpoint !== ROUTE_IDS.REFRESH && status === 401) {
      console.log('401', error);
      const refreshPayload = new FormData();
      refreshPayload.append('type', ROUTE_IDS.REFRESH);
      let refreshToken = getCookie('refreshToken');
      if (refreshToken != null) {
        try {
          const data = await refreshExpiredToken();
          const {token} = data.data || {};
          console.log('refreshdata', data);
          if (data.status == 200 ) {
            console.log(data);
            console.log("====================================", token);
            const decodedToken = jwtDecode(token)
            setCookie('token', token, decodedToken.exp);
            // document.cookie = `token=${data?.data.token}; expires=Thu, 18 Dec 2030 12:00:00 UTC; path=/`;
            originalRequest.headers['Authorization'] = 'Bearer ' + token;
            return api(originalRequest)
          }  else {
            deleteAllCookies();
            document.location.href = '/auth/login';
            return Promise.reject(error);
          }
        } catch (err) {
          await Swal.fire({
            timer: 3000,
            position: 'top-end',
            icon: 'error',
            title: 'Session expired',
            text: 'Please login again',
            showConfirmButton: false
          }).then((result) => {
            deleteAllCookies(); // TODO this may not be working
            console.log("FAILED TO REFRESGH COOKIES");
            document.location.href = '/auth/login';
            return Promise.reject()
          })
          return Promise.reject()
        }
      } else {
        console.log("FAILED TO REFRESGH COOKIES– NO REFRESH TOKEN");
        deleteAllCookies();
      }
    }

    return Promise.reject(error);
  }
);

export default api;
