import useAllBranches from '@/api/branches/getAllBranches';
import { MiniCard } from '@/components/cards/MiniCard';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { ForBranch } from '@/routes/Main/Reporting/Presets/ThirtyDayInactivity/ForBranch';
import { ForBranches } from '@/routes/Main/Reporting/Presets/ThirtyDayInactivity/ForBranches';
import React from 'react';


export const BMThirtyDayInactivity = ({meQuery, ...props}) => {
    const [asOne, setAsOne] = React.useState(1)
    const [expandedBranches, setExpandedBranches] = React.useState([])
    const branchesQuery = useAllBranches({
        select: (data) => data.filter(branch => !meQuery.data.branches ? true : meQuery.data.branches.includes(branch.id))
    })
    console.log(branchesQuery.data, meQuery?.data);
    console.log('bd',branchesQuery.data);
    return (
        <div>
            <Tabs value={asOne} onValueChange={setAsOne}>
                <div className="flex w-full justify-center">
                    <TabsList className='shadow-md'>
                        <TabsTrigger value={1}>Separate by branch</TabsTrigger>
                        <TabsTrigger value={2}>Combine</TabsTrigger>
                    </TabsList>
                </div>
                <TabsContent value={1} className='overflow-visible'>
                    <Accordion type='multiple' className='space-y-2 overflow-visible' value={expandedBranches} onValueChange={setExpandedBranches}>
                    { branchesQuery.data?.map?.((b) => (
                        <MiniCard key={b.id} className={'rounded-md '}>
                            <AccordionItem value={b.id} key={b.id} className='border-b-0 w-full '>
                                <AccordionTrigger className='!border-none'>
                                    <span className='font-bold'>{b.Name} </span>
                                </AccordionTrigger>
                                <AccordionContent >
                                        <ForBranch key={b.id} branchdata={b}/>
                                </AccordionContent>
                            </AccordionItem>
                        </MiniCard>
                    ))}
                    </Accordion>
                </TabsContent>
                <TabsContent value={2}>
                    <ForBranches
                        meQuery={meQuery}
                    />
                </TabsContent>
            </Tabs>
        </div>
    );
};

