import { postPromise } from '@/api/promises';
import { ROUTE_IDS } from '@/api/routeIDs';
import { composeFormData } from '@/util/apiUtils';
import { queryOptions, useQuery } from '@tanstack/react-query';

export const getBranchAdmins = ({queryKey}) => {
    const {_key, params} = queryKey;
    return postPromise(import.meta.env.VITE_API_BASE_URL,composeFormData({}, ROUTE_IDS.GET_REGION_ADMINS));
}

export default function useBranchAdmins(params) {
    const query = useQuery(getBranchAdminsQueryOptions);
    return query;
}
export const getBranchAdminsQueryOptions = queryOptions({
    queryKey: ['user', 'branch'],
    queryFn: getBranchAdmins,   
    select
})

function select(data) {
    return Object.values(data).sort(compare)
}

function compare(a, b) {
    return  Number(b.adminLevel) - Number(a.adminLevel);
}