import { Table } from "../../../../../components/tables/Table"
import { BranchUsageRatingsCols } from "../../../../../components/tables/columnHelpers/BranchUsageRatingsCols"

export const BranchRankings = ({pdfRef,tableFnRef, branches, className='', children=undefined}) => {
    return (
        <div className={className}>
            {children}
            <Table
                controlledPDF
                pdfRef={pdfRef}
                tableFnRef={tableFnRef}
                className="h-full"
                data={branches}
                columnHelper={BranchUsageRatingsCols}
                showSearchBar={false}
                showPagination={false}
                showColumnVisibility={false}
                showExportButton={false}
                showFilters={false}
                headerClassName="whitespace-nowrap text-sm "
                rowClassName="py-1"
                maxHeightPixels={411.2}
            />
        </div>
    )
}