import { FieldGroup } from "@/components/forms/formComponents/FieldGroup";
import { JCheckbox } from "@/wrappers/ui/JCheckbox";

export const ChartFilters = ({state, handleChange}) => state.customFilter &&  (
    <FieldGroup title='Filters' labelPosition='center' className='max-w-[60rem] shadow-xl  mb-4'>
        <JCheckbox 
            value={state.config.showTitle}
            handleChange={(e) => handleChange('config', {...state.config, showTitle: e})}/>
        <JCheckbox 
            value={state.config.showLegend}
            handleChange={(e) => handleChange('config', {...state.config, showLegend: e})}/>
    </FieldGroup>
)