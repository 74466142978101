import { Button } from '@/components/ui/button';
import { ListOrdered, Plus } from 'lucide-react';

export const HelpSettingsActionBar = ({ hasChangedOrder, handleReorderSubmit, enterEntryCreation}) => {
    return (
        <div className="flex gap-4 items-center">
            {hasChangedOrder && (
                <Button variant='default' className='shadow-md w-fit' onClick={handleReorderSubmit}>
                    Save New Order <ListOrdered/>
                </Button>
            )}
            <Button variant='default' className='shadow-md w-fit' onClick={enterEntryCreation}>
                Add <Plus className='h-4 w-4 ml-2'/>
            </Button>
        </div>
    );
};
