import { PieChartSkeleton } from '@/components/skeletons/PieChartSkeleton';
import { ResponsivePie } from '@/components/tables/charts/ResponsivePie';
import { getPieConfig } from '@/routes/Main/Reporting/utils/ChartConfigs';
import { AgeRange } from '@/util/constants';
import { useMemo } from 'react';
import { doughnutOptions } from '../utils/doughnutOptions';

export const AgePie = ({clients, chartRef, config: extraConf, ...props}) => {
    const {datasetColors} = extraConf || {}
    const config = useMemo(() => getPieConfig(AgeRange, clients, (c)=>c.ageRange,datasetColors),[clients])
    
    console.log(config);
    return config ? (
        <ResponsivePie
            ref={chartRef}
            options={doughnutOptions(extraConf)}
            datasetColors={datasetColors}
            {...config} />
    ) : (
        <PieChartSkeleton className={'w-full h-[40rem]'}/>
    )
};


