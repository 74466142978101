import { compactPhoneNumbersCols } from "@/components/tables/columnHelpers/compactPhoneNumbersCols";
import { Button } from "@/components/ui/button";
import { A4_CONTENT_WIDTH, pdfItemPadding } from "@/util/constants";
import {
    addDefaultPDFTitlePage,
    addTableToPDF,
    contrainDimensionsToA4,
    elementToCanvasAndURL,
    getNewPDFWithDefaultConf,
} from "@/util/fileUtils";
import { Info, UploadIcon } from "lucide-react";
import {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import { UsageReportConfig } from "..";
import { Table } from "../../../../../../components/tables/Table";
import { SimpleCard } from "../../../../../../components/ui/SimpleCard";
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "../../../../../../components/ui/accordion";
import { cn } from "../../../../../../lib/utils";
import { AgencyRankings } from "../AgencyRankings";
import { BranchRankings } from "../BranchRankings";
import { UsageReportData } from "../useUsageReportData";
import { UsageMessagesPie } from "./UsageMessagesPie";
import { UsageUserCountPie } from "./UsageUserCountPie";
import { ExportButton } from "@/routes/Main/Reporting/components/ExportButton";
import { CancelExportButton } from "@/routes/Main/Reporting/components/CancelExportButton";

type NationalReportContentProps = {
    data: UsageReportData;
    isPending?: boolean;
    config: UsageReportConfig;
    isExporting?: boolean;
    onExportFinish?: () => void;
};

export const NationalReportContent = forwardRef<
    HTMLDivElement,
    NationalReportContentProps
>((props, ref) => {
    const { data, isExporting: controlledIsExporting, onExportFinish, isPending, config } = props || {};
    const [isExporting, setIsExporting] = useState(false);
    const pdfRef = useRef(null);
    const activeTableRef = useRef(null);
    const inactiveTableRef = useRef(null);
    const branchRankingsTableRef = useRef(null);
    const agencyRankingsTableRef = useRef(null);
    const disclaimerRef = useRef(null);
    const clientChartRef = useRef(null);
    const messagesChartRef = useRef(null);

    useEffect(() => {setIsExporting(controlledIsExporting)},[controlledIsExporting])

    useEffect(() => {
        isExporting && exportReport();
    }, [isExporting]);

    async function exportReport() {
        const pdf = getNewPDFWithDefaultConf();
        pdfRef.current = pdf;
        addDefaultPDFTitlePage(pdf, "USAGE REPORT", "Nation-wide");
        // Get Images
        const promises = [];
        promises.push(elementToCanvasAndURL(disclaimerRef.current));
        promises.push(elementToCanvasAndURL(clientChartRef.current));
        promises.push(elementToCanvasAndURL(messagesChartRef.current));
        let [[dCanv, dURL], [cCanv, cURL], [mCanv, mURL]] = await Promise.all(
            promises
        );
        // Disclaimer
        const { width: dW, height: dH } =
            disclaimerRef.current.getBoundingClientRect();
        const [dFW, dFH] = contrainDimensionsToA4(
            dW,
            dH,
            (A4_CONTENT_WIDTH * 2) / 3,
            undefined
        );
        pdf.addImage(dURL, "png", 20, 220, dFW, dFH);
        const yAfterDisclaimer = 220 + dFH + pdfItemPadding;
        // Client Activity
        const { width: cW, height: cH } =
            clientChartRef.current.getBoundingClientRect();
        const [cFW, cFH] = contrainDimensionsToA4(
            cW,
            cH,
            A4_CONTENT_WIDTH,
            400
        );
        pdf.addImage(cURL, "png", 20, yAfterDisclaimer, cFW, cFH);
        const yAfterClient = yAfterDisclaimer + cFH + pdfItemPadding;
        // Message Activity
        const { width: mW, height: mH } =
            messagesChartRef.current.getBoundingClientRect();
        const [mFW, mFH] = contrainDimensionsToA4(
            mW,
            mH,
            A4_CONTENT_WIDTH,
            400
        );
        pdf.addImage(mURL, "png", 20, yAfterClient, mFW, mFH);

        // Images Cleanup
        dCanv.remove();
        dCanv = null;
        cCanv.remove();
        cCanv = null;
        mCanv.remove();
        mCanv = null;

        // Tables
        await addTableToPDF(pdf, activeTableRef.current, "Active Users");
        await addTableToPDF(pdf, inactiveTableRef.current, "Inactive Users");
        await addTableToPDF(
            pdf,
            branchRankingsTableRef.current,
            "Branch Rankings"
        );
        await addTableToPDF(
            pdf,
            agencyRankingsTableRef.current,
            "Agency Rankings"
        );

        pdf.save(`usage-report-nation-wide.pdf`);
        pdfRef.current = null;
        setIsExporting(false);
        onExportFinish()
    }

    return (
        <div ref={ref} className="flex flex-col w-full">
            <div
                className={cn(
                    "flex flex-col mb-6 gap-4",
                    isPending && "skeletonify"
                )}
            >
                <div className="flex-1 flex flex-col min-h-fit gap-[inherit]">
                    <ExportButton {...{isExporting, setIsExporting,isPending}}>Export report</ExportButton>
                    <CancelExportButton isExporting={isExporting} onClick={() => setIsExporting(false)}/>
                    <SimpleCard
                        ref={disclaimerRef}
                        className="skelechild flex items-center gap-4 w-fit"
                    >
                        <Info className="w-8 h-8 text-info" />
                        <span className="text-info leading-none text-lg">
                            Users are &apos;Active&apos; when they have listened
                            to a message in the last 30 days
                        </span>
                    </SimpleCard>
                    <div className="flex flex-1 min-h-fit gap-[inherit]">
                        <SimpleCard
                            ref={clientChartRef}
                            className="skelechild flex-1 flex-grow  placesel"
                        >
                            <UsageUserCountPie {...props} />
                        </SimpleCard>
                        <SimpleCard
                            ref={messagesChartRef}
                            className="skelechild flex-1 flex-grow"
                        >
                            <UsageMessagesPie {...props} />
                        </SimpleCard>
                    </div>
                </div>

                {/* GRAPHS */}
                <div className="flex w-full gap-4">
                    <SimpleCard className="skelechild  h-full flex-1">
                        <BranchRankings
                            pdfRef={pdfRef}
                            tableFnRef={branchRankingsTableRef}
                            branches={data.branches}
                        >
                            <span className="font-semibold text-xl">
                                Top branches
                            </span>
                        </BranchRankings>
                    </SimpleCard>
                    <SimpleCard className="skelechild flex-1 h-full">
                        <AgencyRankings
                            pdfRef={pdfRef}
                            tableFnRef={agencyRankingsTableRef}
                            agencies={data.agencies}
                        >
                            <span className="font-semibold text-xl">
                                Top Agencies
                            </span>
                        </AgencyRankings>
                    </SimpleCard>
                </div>
                <SimpleCard className="skelechild">
                    <Accordion
                        value={isExporting ? "0" : undefined}
                        type="single"
                        collapsible
                    >
                        <AccordionItem
                            title="Active Clients"
                            value="0"
                            className="border-b-0"
                        >
                            <AccordionTrigger className="font-medium text-md py-0 leading-none">
                                Active Clients
                            </AccordionTrigger>
                            <AccordionContent className="pt-2">
                                <Table
                                    controlledPDF
                                    pdfRef={pdfRef}
                                    tableFnRef={activeTableRef}
                                    showColumnVisibility={false}
                                    showExportButton={false}
                                    showFilters={false}
                                    columnHelper={compactPhoneNumbersCols}
                                    data={data.activeUsers}
                                />
                            </AccordionContent>
                        </AccordionItem>
                    </Accordion>
                </SimpleCard>
                <SimpleCard className="skelechild">
                    <Accordion
                        type="single"
                        collapsible
                        value={isExporting ? "1" : undefined}
                    >
                        <AccordionItem
                            title="Inactive Clients"
                            value="1"
                            className="border-b-0"
                        >
                            <AccordionTrigger className="font-medium text-md py-0 leading-none">
                                Inactive Clients
                            </AccordionTrigger>
                            <AccordionContent className="pt-2">
                                <Table
                                    controlledPDF
                                    pdfRef={pdfRef}
                                    tableFnRef={inactiveTableRef}
                                    showColumnVisibility={false}
                                    showExportButton={false}
                                    showFilters={false}
                                    columnHelper={compactPhoneNumbersCols}
                                    data={data.inactiveUsers}
                                />
                            </AccordionContent>
                        </AccordionItem>
                    </Accordion>
                </SimpleCard>
            </div>
        </div>
    );
});

NationalReportContent.displayName = "NationalReportContent";
