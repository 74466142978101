import { cn } from "@/lib/utils";
import { ChevronRight } from "lucide-react";

type DependentFieldProps = {
    className?: string
    children?: React.ReactNode
}

export const DependentField = ({className, children}: DependentFieldProps) => (
    <div className={cn('flex items-center h-full',className)}>
        <div className="px-8">
            <ChevronRight className=""/>
        </div>
        {children}
    </div>
)