import { MILLISEC } from "@/util/constants";

const MIN_TIME = 1592989991

export const deleteAllCookies = () => {
  let cookies = document.cookie.split(';');
  // console.log("nuking cookies", cookies);
  for (const cookie of cookies) console.log(cookie);
  
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    let name = cookie.split('=')[0];
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;';
  }
};

export const deleteCookie = (name) => {
  document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;';
}


export const getCookie = (cookiename) => {
  let cookiestring = RegExp(cookiename + '=[^;]+').exec(document.cookie);
    const decoded = decodeURIComponent(
      cookiestring ? cookiestring.toString().replace(/^[^=]+./, '') : null
    );
    if (decoded === 'null' ) return null
    if (decoded === 'true' ) return true
    if (decoded === 'false' ) return false
  return decoded
};
export function setCookie(name,value,time) {
    var expires = "";
    if (time) {
      var date = new Date();
      if (time < MIN_TIME) {
        // time is relative (millisec from now)
        console.log('cookie exp param is relative');
        date.setTime(date.getTime() + (time));
        
      } else {
        // time is absolute (unix timestamp)
        console.log('cookie exp param is absolute');
        console.log(time);
        // console.log(date.getTime());
        date.setTime(time);
        // console.log(date.toUTCString());
      }
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}