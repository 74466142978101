import { SearchIcon } from "@/components/misc/icons";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { useRHF } from "@/hooks/useRHF";
import { useYupValidationResolver } from "@/hooks/useYupValidationResolver";
import { ComposedForm } from "@/wrappers/ui/form/ComposedForm";
import { ComposedFormText } from "@/wrappers/ui/form/ComposedFormText";
import { FormError } from "@/wrappers/ui/form/FormError";
import { FormErrorProvider } from "@/wrappers/ui/form/FormErrorProvider";
import Yup from "@/wrappers/yup";
import { Search, SquareArrowOutUpRight } from "lucide-react";
import { number } from "yup";


const validationSchema = Yup.object({
    atLeastOne: Yup.boolean().test(
        'atLeastOne',
        'At least one field must be filled in',
        (_,ctx) => {
            const {name, number, agency} = ctx.parent
            // ctx.parent
            const oneNotNull = name != null || number != null || agency != null
            const oneNotEmpty = name !== '' || number !== '' || agency !== ''
            return oneNotEmpty && oneNotNull
        }   
    ),
    name: Yup.string().notRequired().default(''),
    number: Yup.string().notRequired().default(''),
    agency: Yup.string().notRequired().default(''),
})

export const FindClientPopover = ({onSubmit, children}) => {
    const resolver = useYupValidationResolver(validationSchema)
    const form = useRHF({resolver, defaultValues: validationSchema.getDefault()})
    const {control, handleSubmit} = form
    return (
        <Popover >
            <PopoverTrigger asChild>
                {children}
            </PopoverTrigger>
            <PopoverContent className="w-80" side="bottom" align="start">
                <ComposedForm {...{form, onSubmit}} className='pb-0 py-0'>
                    <div className="pb-6">
                        <div className="flex flex-col">
                            <h1 className="font-medium">Client search</h1>
                            <span className="text-muted-foreground text-sm ">Fill in one or more fields</span>
                        </div>
                        <FormErrorProvider field={'atLeastOne'}>
                                {FormError}
                            </FormErrorProvider>
                    </div>
                    <ComposedFormText
                        {...{
                            control,
                            name: 'name',
                            label: 'Name',
                            placeholder: 'Client name',
                            variant:'large'
                        }}
                    />
                    <ComposedFormText
                        {...{
                            control,
                            name: 'number',
                            label: 'Number',
                            placeholder: 'Client\'s CVM mailbox number',
                            variant:'large'
                        }}
                    />
                    <ComposedFormText
                        {...{
                            control,
                            name: 'agency',
                            label: 'Agency',
                            placeholder: 'Name of the client\'s agency',
                            variant:'large'
                        }}
                    />
                <Button variant="default" className="flex justify-start gap-2 w-fit place-self-end">
                    Search for client
                    <SearchIcon/>
                    {/* <SquareArrowOutUpRight className='w-4 h-4'/> */}
                </Button>
                </ComposedForm>
            </PopoverContent>
        </Popover>
    )
}