import { Card, CardHeader } from '@/components/ui/card';
import { TriangleAlert } from 'lucide-react';


export const NoChartsVisibleCard = ({...props}) => {
    return (
        <Card className='shadow-md border-yellow-400 border-2'>
            <CardHeader>
                <div className="flex gap-4">
                    <TriangleAlert className='h-14 w-14 text-warning'/>
                    <div className="flex flex-col">
                        <span className=' text-xl  font-bold'>No Charts Enabled!</span>
                        <span className='text-muted-foreground'>Enable at least one chart in the global configuration&apos;s visibility section to see results</span>
                    </div>
                </div>
            </CardHeader>
        </Card>
    );
};

