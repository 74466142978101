import { dateFilterFn } from "@/components/tables/helpers/filterFns";
import { nullNum } from "@/util/miscUtils";
import { formatPhoneNumber, getDateString, getNiceDate } from "@/util/textUtils";
import { createColumnHelper, filterFns } from "@tanstack/react-table";

const columnHelper = createColumnHelper();

const stringFilter = filterFns.includesString;
const numRangeFilter = filterFns.inNumberRange
const multiSelectFilter = filterFns.arrIncludesSome
const strictStringFilter = filterFns.includesString.autoRemove

export const compactPhoneNumbersCols = [
    columnHelper.accessor((row) => row.id, {
        id: "ID",
        size: 10    ,
        meta: {
            hiddenFilter:true,
            hiddenCol:true,
            hideExport:true
        },
        filterFn: stringFilter,
        cell: (info) => <span>{info.getValue()}</span>,
        header: (info) => <span>ID</span>,
    }),
    columnHelper.accessor((row) => row.alias, {
        id: "Phone Number",
        size: 10    ,
        filterFn: stringFilter,
        cell: (info) => <span className="whitespace-nowrap  text-md font-semibold">{formatPhoneNumber(info.getValue())}</span>,
        header: (info) => <span>Phone Number</span>,
    }),
    // columnHelper.accessor((row) => row.branchname, {
    //     id: "Branch",
    //     size: 100,
    //     filterFn: stringFilter,
    //     cell: ({row: {original: row}}) => <BranchCell name={row.branchname} id={row.branchid}/>,
    //     header: (info) => <span>Branch</span>,
    // }),
    // columnHelper.accessor((row) => row.agencyname, {
    //     id: "Agency",
    //     size: 100,
    //     filterFn: stringFilter,
    //     cell: ({row: {original: row}}) => <AgencyCell name={row.agencyname} id={row.agencyid} branchid={row.branchid}/>,
    //     header: (info) => <span>Agency</span>,
    // }),
    // columnHelper.accessor((row) => row.clientname, {
    //     id: "Client",
    //     size: 10,
    //     filterFn: stringFilter,
    //     cell: ({row: {original}}) => <LinkedClientCell row={original}/>,
    //     header: (info) => <span>Linked Client</span>,
    // }),
    columnHelper.accessor((row) => nullNum(row.recentReadMsgTime) , {
        id: "Last Read",
        size: 100,
        filterFn: dateFilterFn,
        sortUndefined: 'last',
        meta: {
            filterType: 'date',
        },
        cell: ({row, getValue}) => <span className='whitespace-nowrap text-md font-semibold'>{<DateCell concise value={getValue()}/>}</span>,
        header: (info) => <span>Last Listened</span>,
    }),
    // The field names are backwards
    columnHelper.accessor((row) => nullNum(row.firstMsgTime) , {
        id: "First received",
        size: 100,
        filterFn: dateFilterFn,
        meta: {
            filterType: 'date',
        },
        cell: ({row, getValue}) => <span className='whitespace-nowrap'>{<DateCell concise value={getValue()}/>}</span>,
        header: (info) => <span>First Msg Received</span>,
    }),
    // The field names are backwards
    columnHelper.accessor((row) => nullNum(row.lastMsgTime) , {
        id: "Last Received",
        size: 100,
        filterFn: dateFilterFn,
        meta: {
            filterType: 'date',
        },
        cell: ({row, getValue}) => <span className='whitespace-nowrap'>{<DateCell concise value={getValue()}/>}</span>,
        header: (info) => <span>Last Msg</span>,
    }),
    columnHelper.accessor((row) => nullNum(row.unreadMsgs), {
        id: "Unread",
        size: 100,
        filterFn: numRangeFilter,
        cell: (info) => <span className=''>{info.getValue()}</span>,
        header: (info) => <span>Unheard Msgs</span>,
    }),
    columnHelper.accessor((row) => nullNum(row.readMsgs), {
        id: "Read",
        size: 100,
        filterFn: numRangeFilter,
        cell: (info) => <span className=''>{info.getValue()}</span>,
        header: (info) => <span># Msgs Heard</span>,
    }),
    columnHelper.accessor((row) => nullNum(row.totalMsgs), {
        id: "Total",
        size: 100,
        filterFn: numRangeFilter,
        cell: (info) => <span className=''>{info.getValue()}</span>,
        header: (info) => <span>Total Msgs</span>,
    }),
]



const DateCell = ({value, concise=false}) => value ? (
    concise ? getDateString(value) : getNiceDate(value)
) : (
    <span>-</span>
)