import { postPromise } from "@/api/promises"
import { ROUTE_IDS } from "@/api/routeIDs"
import { composeFormData } from "@/util/apiUtils"
import { deleteAllCookies } from "@/util/cookieUtils"
import { useMutation, useQueryClient } from "@tanstack/react-query"

export const resetPassword = async (params) => {
    return postPromise(import.meta.env.VITE_API_BASE_URL, composeFormData(params, ROUTE_IDS.RESET_PASSWORD),{},{
        skipToken: true
    })
}

export const useResetPassword = ({onSuccess, ...otherProps}) => {
    const mutation = useMutation({
        mutationKey: [ROUTE_IDS.RESET_PASSWORD],
        mutationFn: resetPassword,
        onSuccess: (data, req) => {
            if (data?.status?.contains?.('not ')) {
                throw new Error('Failed to reset password.');}
            onSuccess && onSuccess(data, req)
        },
    ...otherProps})
    return mutation;
}