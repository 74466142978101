import { Button } from "@/components/ui/button";
import { DropdownMenu, DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { ChevronDown } from "lucide-react";
import { useState } from "react";

export const ColumnVisibilityDropdown = ({columns, visibilityModified}) => {
  const [open, setOpen] = useState(false)
  function close() {setOpen(false)}
  return (
     <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" className={visibilityModified ? 'border-highlight-blue text-highlight-blue' : ''}>
          Columns
          <ChevronDown className="w-4 h-4 ml-2"/>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" onPointerLeave={close}>
        <DropdownMenuLabel>My Account</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {columns.map((column) => (
          <DropdownMenuCheckboxItem
            key={column.id}
            className="capitalize"
            checked={column.getIsVisible()}
            onCheckedChange={(value) =>
              column.toggleVisibility(!!value)
            }
            onSelect={e => e.preventDefault()}
          >
            {column.id}
          </DropdownMenuCheckboxItem>
        ))}
      </DropdownMenuContent>
     </DropdownMenu>
  )
}