import { Button } from '@/components/ui/button';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import React, { useEffect, useMemo } from 'react';
import { PageSizeDropdown } from './PageSizeDropdown';
import { rangeArray } from '@/util/miscUtils';
import { setCookie } from '@/util/cookieUtils';


export const Pagination = ({table, location}) => {
    const {pageIndex, pageSize } = table?.getState?.()?.pagination || {}
    const pageCount = table?.getPageCount()
    const getAvailablePagebuttons = () => {
        let minOption = Math.max(0, pageIndex - 2)
        const maxOption = Math.min(pageCount - 1, minOption + 4)
        const range = rangeArray(minOption, maxOption)
        return range
    }
    const setPageIndex = (idx) => {
        table?.setPageIndex?.(idx)
        setCookie(`${location.pathname}_tablePage`, idx)   
    }
    const pageOptions = useMemo(() => getAvailablePagebuttons(), [pageIndex, pageCount])
    return (
        <div className="flex justify-between pt-2">
            {  pageCount > 0 ? (
                <div className='flex gap-2 items-center'>
                    <Button 
                        variant='outline'
                        size={'xs'}
                        className='px-1'
                        onClick={() => {setPageIndex(pageIndex - 1)}}
                        disabled={!table?.getCanPreviousPage()}>
                        {/* <BackArrowShort className='w-4 h-4'/> */}
                        <ChevronLeft className='w-5 h-5'/>
                    </Button>
                    <div className='join flex gap-1 join-horizontal'>
                        {   (pageIndex + 1) > 3 && 
                            ( 
                                (pageIndex + 1) === 4  ? 
                                <PageButton index={0} onClick={setPageIndex}>{1}</PageButton>
                            : <>
                                    <PageButton index={0} onClick={setPageIndex}>{1}</PageButton>
                                    <PageButton>...</PageButton>
                                </>
                            )
                        }
                        {pageOptions.map((index) => (
                            
                            <PageButton
                                index={index} 
                                key={'page' + index}
                                isActive={pageIndex === index} 
                                onClick={setPageIndex}
                                >
                                    {index + 1}
                            </PageButton>
                            ))}
                        {   pageCount - (pageIndex + 1) > 2 && 
                            ( 
                                pageCount - (pageIndex + 1) === 3  ? 
                                <PageButton index={pageCount - 1} onClick={setPageIndex}>{pageCount}</PageButton>
                            : <>
                                    <PageButton>...</PageButton>
                                    <PageButton index={pageCount - 1} onClick={setPageIndex}>{pageCount}</PageButton>
                                </>
                            )
                        }
                    </div>
                    <Button 
                        variant='outline'
                        size={'xs'}
                        className='px-1'
                        onClick={() => {setPageIndex(pageIndex + 1)}}
                        disabled={!table?.getCanNextPage()}>
                        <ChevronRight  className='w-5 h-5'/>
                    </Button>
                </div>

            ) : (
                <div className="w-1"></div>
            )}

                <PageSizeDropdown table={table}/>
        </div>
    );
};


const PageButton = ({onClick= () => {}, index, isActive, children}) => {
    return (
        <Button data-index={index} className='data-[index="2"]:hidden data-[index="3"]:hidden  data-[index="4"]:hidden  @[400px]:!inline-flex' variant={isActive ? 'default' : 'outline'} size={'xs'} onClick={() => onClick(index)}>
            {children}
        </Button>
    )
}