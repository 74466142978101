import useAllNumbers from "@/api/numbers/getAllNumbers";
import { useNumbersInAgencies } from "@/api/numbers/getNumbersInAgency";
import { MILLISEC } from "@/util/constants";
import { nullNum } from "@/util/miscUtils";
import { useEffect, useState } from "react";

export type UsageReportAgency = Omit<
    UsageReportData,
    "agencies" | "branches"
> & {
    name: string;
    id: number;
    branchname: string;
    branchid: string;
};
export type usageReportBranch = Omit<
    UsageReportData,
    "agencies" | "branches"
> & {
    name: string;
    id: number;
    agencies: UsageReportAgency[];
};
export type UsageReportData = {
    activeCount: number;
    inactiveCount: number;
    activeRead: number;
    activeUnread: number;
    inactiveRead: number;
    inactiveUnread: number;
    totalRead: number;
    totalUnread: number;

    avgReadRatio: number;
    activeAvgReadRatio: number;
    activeReadRatioSum: number;
    readRatioSum: number;
    activeUsers: any[];
    inactiveUsers: any[];
    usageRatio: number;
    neverRead: number;
    agencies?: UsageReportAgency[];
    branches?: usageReportBranch[];
};

const defaultValues = () => ({
    activeCount: 0,
    inactiveCount: 0,
    activeRead: 0,
    activeUnread: 0,
    inactiveRead: 0,
    inactiveUnread: 0,
    totalRead: 0,
    totalUnread: 0,
    avgReadRatio: 0,
    activeAvgReadRatio: 0,
    activeReadRatioSum: 0,
    readRatioSum: 0,
    usageRatio: 0,
    neverRead: 0,
    activeUsers: [],
    inactiveUsers: [],
});

type UsageReportDataHook = ({
    config,
    myUserDataQuery,
    branchesQuery,
    agenciesQuery,
    enabled,
}) => {
    data: UsageReportData;
    isPending: boolean;
};
export const useUsageReportData: UsageReportDataHook = ({
    config,
    myUserDataQuery,
    branchesQuery,
    agenciesQuery,
    enabled,
}) => {
    const isAdmin = myUserDataQuery.data?.isAdmin;
    const [data, setData] = useState<UsageReportData>({
        branches: [],
        agencies: [],
        ...defaultValues(),
    });
    const adminNumbersQuery = useAllNumbers({ enabled: isAdmin });
    const numbersQuery = useNumbersInAgencies(
        agenciesQuery.data,
        // DONT FORGET TO UNCOMMENT
        !isAdmin
    );

    const isPending =
        branchesQuery.isPending ||
        agenciesQuery.isPending ||
        (isAdmin && adminNumbersQuery.isPending);
    useEffect(calculateData, [
        config,
        branchesQuery.data,
        agenciesQuery.data,
        adminNumbersQuery.data,
        numbersQuery.data,
        enabled,
    ]);

    function calculateData() {
        if (isPending || !enabled) return;
        const { view, branch, agency } = config;
        const numbersSrc = isAdmin ? adminNumbersQuery.data : numbersQuery.data;
        let filteredNumbers = [];
        if (view === "national") {
            filteredNumbers = numbersSrc;
        }
        if (view === "branch" && branch != null) {
            filteredNumbers = numbersSrc.filter(
                (n) => n.branchid === String(branch.id)
            );
        }
        if (view === "agency" && agency != null) {
            filteredNumbers = numbersSrc.filter(
                (n) => n.agencyid === String(agency?.id)
            );
        }
        const total = {
            readRatioSum: 0,
            activeRead: 0,
            activeUnread: 0,
            inactiveRead: 0,
            inactiveUnread: 0,
            activeReadRatioSum: 0,
            neverRead: 0,
        };

        const branches = {};
        const agencies = {};
        const thirtyDaysAgo = new Date().getTime() - MILLISEC.DAY * 30;
        const [activeUsers, inactiveUsers] = filteredNumbers.reduce(
            (acc, number, idx) => {
                const {
                    readMsgs: r,
                    unreadMsgs: u,
                    recentReadMsgTime,
                } = number || {};
                const readMsgs = nullNum(r) || 0;
                const unreadMsgs = nullNum(u) || 0;
                const isActive =
                    recentReadMsgTime != null &&
                    Number(recentReadMsgTime) > thirtyDaysAgo;
                number.isActive = isActive;
                const hasZeroMsgs = readMsgs + unreadMsgs === 0;
                const readRatio = hasZeroMsgs
                    ? 0
                    : readMsgs / (readMsgs + unreadMsgs);
                const mutateList = [total];
                if (view === "national") {
                    if (!branches[number.branchid]) {
                        branches[number.branchid] = {
                            name: number.branchname,
                            id: number.branchid,
                            agencies: {},
                            branchRank: 0,
                            ...defaultValues(),
                        };
                    }
                    if (!branches[number.branchid].agencies[number.agencyid]) {
                        branches[number.branchid].agencies[number.agencyid] = {
                            name: number.agencyname,
                            id: number.agencyid,
                            branchname: number.branchname,
                            branchid: number.branchid,
                            agencyRankInBranch: 0,
                            agencyRankTotal: 0,
                            ...defaultValues(),
                        };
                    }
                    branches[number.branchid][
                        isActive ? "activeUsers" : "inactiveUsers"
                    ].push(number);
                    branches[number.branchid].agencies[number.agencyid][
                        isActive ? "activeUsers" : "inactiveUsers"
                    ].push(number);

                    mutateList.push(branches[number.branchid]);
                    mutateList.push(
                        branches[number.branchid].agencies[number.agencyid]
                    );

                    // mergeSum([branches[number.branchid], curr])
                    // mergeSum([branches[number.branchid].agencies[number.agencyid],curr])
                }
                if (view === "branch" || view === "national") {
                    if (!agencies[number.agencyid]) {
                        agencies[number.agencyid] = {
                            name: number.agencyname,
                            id: number.agencyid,
                            branchname: number.branchname,
                            branchid: number.branchid,
                            agencyRankInBranch: 0,
                            ...defaultValues(),
                        };
                    }
                    agencies[number.agencyid][
                        isActive ? "activeUsers" : "inactiveUsers"
                    ].push(number);
                    mutateList.push(agencies[number.agencyid]);
                }
                for (const subscriber of mutateList) {
                    if (hasZeroMsgs) subscriber.neverRead++;
                    if (isActive) {
                        subscriber.activeRead += readMsgs;
                        subscriber.activeUnread += unreadMsgs;
                        subscriber.activeReadRatioSum += readRatio;
                    } else {
                        subscriber.inactiveRead += readMsgs;
                        subscriber.inactiveUnread += unreadMsgs;
                    }
                }
                acc[isActive ? 0 : 1].push(number);
                return acc;
            },
            [[], []]
        );

        // const agenciesSortedByActiveUsers = Object.entries(branches).sort(compareActiveUsersEntries)
        total.activeUsers = activeUsers;
        total.inactiveUsers = inactiveUsers;
        total.branches = Object.values(branches);
        total.agencies = Object.values(agencies);
        for (const branch of total.branches) {
            branch.agencies = Object.values(branch.agencies);
        }
        for (const branch of total.branches) {
            computeAggregates(branch);
            for (const agency of branch.agencies) {
                computeAggregates(agency);
            }
        }
        for (const agency of total.agencies) {
            computeAggregates(agency);
        }
        computeAggregates(total);
        total.readRatioSum = 444;
        const agenciesSortedByActiveUsers = Object.values(agencies)
            .map(countUserTypes)
            .sort(compareActiveUsers);
        const branchesWithAgenciesSortedByActiveUsers = Object.values(branches)
            .map((b: any) => {
                const sortedAgencies = Object.values(b.agencies)
                    .map(countUserTypes)
                    .sort(compareActiveUsers);
                return {
                    ...b,
                    agencies: sortedAgencies,
                };
            })
            .map(countUserTypes)
            .sort(compareActiveUsers);
        setData({
            activeUsers,
            inactiveUsers,
            ...total,
        });
    }

    return {
        data,
        isPending,
    };
};

const mergeSum = (arr) => {
    const result = {}; //(1)
    arr.forEach((obj) => {
        //(2)
        for (const [key, value] of Object.entries(obj)) {
            //(3)
            if (result[key] && Number.isInteger(result[key])) {
                //(4)
                result[key] += value; //(5)
            } else {
                //(6)
                result[key] = value;
            }
        }
    });
    return result; //(7)
};

const computeAggregates = (obj) => {
    const aLen = obj.activeUsers.length;
    const iLen = obj.inactiveUsers.length;
    obj.activeCount = aLen;
    obj.inactiveCount = iLen;
    obj.totalRead = obj.activeRead + obj.inactiveRead;
    obj.totalUnread = obj.activeUnread + obj.inactiveUnread;
    obj.avgReadRatio = obj.readRatioSum / aLen + iLen;
    obj.activeAvgReadRatio = obj.activeReadRatioSum / aLen;
    obj.usageRatio = aLen / aLen + iLen;
};

function compareActiveUsers(a, b) {
    return (b.activeCount || 0) - (a.activeCount || 0);
}

function compareActiveUsersEntries([, a], [, b]) {
    return compareActiveUsers(a, b);
}

function countUserTypes(obj) {
    obj.activeCount = obj.activeUsers.length || 0;
    obj.inactiveCount = obj.inactiveUsers.length || 0;
    return obj;
}
function countUserTypesEntries([_, obj]) {
    obj.activeCount = obj.activeUsers.length || 0;
    obj.inactiveCount = obj.inactiveUsers.length || 0;
    return [_, obj];
}
