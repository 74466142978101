import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastIcon,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from "@/components/ui/toast"
import { useToast } from "@/components/ui/use-toast"
import { AnimatePresence, motion } from "framer-motion"
import * as ToastPrimitives from '@radix-ui/react-toast'


export function Toaster() {
  const { toasts, dismiss} = useToast()
  return (
    <ToastProvider>
      <AnimatePresence presenceAffectsLayout >
        {toasts.map(({ id, title, description, status, action, ...props }) => {
          return (
            <Toast 
              forceMount 
              asChild
              variant={status} 
              key={id} 
              onClick={() => dismiss(id)}
              className="hover:cursor-pointer"
              {...props}
              >
              <div>
                <div className="flex gap-5 h-full items-center">
                  <ToastIcon status={status}/>
                  <div className="grid gap-1">
                    {title && <ToastTitle status={status}>{title}</ToastTitle>}
                    {description && (
                      <ToastDescription status={status}>{description}</ToastDescription>
                    )}
                  </div>
                </div>
              </div>
              {action}
              <ToastClose status={status}/>
            </Toast>
          )
        })}
      </AnimatePresence>
      <ToastViewport />
    </ToastProvider>
  )
}