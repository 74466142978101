import { PIE_RADIUS } from '@/util/constants';
import { commaNumber } from '@/util/textUtils';
import { sub } from 'date-fns';


export const customDataLabels = {
    id: 'customDataLabels',
    afterDatasetsDraw(chart, args, pluginOptions) {
        const {ctx, data, chartArea: {top, bottom, left, right, width, height}} = chart;
        
        const {
            display,
            font: {size=12, weight='bold'} = {}, 
            color, 
            align='start', 
            strokeWidth=2,
            yOffset=25,
            xOffset=15,
        } = pluginOptions

        ctx.save()
        const halfWidth = width / 2 + left
        const halfHeight = height / 2 + top
        
        if (!display) return;
        
        const values = data.datasets[0].data
        if (values.some(v => v == null)) return;
        const sum = values.reduce((a,b)=>a+b,0);
        data.datasets[0].data.forEach((value, index) => {
            if (value > 0) {
                const {x, y} = chart.getDatasetMeta(0).data[index].tooltipPosition();
                ctx.font = `${weight} ${size}px sans-serif`;
                ctx.fillStyle = data.datasets[0].borderColor[index];
                // ctx.textAlign = align;
                ctx.textBaseline = 'middle';
                ctx.lineWidth = strokeWidth
                const xLine = x >= halfWidth ? x + xOffset : x - xOffset;
                const yLine = y >= halfHeight ? y + yOffset : y - yOffset;
                const extraLine = x >= halfWidth ? xOffset : - xOffset;
                const xPastHalf = x >= halfWidth;
                ctx.textAlign = xPastHalf ? 'start' : 'end';
                const text = commaNumber(value)
                const subText = `(${(value / sum * 100).toFixed(1)}%)`
                const textWidth = ctx.measureText(text).width ;
                const subTextWidth = ctx.measureText(subText).width ;
                const combinedWidth = textWidth + subTextWidth;    
                const textWidthPosition = xPastHalf ? 4 : -subTextWidth + 4
                const subTextPosition = xPastHalf ? textWidth + 8  : -4
                ctx.strokeStyle = color || data.datasets[0].borderColor[index];
                ctx.beginPath();
                ctx.moveTo(x, y);
                ctx.lineTo (xLine, yLine);
                ctx.lineTo(xLine + extraLine, yLine);
                ctx.stroke();
                ctx.fillText(text, xLine + extraLine + textWidthPosition , yLine);
                ctx.font = `bolder ${size - 4}px sans-serif`;
                ctx.fillText(subText, xLine + extraLine + subTextPosition , yLine);
            }
        })

        // ctx.beginPath();
        // ctx.moveTo(halfWidth, halfHeight);
        // ctx.arc(95, 50, 40, 0, 2 * Math.PI);
        // ctx.stroke()

    }
}

export const doughnutOptions = ({
    animations=true,
    showTitle = false,
    title,
    showLegend = true,
    alignTitle = 'center',
    titleFontSize,
    legendPos,
    legendDotSize = 20,
    legendFontSize,
    titlePos,
    backgroundColor,
    showAttachedLegend,
    attachedLegendPos,
    attachedLegendFontSize = 15,
    showAttachedLabel = true,
    attachedLabelFontSize=12,
    spacing = 5,
    borderRadius = 10,
    cutout=0,
    showAttachedLabelTail=false,
    disableAnimation = false,
    hoverOffset = 100,
    tailedAttachedLabelStrokeWidth = 2,
    displayTailedAttachedLabel = false,
    tailedAttachedLabelFontSize = 12,
    tailedAttachedLabelYOffset = 25,
    tailedAttachedLabelXOffset = 15,
    displayTooltip= true,
    displayPercentage= false,
    percentageFontSize= 12,
    ...rest
}) => ({
    responsive: true,
    maintainAspectRatio: false,
    cutout: cutout,
    radius: PIE_RADIUS + '%',
    borderWidth: 0,
    spacing: spacing,
    offset: 0,
    hoverOffset: hoverOffset,
    // resizeDelay: 100,
    borderRadius: borderRadius,
    borderAlign: 'inner',
    layout: {
        // padding: 30,
        autoPadding: true,
    },
    // animation: false,
    animations: animations ?
    {
        // numbers: {
        //     type: 'number',
        //     properties: ['x', 'y', 'borderWidth', 'tension','circumference'],
        //     fn: (f,t,fac,g) => {console.log('hey',f,t,fac)}
        // },
        numbers: {
            type: 'number',
            properties: ['circumference', 'endAngle', 'startAngle', 'x', 'y', 'offset', 'borderWidth', 'spacing'],
            duration: 1000
        },
        radii: {
            type: 'number',
            properties: ['radius', 'innerRadius', 'outerRadius'],
            duration: 100
        },
    } : animations,
    // transitions: {
    //     resize: {
    //         animation: {
    //             duration: 0
    //         }
    //     },
    //     active: {
    //         animation: {
    //             duration: 1000,
    //         }
    //     }
    // },
    plugins: {
        customCanvasBackgroundColor: {
            color: backgroundColor
        },
        customDataLabels: {
            display: displayTailedAttachedLabel,
            strokeWidth: tailedAttachedLabelStrokeWidth,
            yOffset: tailedAttachedLabelYOffset,
            xOffset: tailedAttachedLabelXOffset,
            font: {
                size: tailedAttachedLabelFontSize,
                weight: 'bold'
            },
        },
        tooltip: {
            enabled: displayTooltip,
        },
        datalabels: {
            anchor: 'end',
            labels: {
                value: {
                    display: showAttachedLabel,
                    align: function (ctx) {
                        // console.log('align',ctx.chart.scale);
                        return 'center';
                    },
                    color: function (ctx) {
                        return 'black';
                    },
                    backgroundColor: function (ctx) {
                        if (displayTailedAttachedLabel) return
                        var color = ctx.dataset.backgroundColor?.[ctx.dataIndex];
                        return color;
                    },
                    formatter: function (value, context) {
                       
                        return value;
                    },
                    font: {
                        weight: 'bolder',
                        size: attachedLabelFontSize,
                    },
                    borderColor: 'white',
                    borderWidth: 2,
                    borderRadius: 4,
                    padding: 6,
                    offset: 10,
                },
                percentValue: {
                    display: displayPercentage,
                    anchor: 'start',
                    align: function (ctx) {
                        return 'end';
                    },
                    color: function (ctx) {
                        return 'black';
                    },
                    formatter: function (value, ctx) {
                        let sum = 0;
                        let dataArr = ctx.chart.data.datasets[0].data;
                        dataArr.map(data => {
                            sum += data;
                        });
                        let percentage = (value*100 / sum).toFixed(1)+"%";
                        return percentage;
                    },
                    font: {
                        weight: 'bolder',
                        size: percentageFontSize,
                    },
                    offset: (ctx) => {
                        const {width, height} = ctx.chart.chartArea
                        return (Math.min(width, height) - 30) / 4;
                    },
                },
                title: {
                    display: showAttachedLegend,
                    align: attachedLegendPos,
                    font: {
                        size: attachedLegendFontSize,
                        weight: 'bold'
                    },
                    color: 'black',
                    formatter: function (value, ctx) {
                        // console.log('formatter', value,ctx);
                        return ctx.chart.data.labels[ctx.dataIndex];
                    },
                    offset: 15,
                },
            },
        },
        title: {
            display: showTitle,
            text: title,
            align: alignTitle,
            position: titlePos,
            font: {
                size: titleFontSize === '' ? 20 : titleFontSize
            },
            fullSize: true,
        },
        // subtitle: {
        //     display: showTitle,
        //     text: title,
        //     align: alignTitle,
        //     font: {
        //     },
        //     fullSize: true,
        // },
        legend: {
            display: showLegend,
            // fullSize:true,
            position: legendPos,
            align: "center",
            labels: {
                color: 'black',
                // generateLabels: (v) => console.log('gen',v),
                font: {
                    size: legendFontSize,
                },
                boxWidth: legendDotSize,
                boxHeight: legendDotSize,
                usePointStyle: true,
                pointStyle: 'circle',
            }
        }
    },
    ...rest 
});
