import { PinCheckingCell } from "@/components/tables/helpers/PinCheckingCell"
import { PinCheckingRow } from "@/components/tables/helpers/PinCheckingRow"
import { cn } from "@/lib/utils"
import { downloadElementAsPng } from "@/util/fileUtils"
import { flexRender } from "@tanstack/react-table"
import { Fragment } from "react/jsx-runtime"

export const RowSection = ({disabled, rowAccordion, rows, hoverShadow, rowClassName, cellClassName, SubComponent}) => 
  rows?.map?.( row => (
      <Fragment key={row.id}>
          <PinCheckingRow
            row={row}
            key={row.id}
            // onClick={(e) => downloadElementAsPng(e.currentTarget)}
            className={cn(
              ' group text-sm border-b',
              disabled && 'opacity-40', 
              row.getIsSelected() && !rowAccordion && 'bg-green-100 outline-green-150 odd:bg-green-100   outline outline-2 -outline-offset-1',
              rowAccordion && row.getIsSelected() && 'border-none',
              hoverShadow && 'hover:cursor-pointer hover:shadow-card odd:hover:shadow-card',
              rowClassName
            )}
          >
            {row.getVisibleCells().map((cell) => (
              <PinCheckingCell
                hidden={cell.column.columnDef?.meta?.hiddenCol}
                key={cell.id}
                pinned={cell.column.getIsPinned()}
                style={{width: cell.column.getSize()}}
                className={cellClassName}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </PinCheckingCell>
            ))}
          </PinCheckingRow>

          { rowAccordion && row.getIsSelected() && (
            <tr key={'subcomponent' + row.id}>
              <td className="col-span-full px-4" colSpan={'100%'}>
                <SubComponent row={row}/>
              </td>
            </tr>
          )}
      </Fragment>
    ))
