import { login, useLogin } from '@/api/auth/login';
import { getMyUserDataQueryOptions } from '@/api/user/getMyUserData';
import { useToast } from '@/components/ui/use-toast';
import { Background } from '@/routes/Auth/components/Background';
import { MILLISEC } from '@/util/constants';
import { deleteAllCookies, setCookie } from '@/util/cookieUtils';
import { useQueryResultToasts } from '@/wrappers/toasts';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { Outlet, redirect, useLocation, useNavigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';
import { AnimatePresence, motion } from 'framer-motion';

export const Auth = ({ ...props}) => {
    console.log('routerstate',location.state);

    return (
        <div className='flex flex-col py-40 w-full items-center'>
            <Background />
            <AnimatePresence>
                <motion.div
                    className='z-[3]'
                    initial={{opacity: 0, y: 50}}
                    animate={{opacity: 1, y: 0}}
                    exit={{opacity: 0}}
                    transition={{duration: 0.3}}
                >
                    <Outlet />
                </motion.div>
            </AnimatePresence>
            {/* auth */}
        </div>
    );
};


export const loginAction = (queryClient) =>  async (params) => {
    console.log('in login action');
    const data =  await login(params)
    if (data.token) {
        queryClient.reset()
        deleteAllCookies()
        const decodedToken = jwtDecode(data.token)
        const decodedRefreshToken = jwtDecode(data.refreshToken) 
        console.log('decoded',decodedToken, decodedRefreshToken);
        setCookie('token', data.token,MILLISEC.HOUR)
        setCookie('refreshToken', data.refreshToken,MILLISEC.DAY)

        redirect('/')
    }

}