import { AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Separator } from '@/components/ui/separator';
import { cva } from 'class-variance-authority';
import React from 'react';
import { cn } from '../../lib/utils';
import { useToast } from './use-toast';
import { Button } from './button';
import { AccordionHeader } from '@radix-ui/react-accordion';

// export type FormSectionStatus = 'inProgress' | 'submitted' | 'complete' | 'error';
export enum FormSectionStatus {
    inProgress = 'In Progress',
    submitted = 'Submitted',
    complete = 'Complete',
    error = 'Error'

}

interface FormSectionProps {
    title: string;
    children: React.ReactNode;
    sectionIndex: number;
    status?: FormSectionStatus | undefined;
}

export const FormSection = ({ title, children, sectionIndex, status, ...props }: FormSectionProps) => {
    return (
        <AccordionItem value={String(sectionIndex)} className=''>
            <AccordionTrigger iconClassName='w-6 h-6 stroke-[3]' asChild className='hover:cursor-pointer'>
                <div className='hover:cursor-pointer'>
                    <span className='text-xl'>
                        {title}
                    </span>
                    <SectionStatus status={status}/>
                </div>
            </AccordionTrigger>
            {/* <Separator/> */}
            {/* <CardDescription>hell</CardDescription> */}
            <AccordionContent className='pl-6  border-l-4 mb-4 pb-0   mt-5' >   
                {/* <Separator className='mb-4'/> */}
                {children}
            </AccordionContent>
        </AccordionItem>
    );
};


const SectionStatusVariants = cva(
    'ml-2 text-lg flex space-x-1 items-start ml-5',
    {
        variants: {
            variant: {
                'In Progress': 'text-warning',
                Submitted: 'text-blue-500',
                Complete: 'text-success',
                Error: 'text-error'
            }
        },
        defaultVariants: {
            variant: 'In Progress'
        }
    },
    
)
const SectionStatus = ({status}) => {
    return status && (
        <div className={cn(SectionStatusVariants({variant: status}))}>
            <StatusIcon {...{status}}/>
            <span>{status}</span>
        </div>
    )
}

function StatusIcon({status}) {
    switch (status) {
        case FormSectionStatus.inProgress:
            return <InProgressIcon/>
        case FormSectionStatus.submitted:
            return <SubmittedIcon/>
        case FormSectionStatus.complete:
            return <CompleteIcon/>
        case FormSectionStatus.error:
            return <ErrorIcon/>
    }    
}

export function getFormSectionStatus({
    submitted,
    isValid,
    isDirty,
}): FormSectionStatus {
    if (submitted && isDirty && isValid) return FormSectionStatus.complete
    if (submitted && isDirty && !isValid) return FormSectionStatus.error
    if (submitted && !isDirty) return FormSectionStatus.submitted
    return FormSectionStatus.inProgress
}

const InProgressIcon = (props) => (
    <svg {...props} width="24" height="24" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4"><path d="M8 4h32M8 44h32M12 4v12l9 10m15 18V29.5L27 21M12 44V30l6.5-6.5"/><path d="M36 4v12l-6.5 7.5M18 33h1m10.147-.353l.707.707M24 38h1"/></g></svg>
)
const CompleteIcon = (props) => (
    <svg {...props} width="24" height="24" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M512 0C229.232 0 0 229.232 0 512c0 282.784 229.232 512 512 512c282.784 0 512-229.216 512-512C1024 229.232 794.784 0 512 0m0 961.008c-247.024 0-448-201.984-448-449.01c0-247.024 200.976-448 448-448s448 200.977 448 448s-200.976 449.01-448 449.01m204.336-636.352L415.935 626.944l-135.28-135.28c-12.496-12.496-32.752-12.496-45.264 0c-12.496 12.496-12.496 32.752 0 45.248l158.384 158.4c12.496 12.48 32.752 12.48 45.264 0c1.44-1.44 2.673-3.009 3.793-4.64l318.784-320.753c12.48-12.496 12.48-32.752 0-45.263c-12.512-12.496-32.768-12.496-45.28 0"/></svg>
) 
const SubmittedIcon = (props) => (
    <svg {...props}  strokeWidth={1.4} width="24" height="24" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="m1.5 9l16-6.535L14.7 17zm16-6.5l-11 10m0 0v5l3-3"/></svg>   
)
const ErrorIcon = (props) => (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M11 7h2v7h-2zm0 8h2v2h-2z"/><path fill="currentColor" d="m21.707 7.293l-5-5A.996.996 0 0 0 16 2H8a.996.996 0 0 0-.707.293l-5 5A.996.996 0 0 0 2 8v8c0 .266.105.52.293.707l5 5A.996.996 0 0 0 8 22h8c.266 0 .52-.105.707-.293l5-5A.996.996 0 0 0 22 16V8a.996.996 0 0 0-.293-.707M20 15.586L15.586 20H8.414L4 15.586V8.414L8.414 4h7.172L20 8.414z"/></svg>
)
