import { deletePromise, postPromise } from "@/api/promises"
import { ROUTE_IDS } from "@/api/routeIDs"
import { composeFormData, convertObjFieldsToString } from "@/util/apiUtils"
import { MICROSERVICE_URL } from "@/util/constants"
import { deleteAllCookies } from "@/util/cookieUtils"
import { useMutation, useQueryClient } from "@tanstack/react-query"

export const deleteFAQ = async (params) => {
    return deletePromise(`${MICROSERVICE_URL}/faq`, params)
}

export const useDeleteFAQ = ({onSuccess, ...otherProps}) => {
    const client = useQueryClient();
    const mutation = useMutation({
        mutationKey: ['faq', 'delete'],
        mutationFn: deleteFAQ,
        onSuccess: (data, req) => {
            client.invalidateQueries({queryKey: ['faq']}).then(() => {
                onSuccess && onSuccess(data, req)
            })
        },
    ...otherProps})
    return mutation;
}