import { useResetPassword } from '@/api/auth/resetPassword';
import { useSendPasswordResetEmail } from '@/api/auth/sendResetPasswordEmail';
import { BackButton } from '@/components/buttons/BackButton';
import { TooSoonButton } from '@/components/buttons/TooSoonButton';
import { PageHeader } from '@/components/misc/PageHeader';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Form } from '@/components/ui/form';
import { InputOTP, InputOTPGroup, InputOTPSeparator, InputOTPSlot } from '@/components/ui/input-otp';
import { useObjectSearchParams } from '@/hooks/state/useObjectSearchParams';
import { useRHF } from '@/hooks/useRHF';
import { useYupValidationResolver } from '@/hooks/useYupValidationResolver';
import { useTooSoon } from '@/hooks/wrappers/useTooSoon';
import { checkFormForAutofill } from '@/util/formUtils';
import { useQueryResultToasts } from '@/wrappers/toasts';
import { ComposedForm } from '@/wrappers/ui/form/ComposedForm';
import { ComposedFormText } from '@/wrappers/ui/form/ComposedFormText';
import Yup from '@/wrappers/yup';
import { set } from 'date-fns';
import { REGEXP_ONLY_DIGITS_AND_CHARS } from 'input-otp';
import { Mail, Send } from 'lucide-react';
import React, { useEffect, useRef } from 'react';
import { useCountUp } from 'react-countup';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';


const validationSchema = Yup.object({
    email: Yup.string().email().required('Enter an email').default(''),
    newpassword: Yup.string().min(6).required('Enter a new password').default(''),
    confirmPassword: Yup.string().min(6, 'password must be at least 6 characters').required('Please re-enter your new password').default('').equalTo(Yup.ref('newpassword'),'Passwords must match'),
    code: Yup.string().required('Enter the code').default(''),
})
const submissionSchema = Yup.object({
    email: Yup.string(),
    newpassword: Yup.string().default(null),
    oldpassword: Yup.string().default(''),
    code: Yup.string(),
})
export const ResetPassword = ({...props}) => {
    const nav = useNavigate()
    const [value, setValue] = React.useState('');
    const [error, setError] = React.useState(null);
    const [tooSoon, setTooSoon] = React.useState(false);


    const [searchParams,_] = useObjectSearchParams()
    const {code, email} = searchParams

    const queryResultToasts = useQueryResultToasts();
    const resetMutation = useResetPassword(queryResultToasts(
        "Password Reset", 
        "Error Resetting Password",
        handleResetSuccess,
        handleResetFail
    ));
    const sendResetMutation = useSendPasswordResetEmail(queryResultToasts(
        "Reset Code Sent",
        "Error Sending Reset Code"
    ))
    const resolver = useYupValidationResolver(validationSchema,null,null,submissionSchema);
    const form = useRHF({resolver, defaultValues: validationSchema.getDefault()});
    const tooSoonHook = useTooSoon({
        cooldownDuration: 20,
        ref:'timer',
        initialTooSoon: false
    })

    useEffect(validateQueryParams, [code, email]);
    useEffect(putSearchParamsInForm,[searchParams])

    function validateQueryParams() {
        let queryParamError = null
        if (!email) 
            queryParamError = 'Missing email';
        if (!code)
            queryParamError = 'Missing code';
        if (queryParamError) 
            setError(queryParamError);
        if (code) 
            setValue(code);

    }
    function putSearchParamsInForm() {
        form.reset(searchParams);
    }
    function handleResendEmail() {
        if (tooSoon) 
            return;
        setTooSoon(true);
        sendResetMutation.mutate({email})
    }
    function handleResetSuccess() {
        nav('/auth/login');
    }
    function handleResetFail() {
        setError('Reset Failed');
    }
    function handleResetPassword(fields) {
        console.log('fields', fields);
        !resetMutation.isPending && resetMutation.mutate(fields);
    }
    // console.log(tooSoonCountdown.getCountUp().);

    return (
        <div>
            <Card className='max-w-full lg:w-[35rem]' {...props} >
                {error === 'Missing email' ? (
                    <NoEmailError />
                ) : (
                    <>
                        <CardHeader>
                            <CardTitle>Write this one down somewhere</CardTitle>
                            <CardDescription>
                                {resetMutation.isError || error === 'Missing code' 
                                    ? (
                                        <>
                                            <span className='text-destructive !no-underline'>Something went wrong. </span>
                                            <TooSoonButton
                                                tooSoonHook={tooSoonHook}
                                                onClick={handleResendEmail}
                                                
                                                render={({timer, tooSoon}) => (
                                                    <p className='inline-flex items-center w-fit'>
                                                        {tooSoon 
                                                        ?  <>Resend email in {timer}</>
                                                        : <>Resend email? <Mail className='inline text-center ml-1 w-3 h-3'/></>
                                                        }
                                                    </p>
                                                )}
                                            />
                                        </>
                                    )
                                    : <>Please enter the new password you would like to use for <span className=' text-secondary-foreground'>{email}</span></>
                                }
                            </CardDescription>
                        </CardHeader>
                        <ComposedForm {...{form, onSubmit: handleResetPassword}}>
                            <CardContent>
                                        <ComposedFormText
                                            control={form.control}
                                            name='newpassword'
                                            type='password'
                                            label='New Password'
                                            placeholder='Enter your new password'
                                            className='w-full'
                                            />
                                        <ComposedFormText
                                            control={form.control}
                                            name='confirmPassword'
                                            type='password'
                                            label='Confirm Password'
                                            placeholder='Confirm your new password'
                                            className='w-full'
                                            />
                            </CardContent>
                            <CardFooter className='block'>
                                <Button disabled={value?.length < 6} variant={'default'} className='w-full text-lg font-normal' type='submit'>
                                    {resetMutation.isPending 
                                        ? <div className="loading loading-infinity loading-lg"/> 
                                        : 'Reset Password'
                                    }
                                </Button>
                                <Link to={'/auth/login'}>
                                    <BackButton className='mt-1'>
                                        Back to login
                                    </BackButton>
                                </Link>
                            </CardFooter>
                        </ComposedForm>
                    </>
                )}
            </Card>
        </div>
    );
};

const NoEmailError = () => (
    <>
        <CardHeader>
            <CardTitle>Something went wrong</CardTitle>
            <CardDescription>
                We couldn't find an email address to reset the password for. 
            </CardDescription>
        </CardHeader>
        <CardFooter>
            <div className="flex w-full gap-4 !mt-8">
                <Link to={'/auth/forgot-password'} className='w-full'>
                    <Button variant='default' className='w-full text-md'>
                        Re-enter email
                    </Button>
                </Link>
                <Link to='/auth/login' className='w-full'>
                    <Button variant='secondary' className='w-full text-md'>
                        Return to login
                    </Button>
                </Link>
            </div>
        </CardFooter>
        
        
    </>
)