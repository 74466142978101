export function objToQueryParamStr(obj) {
    // console.log('obj', (Object
    //     .entries(obj)
    //     .filter(([k,v]) => v != null)
    //     .map(([k,v]) => {
    //         return `${k}=${v}`;
    //     })).join('&'));
    return (
        '?' + 
        ((Object
            .entries(obj)
            .filter(([k,v]) => v != null)
            .map(([k,v]) => {
                return `${k}=${v}`;
            })).join('&'))
    )
}