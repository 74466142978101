import { Button } from '@/components/ui/button';
import { DashboardIcon } from '@/routes/Main/components/Sidebar/Sidebar';
import { Undo2 } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';

export const NotFound = ({...props}) => {
    const nav = useNavigate()
    function goBack() {
        nav(-1)
    }
    return (
        <div className='h-screen w-full flex flex-col justify-center items-center'>
            <div className="relative rounded-[3rem] w-fit h-fit overflow-clip">
                <img width={1000} height={500} src='404.webp' alt='404'/>
                <span className='absolute text-black bottom-5 left-1/2 -translate-x-1/2  text-4xl font-medium'>Oops! Not found.</span>
                
            <a className=' absolute bottom-0 right-10 text-xs font-extralight text-muted-foreground float-end' href="https://www.freepik.com/free-vector/404-error-with-landscape-concept-illustration_20602785.htm">Image by storyset on Freepik</a>
            </div>
            <div className="flex gap-6 mt-9">
                    <Button  className='flex justify-between px-7 py-7 w-[12rem] text-xl' onClick={goBack}>
                        Go back
                        <Undo2 className='w-7 h-7'/>
                    </Button>
                <Link to='/' draggable={false}>
                    <Button variant='default' className='flex justify-between px-7 py-7 w-[15rem] text-lg'>
                        Go to dashboard
                        <DashboardIcon className='w-8 h08'/>
                    </Button>
                </Link>
            </div>
        </div>
    );
};