import useModClient from "@/api/numbers/modClient";
import { FieldRow } from "@/components/forms/formComponents/FieldRow";
import { subObject } from "@/util/miscUtils";
import { parseServerEnums } from "@/util/queryUtils";
import { useQueryResultToasts } from "@/wrappers/toasts";
import { ComposedForm } from "@/wrappers/ui/form/ComposedForm";
import { ComposedFormRadio } from "@/wrappers/ui/form/ComposedFormRadio";
import { ComposedFormText } from "@/wrappers/ui/form/ComposedFormText";
import { ShowWhenFieldSibling } from "@/wrappers/ui/form/ShowWhenFieldSibling";
import { motion } from "framer-motion";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { CustomUseFormReturn, useRHF } from "../../../hooks/useRHF";
import { useYupValidationResolver } from "../../../hooks/useYupValidationResolver";
import { cn } from "../../../lib/utils";
import Yup from "../../../wrappers/yup";
import { Fn } from "../../misc/Code";
import { FormSection } from "../../ui/FormSection";
import { Button } from "../../ui/button";
import { Checkbox } from "../../ui/checkbox";
import { FormControl, FormField, FormItem, FormLabel } from "../../ui/form";
import { Label } from "../../ui/label";
import { RadioGroup, RadioGroupItem } from "../../ui/radio-group";
import { Separator } from "../../ui/separator";
import { DependentField } from "@/wrappers/ui/form/DependentField";
import { ComposedFormTextarea } from "@/wrappers/ui/form/ComposedFormTextarea";

export type OutcomesData = {
    outcomes: object[];
    otherGoals: string;
    otherGoalsOutcome: string;
    housingOutcome: object[];
    socialPurposeHousingPartner: string;
    supportiveHousingPartner: string;
};
const enumFieldNames = [
    "foundEmployment",
    "gotHousing",
    "accessedSocialServices",
    "usedforHealthCare",
    "increasedFinancialBenefits",
    "achievedSafeCommunication",
    "achievedCommunicationwithFriendsandFamily",
    "accessedLegalJusticeSystem",
    "maintainedCommunityConnections",
    "usedforOtherReasons",
    "financialResource",
    "housingOutcome",
];


export const Outcomes = ({ form, enumsQuery, clientData, sectionIndex }) => {
    const {
        data: {
            housingOutcome,
            foundEmployment,
            gotHousing,
            accessedSocialServices,
            usedforHealthCare,
            increasedFinancialBenefits,
            achievedSafeCommunication,
            achievedCommunicationwithFriendsandFamily,
            accessedLegalJusticeSystem,
            maintainedCommunityConnections,
            usedforOtherReasons,
            financialResource,
            clientGoals,
        } = {},
    } = enumsQuery;
    
    const { setValue, getValues,  reset, control } = form as CustomUseFormReturn;
    const otherGoals = form.watch("otherGoals");
    const clientGoalsFieldValue = form.watch("clientGoals");
    const clientHasOtherGoal = useMemo(() => {
        if (!enumsQuery.data || !clientGoalsFieldValue) return false;
        const otherGoalID =  clientGoals?.find((goal) => goal.Name === "Other" )?.id;
        return clientGoalsFieldValue.includes(otherGoalID);
    },[clientGoalsFieldValue, clientData, enumsQuery.data]);
    return (
        (
            <FormSection sectionIndex={sectionIndex} title={"Outcomes"}>
                {
                    // TODO: Derive usageEndedTimestamp from the other numbers with the same alias
                }
                {clientData?.usageEndedTimestamp && (
                    <div className="flex gap-2">
                        <span>Usage ended on</span>
                        <span>{clientData?.usageEndedTimestamp}</span>
                    </div>
                )}
                <ComposedFormRadio
                    options={foundEmployment}
                    control={control}
                    label={"Achieved employment"}
                    name={"foundEmployment"}
                />
                <FieldRow>
                    <ComposedFormRadio
                        options={gotHousing}
                        control={control}
                        label={"Achieved housing"}
                        name={"gotHousing"}
                    />
                    <ShowWhenFieldSibling
                        sibling="gotHousing"
                        is={gotHousing?.find((o) => o.Name === "Yes")?.id}
                        onConditionFail={() =>
                            setValue("housingOutcome", [])
                        }
                        direction="horizontal"
                    >
                            <DependentField>
                                <ComposedFormRadio
                                    options={housingOutcome}
                                    control={control}
                                    label="Housing outcome"
                                    name="housingOutcome"
                                />
                            </DependentField>
                    </ShowWhenFieldSibling>
                    <ShowWhenFieldSibling
                        sibling="housingOutcome"
                        is={ 
                            housingOutcome
                            ?.find((o) => o.Name === "Social Purpose")
                            ?.id
                        }
                        onConditionFail={() => setValue("socialPurposeHousingPartner", "")}
                        mode="popLayout"
                        direction="horizontal"
                    >
                        <DependentField>
                            <ComposedFormText
                                control={control}
                                name="socialPurposeHousingPartner"
                                label="Social purpose housing partner"
                                placeholder=""
                            />
                        </DependentField>
                    </ShowWhenFieldSibling>
                    <ShowWhenFieldSibling
                        sibling="housingOutcome"
                        is={
                            housingOutcome?.find(
                                (o) => o.Name === "Supportive"
                            )?.id
                        }
                        onConditionFail={() =>
                            setValue("socialPurposeHousingPartner", "")
                        }
                        mode="popLayout"
                        direction="horizontal"
                    >
                        <DependentField>
                            <ComposedFormText
                                control={control}
                                name="supportiveHousingPartner"
                                label="Supportive housing partner,"
                                placeholder=""
                            />
                        </DependentField>
                    </ShowWhenFieldSibling>
                </FieldRow>
                <ComposedFormRadio
                    options={accessedSocialServices}
                    control={control}
                    label={"Accessed social services"}
                    name={"accessedSocialServices"}
                />
                <ComposedFormRadio
                    options={usedforHealthCare}
                    control={control}
                    label={"Used for healthcare"}
                    name={"usedforHealthCare"}
                />
                <FieldRow>
                    <ComposedFormRadio
                        options={increasedFinancialBenefits}
                        control={control}
                        label={"Increased financial benefits"}
                        name={"increasedFinancialBenefits"}
                    />
                    <ShowWhenFieldSibling
                        sibling="increasedFinancialBenefits"
                        is={
                            increasedFinancialBenefits?.find(
                                (o) => o.Name === "Yes"
                            )?.id
                        }
                        onConditionFail={() =>
                            setValue("financialResource", [])
                        }
                        direction="horizontal"
                    >
                        <DependentField>
                            <ComposedFormRadio
                                options={financialResource}
                                control={control}
                                label={"Financial benefit"}
                                name={"financialResource"}
                            />
                        </DependentField>
                    </ShowWhenFieldSibling>
                </FieldRow>
                <ComposedFormRadio
                    options={achievedSafeCommunication}
                    control={control}
                    label={"Achieved safe communication"}
                    name={"achievedSafeCommunication"}
                />
                <ComposedFormRadio
                    options={achievedCommunicationwithFriendsandFamily}
                    control={control}
                    label={"Achieved communication with friends and family"}
                    name={"achievedCommunicationwithFriendsandFamily"}
                />
                <ComposedFormRadio
                    options={accessedLegalJusticeSystem}
                    control={control}
                    label={"Accessed legal justice system"}
                    name={"accessedLegalJusticeSystem"}
                />
                <ComposedFormRadio
                    options={maintainedCommunityConnections}
                    control={control}
                    label={"Maintained community connections"}
                    name={"maintainedCommunityConnections"}
                />
                {
                    
                }
                <div className="flex gap-2">
                    <ComposedFormRadio
                        options={usedforOtherReasons}
                        control={control}
                        label={"Other (custom) goal was met"}
                        name={"usedforOtherReasons"}
                    />
                    {clientHasOtherGoal && (
                        <div className="">
                            <DependentField>
                                <div className="min-h-full pt-2">
                                    <p className="text-muted-foreground italic pb-1">Description of client's 'other' goal:</p> 
                                    <p className=" px-3 py-1 bg-muted rounded-md  w-[16rem]">
                                        {otherGoals ? (
                                                // Client has 'other' goal but has not described it
                                                <>
                                                    {otherGoals}
                                                    {/* Eiusmod et qui incididunt officia cillum minim. Excepteur ullamco anim consequat veniam adipisicing ullamco dolor consequat incididunt incididunt.
                                                    Eiusmod et qui incididunt officia cillum minim. Excepteur ullamco anim consequat veniam adipisicing ullamco dolor consequat incididunt incididunt. */}
                                                </>
                                            ) : (
                                                // Client has 'other' goal and has described it
                                                <>
                                                    (No description given)
                                                </>
                                        )}
                                    </p>
                                </div>
                            </DependentField>
                        </div>
                    )}
                </div>
                <ShowWhenFieldSibling
                    sibling="usedforOtherReasons"
                    is={
                        usedforOtherReasons?.find(
                            (o) => o.Name === "Yes"
                        )?.id
                    }
                >
                    <div className="ml-6 pb-1">
                        <ComposedFormTextarea
                            className="mt-0"
                            control={control}
                            name="otherGoalsOutcome"
                            label=''
                            hideLabel
                            placeholder="Describe outcome of other goal"
                        />
                            
                    </div>
                </ShowWhenFieldSibling>

                <div className="pt-4">
                    <ComposedFormTextarea
                        control={control}
                        name="otherCVMUses"
                        label='Other ways CVM was used'
                        placeholder=""
                    />
                </div>

                {/* <ComposedFormCheckboxGroup
                    {...{
                        control,
                        name: 'housingOutcome',
                        label: 'Housing Outcome',
                        entries: housingOutcomeEntries
                    }}
                /> */}
                {/* <FieldGroup title='Outcomes' error={formState?.outcomes?.message as string}>
                </FieldGroup> */}
            </FormSection>
        )
    );
};

const GoalOutcomePair = ({
    goalLabel,
    goalName,
    outcomeName,
    outcomeLabel,
    control,
    outcomes,
}) => {
    return (
        <FormField
            control={control}
            name={goalName}
            render={({ field: goalField }) => (
                <motion.div
                    variants={containerVariants}
                    initial="initial"
                    animate={goalField.value ? "selected" : "initial"}
                    className={cn(
                        "flex flex-row min-h-fit justify-between border rounded-md p-4 overflow-clip"
                    )}
                >
                    <Label className="hover:cursor-pointer flex-1">
                        <FormItem className="flex items-start text-center gap-2">
                            <FormControl>
                                <Checkbox
                                    checked={goalField.value}
                                    onCheckedChange={goalField.onChange}
                                />
                            </FormControl>
                            <div className="text-lg flex flex-row gap-2 h-4 !mt-0 items-center [&_span]:leading-none">
                                <span className="text">Goal</span>
                                <Separator
                                    orientation="vertical"
                                    className="bg-black w-[1.5px]"
                                />
                                <span>{goalLabel}</span>
                            </div>
                            {/* <FormLabel className='hover:cursor-pointer'>Goal: {goalLabel}</FormLabel> */}
                        </FormItem>
                    </Label>
                    <Separator orientation="vertical" className="mx-4" />
                    <motion.div variants={outcomeVariants} className="flex-1">
                        {/* <Form */}
                        <FormField
                            control={control}
                            name={outcomeName}
                            render={({ field: outcomeField }) => (
                                <FormItem className="">
                                    {Fn(
                                        () =>
                                            !goalField.value &&
                                            outcomeField.value &&
                                            outcomeField.onChange(null),
                                        goalField.value
                                    )}
                                    <div className="text-lg flex gap-2 h-4 font-medium items-center">
                                        <span className="text">Outcome</span>
                                        <Separator
                                            orientation="vertical"
                                            className="bg-black w-[1.5px]"
                                        />
                                        <span>{outcomeLabel}</span>
                                    </div>
                                    <FormControl>
                                        <RadioGroup
                                            onValueChange={
                                                outcomeField.onChange
                                            }
                                            defaultValue={outcomeField.value}
                                            value={outcomeField.value}
                                            className="flex flex-col ml-1"
                                        >
                                            {outcomes?.map?.((option) => (
                                                <FormItem key={option.value}>
                                                    <FormLabel className="flex items-center space-x-2 space-y-0 hover:cursor-pointer">
                                                        <FormControl>
                                                            <RadioGroupItem
                                                                value={
                                                                    option.value
                                                                }
                                                            />
                                                        </FormControl>
                                                        <FormLabel className="w-fit text-sm font-medium hover:cursor-pointer">
                                                            {option.label}
                                                        </FormLabel>
                                                    </FormLabel>
                                                </FormItem>
                                            ))}
                                        </RadioGroup>
                                    </FormControl>
                                    {/* {description && <FormDescription>{description}</FormDescription>} */}
                                </FormItem>
                            )}
                        />
                    </motion.div>
                </motion.div>
            )}
        ></FormField>
    );
};

const containerVariants = {
    initial: {
        height: "3.2rem",
        borderColor: "hsla(214.3 31.8% 91.4% / 1)",
        // backgroundColor: '#ffffff'
    },
    selected: {
        height: "8rem",
        // borderColor: 'hsla(0.0 0.0 0.0 0.0 / 0)',
        borderColor: "hsla(0 50% 0% / 1)",
        // backgroundColor: '#000000'
    },
};

const outcomeVariants = {
    initial: {
        opacity: "0%",
        y: 10,
    },
    selected: {
        opacity: "100%",
        y: 0,
    },
};
