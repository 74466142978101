import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { Dialog, DialogBody, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Switch } from '@/components/ui/switch';
import { useRHF } from '@/hooks/useRHF';
import { useYupValidationResolver } from '@/hooks/useYupValidationResolver';
import { capitalizeFirst } from '@/util/textUtils';
import { ComposedForm } from '@/wrappers/ui/form/ComposedForm';
import { ComposedFormSelect } from '@/wrappers/ui/form/ComposedFormSelect';
import { ComposedFormText } from '@/wrappers/ui/form/ComposedFormText';
import { ComposedFormMultiSelect } from '@/wrappers/ui/form/ComposedMultiSelect';
import { ShowWhenFieldSibling } from '@/wrappers/ui/form/ShowWhenFieldSibling';
import { Select } from '@/wrappers/ui/Select';
import Yup from '@/wrappers/yup';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';

const validationSchema = Yup.object({
    isEdit: Yup.boolean().default(false),
    firstname: Yup.string().required('required').trim().default(''),
    lastname: Yup.string().required('required').trim().default(''),
    email: Yup.string().email('Invalid email').required('required').trim().default(''),
    usertype: Yup.string().required('required').default('agency').lowercase(),
    password:Yup.string().default('').when(['isEdit'],{
        is: false,
        then: () => Yup.string().min(6, 'Must be 6 characters or longer').equals([Yup.ref('confirmPassword')], 'Passwords must match').required('required') ,
        otherwise: () => Yup.string().notRequired()
    }),
    confirmPassword:Yup.string().default('').equals([Yup.ref('password')], 'Passwords must match').required('required').when(['isEdit'],{
        is: false,  
        then: () => Yup.string().equals([Yup.ref('password')], 'Passwords must match').required('required'),
        otherwise: () => Yup.string().notRequired()
    }),
    nodeids: Yup.array().default([]).when(['usertype'], {
        is: 'admin',
        then: (schema) => schema.notRequired(),
        otherwise: (schema) => schema.required('required').min(1)
    })  
})

export const EditBranchUserDialog = ({
    isEdit,
    creatingAgencyUser,
    setCreatingAgencyUser,
    editData, 
    loading,
    onSubmit,
    open,
    onOpenChange,
    branchesQuery,
    agenciesQuery,
    creatableUserTypes,
    ...props
}) => {
    const [editPassword, setEditPassword] = useState(false)

    useEffect(loadExistingData,[editData,isEdit, loading, branchesQuery.data])

    const resolver = useYupValidationResolver(validationSchema,{isEdit, creatingAgencyUser});
    const form = useRHF({ resolver, defaultValues: validationSchema.getDefault(), disabled: loading})
    
    function loadExistingData() {
        if (isEdit && editData && !loading && branchesQuery.data) {
            const {nodeids, firstName, lastName, adminLevel} = editData || {}
            const adminLevelNum = parseInt(adminLevel)
            const usertype = adminLevelNum > 5 
                ? 'Admin' 
                : adminLevelNum > 3 
                    ? 'Branch' 
                    : 'Agency'
            const newValues = validationSchema.cast({
                ...editData, 
                usertype,
                firstname: firstName,
                lastname: lastName,
            }, {stripUnknown: true, context: {isEdit},assert: false})
            form.reset(newValues)
            form.setValue('isEdit', isEdit)
        }
    }
    function handleOpenChange(val) {
        onOpenChange(val)
        if (!val)
            form.reset(validationSchema.getDefault(),{})
    }


    return (
            <Dialog {...{open, onOpenChange: handleOpenChange}} modal>
            <DialogContent className="min-w-fit flex flex-col">
                <DialogHeader>
                    <DialogTitle>{isEdit ? 'Edit User' : 'Create User'}</DialogTitle>
                    <DialogDescription>
                        Make changes to branchal admins here. Click {isEdit ? 'Save Changes' : 'Create User'} when you&apos;re done.
                    </DialogDescription>
                </DialogHeader>
                <ComposedForm {...{form,onSubmit,loading}}>
                    <DialogBody>

                        <ComposedFormSelect
                            control={form.control}
                            buttonClassName='w-full'
                            name='usertype'
                            label='What Kind of User?'
                            options={creatableUserTypes}
                            onChange={() => form.setValue('nodeids', [])}
                            IDFn={(opt) => opt} 
                            renderFn={(opt) => capitalizeFirst(opt)}/>
                        <div className='pt-6'>
                            <ShowWhenFieldSibling 
                                noAnimation
                                sibling='usertype'
                                is={'agency'}
                            >
                                <ComposedFormMultiSelect 
                                    buttonClassName='w-full'
                                    popupClassName='w-96'
                                    showSearch
                                    valueIsID
                                    control={form.control} 
                                    searchLabel='Search Agencies'                                
                                    name="nodeids" 
                                    label="Authorized Agencies" 
                                    placeholder='Select agencies'
                                    options={agenciesQuery?.data}
                                    renderFn={(agency) => `${agency.branchName} ${agency.Name}`}
                                    IDFn={(agency) => agency.id}
                                    selectedFn={(agency, sel) =>  sel.includes(agency.id)}
                                    />
                            </ShowWhenFieldSibling>
                            <ShowWhenFieldSibling 
                                noAnimation
                                sibling='usertype'
                                is={'branch'}
                            >
                                <ComposedFormMultiSelect 
                                    showSearch
                                    valueIsID
                                    control={form.control} 
                                    name="nodeids" 
                                    searchLabel='Search Branches'
                                    label="Authorized Branches" 
                                    placeholder='Select branches'
                                    options={branchesQuery?.data}
                                    renderFn={(branch) => branch.Name}
                                    IDFn={(branch) => branch.id}
                                    selectedFn={(branch, sel) => sel.includes(branch.id)}
                                    />
                            </ShowWhenFieldSibling>
                        </div>
                        <div className="flex gap-2">
                            <ComposedFormText control={form.control} name="firstname" label="First Name" placeholder='John'/>
                            <ComposedFormText control={form.control} name="lastname" label="Last Name" placeholder='Smith'/>
                        </div>
                        <ComposedFormText variant='medium' control={form.control} name="email" label="Email" placeholder='123@example.com'/>
                        {isEdit && (
                            <div className="flex items-center gap-2">
                                <Checkbox
                                    checked={editPassword}
                                    onCheckedChange={setEditPassword}
                                />
                                <span className='text-sm font-semibold'>Edit password?</span>
                            </div>
                        )}
                        <AnimatePresence {...props}>
                            { (!isEdit || editPassword) && (
                                <motion.div
                                    layout
                                    initial={ {height: isEdit ? 0 : 'auto'}}
                                    animate={{height: 'auto'}}
                                    exit={{height:0}}
                                    className='flex flex-col -my-2 overflow-y-clip'
                                >
                                    <ComposedFormText variant='medium' control={form.control} name="password" label="Password" placeholder=''/>
                                    <ComposedFormText variant='medium' control={form.control} name="confirmPassword" label="Confirm Password" placeholder=''/>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </DialogBody>
                <DialogFooter>
                    <DialogClose asChild>
                        <Button type="reset">Cancel</Button>
                    </DialogClose>
                    <Button variant={'default'} type="submit">{isEdit ? 'Save Changes' : 'Create User'}</Button>
                </DialogFooter>
                </ComposedForm>
            </DialogContent>
        </Dialog>
    );
};