import { postPromise } from "@/api/promises";
import { ROUTE_IDS } from "@/api/routeIDs";
import { convertObjFieldsToString } from "@/util/apiUtils";
import { MILLISEC } from "@/util/constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const createUser = (params) => 
    postPromise(import.meta.env.VITE_API_BASE_URL, convertObjFieldsToString({...params, type:ROUTE_IDS.CREATE_USER}), {json:true})

export default function useCreateUser(props) {
    const {onSuccess, ...otherProps } = props || {}
    const client = useQueryClient();
    const mutation = useMutation({
        mutationKey: [ROUTE_IDS.CREATE_USER],
        mutationFn: createUser,
        staleTime: MILLISEC.MIN_15,
        onSuccess: (data, req) => {
            if (data?.status?.includes('already')) {
                throw new Error('User already exists.');
            }
            client.invalidateQueries({queryKey: ['user', 'branch']}).then(() => {
                onSuccess && onSuccess(data, req)
            })
        },
        ...otherProps
    });
    return mutation;
}