import { Button } from '@/components/ui/button';
import React from 'react';

export const ToggleButton = ({toggled, onToggle, ...props}) => {
    return (
        <Button 
            variant={'outline'}
            className={toggled ? 'border-highlight-blue text-highlight-blue hover:text-highlight-blue' : ''}
            onClick={onToggle} 
            {...props}
        >
            {props.children}
        </Button>
    );
};
