import { MiniCard } from '@/components/cards/MiniCard';
import { Checkbox } from '@/components/ui/checkbox';
import { Input } from '@/components/ui/input';
import { Separator } from '@/components/ui/separator';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { SubcontrolContainer } from '@/routes/Main/Reporting/components/controls/subcontrols/SubcontrolContainer';
import { SubcontrolTitle } from '@/routes/Main/Reporting/components/controls/subcontrols/SubcontrolTitle';
import { VanishingControls } from '@/routes/Main/Reporting/components/controls/subcontrols/VanishingControls';


export const AttachedLegendControls = ({showAttachedLegend, attachedLegendFontSize, attachedLegendPos, handleChange, ...props}) => {
    return (
        <MiniCard className={'flex-row shadow-none h-16 w-1/2'} active={showAttachedLegend}>
            <div className="items-center flex space-x-2">
                <Checkbox
                    defaultChecked={showAttachedLegend}
                    value={showAttachedLegend}
                    onCheckedChange={(v) => handleChange('showAttachedLegend',   v)}
                    className={'w-4 h-4 stroke-[2]  data-[state=checked]:bg-candy data-[state=checked]:text-candy-foreground'}
                />
                <span className='text-lg font-medium'>Attached Legend</span>
            </div>
            <VanishingControls disabled={!showAttachedLegend}>

                <Separator orientation='vertical'/>
                <SubcontrolContainer>
                    <SubcontrolTitle>Font</SubcontrolTitle>
                    <Input
                        className='w-10 text-center pr-1 pl-1 py-1 h-8 text-xs'
                        type='number'
                        min={0}
                        max={90}
                        disabled={!showAttachedLegend}
                        value={attachedLegendFontSize}
                        onChange={(e) => handleChange('attachedLegendFontSize',   e.target.value)}/>
                </SubcontrolContainer>
                <Separator orientation='vertical'/>
                <SubcontrolContainer>
                    <SubcontrolTitle>Position</SubcontrolTitle>
                    <Tabs
                        value={attachedLegendPos}
                        onValueChange={(v) => handleChange('attachedLegendPos',  v)}>
                        <TabsList  className=' h-8' onChange={(e) => console.log(e)}>
                            <TabsTrigger disabled={!showAttachedLegend} className='py-0 h-5 bg-none data-[state=active]:bg-none' value='top'>Top</TabsTrigger>
                            <TabsTrigger disabled={!showAttachedLegend} className='py-0 h-5 bg-none data-[state=active]:bg-none' value='end'>Outside</TabsTrigger>
                        </TabsList>
                    </Tabs>
                </SubcontrolContainer>
            </VanishingControls>
        </MiniCard>
    );
};

