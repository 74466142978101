import { Table } from "@/components/tables/Table";
import { goalsAndOutcomesCols } from "@/components/tables/columnHelpers/goalsAndOutcomesCols";
import { SimpleCard } from "@/components/ui/SimpleCard";
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "@/components/ui/accordion";
import { cn } from "@/lib/utils";
import { DemographicsReportAgency } from "@/routes/Main/Reporting/Presets/DemographicsReport/useDemographicsReportData";
import { FinancialResourcesOutcomeDoughnut } from "@/routes/Main/Reporting/Presets/GoalsAndOutcomesReport/charts/FinancialResourcesOutcomeDoughnut";
import { GAOChart } from "@/routes/Main/Reporting/Presets/GoalsAndOutcomesReport/charts/GAOChart";
import { HousingOutcomeDoughnut } from "@/routes/Main/Reporting/Presets/GoalsAndOutcomesReport/charts/HousingOutcomeDoughnut";
import { GaoStats } from "@/routes/Main/Reporting/Presets/GoalsAndOutcomesReport/components/GaoStats";
import { UsageReportConfig } from "@/routes/Main/Reporting/Presets/UsageReport";
import { CancelExportButton } from "@/routes/Main/Reporting/components/CancelExportButton";
import { ExportButton } from "@/routes/Main/Reporting/components/ExportButton";
import { A4_CONTENT_WIDTH, A4_MARGIN, pdfItemPadding } from "@/util/constants";
import {
    addDefaultPDFTitlePage,
    addTableToPDF,
    contrainDimensionsToA4,
    elementToCanvasAndURL,
    getNewPDFWithDefaultConf,
} from "@/util/fileUtils";
import { JCheckbox } from "@/wrappers/ui/JCheckbox";
import { toCanvas } from "html-to-image";
import jsPDF from "jspdf";
import { Info } from "lucide-react";
import { useEffect, useRef, useState } from "react";

type AgencyGAOReportContentProps = {
    isPending?: boolean;
    config: UsageReportConfig;
    agency: DemographicsReportAgency;
    isExporting?: boolean;
    onExportFinish?: () => void;
};

export const AgencyGAOReportContent = (props: AgencyGAOReportContentProps) => {
    const [hideUnknown, setHideUnknown] = useState(false);

    const {
        agency: data,
        isExporting: controlledIsExporting,
        onExportFinish,
        isPending,
        config,
    } = props || {};
    const [isExporting, setIsExporting] = useState(false);
    const pdfRef = useRef(null);

    const withGoalsTableRef = useRef(null);
    const statsRef = useRef(null);
    const outcomesChartRef = useRef(null);
    const financialOutcomesDoughnutRef = useRef(null);
    const housingOutcomeChartRef = useRef(null);

    useEffect(() => {
        setIsExporting(controlledIsExporting);
    }, [controlledIsExporting]);


    useEffect(() => {
        isExporting && exportReport();
    }, [isExporting]);
    async function exportReport() {
        const pdf = getNewPDFWithDefaultConf();
        pdfRef.current = pdf;
        addDefaultPDFTitlePage(pdf, "Goals & Outcomes", `${data.Name}`);
        // Get Images
        const start = performance.now();
        const promises = [];
        promises.push(elementToCanvasAndURL(statsRef.current));
        promises.push(elementToCanvasAndURL(outcomesChartRef.current));
        promises.push(
            elementToCanvasAndURL(financialOutcomesDoughnutRef.current)
        );
        promises.push(elementToCanvasAndURL(housingOutcomeChartRef.current));
        let [[sCanv, sURL], [oCanv, oURL], [fCanv, fURL], [hCanv, hURL]] =
            await Promise.all(promises);
        // Outcomes
        const { width: oW, height: oH } =
            outcomesChartRef.current.getBoundingClientRect();
        const [oFW, oFH] = contrainDimensionsToA4(
            oW,
            oH,
            A4_CONTENT_WIDTH,
            400
        );
        pdf.addImage(oURL, "png", 20, 220, oFW, oFH,'','FAST',)
        const yAfterOutcomes = 220 + oFH + pdfItemPadding;
        // Stats
        const { width: sW, height: sH } =
            statsRef.current.getBoundingClientRect();
        const [sFW, sFH] = contrainDimensionsToA4(
            sW,
            sH,
            A4_CONTENT_WIDTH,
            undefined
        );

        pdf.addImage(
            sURL,
            "png",
            20,
            yAfterOutcomes + pdfItemPadding,
            sFW,
            sFH,
            '',
            'FAST'
        );
        pdf.addPage();
        // Financial Outcomes
        const { width: fW, height: fH } =
            financialOutcomesDoughnutRef.current.getBoundingClientRect();
        const [fFW, fFH] = contrainDimensionsToA4(
            fW,
            fH,
            A4_CONTENT_WIDTH,
            540
        );
        pdf.addImage(fURL, "png", 60, A4_MARGIN, fFW, fFH,'','FAST');
        const yAfterFinancialOutcomes = A4_MARGIN + fFH + pdfItemPadding;
        // Housing Outcomes
        const { width: hW, height: hH } =
            housingOutcomeChartRef.current.getBoundingClientRect();
        const [hFW, hFH] = contrainDimensionsToA4(
            hW,
            hH,
            A4_CONTENT_WIDTH,
            540,
        );
        pdf.addImage(hURL, "png", 60, yAfterFinancialOutcomes, hFW, hFH,'','FAST');

        // Images Cleanup
        sCanv.remove();
        sCanv = null;
        oCanv.remove();
        oCanv = null;
        fCanv.remove();
        fCanv = null;
        hCanv.remove();
        hCanv = null;

        // Tables
        const tableTime = performance.now();
        await addTableToPDF(
            pdf,
            withGoalsTableRef.current,
            "Clients With Goals"
        );

        pdf.save(`Goals-and-outcomes-report-${data.Name}.pdf`);
        pdfRef.current = null;
        setIsExporting(false);
        onExportFinish();
    }
    return (
        <div className="flex flex-col w-full gap-2">
            <div className="flex items-center gap-2">
                <ExportButton {...{isExporting, setIsExporting,isPending}}>Export report</ExportButton>
                <CancelExportButton isExporting={isExporting} onClick={() => setIsExporting(false)}/>
                <JCheckbox
                    value={hideUnknown}
                    handleChange={setHideUnknown}
                    className="skelechild bg-background w-fit py-[0.6rem]"
                >
                    Hide Unknown
                </JCheckbox>
            </div>
            <div
                className={cn(
                    "flex flex-col mb-6 gap-4",
                    isPending && "skeletonify"
                )}
            >
                <SimpleCard
                    ref={statsRef}
                    className="flex-1 skelechild min-h-fit flex flex-col"
                >
                    <GaoStats data={data} />
                    <div className="flex mt-6 items-center gap-4">
                        <Info className="text-muted-foreground w-8 h-8" />
                        <div className="flex flex-col gap-2">
                            <span className="text-muted-foreground">
                                Statistics for goals only draw from client
                                profiles with at least one goal set
                            </span>
                            <span className="text-muted-foreground">
                                Statistics for outcomes only draw from client
                                profiles with at least one outcome set unless
                                otherwise stated
                            </span>
                        </div>
                    </div>
                </SimpleCard>
                <SimpleCard ref={outcomesChartRef} className="skelechild">
                    <GAOChart {...props} data={data} hideUnknown={hideUnknown} />
                </SimpleCard>
                <div className="flex gap-4">
                    <SimpleCard
                        ref={financialOutcomesDoughnutRef}
                        className="skelechild flex-1"
                    >
                        <FinancialResourcesOutcomeDoughnut
                            {...props}
                            data={data}
                            hideUnknown={hideUnknown}
                        />
                    </SimpleCard>
                    <SimpleCard
                        ref={housingOutcomeChartRef}
                        className="skelechild flex-1"
                    >
                        <HousingOutcomeDoughnut
                            {...props}
                            data={data}
                            hideUnknown={hideUnknown}
                        />
                    </SimpleCard>
                </div>

                <SimpleCard className="skelechild">
                    <Accordion
                        type="single"
                        collapsible
                        value={isExporting ? "0" : undefined}
                    >
                        <AccordionItem
                            title="Active Clients"
                            value="0"
                            className="border-b-0"
                        >
                            <AccordionTrigger className="font-medium text-md py-0 leading-none">
                                With goals or outcomes filled in
                            </AccordionTrigger>
                            <AccordionContent className="pt-2">
                                <Table
                                    showSearchBar={!isExporting}
                                    controlledPDF
                                    pdfRef={pdfRef}
                                    tableFnRef={withGoalsTableRef}
                                    showColumnVisibility={false}
                                    showExportButton={false}
                                    showFilters={false}
                                    columnHelper={goalsAndOutcomesCols}
                                    data={data.withGoalsOrOutcomes}
                                />
                            </AccordionContent>
                        </AccordionItem>
                    </Accordion>
                </SimpleCard>
            </div>
        </div>
    );
};
