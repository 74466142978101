import { cn } from "@/lib/utils";
import { getDateString, getNiceDate } from "@/util/textUtils";
import { useMemo } from "react";

type DateCellProps = {
    className?: string,
    value: string | number | null,
    concise?: boolean,
    nullPlaceholder?: string,
    mode?: 'date' | 'days'
}

const today = new Date()

export const DateCell = 
({
    className='',
    value,
    concise=false,
    nullPlaceholder='-',
    mode='date'
} : DateCellProps ) => {
    const date = useMemo(() => {
        return typeof value === 'string' ? new Date(value).getTime() : value
    },[value])
    const daysAgo = useMemo(() => {
        return Math.floor((today.getTime() - date) / (1000 * 60 * 60 * 24))
    },[today, value])
    
    if (!value ) return (
        <span className={cn("",className)}>{nullPlaceholder}</span>
    )
        
    
    if (mode === 'date') return (
        <span className={cn("",className)}>
            {concise ? getDateString(date) : getNiceDate(date)}
        </span>
    )
        
    if (mode === 'days') return (
        <span className={cn("",className)}>
            {daysAgo} days ago
        </span>
    )
    
}