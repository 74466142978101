import useAllBranches from '@/api/branches/getAllBranches';
import { useNumbersInBranches } from '@/api/numbers/getNumbersInBranch';
import { Table } from '@/components/tables/Table';
import { phoneNumbersColumnHelper } from '@/components/tables/columnHelpers/phoneNumbersColumnHelper';

export const ForBranches = ({meQuery, ...props}) => {
    const branchesQuery = useAllBranches()
    const numbersQuery = useNumbersInBranches(meQuery.data?.branches || [], meQuery.data?.branches?.map?.(b => b.Name) || [])
    return (
        <Table
            exportFileName="phone_numbers"
            pdfName={'Phone Numbers'}
            data={numbersQuery.data}
            showLoading={numbersQuery.isPending}
            columnHelper={phoneNumbersColumnHelper}/>
    );
};
