const addArrayToFormData = (fieldName, array, formData) => {
    for (let i = 0; i < array.length; i++) {
        formData.append(fieldName, String(array[i]))
    }
    return formData
}

const jsonToFormData = (json) => {
  const formData = new FormData();
  Object.keys(json).forEach((key) => {
    if (json[key] instanceof Array) 
      addArrayToFormData(key, json[key], formData) 
    else
      formData.append(key, String(json[key]))
  });
  return formData;
};

export function addRouteIDToFormData(formData, routeID) {
  let dataWithId = formData;
  dataWithId?.append('type', routeID);
  return dataWithId;
}

export function composeFormData(json, routeID) {
  return addRouteIDToFormData(jsonToFormData(json), routeID);
}
  

export const convertObjFieldsToString = (obj) => {
    Object.keys(obj).forEach(k => {
      if (typeof obj[k] === 'object') {
        return obj[k]?.toString?.();
      }
      obj[k] = '' + obj[k];
    });
    
    return obj;
}

// export function safeEnsureQueryData(queryClient, )