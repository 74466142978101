import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';

export const AgencyCell = ({name, id, branchid, ...props}) => {
    return (
        <Link to={`/agency/${branchid}/${id}`}>
            <Button variant={'link'} size={'xs'} className='whitespace-nowrap pl-0'>
                {name}
            </Button>
        </Link>
    );
};
