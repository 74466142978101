import React from 'react';

export const PageHeader = ({title, ...props}) => {
    return (
        <div className='flex justify-between pb-5'>
            <span className='text-xl font-semibold'>{title}</span>
            {props.children}
        </div>
    );
};
