import { ControlGroup } from '@/routes/Main/Reporting/components/controls/components/ControlGroup';
import { AttachedDataControls } from '@/routes/Main/Reporting/components/controls/subcontrols/AttachedDataControls';
import { AttachedLegendControls } from '@/routes/Main/Reporting/components/controls/subcontrols/AttachedLegendControls';
import { ColorsControls } from '@/routes/Main/Reporting/components/controls/subcontrols/ColorsControls';
import { LegendControls } from '@/routes/Main/Reporting/components/controls/subcontrols/LegendControls';
import { TitleControls } from '@/routes/Main/Reporting/components/controls/subcontrols/TitleControls';
import React from 'react';


export const PieControls = ({datasetEnum, state, handleChange: onChange, ...props}) => {
    function handleChange(key, value) {
        console.log('onchange', key, value);
        onChange('config', {...state.config, [key]: value})
    }
    return (
        <ControlGroup title={'Config'}>
            <TitleControls {...state.config} handleChange={handleChange}/>
            <LegendControls {...state.config} handleChange={handleChange}/>
            <div className="flex w-full space-x-2">
                <AttachedLegendControls {...state.config} handleChange={handleChange}/>
                <AttachedDataControls {...state.config} handleChange={handleChange}/>
            </div>
            <ColorsControls {...{...state.config}} config={state.config} datasetEnum={datasetEnum} handleChange={handleChange}/>
        </ControlGroup>
    );
};

