import { useToast } from "@/components/ui/use-toast"

export const useSuccessToast = () => {
    const { successToast } = useToast();
    return (successMessage) => successToast(
        successMessage,
        {
            title: 'Success',
            status: 'success',
        }
    );
};

export const useWarningToast = () => {
    const { warningToast } = useToast();

    return (warningMessage) => warningToast(
        warningMessage,
        {
            title: 'Warning',
            status: 'warning',
        }
    );
};

export const useErrorToast = () => {
    const { errorToast } = useToast();

    return (errorMessage) => errorToast(
        errorMessage,
        {
            title: 'Error',
            status: 'error',
        }
    );
};

export const useQueryResultToasts = () => {
    const {successToast, errorToast} = useToast()
    return (successMessage, errorMessage, onSuccess, onError) => ({ 
        onSuccess: () => {
            successToast(successMessage)
            onSuccess && onSuccess()
        },
        onError: () => {
            errorToast(errorMessage)
            onError && onError()
        },
    })
}

