import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { cn } from '@/lib/utils';


type ReportConfigStepProps = React.HTMLAttributes<HTMLDivElement> & {
    index: number;
    stepTitle: string;
    activeStep: number;
    error?: {
        message: string;
    }
    children?: React.ReactNode;
}
export const ReportConfigStep = ({index, stepTitle, activeStep, children, error, ...props}: ReportConfigStepProps) => {
    const isActive = activeStep >= index;
    return (
        <AnimatePresence>
            { isActive && (
                <motion.div
                    initial={{ opacity: 0}}            
                    animate={{ opacity: 1}}
                    className='flex py-5 px-2 items-start gap-8 w-full'
                >
                    <div className={cn('flex items-center font-medium justify-center w-10 h-10 leading-none border-[2px] text-2xl  rounded-full ', isActive && 'border-secondary-foreground', error && 'border-error text-error')}>
                        {index}
                    </div>
                    <div className="flex flex-col mt-2 w-full">
                        <p>
                            <span className='text-lg'>{stepTitle}</span>
                            {error && <span className=' ml-2 text-sm text-destructive'>{error.message}</span>}
                        </p>
                        <div className="mt-5">
                            {children}
                        </div>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};
