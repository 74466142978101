import { Table } from "@/components/tables/Table";
import { BranchDemographicRatingsCols } from "@/components/tables/columnHelpers/branchDemographicRatingsCols";

export const DemographicAgencyRankings = ({
    tableRef,
    pdfRef,
    agencies,
    className = "",
    children = undefined,
}) => {
    return (
        <div className={className}>
            {children}
            <Table
                controlledPDF
                tableFnRef={tableRef}
                pdfRef={pdfRef}
                className="h-full"
                data={agencies}
                columnHelper={BranchDemographicRatingsCols}
                showColumnVisibility={false}
                showExportButton={false}
                showFilters={false}
                headerClassName="whitespace-nowrap text-sm "
                rowClassName="py-1"
                maxHeightPixels={328}
            />
        </div>
    );
};
