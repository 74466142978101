import useNumbersInAgency from "@/api/numbers/getNumbersInAgency";
import { selectUnassignedNumbers } from "@/api/selectors/selectUnassignedNumbers";
import { Button } from "@/components/ui/button";
import { Popover, PopoverContent, PopoverDescription, PopoverHeader, PopoverTitle, PopoverTrigger } from "@/components/ui/popover";
import { Select } from "@/wrappers/ui/Select";
import { useEffect, useState } from "react";

export const AddClientProfilePopover = ({agenciesQuery, onSubmit, children}) => {
    const [agency, setAgency] = useState(null)
    const [selectedNum, setSelectedNum] = useState(null)

    const numbersQuery = useNumbersInAgency(agency?.id, {
        select: selectUnassignedNumbers
    })

    useEffect(filterNumbers, [numbersQuery.data, agenciesQuery.data, agency])

    function filterNumbers() {
        if (numbersQuery.isPending || agenciesQuery.isPending || !agency) return
        if (numbersQuery.isError || agenciesQuery.isError) return
    }
    return (
        <Popover >
            <PopoverTrigger asChild>
                {children}
            </PopoverTrigger>
            <PopoverContent className="w-80 flex flex-col gap-3" side="bottom" align="start">
                <PopoverHeader>
                    <PopoverTitle>Add Client Profile</PopoverTitle>
                    <PopoverDescription>Begin by selecting an agency and an unassigned number</PopoverDescription>
                </PopoverHeader>
                <div className="mt-4 flex flex-col gap-1">
                    <span className="text-sm font-semibold leading-tight">Add to which agency?</span>
                    <Select
                        showSearch
                        buttonClassName="w-[18rem]"
                        popupClassName="w-[18rem]"
                        options={agenciesQuery.data}
                        handleChange={setAgency}
                        value={agency}
                        placeholder="Select an agency"
                        renderFn={agency => `${agency.branchName} ${agency?.Name}`}
                        inForm={false}
                    />              
                </div>
                <div className="mt-4 flex flex-col gap-1">
                    <span className="text-sm font-semibold leading-tight">Add to which number?</span>
                    <Select 
                        value={selectedNum}
                        handleChange={setSelectedNum}
                        disabled={!agency || !numbersQuery.data}
                        placeholder="Select an unlinked number"
                        renderFn={num => num.alias}
                        showSearch={false}
                        inForm={false}
                        emptyMessage={`No unassigned numbers for ${agency?.Name}`}
                        options={numbersQuery.data}
                        buttonClassName="w-[18rem]"
                        popupClassName="w-[18rem]"
                    />
                </div>
                <Button 
                    onClick={() => onSubmit({agency, number: selectedNum})}
                    variant="default" className="gap-4 ">
                    Begin client profile 
                </Button>
            </PopoverContent>
        </Popover>
    )
}