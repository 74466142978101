import { getChartColors, getManualPieConfig } from '@/routes/Main/Reporting/utils/ChartConfigs';
import React, { useMemo } from 'react';
import { Doughnut, Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart } from 'chart.js';
import CountUp from 'react-countup';
import { customDataLabels, doughnutOptions } from '@/routes/Main/Reporting/utils/doughnutOptions';
import { GAOReportData } from '@/routes/Main/Reporting/Presets/GoalsAndOutcomesReport/useGAOReportData';
import { LegendEntry } from '@/components/charting/LegendEntry';
import { PieChartSkeleton } from '@/components/skeletons/PieChartSkeleton';

type HousingOutcomeDoughnutProps = {
    data: GAOReportData,
    hideUnknown?: boolean,
};
export const HousingOutcomeDoughnut = React.forwardRef<Chart<"doughnut", any, unknown>, HousingOutcomeDoughnutProps>(({ data, hideUnknown, ...props }, ref) => {
    const { housingOutcome, clientGoals: {['Housing'] : benefitGoal} = {} } = data || {};
    const {hasGoal, Yes, No, Unknown} = benefitGoal || {};
    const [config, l, c] = useMemo(() => {
        if (!data) return null;
        const v = Object.values(housingOutcome || {});
        const l = Object.keys(housingOutcome || {});
        const c = getChartColors(l.length)
        console.log(v,c,l);
        return [getManualPieConfig(v,c,l), l, c]
    }, [data]);
    const options = useMemo(() => {
        return doughnutOptions({
            animations: !props?.isExporting,
            showTitle: false,
            showLegend: false,
            alignTitle: 'center',
            legendPos: 'bottom',
            backgroundColor: 'transparent',
            legendDotSize: '20',
            legendFontSize: '16',
            displayTooltip: false,
            showAttachedLegend: false,
            showAttachedLabel: false,
            displayTailedAttachedLabel: true,
            tailedAttachedLabelFontSize: 16,
            tailedAttachedLabelStrokeWidth: 2,
            tailedAttachedLabelYOffset: 25,
            tailedAttachedLabelXOffset: 15,
            borderRadius: 0,
            spacing: 0,
            cutout: '80%',
            hoverOffset: 0,
            layout: { padding: {
                left: 15,
                right: 10
            } },
        } as any);
    }, []);
    console.log(housingOutcome);
    return config ? (
        <div className='w-full h-full'>
            <span className='font-semibold text-xl'>Housing Secured</span>
            <div className='h-[20rem] relative -mb-2'>
                <Doughnut
                    ref={ref}
                    options={options as any}
                    plugins={[ChartDataLabels, customDataLabels]}
                    {...config}
                />
                <div className="absolute flex flex-col items-center left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                    <CountUp className='font-semibold' start={0} end={Yes || 0} separator=','/>
                    <span className='font-light mt-1'>
                        Clients secured housing
                    </span>
                </div>
            </div>
            <div className='flex flex-col gap-1 justify-center'>
                {l.map((label, idx) => (
                    <LegendEntry key={label} color={c[idx]} label={label} />
                ))}
            </div>
        </div>
    ) : <PieChartSkeleton />;
});

HousingOutcomeDoughnut.displayName = 'UsageUserCountPie';