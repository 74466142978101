import useAllBranches from "@/api/branches/getAllBranches";
import { getBranchesQueryOptions } from "@/api/branches/getBranch";
import useModBranch from "@/api/branches/modBranch";
import useMyUserData, {
    getMyUserDataQueryOptions,
} from "@/api/user/getMyUserData";
import { PageHeader } from "@/components/misc/PageHeader";
import { Table } from "@/components/tables/Table";
import { branchesColumnHelper } from "@/components/tables/columnHelpers/branchesColumnHelper";
import { useMyAuthorizedBranches } from "@/hooks/state/useMyAuthorizedBranches";
import { useQueryResultToasts } from "@/wrappers/toasts";

export const Branches = ({ ...props }) => {
    const myUserDataQuery = useMyUserData();
    const allBranchesQuery = useAllBranches()
    const branchesQuery = useMyAuthorizedBranches({
        myUserDataQuery,
        allBranchesQuery
    });
    const queryToasts = useQueryResultToasts();
    const modBranchMutation = useModBranch(queryToasts(
        "Updated branch.",
        "Failed to update branch."
    ));
    function setHostAgency(row, agency) {
        modBranchMutation.mutate({
            branchid: row.id,
            hostAgency: agency.id,
        })
    }
    return (
        <div>
            <PageHeader title="Branches"></PageHeader>
            <Table
                retrieveSearchParamFilters
                exportFileName="branches"
                columnHelper={branchesColumnHelper}
                data={branchesQuery.data}
                meta={{
                    setHostAgency
                }}
            />
        </div>
    );
};

export const BranchesLoader = (queryClient) => async () => {
    const myData = await queryClient.ensureQueryData({
        queryKey: getMyUserDataQueryOptions.queryKey,
    });
    const branchPromises = myData.nodeids.map((branchID) =>
        queryClient.ensureQueryData({ ...getBranchesQueryOptions(branchID) })
    );
    const branchData = await Promise.all(branchPromises);
    return { branchData };
};
