import { cn } from '@/lib/utils';
import React from 'react';


export const MiniCard = ({className, active, ...props}) => {
    return (
        <div className={cn(
            'flex items-center gap-x-3 rounded-md border shadow-md py-2 px-4 ',
            active && 'border-candy',
            className)} {...props}>
        </div>
    );
};
