export const PinCheckingRow = ({
  row,
  className,
  children,
  ...props
}) => 
  row.getIsPinned() === 'top' ? (
      <tr className={`hover:bg-muted/50 sticky ${className} `}>
        {children}
      </tr>
    ) : (
      <tr {...{className:`hover:bg-muted/50 ${className}`, ...props}}>
        {children}
      </tr>
    )