import { MiniCard } from "@/components/cards/MiniCard";
import { Button } from "@/components/ui/button";
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";
import { BarControls } from "@/routes/Main/Reporting/components/controls/BarControls";
import { ChartFilters } from "@/routes/Main/Reporting/components/controls/ChartFilters";
import { PieControls } from "@/routes/Main/Reporting/components/controls/PieControls";
import { getChartColors } from "@/routes/Main/Reporting/utils/ChartConfigs";
import React from "react";

export const ChartCard = ({
    children,
    chartType = "pie",
    handleDownloadClicked,
    renderFn,
    chartTitleAlignment: initAlign = "center",
    chartName: initName = "Title of Chart goes here",
    chartDesc: initDesc = "Description of chart goes here",
    titleFontSize: initTitleFontSize = "25",
    legendPos: initLegendPos = "right",
    legendFontSize = 20,
    legendDotSize = 15,
    backgroundColor = "transparent",
    titleColor: initTitleColor = "black",
    legendColor: initLegendColor = "black",
    showAttachedLegend: initShowAttachedLegend = false,
    attachedLegendPos: initAttachedLegendPos = "end",
    attachedLegendFontSize: initAttachedLegendFontSize = 15,
    showAttachedLabel: initShowAttachedLabel = true,
    attachedLabelFontSize: initAttachedLabelFontSize = 12,
    datasetEnum,
    datasetColors: initColors = getChartColors(
        Object.keys(datasetEnum)?.length || 0
    ),
    ...props
}) => {
    const [state, setState] = React.useState({
        name: initName,
        desc: initDesc,
        customConfig: true,
        customFilter: false,
        config: {
            alignTitle: initAlign,
            showTitle: false,
            title: "",
            showLegend: true,
            titleFontSize: initTitleFontSize,
            legendPos: initLegendPos,
            legendFontSize,
            legendDotSize,
            backgroundColor,
            titlePos: "top",
            datasetColors: initColors,
            titleColor: initTitleColor,
            legendColor: initLegendColor,
            showAttachedLegend: initShowAttachedLegend,
            attachedLegendPos: initAttachedLegendPos,
            attachedLegendFontSize: initAttachedLegendFontSize,
            showAttachedLabel: initShowAttachedLabel,
            attachedLabelFontSize: initAttachedLabelFontSize,
        },
        filters: {},
    });
    function handleChange(key, v) {
        setState((prev) => ({ ...prev, [key]: v }));
    }
    return (
        <Card className="shadow-md">
            <CardHeader className="flex flex-row items-start justify-between">
                <div className="flex flex-col w-fit">
                    <CardTitle className="relative">
                        <input
                            value={state.nameame}
                            onChange={(e) =>
                                handleChange("name", e.target.value)
                            }
                        />
                        {/* {state.Name} */}
                    </CardTitle>
                    <CardDescription>{state.desc}</CardDescription>
                </div>
                <div className="flex gap-2 !mt-0">
                    <ChartCheckboxes
                        state={state}
                        handleChange={handleChange}
                    />
                    <Button
                        variant={"default"}
                        size={"default"}
                        className="w-fit gap-4 pr-2"
                        onClick={handleDownloadClicked}
                    >
                        Download Chart
                        <DownloadIcon className="w-9 h-9" />
                    </Button>
                </div>
            </CardHeader>
            <CardContent className="w-full">
                <div className="flex flex-col justify-between items-stretch">
                    {
                        {
                            pie: state.customConfig && (
                                <PieControls
                                    {...{ datasetEnum, handleChange, state }}
                                />
                            ),
                            bar: state.customFilter && (
                                <BarControls
                                    {...{ datasetEnum, handleChange, state }}
                                />
                            ),
                        }[chartType]
                    }
                    <ChartFilters
                        datasetEnum={datasetEnum}
                        state={state}
                        handleChange={handleChange}
                    />
                </div>
                {renderFn(state.config)}
            </CardContent>
        </Card>
    );
};

const ChartCheckboxes = ({ state, handleChange }) => (
    <>
        <MiniCard active={state.customConfig}>
            <Checkbox
                className="w-5 h-5 stroke-[2]  data-[state=checked]:bg-candy data-[state=checked]:text-candy-foreground"
                checked={state.customConfig}
                onCheckedChange={(e) => handleChange("customConfig", e)}
            />
            <span className="text-md">Configure Individually</span>
        </MiniCard>
        <MiniCard active={state.customFilter}>
            <Checkbox
                className="w-5 h-5 stroke-[2]  data-[state=checked]:bg-candy data-[state=checked]:text-candy-foreground"
                checked={state.customFilter}
                onCheckedChange={(e) => handleChange("customFilter", e)}
            />
            <span className="text-md">Filter Individually</span>
        </MiniCard>
    </>
);

{
    /* <div className="flex flex-col gap-2 mb-4">
    </div> */
}

const DownloadIcon = (props) => (
    <svg
        {...props}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill="currentColor"
            d="m12 15.577l-3.539-3.538l.708-.72L11.5 13.65V5h1v8.65l2.33-2.33l.709.719zM6.616 19q-.691 0-1.153-.462T5 17.384v-2.423h1v2.423q0 .231.192.424t.423.192h10.77q.23 0 .423-.192t.192-.424v-2.423h1v2.423q0 .691-.462 1.153T17.384 19z"
        />
    </svg>
);
