import { ChevronsDown } from 'lucide-react';
import React from 'react';


export const ReportLoader = ({isPending, ...props}) => {
    return (
        <div className="flex w-full justify-center">
                <div className="h-10 mb-6">
                    {isPending ? (
                        <div className="loading loading-xl loading-infinity text-primary" />
                    ) : (
                        <ChevronsDown className="w-8 h-8" />
                    )}
                </div>
            </div>
    );
};

