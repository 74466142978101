// TODO: Figure out how to determine if a number is unassigned once chris finishes the client mod API
export function selectUnassignedNumbers(data) {
    return data.filter(d => true)
}

export function selectNumbersWithClientData(data) {
    return data
        .filter(num => Object.keys(num.clientdata || {})?.length > 2)
        .map(num => ({
            ...num,
            ...num?.clientdata,
            Name: num.Name,
            ciscoFirstName: num?.firstName,
            ciscoLastName: num?.lastName,
        }))
}