import { useNumbersInAgencies } from "@/api/numbers/getNumbersInAgency"

export const useAuthorizedPhoneNumbers = ({
    agenciesQuery
}) => {
    const numbers = useNumbersInAgencies(
        agenciesQuery.data || [], 
        !agenciesQuery.isPending && !agenciesQuery.isError
    )
    return numbers

}