import { FieldGroup } from '@/components/forms/formComponents/FieldGroup';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { Checkbox } from '@/components/ui/checkbox';
import { ControlGroup } from '@/routes/Main/Reporting/components/controls/components/ControlGroup';
import { AttachedDataControls } from '@/routes/Main/Reporting/components/controls/subcontrols/AttachedDataControls';
import { AttachedLegendControls } from '@/routes/Main/Reporting/components/controls/subcontrols/AttachedLegendControls';
import { LegendControls } from '@/routes/Main/Reporting/components/controls/subcontrols/LegendControls';
import { TitleControls } from '@/routes/Main/Reporting/components/controls/subcontrols/TitleControls';
import { JCheckbox } from '@/wrappers/ui/JCheckbox';
import { Settings, Settings2 } from 'lucide-react';
import React from 'react';


export const MainReportingControls = ({state, setState, visibility, setVisibility, ...props}) => {
    function handleChange(key, v) {
        setState(prev => ({...prev, [key]: v}))
    }
    function handleVisChange(key, v) {
        setVisibility(prev => ({...prev, [key]: v}))
    }
    function handleVisGroupToggle(key,value) {
        setVisibility(prev => ({...prev, [key]: Object.keys(prev[key]).reduce((acc, k) => ({...acc, [k]: value}),{})}))
    }
    return (
        <Card>
            <CardHeader>
                <div className="flex items-center gap-4">
                    <Settings className='w-14 h-14'/>
                    <div>
                        <CardTitle>Global Configuration</CardTitle>
                        <CardDescription>
                            Changes made here will affect all enabled charts that are not configured individually.
                            <br/>
                            <span><strong>Note</strong>: config options will only apply to the charts that support them</span>
                        </CardDescription>
                    </div>
                </div>
                <CardContent className='!mt-10'>
                    <div className="flex flex-col gap-4">
                        <ControlGroup title={'Config'}>
                            <TitleControls {...state} handleChange={handleChange}/>
                            <LegendControls {...state} handleChange={handleChange}/>
                            <div className="flex w-full space-x-2">
                                <AttachedLegendControls {...state} handleChange={handleChange}/>
                                <AttachedDataControls {...state} handleChange={handleChange}/>
                            </div>
                        </ControlGroup>
                        {/* <ControlGroup title={'Filter'}>
                            ji
                        </ControlGroup> */}
                        <ControlGroup title={'Enabled Reports'} className='w-fit pt-10  justify-center'>
                            <div className="flex gap-2">
                                <FieldGroup 
                                    className='w-[15rem] gap-0' 
                                    title={
                                        <div className='flex items-center gap-2'>
                                            <Checkbox 
                                                defaultChecked={Object.values(visibility.demographics).every(v => v)}
                                                value={Object.values(visibility.demographics).every(v => v)}
                                                onCheckedChange={v => handleVisGroupToggle('demographics', v)}
                                                />
                                            Demographics 
                                        </div>
                                    }
                                    labelPosition='center'>
                                    {Object.keys(visibility.demographics).map((k,i) => (
                                        <div className="flex gap-2 items-center" key={k}>
                                            <Checkbox
                                                defaultChecked={visibility.demographics[k]}
                                                value={visibility.demographics[k]}
                                                onCheckedChange={v => handleVisChange('demographics', {...visibility.demographics, [k]: v})}
                                                />
                                            <span>{k}</span>
                                        </div>
                                    ))}
                                </FieldGroup>
                                <FieldGroup 
                                    className='w-[15rem] gap-0' 
                                    title={
                                        <div className='flex items-center gap-2'>
                                            <Checkbox 
                                                defaultChecked={Object.values(visibility.usage).every(v => v)}
                                                value={Object.values(visibility.usage).every(v => v)}
                                                onCheckedChange={v => handleVisGroupToggle('demographics', v)}
                                                />
                                            Usage 
                                        </div>
                                    }
                                    labelPosition='center'>
                                    {Object.keys(visibility.demographics).map((k,i) => (
                                        <div className="flex gap-2 items-center" key={k}>
                                            <Checkbox
                                                defaultChecked={visibility.demographics[k]}
                                                value={visibility.demographics[k]}
                                                onCheckedChange={v => handleVisChange('demographics', {...visibility.demographics, [k]: v})}
                                                />
                                            <span>{k}</span>
                                        </div>
                                    ))}
                                </FieldGroup>
                            </div>
                        </ControlGroup>
                    </div>
                </CardContent>
            </CardHeader>
        </Card>
    );
};

