import { cn } from '@/lib/utils';
import React from 'react';

export const SelectableBadge = ({active, error=false, onClick, className=false, ...props}) => {
    return (
        <div 
            className={cn(
                "border-2 rounded-xl px-4 py-2 hover:cursor-pointer hover:bg-muted", 
                active && 'border-primary', 
                error && 'border-error',
                className)}
            onClick={onClick}
            {...props}

            />
    );
};
