import { ROUTE_IDS } from '@/api/routeIDs';
import { getMockUsers } from '@/util/debugUtils';
import { queryOptions, useQuery } from '@tanstack/react-query';



export const getAllClientData = ({queryKey}) => {
    const {_key, } = queryKey;
    return new Promise(r => r(getMockUsers()))
    //return errorPromise()
    // postPromise(import.meta.env.VITE_API_BASE_URL,composeFormData(params, ROUTE_IDS.GET_ALL_CLIENT_DATA));
}

export default function useAllClientData(params) {
    const query = useQuery(getAllClientDataQueryOptions);
    return query;
}

export const getAllClientDataQueryOptions = queryOptions({
    queryKey: [ROUTE_IDS.GET_ALL_CLIENT_DATA, ],
    queryFn: getAllClientData,
})