import useAgenciesInBranch from "@/api/agencies/getAgenciesInBranch";
import useAllBranches, {
    getAllBranchesQueryOptions,
} from "@/api/branches/getAllBranches";
import useModBranch from "@/api/branches/modBranch";
import useMyUserData from "@/api/user/getMyUserData";
import { PageHeader } from "@/components/misc/PageHeader";
import { Table } from "@/components/tables/Table";
import { branchesColumnHelper } from "@/components/tables/columnHelpers/branchesColumnHelper";
import { useQueryResultToasts } from "@/wrappers/toasts";

export const AllBranches = ({ ...props }) => {
    const myDataQuery = useMyUserData();
    const queryToasts = useQueryResultToasts();
    const branchesQuery = useAllBranches();
    // console.log(branchesQuery.data);
    const modBranchMutation = useModBranch(queryToasts(
        "Updated branch.",
        "Failed to update branch."
    ));
    function setHostAgency(row, agency) {
        modBranchMutation.mutate({
            branchid: row.id,
            hostAgency: agency.id,
        })
    }
    return (
        <div className="h-full flex flex-col">
            <PageHeader title="All Branches"></PageHeader>
            <Table
                retrieveSearchParamFilters
                exportFileName="all_branches"
                columnHelper={branchesColumnHelper}
                data={branchesQuery.data}
                maxHeightPixels={'100%'}
                meta={{
                    setHostAgency,
                }}
            />
            {/* <Select
                inForm={false}
                options={}
            /> */}
        </div>
    );
};

export const AllBranchesLoader = (queryClient) => async () => {
    // const myData = await queryClient.ensureQueryData({queryKey: getMyUserDataQueryOptions.queryKey})
    // const branchData = await queryClient.ensureQueryData({
    //     queryKey: getAllBranchesQueryOptions(queryClient).queryKey,
    // });
    return { };
};
