import * as Yup from 'yup';
import { SuperBool } from '../util/constants';
import { camelCaseToWords } from '../util/textUtils';


// eslint-disable-next-line @typescript-eslint/ban-types
type AnyPresentValue = {};
declare module 'yup' {
    interface BooleanSchema<
        TType extends Yup.Maybe<boolean> = boolean | undefined, 
        TContext = Yup.AnyObject, TDefault = undefined, 
        TFlags extends Yup.Flags = ""> {
            defaultNullBool(): BooleanSchema
            testSiblings(message: string, testFn: (vals: any) => boolean): BooleanSchema
    }
    interface ArraySchema<
        TIn extends any[] | null | undefined, 
        TContext, 
        TDefault = undefined, 
        TFlags extends Yup.Flags = ""
        > {
            arrayOfAtLeastOneOfEnum<T>(fieldName: string) : ArraySchema<TIn,TContext, TDefault,TFlags>
            ofServerEnum(enumKey: string): ArraySchema<TIn,TContext, TDefault,TFlags>
    }
    interface StringSchema<
        TType extends Yup.Maybe<string> = string | undefined, 
        TContext extends Yup.AnyObject = Yup.AnyObject, 
        TDefault = undefined, TFlags extends Yup.Flags = "", TOut extends TType = TType
        > extends Yup.Schema<TType, TContext, TOut> {
            smartLabel(): StringSchema            
            capitalizeFirstLetter(): StringSchema
            capitalizeEachFirstLetter(): StringSchema
            equalTo(ref: string | Yup.Reference, msg?: string): StringSchema
        }
    interface NumberSchema< 
        TType extends Yup.Maybe<number> = number | undefined, 
        TContext = Yup.AnyObject, TDefault = undefined, 
        TFlags extends Yup.Flags = ""
    > extends Yup.Schema<TType, TContext, TFlags>{
        priceStrToNum(): NumberSchema
        superBool(): NumberSchema

    }
    interface MixedSchema<
        TType extends Yup.Maybe<AnyPresentValue> = AnyPresentValue | undefined, TContext = Yup.AnyObject, 
        TDefault = undefined, 
        TFlags extends Yup.Flags = ""> extends Yup.Schema<TType, TDefault, TFlags> {
            oneOfEnum<T>(e: T) 
            smartLabel()
            oneOfServerEnum(enumKey: string): MixedSchema
            toArray(): MixedSchema
            initializeEnumFromArray(): MixedSchema
        }
    interface AnySchema<
        TType = any, TContext = any, D = any, TFlags extends Yup.Flags = Yup.Flags
        >
        extends Yup.Schema<TType, TContext, TFlags> {
        smartLabel(): AnySchema
    }
    interface Schema<
        TType = any, TContext = any, TDefault = any, TFlags extends Yup.Flags = ""
    >  {
        smartLabel(): AnySchema
    }
    interface ObjectSchema<
        TIn extends Yup.Maybe<Yup.AnyObject>, 
        TContext = Yup.AnyObject, 
        TDefault = any, 
        TFlags extends Yup.Flags = ""
    > extends Yup.Schema<TIn, TContext, TFlags> {
        minOneTrue(message?: string): ObjectSchema<TIn,TContext,TDefault,TFlags>
    }

    
}

function equalTo(ref, msg) {
	return this.test({
		name: 'equalTo',
		exclusive: false,
        message: msg || '${path} must be the same as ${reference}',
		params: {
			reference: ref.path
		},
		test: function(value) {
            if (typeof ref === 'string') {
                return value === this.resolve(Yup.ref(ref))    
            } else {
                return value === this.resolve(ref) 
            }
		}
	})
};



function NaNCheck(schema) {
    // console.log('schema',schema');
    return this.transform(v => isNaN(v) ? null : v) 
}

function requiredNonNull(msg) {
    return this.required(msg).nonNullable(msg).typeError(msg)
}

function defaultRequiredEmptyMin(name, min=1,setToMin=false) {
    const requiredStr = `${Name} is required`
    console.log('in min', min, setToMin,this);  
    return this
        .transform(v => 1)
        .min(min,`${Name} must be greater than ${min}`)
        // .typeError(requiredStr)
        .nonNullable(requiredStr)
        // .required(requiredStr)
        .default('')
}

function emptyNum() {
    return this.default('').transform(v => v === '' ? null : v)
}

function priceStrToNum() {
    return this.transform((v,orig) => {
        return Number((orig)?.replace(/,/g, '')?.replace(/[^0-9.]|\.(?=.*\.)/g, ""))
    })
}

function reqa(fieldName) {
    return this.required(`Please enter a ${fieldName}`)
}
function reqan(fieldName) {
    return this.required(`Please enter an ${fieldName}`)
}
function pickOne(fieldName) {
    return this.oneOf([true],`Please select a ${fieldName}`)
}
function oneOfEnum(e,fieldName, an=false) {
    return this.oneOf(Object.values(e),`Please pick one'} ${fieldName}`)
}
function arrayOfAtLeastOneOfEnum<T extends AnyPresentValue>() {
    return this.of(Yup.mixed<T>()).min(1,`Please pick at least one`)
}
function defaultNullBool() {
    return this.default(SuperBool.unknown).nullable()
}
function smartLabel() {
    return this.label(camelCaseToWords('${path}'))
    // const camelCase =camel.replace(/([a-z])([A-Z])/g, '$1 $2')

    // return camelCase
}

function initializeEnumFromArray() {
    return (this as Yup.MixedSchema).when('$arrayToEnum', ([arrayToEnum], schema) =>
        arrayToEnum 
        ?  (schema as Yup.MixedSchema).transform((v, o) => Array.isArray(o) ? o?.[0] : o)
        : schema
    )
}
function minOneTrue(message='At least one must be selected') {
    return this.test('minOneTrue', message, (vals) => {
        // console.log(Object.entries(vals));
        return Object.values(vals).some(val => val)
    })
}
function oneOfServerEnum(enumKey) {
    return (this as Yup.MixedSchema)
        .test(
            'oneOfServerEnum', 
            `Please pick one ${enumKey}`, 
            (val,context) => {
                // console.log('yyyyyup',context.options.context[enumKey]);
                if (val == null) return true
                const valAsArray = (Array.isArray(val) ? val : [val]).flat()
                if (valAsArray.length === 0) {
                    // console.log('empty');
                    return true
                }
                if (!context) {
                    console.log('context not proiceded to yup resolver');
                    throw new Error('Context not provided to yup resolver')
                }
                if (!context.options.context[enumKey]) {
                    console.log(`enum provided to yup resolver does not contain the enumKey ${enumKey}`);
                    throw new Error(`enum provided to yup resolver does not contain the enumKey ${enumKey}`)
                }
                // console.log('not empty', valAsArray);
                return context.options.context[enumKey]
                    .map(e => e.id)
                    .includes(valAsArray?.[0])
            })
}

function toArray() {
    return this.transform((v) => Array.isArray(v) ? v : [v])
}
function fromArray(){
    return this.transform((v) => Array.isArray(v) ? v?.[0] : v)
}
function ofServerEnum(enumKey) {
    return this.of(Yup.mixed().oneOfServerEnum(enumKey))
}
function superBool() {
    return this.oneOf([-1,0,1])
}
function testSiblings(message, testFn) {
    return this.test('testSiblings', message, (v,ctx) => {
        console.log('inyup',v,ctx);
        return testFn(ctx.parent)
    })
}

function capitalizeFirstLetter(string) {
    return this.transform((v, o) => o.charAt(0).toUpperCase() + o.slice(1));
}

function capitalizeEachFirstLetter(string) {
    return this.transform((v, o) => o.split(' ').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' '));
}



Yup.addMethod<Yup.StringSchema>(Yup.string, 'equalTo', equalTo);
Yup.addMethod<Yup.NumberSchema>(Yup.number, 'NaNCheck', NaNCheck)
Yup.addMethod<Yup.NumberSchema>(Yup.number, 'superBool', superBool)
Yup.addMethod<Yup.NumberSchema>(Yup.number, 'requiredNonNull', requiredNonNull)
Yup.addMethod<Yup.NumberSchema>(Yup.number, 'defaultRequiredEmptyMin', defaultRequiredEmptyMin)
Yup.addMethod<Yup.NumberSchema>(Yup.number, 'priceStrToNum', priceStrToNum)
Yup.addMethod<Yup.StringSchema>(Yup.string, 'reqa', reqa)
Yup.addMethod<Yup.StringSchema>(Yup.string, 'reqan', reqan)
Yup.addMethod<Yup.StringSchema>(Yup.string, 'capitalizeFirstLetter', capitalizeFirstLetter)
Yup.addMethod<Yup.StringSchema>(Yup.string, 'capitalizeEachFirstLetter', capitalizeEachFirstLetter)
Yup.addMethod<Yup.MixedSchema>(Yup.mixed, 'pickOne', pickOne)
Yup.addMethod<Yup.MixedSchema>(Yup.mixed, 'oneOfEnum', oneOfEnum)
Yup.addMethod<Yup.MixedSchema>(Yup.mixed, 'toArray', toArray)
Yup.addMethod<Yup.MixedSchema>(Yup.mixed, 'fromArray', fromArray)
Yup.addMethod<Yup.MixedSchema>(Yup.mixed, 'initializeEnumFromArray', initializeEnumFromArray)
Yup.addMethod<Yup.BooleanSchema>(Yup.boolean, 'defaultNullBool', defaultNullBool)
Yup.addMethod<Yup.BooleanSchema>(Yup.boolean, 'testSiblings', testSiblings)
Yup.addMethod<Yup.Schema>(Yup.bool, 'smartLabel', smartLabel)
Yup.addMethod<Yup.StringSchema>(Yup.string, 'smartLabel', smartLabel)
Yup.addMethod<Yup.Schema>(Yup.array, 'smartLabel', smartLabel)
Yup.addMethod<Yup.MixedSchema>(Yup.mixed, 'smartLabel', smartLabel)
Yup.addMethod<Yup.MixedSchema>(Yup.mixed, 'oneOfServerEnum', oneOfServerEnum)
Yup.addMethod<Yup.Schema>(Yup.object, 'smartLabel', smartLabel)
Yup.addMethod<Yup.DateSchema>(Yup.date, 'smartLabel', smartLabel)
Yup.addMethod<Yup.ArraySchema<any,any>>(Yup.array, 'ofServerEnum', ofServerEnum)
Yup.addMethod(Yup.object, 'smartLabel', smartLabel)
Yup.addMethod(Yup.object, 'minOneTrue', minOneTrue)
Yup.addMethod(Yup.array,'arrayOfAtLeastOneOfEnum', arrayOfAtLeastOneOfEnum)

export default Yup