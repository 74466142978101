import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';

export const BranchCell = ({name, id,  ...props}) => {
    return (
        <Link to={'/branch/' + id}>
            <Button variant={'link'} size={'xs'} className='whitespace-nowrap pl-0'>
                {name}
            </Button>
        </Link>
    );
};
