
import { getChartColors, getManualBarConfig, getManualPieConfig } from '@/routes/Main/Reporting/utils/ChartConfigs';
import React, { forwardRef, useMemo, useRef } from 'react';
import { Bar, Doughnut, Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart } from 'chart.js';
import CountUp from 'react-countup';
import { DemographicsData } from '@/components/forms/ClientForm/Demographics';
import { customDataLabels, doughnutOptions } from '@/routes/Main/Reporting/utils/doughnutOptions';
import { mergeUnknownAndNoData } from '@/util/miscUtils';
import { DemographicsReportData } from '@/routes/Main/Reporting/Presets/DemographicsReport/useDemographicsReportData';
import mergeRefs from 'merge-refs';
import { LegendEntry } from '@/components/charting/LegendEntry';
import { barOptions, demoReportBarOptions } from '@/routes/Main/Reporting/utils/barOptions';
import { BarChartSkeleton } from '@/components/skeletons/BarChartSkeleton';

type MiscCharacteristicsProps = {
    data: DemographicsReportData,
    hideUnknown: boolean
};
export const MiscCharacteristicsChart = forwardRef<Chart<"bar", any, unknown>,MiscCharacteristicsProps>(({data, hideUnknown, ...props}: MiscCharacteristicsProps, ref) => {
    const internalRef = useRef()
    const [labels, values, colors] = useMemo(() => {
        if (!data.miscCharacteristics) return [[],[]];
        const withMergedUnknown = mergeUnknownAndNoData(data.miscCharacteristics)
        withMergedUnknown.Unknown += data.withoutProfileCount
        const [l,v] =  Object
            .entries(withMergedUnknown)
            .reduce(
                (acc, [key, value]) => {
                    if (hideUnknown && key === 'Unknown') return acc;
                    acc[0].push(key);
                    acc[1].push(value);
                    return acc;
                },
                [[],[]])
        const c = getChartColors(l.length)
        return [l, v, c]
    },[data, hideUnknown])

    const config = useMemo(() => {
        if (!data.miscCharacteristics || !labels || !values) return null;
        return getManualBarConfig(
            values,
            colors,
            labels,
        );
    }, [data, labels, values]);

    return config ? (
        <div className='h-full flex flex-col max-w-full '>
            <span className='font-semibold text-xl'>Personal Challenges</span>
            <div className='h-[20rem] w-[99%] relative -mb-2 ' style={{position: 'relative'}}>
                <Bar
                    ref={mergeRefs(ref, internalRef)}
                    options={demoReportBarOptions({
                        padding: {top:10},
                        animations: !props?.isExporting,
                    }) as any}
                    plugins={[ChartDataLabels]}
                    {...config}
                />
            </div>
                {/* <div className="absolute flex flex-col items-center left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                    <CountUp className='font-semibold' start={0} end={data.withProfileCount} separator=','/>
                    <span className='font-light mt-1'>Total Messages</span>
                </div> */}
            <div className='flex flex-col flex-wrap max-h-[5rem]  gap-2 justify-center pt-2'>
                {labels.map((label, index) => (
                    <div key={label} className='basis-[25%]'>
                        <LegendEntry key={label} color={colors[index]} label={label}/>
                    </div>
                ))}
            </div>
        </div>
    ) : (
        <BarChartSkeleton />
    )
})