import { FormControl, FormDescription, FormField, FormHeader, FormItem, FormLabel } from '@/components/ui/form';
import { Input, InputProps } from '@/components/ui/input';
import { cn } from '@/lib/utils';
import { cva } from 'class-variance-authority';
import React, { forwardRef, useRef } from 'react';
import { Toggle } from '../../../components/ui/toggle';
import { camelCaseToWords } from '../../../util/textUtils';
import { Switch } from '@/components/ui/switch';

type ComposedFormSwitchBoxProps = InputProps & {
    control: any;
    name: string;
    small?: boolean;
    label?: string;
    placeholder?: string;
    description?: string;
};

export const ComposedFormSwitchBox = forwardRef<HTMLInputElement, ComposedFormSwitchBoxProps>(({
    control,
    name, 
    small=false,
    label=`${name}`,
    description=null,
    className='',
    type,
    ...props}, 
    ref) => {
    
    return (
        <FormField
            control={control}
            name={name}
            render={({ field }) => (
                <FormItem 
                    className={cn("flex items-center justify-between rounded-lg border p-4", className)}>
                        <div 
                            className={cn(
                                "space-y-0.5",
                                )}>
                            <FormHeader label={label}/>
                            {description && <FormDescription>{description}</FormDescription>}
                        </div>
                        <FormControl>
                            <Switch
                                checked={field.value}
                                onCheckedChange={field.onChange}
                                />
                        </FormControl>
                </FormItem>
            )}
            />
    );
});
ComposedFormSwitchBox.displayName = 'ComposedFormSwitchBox';

