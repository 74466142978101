import { DateCell } from "@/components/tables/cells/DateCell";
import { LinkedClientCell } from "@/components/tables/cells/LinkedClientCell";
import { Button } from "@/components/ui/button";
import { formatPhone } from "@/util/textUtils";
import { createColumnHelper, filterFns } from "@tanstack/react-table";
import { ExternalLinkIcon } from "lucide-react";
import { Link } from "react-router-dom";

const columnHelper = createColumnHelper();

const stringFilter = filterFns.includesString;
const numRangeFilter = filterFns.inNumberRange;
const multiSelectFilter = filterFns.arrIncludesSome;
const strictStringFilter = filterFns.includesString.autoRemove;

export const clientsColumnHelper = [
    columnHelper.accessor((row) => row.id, {
        id: "ID",
        size: 100,
        filterFn: stringFilter,
        meta: {
            hiddenFilter: true,
            hiddenCol: true,
            hideExport: true,
        },
        cell: (info) => <span>{info.getValue()}</span>,
        header: (info) => <span>ID</span>,
    }),
    columnHelper.accessor((row) => row.alias, {
        id: "alias",
        size: 100,
        meta: {
            columnLabel: "Phone Number",
        },
        filterFn: stringFilter,
        cell: (info) => <span>{formatPhone(info.getValue())}</span>,
        header: (info) => <span>Phone Number</span>,
    }),
    columnHelper.accessor((row) => row.Name, {
        id: "Name",
        size: 100,
        filterFn: stringFilter,
        cell: (info) => <LinkedClientCell row={info.row.original} />,
        header: (info) => <span>Client Profile</span>,
    }),
    columnHelper.accessor(
        (row) => `${row.ciscoFirstName} ${row.ciscoLastName}`,
        {
            id: "Cisco Name",
            size: 100,
            filterFn: stringFilter,
            cell: (info) => (
                <span className="whitespace-nowrap">{info.getValue()}</span>
            ),
            header: (info) => <span>Cisco Name</span>,
        }
    ),
    columnHelper.accessor((row) => row.agencyname, {
        id: "AgencyName",
        size: 100,
        meta: {
            hiddenCol: true,
            // hiddenFilter: true,
            hideExport: true,
        },
        filterFn: stringFilter,
        cell: (info) => (
            <span className="whitespace-nowrap">{info.getValue()}</span>
        ),
        header: (info) => <span>Cisco Name</span>,
    }),

    // columnHelper.accessor((row) => row.isAc, {
    //     id: "Status",
    //     size: 100,
    //     filterFn: stringFilter,
    //     cell: (info) => <span>{info.getValue()}</span>,
    //     header: (info) => <span>Status</span>,
    // }),
    columnHelper.accessor((row) => row.dateEnrolled, {
        id: "Date Started",
        size: 100,
        filterFn: stringFilter,
        cell: (info) => <DateCell value={info.getValue()} concise />,
        header: (info) => <span>Date Started</span>,
    }),
    // columnHelper.accessor((row) => row.endedTimestamp, {
    //     id: "Date Ended",
    //     size: 100,
    //     filterFn: stringFilter,
    //     cell: (info) => <DateCell value={info.getValue()} />,
    //     header: (info) => <span>Date Ended</span>,
    // }),
];

const Actions = ({ row, table }) => {
    return (
        <Link to={"/client/" + row.id} className="float-right">
            <Button
                variant="outline"
                size={"sm"}
                className="flex gap-2 items-center text-end"
            >
                Go to client <ExternalLinkIcon className="w-4" />
            </Button>
        </Link>
    );
};
