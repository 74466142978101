import { postPromise } from "@/api/promises"
import { ROUTE_IDS } from "@/api/routeIDs"
import { composeFormData } from "@/util/apiUtils"
import { deleteAllCookies } from "@/util/cookieUtils"
import { useMutation, useQueryClient } from "@tanstack/react-query"

export const login = async (params) => {
    return postPromise(import.meta.env.VITE_API_BASE_URL, composeFormData(params, ROUTE_IDS.LOGIN),{},{
        skipToken: true
    })
    // return {token: 'token', refreshToken:'refreshToken'}
}

export const useLogin = ({onSuccess, ...otherProps}) => {
    const client = useQueryClient();
    const mutation = useMutation({
        mutationKey: [ROUTE_IDS.LOGIN],
        mutationFn: login,
        onSuccess: (data, req) => {
            if (data.token) {
                onSuccess && onSuccess(data, req)
            }
        },
    ...otherProps})
    return mutation;
}