import useFlagAgency from "@/api/agencies/flagAgency";
import useAllBranches from "@/api/branches/getAllBranches";
import useMyUserData, {
    getMyUserDataQueryOptions,
} from "@/api/user/getMyUserData";
import { PageHeader } from "@/components/misc/PageHeader";
import { Table } from "@/components/tables/Table";
import { agenciesColumnHelper } from "@/components/tables/columnHelpers/agenciesColumnHelper";
import { AgencyFlagFilterControls } from "@/components/tables/helpers/AgencyFlagFilterControls";
import { timeStampToFilter } from "@/components/tables/helpers/tableUtils";
import { useMyAuthorizedAgencies } from "@/hooks/state/useMyAuthorizedAgencies";
import { useQueryResultToasts } from "@/wrappers/toasts";
import { useState } from "react";

export const Agencies = ({ ...props }) => {
    const [maxFlaggedTimestamp, setMaxFlaggedTimestamp] = useState(null);
    const myUserDataQuery = useMyUserData();
    const allBranchesQuery = useAllBranches();
    const authorizedAgenciesQuery = useMyAuthorizedAgencies({
        myUserDataQuery,
        allBranchesQuery,
    });
    console.log(myUserDataQuery.data?.nodeids);
    const queryResultToasts = useQueryResultToasts();
    const flagAgencyMutation = useFlagAgency({
        ...queryResultToasts("Agency Flagged", "Error Flagging Agency"),
    });
    function flagAgency(row) {
        flagAgencyMutation.mutate({ agencyid: row.id });
    }
    return (
        <div className="h-full flex flex-col">
            <PageHeader title="Agencies"></PageHeader>
            <Table
                retrieveSearchParamFilters
                exportFileName="agencies"
                columnHelper={agenciesColumnHelper}
                data={authorizedAgenciesQuery.data}
                showLoading={authorizedAgenciesQuery.isPending}
                meta={{ flagAgency }}
                extraFilters={timeStampToFilter(maxFlaggedTimestamp, "Flagged")}
                maxHeightPixels={"100%"}
            >
                <AgencyFlagFilterControls
                    {...{ maxFlaggedTimestamp, setMaxFlaggedTimestamp }}
                />
            </Table>
        </div>
    );
};

export const AgenciesLoader = (queryClient) => async () => {
    try {
        const myData = await queryClient.ensureQueryData({
            queryKey: getMyUserDataQueryOptions.queryKey,
        });
        // const agencyPromises =
        //     myData.branches.map(branchID =>
        //         queryClient.ensureQueryData({
        //             ...getAgenciesInBranchQueryOptions(branchID)
        //         }))
        // const agencyData = await Promise.all(agencyPromises);
        return {};
    } catch (error) {
        console.log("error in Agencies loader", error);
        return {};
    }
};
