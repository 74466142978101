import React from 'react';
import {motion} from 'framer-motion';
export const SkeletonLinearGradient = ({rotation, ...props}) => {
    return (
        <motion.linearGradient
            transition={{
                duration: 2,
                ease: "easeInOut",
                repeat: Infinity,
                repeatType: "loop",
            }}
            gradientUnits={"userSpaceOnUse"}
            initial={{
                gradientTransform:  'translate(-15,-10) rotate(12)'  
            }}
            animate={{
                gradientTransform: 'translate(15,10) rotate(12)'
            }}
          {...props}
        >
            <motion.stop offset="0%"  className='skeleton-stop-2'/>
            <stop offset="25%" className='skeleton-stop-2'/>
            <stop offset="50%" className='skeleton-stop-1' />
            <stop offset="75%" className='skeleton-stop-2'/>
            <stop offset="100%"className='skeleton-stop-2'/>
        </motion.linearGradient>        
    );
};
