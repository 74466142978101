import { FieldGroup } from '@/components/forms/formComponents/FieldGroup';
import { cn } from '@/lib/utils';
import React from 'react';


export const ControlGroup = ({title, className, ...props}) => {
    return (
        <FieldGroup
            title={title}
            labelPosition='center'
            labelClassName='text-lg'
            className={cn('w-full  mb-4 gap-2', className)} {...props}/>

    );
};

