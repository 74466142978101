import { prettyPrint } from "@/util/miscUtils";
import Yup from "@/wrappers/yup";
import { useCallback } from "react";

type useYupValidationResolverReturn = (data: any) => Promise<{ values: any; errors: any }>;
type useYupValidationResolver = (
    validationSchema: Yup.AnyObjectSchema,
    context?: Yup.ValidateOptions['context'] | null,
    config?: Omit<Yup.ValidateOptions, 'context'> | null,
    submissionSchema?: Yup.AnyObjectSchema,
) => useYupValidationResolverReturn;

export const useYupValidationResolver: useYupValidationResolver = (
    validationSchema,
    context = {},
    config = {},
    submissionSchema=undefined
) => {
 
    return useCallback(
        async (data) => {
            // console.log('data',data);
            try {
                let values = await validationSchema.validate(data, {
                    abortEarly: false,
                    context,
                    ...config,
                });

                if (submissionSchema) {
                    values = await submissionSchema.cast(values, {
                        abortEarly: false,
                        stripUnknown: true,
                        context,
                        ...config,
                    })
                }
                
                return {
                    values,
                    errors: {},
                };
                

            } catch (errors) {
                const errorObj = Object.fromEntries(errors?.inner?.map?.((e) => [e.path,e.message]) || [])
                console.log('yup errors',errorObj);
                // prettyPrint(errors);
                const ret = {
                    values: {},
                    errors: errors?.inner?.reduce?.(
                        (allErrors, currentError) => ({
                            ...allErrors,
                            [currentError.path]: {
                                type: currentError.type ?? "validation",
                                message: currentError.message,
                            },
                        }),
                        {}
                    ),
                }
                console.log('yup resolver ret',ret);
                return ret;
            }
        },
        [validationSchema, config]
    );
}

export const useSyncYupValidationResolver = (
    validationSchema,
    context = {},
    config = {}
) =>
    useCallback(
        (data) => {
            try {
                const values = validationSchema.validateSync(data, {
                    abortEarly: false,
                    context,
                    ...config,
                });

                return {
                    values,
                    errors: {},
                };
            } catch (errors) {
                // console.log(errors);
                return {
                    values: {},
                    errors: errors?.inner.reduce(
                        (allErrors, currentError) => ({
                            ...allErrors,
                            [currentError.path]: {
                                type: currentError.type ?? "validation",
                                message: currentError.message,
                            },
                        }),
                        {}
                    ),
                };
            }
        },
        [validationSchema, config]
    );
