import { Skeleton } from '@/components/ui/skeleton';
import React from 'react';

export const TableRowSkeletons = ({numRows=10, ...props}) => {
    return (
        <>
            <tr>
                <td className='px-4 py-3  w-full' colSpan={100}>
                    <div className="flex w-full justify-center">
                        <div className="loading loading-lg loading-infinity text-primary"></div>
                    </div>
                </td>
            </tr>
            {Array(numRows).fill(0).map((_,i) =>(
                <tr key={i} className=''>
                    <td className='px-4 py-3 w-full' colSpan={100}>
                            <Skeleton className='w-full h-5 !rounded-sm'/>
                    </td>
                </tr>
            ))}
        </>
    )
};
