import { useAllEnums } from "@/api/enum/getAllEnums";
import useNumbersInAgency from "@/api/numbers/getNumbersInAgency";
import useModClient from "@/api/numbers/modClient";
import { getMyUserDataQueryOptions } from "@/api/user/getMyUserData";
import { ClientForm } from "@/components/forms/ClientForm";
import { useRHF } from "@/hooks/useRHF";
import { useYupValidationResolver } from "@/hooks/useYupValidationResolver";
import { subObject } from "@/util/miscUtils";
import { parseBooleans, parseServerEnums } from "@/util/queryUtils";
import { useQueryResultToasts } from "@/wrappers/toasts";
import Yup from "@/wrappers/yup";
import { useEffect } from "react";
import { redirect, useParams } from "react-router-dom";

const enumFieldNames = [
    "foundEmployment",
    "gotHousing",
    "accessedSocialServices",
    "usedforHealthCare",
    "increasedFinancialBenefits",
    "achievedSafeCommunication",
    "achievedCommunicationwithFriendsandFamily",
    "accessedLegalJusticeSystem",
    "maintainedCommunityConnections",
    "usedforOtherReasons",
    "financialResource",
    "housingOutcome",
    "ageRange",
    "gender",
    "currentHousing",
    "identity",
    "miscCharacteristics",
    "clientGoals",
];

export const fieldNameSections = {
    otherCVMUses: "1",
    otherGoalsOutcome: "1",
    housingOutcome: "1",
    socialPurposeHousingPartner: "1",
    foundEmployment: "1",
    gotHousing: "1",
    accessedSocialServices: "1",
    usedforHealthCare: "1",
    increasedFinancialBenefits: "1",
    achievedSafeCommunication: "1",
    achievedCommunicationwithFriendsandFamily: "1",
    accessedLegalJusticeSystem: "1",
    maintainedCommunityConnections: "1",
    usedforOtherReasons: "1",
    financialResource: "1",
    supportiveHousingPartner: "1",

    objectid: "2",
    dateEnrolled: "2",
    firstName: "2",
    middleName: "2",
    lastName: "2",
    email: "2",
    clientPassword: "2",
    hasCellPhone: "2",
    cellNumber: "2",
    staffThatEnrolled: "2",
    comment: "2",
    hasStudentVisa: "2",

    ageRange: "3",
    gender: "3",
    currentHousing: "3",
    miscCharacteristics: "3",
    identity: "3",
    otherGender: "3",
    otherCurrentHousing: "3",
    otherIdentity: "3",
    otherMiscCharacteristics: "3",
    frequentShelterUse: "3",
    isRefugee: "3",
    hasStatusCard: "3",

    otherGoals: "4",
    clientGoals: "4",
};

const validationSchema = Yup.object({
    objectid: Yup.string().required(),

    //outcomes
    otherCVMUses: Yup.string().notRequired(),
    otherGoalsOutcome: Yup.string().notRequired(),

    housingOutcome: Yup.array().ensure().notRequired(),
    socialPurposeHousingPartner: Yup.string().notRequired(),

    foundEmployment: Yup.mixed()
        .initializeEnumFromArray()
        .toArray()
        .oneOfServerEnum("foundEmployment")
        .notRequired(),
    gotHousing: Yup.mixed()
        .initializeEnumFromArray()
        .toArray()
        .oneOfServerEnum("gotHousing")
        .notRequired(),
    accessedSocialServices: Yup.mixed()
        .initializeEnumFromArray()
        .toArray()
        .oneOfServerEnum("accessedSocialServices")
        .notRequired(),
    usedforHealthCare: Yup.mixed()
        .initializeEnumFromArray()
        .toArray()
        .oneOfServerEnum("usedforHealthCare")
        .notRequired(),
    increasedFinancialBenefits: Yup.mixed()
        .initializeEnumFromArray()
        .toArray()
        .oneOfServerEnum("increasedFinancialBenefits")
        .notRequired(),
    achievedSafeCommunication: Yup.mixed()
        .initializeEnumFromArray()
        .toArray()
        .oneOfServerEnum("achievedSafeCommunication")
        .notRequired(),
    achievedCommunicationwithFriendsandFamily: Yup.mixed()
        .initializeEnumFromArray()
        .toArray()
        .oneOfServerEnum("achievedCommunicationwithFriendsandFamily")
        .notRequired(),
    accessedLegalJusticeSystem: Yup.mixed()
        .initializeEnumFromArray()
        .toArray()
        .oneOfServerEnum("accessedLegalJusticeSystem")
        .notRequired(),
    maintainedCommunityConnections: Yup.mixed()
        .initializeEnumFromArray()
        .toArray()
        .oneOfServerEnum("maintainedCommunityConnections")
        .notRequired(),
    usedforOtherReasons: Yup.mixed()
        .initializeEnumFromArray()
        .toArray()
        .oneOfServerEnum("usedforOtherReasons")
        .notRequired(),
    financialResource: Yup.mixed()
        .initializeEnumFromArray()
        .toArray()
        .oneOfServerEnum("financialResource")
        .notRequired(),

    supportiveHousingPartner: Yup.string().notRequired(),

    // participant info
    dateEnrolled: Yup.string().required("Please enter a start date"),
    firstName: Yup.string(),
    middleName: Yup.string().notRequired(),
    lastName: Yup.string(),
    email: Yup.string().email("Must be a valid email address").notRequired(),
    clientPassword: Yup.string().notRequired(),
    hasCellPhone: Yup.boolean().notRequired(),
    cellNumber: Yup.string().notRequired(),
    staffThatEnrolled: Yup.string().notRequired(),
    comment: Yup.string().notRequired(),
    hasStudentVisa: Yup.boolean().notRequired(),

    // Demographic
    ageRange: Yup.mixed().toArray().oneOfServerEnum("ageRange"),
    gender: Yup.mixed().toArray().oneOfServerEnum("gender"),
    currentHousing: Yup.array().ofServerEnum("currentHousing").required().min(1,'At least one housing type must be selected'),
    miscCharacteristics: Yup.array()
        .ofServerEnum("miscCharacteristics")
        .min(1, 'At least one personal challenge must be selected')
        .required(),
    identity: Yup.array().ofServerEnum("identity"),

    otherGender: Yup.string().notRequired(),
    otherCurrentHousing: Yup.string().notRequired(),
    otherIdentity: Yup.string().notRequired(),
    otherMiscCharacteristics: Yup.string().notRequired(),

    frequentShelterUse: Yup.boolean().notRequired(),
    isRefugee: Yup.boolean().notRequired(),
    hasStatusCard: Yup.boolean().notRequired(),

    // Goals
    otherGoals: Yup.string().notRequired(),
    clientGoals: Yup.array().min(1, "At least one goal must be selected"),
});

export const ClientDetails = ({ ...props }) => {
    const { branchID, agencyID, objectID: objectid } = useParams() || {};

    // React Query
    const numberQuery = useNumbersInAgency(agencyID, {
        select,
    });
    function select(data) {
        const num = data.find((n) => n.Name === objectid);
        if (!num) console.log("number does not exist on this agency");
        const numsWithSameAlias = data.filter((n) => n.alias === num.alias);
        // TODO: Check if number is newest
        // if (numsWithSameAlias.length > 1)
        const {firstName, lastName, ...otherNum} = num
        return {
            ...otherNum,
            ...otherNum?.clientdata,
            ciscoFirstName: firstName,
            ciscoLastName: lastName,
        };
    }
    const enumsQuery = useAllEnums();
    const queryToasts = useQueryResultToasts();
    const clientMutation = useModClient({
        agencyID,
        ...queryToasts("Client Updated", "Client Update Failed"),
    });

    // Form stuff
    useEffect(loadData, [numberQuery.data, enumsQuery.data, objectid]);
    const resolver = useYupValidationResolver(
        validationSchema,
        enumsQuery.data
    );
    const form = useRHF({
        resolver,
        defaultValues: validationSchema.getDefault(),
    });
    const { setValue, getValues, reset, control } = form;
    function loadData() {
        if (!numberQuery.data || enumsQuery.isPending)
            return setValue("objectid", objectid);
        const enumArr = Object.values(
            subObject(enumsQuery.data, enumFieldNames)
        );
        const parsedBooleans = parseBooleans(numberQuery.data);
        const parsedEnums = parseServerEnums(
            parsedBooleans,
            enumArr,
            enumFieldNames
        );

        reset(
            validationSchema.cast(
                { ...parsedEnums, objectid },
                {
                    context: { ...enumsQuery.data, arrayToEnum: true },
                    assert: false,
                    stripUnknown: true,
                }
            )
        );
    }
    // console.log('ndata',numberQuery.data);
    function onSubmit(fields) {
        console.log("submitting", fields);
        clientMutation.mutate(fields);
    }

    return (
        <ClientForm
            onSubmit={onSubmit}
            form={form}
            enumsQuery={enumsQuery}
            clientData={numberQuery.data}
            loading={numberQuery.isPending}
        />
    );
};

export const ClientDetailsLoader = (QueryClient) => async ({params}) => {
    const myData = await QueryClient.ensureQueryData(getMyUserDataQueryOptions);
    const { agencyID, branchID, clientID } = params || {};
    const {adminLevel, nodeids} = myData;
    const isAdmin = Number(adminLevel) > 5;
    const isBranchUser = Number(adminLevel) > 3;
    const isAgencyUser = Number(adminLevel) === 3;

    // Check if user has permissions to access things in this agency
    const userHasBranchPermissions = isBranchUser && nodeids.includes(branchID);
    const userHasAgencyPermissions = isAgencyUser && nodeids.includes(agencyID);
    if (!(isAdmin || userHasBranchPermissions || userHasAgencyPermissions)) return redirect("/");
    return {};
};
