import { Progress } from '@/components/ui/progress';


export const TableProgress = ({progress}) => {
    const {pct, step, numSteps} = progress || {}
    return (
          <div className="absolute flex flex-col gap-2 px-6 py-4 mx-auto  w-[30rem] h-[6rem] rounded-3xl top-40  right-0 left-0 bg-background shadow-xl bg-opacity-100 z-10 border">
            <span className='font-black text-lg'>Export Progress {step} / {numSteps}</span>
            <Progress value={pct} className="w-full h-40" />
          </div>
    );
};

