export const ROUTE_IDS = {
    // AUTH
    LOGIN: 'login',
    REFRESH: 'refreshtoken',
    RESET_PASSWORD: 'changePassword',
    SEND_PASSWORD_RESET_EMAIL: 'sendResetPasswordEmail', 
    SIGNUP_WITH_INVITE: 'createInvitedUser',

    // USER
    GET_MY_USER_DATA: 'getMyUserData',
    GET_REGION_ADMINS: 'getAllUsers',
    DELETE_REGION_ADMINS: 'deleteBranchAdmins',
    CREATE_USER: 'createUser',
    SEND_SIGNUP_INVITE: 'sendInviteUserEmail',
    MOD_USER: 'modUser',
    DELETE_USER: 'delUser',

    // BRANCHES
    GET_REGION: 'getBranch',
    GET_ALL_REGIONS: 'getBranches',
    MOD_BRANCH: 'modBranch',
    GET_AGENCY_BRANCH_INFO: 'getAgencyBranch',

    // AGENCIES
    GET_AGENCY: 'getAgencyByID',
    GET_AGENCIES_IN_REGION: 'getAgencies',
    MOD_AGENCY: 'modAgency',    
    FLAG_AGENCY: 'flagAgency',

    // CLIENTS
    GET_CLIENT: 'getClient',
    GET_CLIENTS_IN_AGENCY: 'getClientsInAgency',
    GET_CLIENTS_IN_REGION: 'getClientsInBranch',

    // ENUMS
    GET_ALL_ENUMS: 'getAllEnums',

    // NUMBERS
    GET_NUMBER: 'getNumber',
    GET_NUMBERS_IN_AGENCY: 'getNumberAlias',
    GET_ALL_NUMBERS: 'getAllNumberAlias',
    MOD_CLIENT: 'modClient',
    
    // MAILBOXES
    GET_INBOX: 'cvminbox',
    GET_MAILBOXES_IN_AGENCY: 'getMailboxesInAgency',
}