import React from 'react';
import { UsageReportConfig } from '.';
import { SelectableBadge } from '../../../../../components/badges/SelectableBadge';
import { SimpleCard } from '../../../../../components/ui/SimpleCard';
import { Button } from '../../../../../components/ui/button';
import { subObject } from '../../../../../util/miscUtils';
import { capitalizeFirst } from '../../../../../util/textUtils';
import { Select } from '../../../../../wrappers/ui/Select';
import { ReportConfigStep } from '../../components/ReportConfigStep';

type UsageReportSettingsProps = React.HTMLAttributes<HTMLDivElement> & {
    activeStep: number;
    setActiveStep: (step: number) => void;
    branches: any[];
    agencies: any[];
    config: UsageReportConfig;
    setConfig: (update: UsageReportConfig | ((prev: UsageReportConfig) => UsageReportConfig)) => void
    myUserData?: any,
    errors: any,
    configValid?: boolean,
    shouldGenerateReport?: boolean,
    setShouldGenerateReport?: (value: boolean) => void
    setConfigValid?: (value: boolean) => void
}
export const UsageReportSettings = (props: UsageReportSettingsProps) => {
    const {config, branches, agencies, myUserData, setConfig, errors, configValid, setConfigValid, setShouldGenerateReport} = props;
    const {view, branch, agency , separateAgencies, separateBranches} = config
    const visibleViews = [
        (myUserData?.isAdmin ? { value: 'national', label: 'national'} : []), 
        (!myUserData?.isAgencyUser ? { value: 'branch', label: 'for a specific branch'} : []), 
        { value: 'agency', label: 'for a specific agency'}
    ].flat()
    
    function handleChange(field, newValue) {
        setShouldGenerateReport(false)
        setConfigValid(false)
        setConfig(p => ({...p, [field]: newValue}))
    }
    
    return (
        <SimpleCard>
            <ReportConfigStep 
                index={1}
                stepTitle={'How large of a report?'}
                {...props}
            >
                <div className="flex gap-4 flex-wrap">
                    {visibleViews.map((v) => (
                        <SelectableBadge
                            key={v.value}
                            active={view === v.value}
                            onClick={() => handleChange('view',v.value)}>
                            <div>{capitalizeFirst(v.label)}</div>
                        </SelectableBadge>
                    ))}
                </div>
            </ReportConfigStep>
            {view === 'national' && (
                <ReportConfigStep 
                    index={2}
                    stepTitle={'Do you want a national overview or separate reports for each branch?'}
                    {...props}
                >
                    <div className="flex gap-4 ">
                        <SelectableBadge 
                            active={(separateBranches === false)}
                            onClick={() => handleChange('separateBranches', false)}>
                            {'National overview'}
                        </SelectableBadge>
                        
                        <SelectableBadge 
                            active={(separateBranches === true)}
                            onClick={() => handleChange('separateBranches', true)}>
                            {'Separate reports'}
                        </SelectableBadge>
                    </div>
                </ReportConfigStep>
            )}

            {view === 'branch' && (
                <ReportConfigStep 
                    index={2}
                    stepTitle={'Which Branch?'}
                    {...props}
                >
                    <div className="flex gap-4 ">
                        {branches.map((b) => (
                            <SelectableBadge 
                                key={b.id}
                                active={String(branch?.id) === b.id}
                                onClick={() => handleChange('branch', subObject(b, ['name', 'id']))}>
                                {b.Name}
                            </SelectableBadge>
                        ))}
                    </div>
                </ReportConfigStep>
            )}
                {((view === 'national' && separateBranches) || view === 'branch') && (
                    <ReportConfigStep 
                        index={3}
                        stepTitle={'Do you want a branch overview or separate reports for each agency?'}
                        {...props}
                    >
                        <div className="flex gap-4 ">
                            <SelectableBadge 
                                active={(separateAgencies === false)}
                                onClick={() => handleChange('separateAgencies', false)}>
                                {'Branch overview'}
                            </SelectableBadge>
                            <SelectableBadge 
                                active={(separateAgencies === true)}
                                onClick={() => handleChange('separateAgencies', true)}>
                                {'Separate reports'}
                            </SelectableBadge>
                        </div>
                    </ReportConfigStep>
                )}

            {view === 'agency' && (
                <ReportConfigStep 
                    index={2}
                    stepTitle={'Which Agency?'}
                    {...props}
                >
                    <div className="flex gap-4">
                        <Select 
                            showSearch
                            buttonClassName='w-fit'
                            popupClassName='w-fit'
                            handleChange={(opt) => handleChange('agency',subObject(opt, ['Name', 'id', 'branchID','branchName']))}
                            value={agency}
                            options={agencies}
                            inForm={false}
                            placeholder={'Select Agency'}
                            renderFn={AgencyOption}
                            displayFn={AgencyOption}               
                            IDFn={(opt) => opt.id}
                            valueFn={(opt) => `${opt.branchName} ${opt.Name}`}
                            />
                    </div>
                </ReportConfigStep>
            )}

            {/* <ReportConfigStep 
                index={3}
                stepTitle={'What should be included in the report?'}
                error={errors?.scope}
                {...props}
            >
                <div className="flex flex-col gap-4">
                    <JCheckbox
                        className='border-none font-medium py-0'
                        value={config?.scope?.active === true}
                        handleChange={() => selectScope('active')}
                        label={<Active />}
                    />
                    <JCheckbox
                        className='border-none font-medium py-0'
                        value={config?.scope?.inactive === true}
                        handleChange={() => selectScope('inactive')}
                        label={<Inactive />}
                    />
                </div>
            </ReportConfigStep> */}
            {configValid && (
                <Button
                    variant='default'
                    className='w-64 text-md'
                    onClick={() => setShouldGenerateReport(true)}
                    >
                    Generate Report
                </Button>
            )}
        </SimpleCard>
    );
};

const AgencyOption = (agency) => {
    return  (
        <>
            <span className='font-semibold'>{agency.branchName}</span>
            &nbsp;-&nbsp;
            <span className='whitespace-nowrap'>{agency.Name}</span>
        </>
    )
    
    // `${agency.branchName} ${agency.Name}`
}

// <SelectableBadge 
//     key={agency.id}
//     active={config.agency === agency.id}
//     onClick={() => selectAgency(agency.id)}>
//     {agency.Name}
// </SelectableBadge>

const Inactive = () => (
    <p>Info on inactive users <span className='text-muted-foreground text-sm'>(have not listened to a message in the last 30 days)</span></p>
)
const Active = () => (
    <p>Info on active users <span className='text-muted-foreground text-sm'>(have listened to a message in the last 30 days)</span></p>
)