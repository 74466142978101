import { useEffect } from "react"
import { useRHF } from "../../../hooks/useRHF"
import { useYupValidationResolver } from "../../../hooks/useYupValidationResolver"
import Yup from "../../../wrappers/yup"
import { FormSection } from "../../ui/FormSection"
import { BranchStatus } from "../../../util/constants"
import { ComposedForm } from "../../../wrappers/ui/form/ComposedForm"
import { enumToOptions } from "../../../lib/utils"
import { ComposedFormSelect } from "../../../wrappers/ui/form/ComposedFormSelect"
import { ComposedFormText } from "../../../wrappers/ui/form/ComposedFormText"
import { FieldGroup } from "../formComponents/FieldGroup"
import { FieldRow } from "../formComponents/FieldRow"

const validationSchema = Yup.object({
    managerName: Yup.string().required('required'),
    managerPhone: Yup.string().required('required'),
    managerEmail: Yup.string().required('required'),
    directorName: Yup.string().required('required'),
    directorPhone: Yup.string().required('required'),
    directorEmail: Yup.string().required('required'),
})
export const BranchAdminInfo = ({branchData, hasPrev, sectionIndex}) => {
    const resolver = useYupValidationResolver(validationSchema)
    const form = useRHF({resolver, defaultValues: validationSchema.getDefault()})
    const {register, handleSubmit, formState, setValue, reset, control} = form
    useEffect(loadData, [branchData])
    function loadData() {
        if (!branchData) return
        reset(branchData)
    }
    function onSubmit(data: any) {}
    return (
        <FormSection {...{sectionIndex, title: 'Branch Administrator Info'}}>
            <ComposedForm {...{form, onSubmit}}>
                <FieldRow variant={{width: 'wide'}}>
                    <FieldGroup variant={{width: 'wide'}}>
                        <ComposedFormText
                            variant={'medium'}
                            placeholder="Manager Name"
                            label="Manager Name"
                            name={'managerName'}
                            control={control}/>
                        <ComposedFormText
                            variant={'medium'}
                            placeholder="Manager Phone"
                            label="Manager Phone"
                            name={'managerPhone'}
                            control={control}/>
                        <ComposedFormText
                            variant={'medium'}
                            placeholder="Manager Email"
                            label="Manager Email"
                            name={'managerEmail'}
                            control={control}/>
                    </FieldGroup>
                    <FieldGroup variant={{width: 'wide'}}>
                        <ComposedFormText
                            variant="medium"
                            placeholder="Director Name"
                            label="Director Name"
                            name={'directorName'}
                            control={control}/>
                        <ComposedFormText
                            variant="medium"
                            placeholder="Director Phone"
                            label="Director Phone"
                            name={'directorPhone'}
                            control={control}/>
                        <ComposedFormText
                            variant="medium"
                            placeholder="Director Email"
                            label="Director Email"
                            name={'directorEmail'}
                            control={control}/>
                    </FieldGroup>
                </FieldRow>
            </ComposedForm>
        </FormSection>
    )
}