import { Button } from "@/components/ui/button"
import { Calendar } from "@/components/ui/calendar"
import { Command, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command"
import { Input } from "@/components/ui/input"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { useDebounce } from "@/hooks/useDebounce"
import { cn } from "@/lib/utils"
import { preventDefault } from "@/util/miscUtils"
import { MultiSelect } from "@/wrappers/MultiSelect"
import { CommandEmpty } from "cmdk"
import { format } from "date-fns"
import { Calendar as CalendarIcon, ChevronDown } from "lucide-react"
import React, { useEffect, useRef } from "react"
 

export const Filters = ({ disabled, headers, table }) => {
    const filteredHeaders = headers.filter(h => 
      h.column.getCanFilter() &&
      h.column.getIsVisible()
    )
  
      return (
          <div className={`flex items-start justify-start flex-wrap gap-2
          ${disabled ? 'opacity-40' : '' }`}
          >
          {
            filteredHeaders
                .map((header, idx) => (
                  <Filter disabled={disabled} key={header.id + 'filter'} column={header.column} table={table} />    
            ))
          }
          </div>
      )
}

const Filter = ({table, column, ...props}) => {
    const firstValue = table
    .getPreFilteredRowModel()
    .flatRows
    ?.find(r => r.getValue(column.id) != null)
    ?.getValue(column.id)
  const isDate = column?.columnDef?.meta?.filterType === 'date'
  const columnFilterValue = column.getFilterValue()
  const sortedUniqueValues = React.useMemo(
      () => { 
        switch (typeof firstValue) {
          case 'number':  return []
          case 'object': 
            // This dedupes column values that are arrays
            return Array.isArray(firstValue) 
              ? [...(new Set( 
                  [...Array.from(column.getFacetedUniqueValues().keys())]
                ))] 
              : []
          default: return Array.from(column.getFacetedUniqueValues().keys())?.filter(k => k!=null).sort()
        }
      },
      [column.getFacetedUniqueValues()]
    ) 
    const placeholder = column?.columnDef?.meta?.columnLabel || `${column.id}`  
    if (column.columnDef?.meta?.hiddenFilter != null) return <></>
  return {
    'number': isDate ? (
      <DateFilter {...{columnFilterValue,column, placeholder}} {...props} />
    ) : (
      <NumberFilter {...{columnFilterValue,column, placeholder}} {...props} />
    ),
    'object': 
      Array.isArray(firstValue) 
      ? <ArrayFilter {...{columnFilterValue,column, placeholder, sortedUniqueValues}} {...props} />
      : <></> ,
    'string': <StringFilter {...{columnFilterValue,column, placeholder, sortedUniqueValues}} {...props} />,
  }[typeof firstValue] ?? <></>
}

const FilterMinMaxInput = ({
  value: initialValue,
  onChange,
  className,
  debounce = 500,
  ...props
}) => {
  const [value, setValue] = React.useState(initialValue)
      
        React.useEffect(() => {
          setValue(initialValue)
        }, [initialValue])
      
        React.useEffect(() => {
          const timeout = setTimeout(() => {
            onChange(value)
          }, debounce)
      
          return () => clearTimeout(timeout)
        }, [value])

    return (
      <input 
        value={value} 
        onChange={(e) => setValue(e.target.value)} 
        type="search" 
        className={`
          nospin
          bg-transparent placeholder:select-none  text-[13px] h-12    focus:outline-none  [&::-webkit-search-cancel-button]:hidden placeholder:hover:font-medium bg-base-100
          ${className}`}
        {...props}     
      />
    )
}

const FilterTextInput = ({
    value: initialValue,
    onChange,
    className,
    debounce = 500,
    ...props}) => {
        const [value, setValue] = React.useState(initialValue)
      
        React.useEffect(() => {
          setValue(initialValue)
        }, [initialValue])
      
        React.useEffect(() => {
          const timeout = setTimeout(() => {
            onChange(value)
          }, debounce)
      
          return () => clearTimeout(timeout)
        }, [value])
      
        return (
        <div className="flex mr-2 flex-row justify-between h-full align-middle">
            <input 
              {...props} 
                value={value} 
                  onChange={(e) => setValue(e.target.value)} 
                  type="search" 
                  className={`
                    bg-transparent placeholder:select-none px-5 text-[13px] h-12  w-full  focus:outline-none [&::-webkit-calendar-picker-indicator]:hidden [&::-webkit-search-cancel-button]:hidden placeholder:hover:font-medium bg-base-100
                    ${className} `}/>
            <div className="w-4"/>
            <div className="relative h-full place-self-center  ">
            {
                value.length === 0 ? (
                <ChevronDown className=" pointer-events-none absolute right-4 w-[1.2rem] h-[1.2rem]  bottom-0 -top-2  stroke-accent" />
                ) : (
                    <div className="absolute text-[12px] right-3 w-[1.2rem] h-[1.2rem]  bottom-0 -top-2  text-accent hover:cursor-pointer" onClick={() => setValue('')} >✕</div>
                )
            }
            </div>
        </div>
        )
    //   }
    // return (
    // )
}

const NumberFilter = ({column, columnFilterValue, placeholder, ...props}) => {
  return (
    <>
        {/* <div className="w-full mr-2">
          <div className="indicator-item  border text-xs top-1 border-gray-border rounded-lg px-4  bg-base-100 shadow-sm">
            {column.id}
          </div>
          <FilterMinMaxInput
            {...props}
            type="number"
            min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
            max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
            value={(columnFilterValue)?.[0] ?? ''}
            onChange={value =>
              column.setFilterValue((old) => [value, old?.[1]])
            }
            placeholder={`Min ${
              column.getFacetedMinMaxValues()?.[0]
                ? `(${column.getFacetedMinMaxValues()?.[0]})`
                : ''
            }`}
            className='w-24 pl-5'
          />
        </div>
        <div className="divider px-0 mx-0 divider-horizontal after:w-[1.5px] before:w-[1.5px]"></div>  
        <div className="join-item w-full">
          <FilterMinMaxInput
            {...props}
            type="number"
            min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
            max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
            value={(columnFilterValue)?.[1] ?? ''}
            onChange={value =>
              column.setFilterValue((old) => [old?.[0], value])
            }
            placeholder={`Max ${
              column.getFacetedMinMaxValues()?.[1]
                ? `(${column.getFacetedMinMaxValues()?.[1]})`
                : ''
            }`}
            className='w-24 pl-2'
          />
        </div> */}
    </>
  )
}

const DateFilter = ({column, columnFilterValue, placeholder, ...props}) => {
  // useLayoutEffect(() => {},[columnFilterValue])
  const {to, from} = columnFilterValue ?? {}
  return (
    <div className={cn("grid gap-2")}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={"outline"}
            className={cn(
              "w-[300px] justify-start text-left font-normal",
              !columnFilterValue && "text-muted-foreground"
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {from ? (
              to ? (
                <>
                  {format(from, "LLL dd, y")} -{" "}
                  {format(to, "LLL dd, y")}
                </>
              ) : (
                format(from, "LLL dd, y")
              )
            ) : (
              <span>Filter &apos;{placeholder}&apos;</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            captionLayout="dropdown-buttons"
            initialFocus
            mode="range"
            defaultMonth={from}
            selected={columnFilterValue}
            onSelect={(v) => {column.setFilterValue(v); console.log(v)}}
            numberOfMonths={2}
          />
        </PopoverContent>
      </Popover>
    </div>
  )
}

const ArrayFilter = ({column, sortedUniqueValues, columnFilterValue=[], ...props}) => {
  const {renderFn, tagRenderFn, IDFn} = column.columnDef?.meta ?? {}
  const safeRenderFn = renderFn ?? ((opt) => opt)
  // const safeTagRenderFn = tagRenderFn ?? renderFn ?? ((opt) => opt)
  const safeIDFn = IDFn ?? ((opt) => opt)
  function handleSetSelected(value)  {
    if (typeof value === 'function')  {
      column.setFilterValue(value(columnFilterValue))
    } else {
      // console.log('value',value);
      column.setFilterValue(value)
    }
  }
   return (
    <div >
      <MultiSelect
        {...props}
        inForm={false}
        renderFn={safeRenderFn}
        IDFn={safeIDFn}
        onChange={handleSetSelected}
        selected={columnFilterValue ?? []}
        options={sortedUniqueValues}
      />
    </div>
   )
}


const StringFilter = ({column, sortedUniqueValues, columnFilterValue, placeholder, ...props}) => {
  const inputRef = useRef()
  const containerRef = useRef()
  const [internalTextValue, setInternalTextValue] = React.useState('')
  const [selectedOption, setSelectedOption] = React.useState('-')
  const [open, setOpen] = React.useState(false)
  
  useEffect(handleOpenChange,[open])

  useEffect(syncInternalValueWithFilter,[columnFilterValue])

  useDebounce(prepareFilterValueChange, [internalTextValue], 200)

  function handleOptionSelect(option) {
    column.setFilterValue(option)
    setOpen(false)
  }

  function handleInputKeyDown(e) {
    if(!open) return
    switch(e.key) {
      case 'Tab': 
        e.preventDefault()
      case 'ArrowDown': 
        document.getElementById('command')?.focus()
        break
      default:
        break
    }
  }
  function handleOpenChange() {
    if (!open) return
    setSelectedOption(columnFilterValue || '-')
  }
  function handleCommandItemKeyDown(e) {
    const isFirstFilteredItem = containerRef?.current?.children?.[0]?.children?.[0]?.textContent === selectedOption
    if (isFirstFilteredItem)
    switch (e.key) {
      case 'Tab':
        if (e.shiftKey) {
          inputRef.current?.focus()
          preventDefault(e)
        }
        break
      case 'ArrowUp':
        if (selectedOption === sortedUniqueValues?.[0])
          inputRef.current?.focus()
    }

  }
  function handleSelectionChange(o) {
    // console.log('selection changed');
    setSelectedOption(o)
  }
  function prepareFilterValueChange() {
    if (internalTextValue == null) return
    const transformed = replaceNonAlphaNum(internalTextValue)
    const numRemovedValues = internalTextValue.length - transformed.length
    column.setFilterValue(transformed)
  }
  function syncInternalValueWithFilter() {
    if (columnFilterValue != null && replaceNonAlphaNum(internalTextValue) != columnFilterValue) {
      // Internal text input and actual column filter value are desynchronized. sync them
      setInternalTextValue(columnFilterValue)
    }
  }
  return (
     <div>
        <Popover open={open} onOpenChange={setOpen}>
          <PopoverTrigger asChild>
            <Input
              ref={inputRef}
              {...props}
              // onFocus={() => setOpen(true)}
              onKeyDown={handleInputKeyDown}
              type="text"
              value={(internalTextValue ?? '')}
              onChange={(e) => setInternalTextValue(e.target.value)}
              tabIndex={0}
              placeholder={placeholder}
              className="w-42 nospin "
            />
          </PopoverTrigger>
          <PopoverContent onOpenAutoFocus={preventDefault}>
              <Command id='command' className="focus-within:outline-none" onKeyDown={handleCommandItemKeyDown} value={selectedOption} onValueChange={handleSelectionChange}>
                <CommandEmpty  className="text-muted-foreground text-sm">No results in column</CommandEmpty>
                <CommandInput hide value={columnFilterValue || ''} className="hidden"/>
                <CommandList >
                  <CommandGroup ref={containerRef}>
                    {sortedUniqueValues?.length === 0 && (
                      <span>
                        No results in column
                      </span>
                    )}
                    {sortedUniqueValues.map((value, idx) => value != null && (
                      <CommandItem
                        aria-selected={true}
                        // ref={idx === 0 ? firstRef : undefined}
                        // tabIndex={0}
                        key={value}
                        onSelect={handleOptionSelect}
                        >
                            {/* {console.log('value',value)} */}
                          {value}
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </CommandList>
              </Command>
          </PopoverContent>
        </Popover>
    </div>
  )
}

// Helpers
function replaceNonAlphaNum(input) {
  if (input == null) return
  return input.replace(/([^a-zA-Z0-9])/g, ''); 
}