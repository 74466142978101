
export const MILLISEC = {
  SEC: 1000,
  MIN: 60000,
  MIN_5: 300000,
  MIN_10: 600000,
  MIN_14: 840000,
  MIN_15: 900000,
  MIN_30: 1800000,
  HOUR: 3600000,
  DAY: 86400000,
  WEEK: 604800000,
  MONTH: 2592000000,
  YEAR: 31536000000,
};


export enum ClientGoals {
  employment = 'Employment',
  housing = 'Housing',
  socialServices = 'Social Services',
  healthcare = 'Healthcare',
  increaseFinancialBenefits = 'Increase Financial Benefits',
  safeCommunications = 'Safe Communications', // Domestic violence clients only
  other = 'Other'
}

export enum AgeRange {
  under18 = 'Under 18',
  '18To25' = '18 to 25',
  '46To44' = '46 to 44',
  '45To59' = '45 to 59',
  '60AndUp' = '60 and up',
  unknown = 'Unknown',
  other = 'Other'
}

export enum Race {
  indigenous = 'Indigenous',
  asian = 'Asian',
  black = 'Black',
  pacificIslander = 'Hawaiian or Pacific Islander',
  white = 'White',
  other = 'Other',
  unknown = 'Unknown'
}

export enum ClientMiscResponse {
  noInput='-',
  yes = 'Yes',
  no = 'No',
  dontKnow = 'Don\'t know',
  refused = 'Refused'
}

export enum SuperBool {
  true = 'Yes',
  false = 'No',
  unknown = 'Unknown'
}

export enum DependentsOrFamily {
  yes='Yes',
  noDependents='No dependents other than self',
  unknown='Unknown',
}

export enum primaryDemographics {
  singleAdults='Single Adults',
  youth='Youth',
  familiesWithChildren='Families With Children'
}


export enum CurrentHousing {
  rentedHouseOrApartment = 'Rented House / Apartment',
  permanentSupportHousing = 'Permanent Support Housing',
  transitionalHousing = 'Transitional Housing',
  emergencyShelter = 'Emergency Shelter',
  domesticViolenceShelter = 'Domestic Violence Shelter',
  ownHome = 'Own Home',
  streets = 'On the Streets',
  friendsAndFamily = 'Friends and Family',
  car = 'In a Car',
  otherHomeless = 'Other Homeless',
  workRelease = 'Work Release Program'
}

export enum MiscCharacteristics {
  atRiskOfHomelessness = 'At Risk of Homelessness',
  unemployed = 'Unemployed',
  limitedEnglish = 'Limited English Skills',
  domesticViolenceSurvivor = 'Domestic Violence Survivor',
  fosterCareParticipant = 'Foster Care Participant',
  formerlyIncarcerated = 'Formerly Incarcerated (prison)',
  residentialSchoolSurvivor = 'Residential School Survivor',
  other = 'Other'
}


export enum IncomeSources {
  earned = 'Earned Income',
  unemploymentInsurance = 'Unemployment Insurance',
  supplementalSecurity = 'Supplemental Security Income (SSI)',
  socialSecurityDisability = 'Social Security Disability Income (SSDI)',
  otherDisability = 'Other Disability Income',
  temporaryAssistanceForNeedyFamilies = 'Temporary Assistance for Needy Families (TANF) or equivalent',
  generalAssistanceOrEquivalent = 'General Assistance or Local Equivalent',
  pensionOrRetirement = 'Pension or Retirement Income',
  childSupport = 'Child Support',
  foodStamps = 'Food Stamps',
  veteransBenefits = 'Veteran\'s Financial Benefits',
  other = 'Other Source',
  noFinancialResources = 'No Financial Resources'
}

export enum AgencyStatus {
  active='Active',
  inactive='Inactive',
  pending='Pending'
}

export enum BranchStatus {
  active='Active',
  inactive='Inactive',
  emerging='Emerging',
  pending='Pending'
}

export enum Gender {
  male = 'Male',
  female = 'Female',
  transgender = 'Transgender',
  unknown = 'Unknown'
}

export enum Ethnicity {
  // Add reader-friendly values here
  hispanic = 'Hispanic',
  nonHispanic = 'Non-Hispanic',
  white = 'White',
  black = 'Black',
  asian = 'Asian',
  pacificIslander = 'Hawaiian or Pacific Islander',
  indigenous = 'Indigenous',
  unknown = 'Unknown',
}

// Fake Enums

export enum USAGE {
  active= 'Active',
  inactive= 'Inactive',
}


export const MIN_PIE_HEIGHT = 600;
export const PIE_RADIUS = 85
export const PIE_LEGEND_RADIUS_FACTOR = 30
export const PIE_LEGEND_FONT_FACTOR = 20
export const A4_WIDTH =  793;
export const A4_HEIGHT = 1122;
export const A4_MARGIN = 20;
export const A4_CONTENT_WIDTH = A4_WIDTH - A4_MARGIN * 2;
export const A4_CONTENT_HEIGHT = A4_HEIGHT - A4_MARGIN * 2;
export const pdfItemPadding = 10;


export const REPORT_SCOPE = {
  AGENCY: 'agency',
  BRANCH: 'branch',
  SITEWIDE: 'sitewide'
}

export const CHART_TYPES = {
  PIE: 'pie',
  BAR: 'bar',
  LINE: 'line'
}



export type BasicJetDB = {
  id: string;
  Name: string;
}


export type JetDBEnums = {

  currentHousing: BasicJetDB[],
  miscCharacteristics: BasicJetDB[],
  incomeSources: BasicJetDB[],
  gender: BasicJetDB[],
  identity: BasicJetDB[],
  primaryDemographics: BasicJetDB[],
  ageRange: BasicJetDB[],
  clientGoals: BasicJetDB[],
  miscResponse: BasicJetDB[],
  superBool: BasicJetDB[],
  housingOutcome: BasicJetDB[],
}

export const orange = '#FD5B41'
export const blue = '#3797B7'
export const yesGreen = '#00A795'
export const noRed = '#A73535'
export const unknownGray = '#3B484B'

export const themeStorageKey = 'vite-ui-theme'

export const MICROSERVICE_URL = import.meta.env.VITE_MICROSERVICE_BASE_URL as string
export const ASSETS_URL = import.meta.env.VITE_ASSETS_BASE_URL as string