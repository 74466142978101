import { JCheckbox } from "@/wrappers/ui/JCheckbox";
import { Select } from "@/wrappers/ui/Select";
import { addDays } from "date-fns";
import { useState } from "react";

const today = new Date();
const selectOptions = [
    {
        value: addDays(today, -30).getTime(),
        label: "30 days",
    },
    {
        value: addDays(today, -60).getTime(),
        label: "60 days",
    },
    {
        value: addDays(today, -90).getTime(),
        label: "90 days",
    },
];

export const ShowActiveNumbersControls = ({
    minLastReadTime,
    setMinLastReadTime,
    ...props
}) => {
    const [enabled, setEnabled] = useState(false);
    const [internalMin, setInternalMin] = useState(
        selectOptions[0].value
    );

    function handleToggleEnabled(newV) {
        console.log("newV", newV)
        setEnabled(newV);
        if (newV) setMinLastReadTime(internalMin);
        else setMinLastReadTime(null);
    }
    function handleChange(value) {
        setInternalMin(value);
        setMinLastReadTime(value);
    }
    return (
        <JCheckbox
            className='pr-3 pl-4 py-1 h-10 bg-background'
            value={enabled}
            handleChange={handleToggleEnabled}
            label="Show active within"
        >
            <Select
                inForm={false}
                valueIsID
                buttonClassName="w-[8rem] h-7 text-sm"
                popupClassName="w-[8rem]"
                options={selectOptions}
                value={minLastReadTime}
                handleChange={({ value }) => handleChange(value)}
                renderFn={(opt) => opt.label}
                IDFn={(opt) => opt.value}
                disabled={!enabled}
                showSearch={false}
                placeholder="30 days"
            />
        </JCheckbox>
    );
};
