import { capitalizeFirst } from '@/util/textUtils';
import {AnimatePresence, motion} from 'framer-motion';
import React, { useEffect, useMemo, useRef } from 'react';


export type animationDirection = 'vertical' | 'horizontal'

type AnimateConditionalRenderProps = {
    mode?: 'sync' | 'async' | 'popLayout',
    direction?: animationDirection,
    children: React.ReactNode,
    [key: string]: any
}
export const AnimateConditionalRender = ({mode='sync', children, direction='vertical', ...props}: AnimateConditionalRenderProps) => {
    const ref = useRef()
    const hasLoadedRef = useRef(false)
    useEffect(handleMount,[])
    const numRenderableChildren = useMemo(countRenderableChildren,[children])
    const visibleVariant = useMemo(() => `${direction}Visible`,[direction])
    const hiddenVariant = useMemo(() => `${direction}Hidden`,[direction])
    function handleMount() {
        if (hasLoadedRef.current) return
        hasLoadedRef.current = true
    }
    function countRenderableChildren() {
        if (!children) return 0
        if (!Array.isArray(children)) return 1
        return children?.filter(child => !!child).length
    }

    
    return (
        <AnimatePresence presenceAffectsLayout mode={mode}>
                { numRenderableChildren > 0 && (
                    <motion.div  
                        layout 
                        variants={variants}
                        initial={hasLoadedRef ? hiddenVariant : true}
                        animate={visibleVariant}
                        exit={hiddenVariant}
                        className='overflow-clip whitespace-nowrap p-[2.5px]'
                        {...props}
                        >
                        {children}
                    </motion.div>
                )}
        </AnimatePresence>
    );
};





const variants = {
    verticalHidden: {
        opacity: 0,
        height: 0,
    },
    verticalVisible: {
        opacity: 1,
        height: 'auto',
    },
    horizontalHidden: {
        opacity: 0,
        width: 0,
    },
    horizontalVisible: {
        opacity: 1,
        width: 'auto',
    },
}