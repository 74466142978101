import { useMutation, useQueryClient } from '@tanstack/react-query';
import { MILLISEC } from "@/util/constants";
import { ROUTE_IDS } from '@/api/routeIDs';
import { postPromise } from '@/api/promises';
import { convertObjFieldsToString } from '@/util/apiUtils';

export const modAgency = (params) => {
    params.type = ROUTE_IDS.MOD_AGENCY
    return postPromise(import.meta.env.VITE_API_BASE_URL, convertObjFieldsToString(params), {json: true})
}

export default function useModAgency(props) {
    const {onSuccess, ...otherProps } = props || {}
    const client = useQueryClient();
    const mutation = useMutation({
        mutationKey: [ROUTE_IDS.MOD_AGENCY],
        mutationFn: modAgency,
        staleTime: MILLISEC.MIN_15,
        onSuccess: (data: any, req) => {
            if (data?.status?.includes?.('not')) throw new Error(data.status)
            // client.invalidateQueries({queryKey: ['getAgencies']})
            onSuccess && onSuccess(data, req)
        },
        ...otherProps
    });
    return mutation;
}