import { ResizableHandle, ResizablePanel, ResizablePanelGroup } from "@/components/ui/resizable";
import { MIN_PIE_HEIGHT } from "@/util/constants";

export const ChartContainer = (props) => {
    return (
        <ResizablePanelGroup className={`rounded-lg border shadow-md max-h-[35rem] w-full max-w-full`} style={{minHeight: MIN_PIE_HEIGHT + 'px',}} direction="vertical">
            <ResizablePanel size="auto" defaultSize={0}/>
            <ResizableHandle/>
            <ResizablePanel size="auto">
                <ResizablePanelGroup direction="horizontal">
                    <ResizablePanel size="auto" defaultSize={0}>
                    </ResizablePanel>

                    <ResizableHandle/>

                    <ResizablePanel size="auto" className="">
                        <div className='relative w-full h-full overflow-visible' {...props}/>
                    </ResizablePanel>

                    <ResizableHandle/>

                    <ResizablePanel size="auto" defaultSize={0}>
                    </ResizablePanel>
                </ResizablePanelGroup>
            </ResizablePanel>
            <ResizableHandle/>
            <ResizablePanel defaultSize={0} size={0}/>
        </ResizablePanelGroup>
        

    );
};
