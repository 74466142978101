import { postPromise } from "@/api/promises"
import { ROUTE_IDS } from "@/api/routeIDs"
import { composeFormData, convertObjFieldsToString } from "@/util/apiUtils"
import { MICROSERVICE_URL } from "@/util/constants"
import { deleteAllCookies } from "@/util/cookieUtils"
import { consumePresignedUrl } from "@/util/queryUtils"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import axios from "axios"

type createFAQParams = {
    title: string,
    file: File
}
export const createFAQ = async (params: createFAQParams) => {
    const {file, title} = params
    const creationResult = await postPromise(`${MICROSERVICE_URL}/faq`, {title}, {json: true})

    const {presignedUrl} = creationResult
    const tempApi = axios.create()
    return consumePresignedUrl(tempApi, file, file.type, presignedUrl)
}

export const useCreateFAQ = ({onSuccess, ...otherProps}) => {
    const client = useQueryClient();
    const mutation = useMutation({
        mutationKey: ['faq', 'create'],
        mutationFn: createFAQ,
        onSuccess: (data, req) => {
            client.invalidateQueries({queryKey: ['faq']})
                .then(() => onSuccess && onSuccess(data, req))
        },
    ...otherProps})    
    return mutation;
}