import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "@/components/ui/accordion";
import { cn } from "@/lib/utils";
import { UsageReportConfig } from "@/routes/Main/Reporting/Presets/UsageReport";
import { UsageReportData } from "@/routes/Main/Reporting/Presets/UsageReport/useUsageReportData";
import { CancelExportButton } from "@/routes/Main/Reporting/components/CancelExportButton";
import { ExportButton } from "@/routes/Main/Reporting/components/ExportButton";
import { ReportLoader } from "@/routes/Main/Reporting/components/ReportLoader";
import { TextSearchBar } from "@/wrappers/ui/TextSearchBar";
import fuzzysort, { cleanup } from "fuzzysort";
import { set } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";

type NationalReportProps = {
    data: UsageReportData;
    isPending?: boolean;
    config: UsageReportConfig;
    AgencyReportComponent: (any) => JSX.Element;
    BranchReportComponent: (any) => JSX.Element;
    NationalReportComponent: (any) => JSX.Element;
};

export const NationalReport = (props: NationalReportProps) => {
    const {
        config,
        data,
        isPending,
        AgencyReportComponent,
        BranchReportComponent,
        NationalReportComponent,
        ...rest
    } = props || {};
    const { agencies, branches } = data || {};

    const containerRef = useRef(null);
    const [search, setSearch] = useState("");
    const [filteredAgencies, setFilteredAgencies] = useState(agencies || []);
    const [isExporting, setIsExporting] = useState(false);
    const [exportingIndex, setExportingIndex] = useState(0);
    const preparedAgencies = useMemo(prepareAgenciesForFilter, [agencies]);

    useEffect(scrollToContainer, [isPending, containerRef.current]);
    useEffect(filterAgencies, [
        search,
        config.separateAgencies,
        config.separateBranches,
        preparedAgencies,
    ]);

    function sectionDidFinishExport(idx) {
        let numExports = 0
        if (config.separateBranches) {
            if (config.separateAgencies) {
                numExports = agencies.length
            } else {
                numExports = branches.length
            }
        } else {
            return cleanupExport()
        }
        if (idx === exportingIndex) {
            if (idx + 1 < numExports) {
                setExportingIndex(idx + 1)
            } else {
                cleanupExport()
            }
        }
    }

    function cleanupExport() {
        setIsExporting(false)
        setExportingIndex(0)
    }

    function scrollToContainer() {
        if (containerRef.current && !isPending) {
            containerRef.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
                inline: "nearest",
            });
        }
    }
    function prepareAgenciesForFilter() {
        if (!agencies) return [];
        const clone = structuredClone(agencies);
        clone.forEach((a) => {
            (a as any).searchKey = fuzzysort.prepare(
                a.branchname + " " + a.Name
            );
            // console.log('after',a);
        });
        return clone;
    }
    function filterAgencies() {
        if (search === "") return setFilteredAgencies(agencies);
        const res = fuzzysort.go(search, preparedAgencies, {
            key: "searchKey",
        });
        setFilteredAgencies(res.map((r) => r.obj));
    }
    return (
        <div ref={containerRef} className={cn(isPending && "skeletonify")}>
            <ReportLoader isPending={isPending} />
            {config.separateBranches ? (
                <>
                    {config.separateAgencies ? (
                        <>
                            <div className="flex gap-2">
                                <ExportButton {...{isExporting, setIsExporting,isPending}}>Export All Agencies</ExportButton>
                                <CancelExportButton isExporting={isExporting} onClick={cleanupExport}/>
                            </div>
                            <TextSearchBar
                                placeholder="Filter agencies ..."
                                className={"skelechild !w-[30rem] !h-[3rem] mt-2"}
                                value={search}
                                onChange={setSearch}
                            />
                            <Accordion type="single" collapsible value={isExporting ? `${exportingIndex}` : undefined} >
                                {filteredAgencies?.map?.((a,idx) => (
                                    <AccordionItem
                                        key={a.id}
                                        value={`${idx}`}
                                        className="bg-background p-2 mt-2 rounded-2xl border"
                                    >
                                        <AccordionTrigger>
                                            <span className="ml-2 font-semibold mr-1">
                                                {a.branchname}
                                            </span>
                                            - {a.Name || a.name}
                                        </AccordionTrigger>
                                        <AccordionContent className="bg-muted/40 p-2 rounded-2xl border">
                                            <AgencyReportComponent
                                                {...props}
                                                onExportFinish={() => sectionDidFinishExport(idx)}
                                                isExporting={isExporting && exportingIndex === idx}
                                                agency={a}
                                            />
                                            {/* <AgencyReportContent {...props} agency={a} />             */}
                                        </AccordionContent>
                                    </AccordionItem>
                                ))}
                            </Accordion>
                        </>
                    ) : (
                        <>
                            <ExportButton {...{isExporting, setIsExporting,isPending}}>Export All Branches</ExportButton>
                            <Accordion type="single" collapsible value={isExporting ? `${exportingIndex}` : undefined}>
                                {branches?.map?.((b,idx) => (
                                    <AccordionItem
                                        key={b.id}
                                        value={`${idx}`}
                                        className="bg-background p-2 mt-2 rounded-2xl border"
                                    >
                                        <AccordionTrigger className="ml-2">
                                            {b.Name || b.name}
                                        </AccordionTrigger>
                                        <AccordionContent className="bg-muted/40 p-2 rounded-2xl border">
                                            <BranchReportComponent
                                                {...props}
                                                onExportFinish={() => sectionDidFinishExport(idx)}
                                                isExporting={isExporting && exportingIndex === idx}
                                                branch={b}
                                            />
                                            {/* <BranchReportContent {...props} branch={b} />       */}
                                        </AccordionContent>
                                    </AccordionItem>
                                ))}
                            </Accordion>
                        </>
                    )}
                </>
            ) : (
                <>
                    <NationalReportComponent onExportFinish={sectionDidFinishExport} {...props} />
                </>
            )}
        </div>
    );
};
