import { DateCell } from "@/components/tables/cells/DateCell";
import { LinkedClientCell } from "@/components/tables/cells/LinkedClientCell";
import { dateFilterFn } from "@/components/tables/helpers/filterFns";
import { cn } from "@/lib/utils";
import { nullNum } from "@/util/miscUtils";
import { formatPhone } from "@/util/textUtils";
import { createColumnHelper, filterFns } from "@tanstack/react-table";

const columnHelper = createColumnHelper();

const stringFilter = filterFns.includesString;
const numRangeFilter = filterFns.inNumberRange
const multiSelectFilter = filterFns.arrIncludesSome
const strictStringFilter = filterFns.includesString.autoRemove

export const allClientFieldsCols = [
    columnHelper.accessor((row) => row.Name, {
        id: "Name",
        size: 100,
        filterFn: stringFilter,
        cell: (info) => (
            <div className="px-2">
                <LinkedClientCell row={info.row.original} />
            </div>
        ),
        header: (info) => <span className="pl-4 pr-2 text-sm">Name</span>,
    }),
    columnHelper.accessor((row) => row.alias, {
        id: "Phone Number",
        size: 100,
        filterFn: stringFilter,
        cell: (info) => <span className="whitespace-nowrap ">{formatPhone(info.getValue())}</span>,
        header: (info) => <span className="whitespace-nowrap text-sm">Phone Number</span>,
    }),
    columnHelper.accessor((row) => nullNum(row.recentReadMsgTime) , {
        id: "Last Read",
        size: 100,
        filterFn: dateFilterFn,
        sortUndefined: 'last',
        meta: {
            filterType: 'date',
        },
        cell: ({row, getValue}) => <span className='px-2 whitespace-nowrap text-md font-semibold'>{<DateCell concise value={getValue()}/>}</span>,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>Last Listened Msg</span>,
    }),
    // The field names are backwards
    columnHelper.accessor((row) => nullNum(row.firstMsgTime) , {
        id: "First received",
        size: 100,
        filterFn: dateFilterFn,
        meta: {
            filterType: 'date',
        },
        cell: ({row, getValue}) => <span className='px-2 whitespace-nowrap'>{<DateCell concise value={getValue()}/>}</span>,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>First Received Msg</span>,
    }),
    // The field names are backwards
    columnHelper.accessor((row) => nullNum(row.lastMsgTime) , {
        id: "Last Received",
        size: 100,
        filterFn: dateFilterFn,
        meta: {
            filterType: 'date',
        },
        cell: ({row, getValue}) => <span className='px-2 whitespace-nowrap'>{<DateCell concise value={getValue()}/>}</span>,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>Last Received Msg</span>,
    }),
    columnHelper.accessor((row) => nullNum(row.unreadMsgs), {
        id: "Unread",
        size: 100,
        filterFn: numRangeFilter,
        cell: (info) => <span className='px-2'>{info.getValue()}</span>,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>Unheard Msgs</span>,
    }),
    columnHelper.accessor((row) => nullNum(row.readMsgs), {
        id: "Read",
        size: 100,
        filterFn: numRangeFilter,
        cell: (info) => <span className='px-2'>{info.getValue()}</span>,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>Listened Msgs</span>,
    }),
    columnHelper.accessor((row) => nullNum(row.totalMsgs), {
        id: "Total",
        size: 100,
        filterFn: numRangeFilter,
        cell: (info) => <span className='px-2'>{info.getValue()}</span>,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>Total Msgs</span>,
    }),
    columnHelper.accessor((row) => row.dateEnrolled, {
        id: "Date Enrolled",
        size: 100,
        cell: (info) => <DateCell className='px-2 whitespace-nowrap text-xs' value={info.getValue()} concise/>,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>Date Enrolled</span>,
    }),
    columnHelper.accessor((row) => row.dateUsageEnded, {
        id: "Date Usage Ended",
        size: 100,
        cell: (info) => <DateCell className='px-2 whitespace-nowrap text-xs' value={info.getValue()} concise/>,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>Date Usage Ended</span>,
    }),
    columnHelper.accessor((row) => row.socialPurposeHousingPartner, {
        id: "Social Purpose Housing Partner",
        size: 100,
        cell: (info) => <div className={cn('px-2 whitespace-nowrap text-xs', [null,'',undefined]?.includes(info.getValue())  && '!bg-muted w-full h-full flex justify-start items-center')}>{info.getValue()}</div>,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>Social Purpose Housing Partner</span>,
    }),
    columnHelper.accessor((row) => row.foundEmployment, {
        id: "Found Employment",
        size: 100,
        cell: (info) => <div className={cn('px-2 whitespace-nowrap text-xs', ([null,'',undefined]?.includes(info.getValue()) || info.getValue()?.length == 0) && '!bg-muted w-full h-full flex justify-start items-center')}>{info.getValue()}</div>,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>Found Employment</span>,
    }),
    columnHelper.accessor((row) => row.gotHousing, {
        id: "Got Housing",
        size: 100,
        cell: (info) => <div className={cn('px-2 whitespace-nowrap text-xs', ([null,'',undefined]?.includes(info.getValue()) || info.getValue()?.length == 0)  && '!bg-muted w-full h-full flex justify-start items-center')}>{info.getValue()}</div>,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>Got Housing</span>,
    }),
    columnHelper.accessor((row) => row.accessedSocialServices, {
        id: "Accessed Social Services",
        size: 100,
        cell: (info) => <div className={cn('px-2 whitespace-nowrap text-xs', ([null,'',undefined]?.includes(info.getValue()) || info.getValue()?.length == 0)  && '!bg-muted w-full h-full flex justify-start items-center')}>{info.getValue()}</div>,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>Accessed Social Services</span>,
    }),
    columnHelper.accessor((row) => row.usedforHealthCare, {
        id: "Used for Health Care",
        size: 100,
        cell: (info) => <div className={cn('px-2 whitespace-nowrap text-xs', ([null,'',undefined]?.includes(info.getValue()) || info.getValue()?.length == 0)  && '!bg-muted w-full h-full flex justify-start items-center')}>{info.getValue()}</div>,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>Used for Health Care</span>,
    }),
    columnHelper.accessor((row) => row.increasedFinancialBenefits, {
        id: "Increased Financial Benefits",
        size: 100,
        cell: (info) => <div className={cn('px-2 whitespace-nowrap text-xs', ([null,'',undefined]?.includes(info.getValue()) || info.getValue()?.length == 0)  && '!bg-muted w-full h-full flex justify-start items-center')}>{info.getValue()}</div>,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>Increased Financial Benefits</span>,
    }),
    columnHelper.accessor((row) => row.achievedSafeCommunication, {
        id: "Achieved Safe Communication",
        size: 100,
        cell: (info) => <div className={cn('px-2 whitespace-nowrap text-xs', ([null,'',undefined]?.includes(info.getValue()) || info.getValue()?.length == 0)  && '!bg-muted w-full h-full flex justify-start items-center')}>{info.getValue()}</div>,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>Achieved Safe Communication</span>,
    }),
    columnHelper.accessor((row) => row.achievedCommunicationwithFriendsandFamily, {
        id: "Achieved Communication with Friends and Family",
        size: 100,
        cell: (info) => <div className={cn('px-2 whitespace-nowrap text-xs', ([null,'',undefined]?.includes(info.getValue()) || info.getValue()?.length == 0)  && '!bg-muted w-full h-full flex justify-start items-center')}>{info.getValue()}</div>,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>Achieved Communication with Friends and Family</span>,
    }),
    columnHelper.accessor((row) => row.accessedLegalJusticeSystem, {
        id: "Accessed Legal Justice System",
        size: 100,
        cell: (info) => <div className={cn('px-2 whitespace-nowrap text-xs', ([null,'',undefined]?.includes(info.getValue()) || info.getValue()?.length == 0)  && '!bg-muted w-full h-full flex justify-start items-center')}>{info.getValue()}</div>,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>Accessed Legal Justice System</span>,
    }),
    columnHelper.accessor((row) => row.maintainedCommunityConnections, {
        id: "Maintained Community Connections",
        size: 100,
        cell: (info) => <div className={cn('px-2 whitespace-nowrap text-xs', ([null,'',undefined]?.includes(info.getValue()) || info.getValue()?.length == 0)  && '!bg-muted w-full h-full flex justify-start items-center')}>{info.getValue()}</div>,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>Maintained Community Connections</span>,
    }),
    columnHelper.accessor((row) => row.usedforOtherReasons, {
        id: "Other (custom) Goal Was Met",
        size: 100,
        cell: (info) => <div className={cn('px-2 whitespace-nowrap text-xs', ([null,'',undefined]?.includes(info.getValue()) || info.getValue()?.length == 0)  && '!bg-muted w-full h-full flex justify-start items-center')}>{info.getValue()}</div>,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>Other (custom) Goal Was Met</span>,
    }),
    columnHelper.accessor((row) => row.otherGoalsOutcome, {
        id: "Description of Other Goal Outcome",
        size: 100,
        cell: (info) => <div className={cn('px-2 whitespace-nowrap text-xs', [null,'',undefined]?.includes(info.getValue())  && '!bg-muted w-full h-full flex justify-start items-center')}>{info.getValue()}</div>,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>Description of Other Goal Outcome</span>,
    }),
    columnHelper.accessor((row) => row.otherCVMUses, {
        id: "Other Ways CVM Was Used",
        size: 100,
        cell: (info) => <div className={cn('px-2 whitespace-nowrap text-xs', [null,'',undefined]?.includes(info.getValue())  && '!bg-muted w-full h-full flex justify-start items-center')}>{info.getValue()}</div>,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>Other Ways CVM Was Used</span>,
    }),
    columnHelper.accessor((row) => row.housingOutcome, {
        id: "Housing Outcome",
        size: 100,
        cell: (info) => <div className={cn('px-2 whitespace-nowrap text-xs', ([null,'',undefined]?.includes(info.getValue()) || info.getValue()?.length == 0)  && '!bg-muted w-full h-full flex justify-start items-center')}>{info.getValue()}</div>,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>Housing Outcome</span>,
    }),
    columnHelper.accessor((row) => row.financialResource, {
        id: "Financial Resource",
        size: 100,
        cell: (info) => <div className={cn('px-2 whitespace-nowrap text-xs', ([null,'',undefined]?.includes(info.getValue()) || info.getValue()?.length == 0)  && '!bg-muted w-full h-full flex justify-start items-center')}>{info.getValue()}</div>,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>Financial Resource</span>,
    }),
    columnHelper.accessor((row) => row.supportiveHousingPartner, {
        id: "Supportive Housing Partner",
        size: 100,
        cell: (info) => <div className={cn('px-2 whitespace-nowrap text-xs', [null,'',undefined]?.includes(info.getValue())  && '!bg-muted w-full h-full flex justify-start items-center')}>{info.getValue()}</div>,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>Supportive Housing Partner</span>,
    }),
    columnHelper.accessor((row) => row.firstName, {
        id: "First Name",
        size: 100,
        cell: (info) => <div className={cn('px-2 whitespace-nowrap text-xs', [null,'',undefined]?.includes(info.getValue())  && '!bg-muted w-full h-full flex justify-start items-center')}>{info.getValue()}</div>,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>First Name</span>,
    }),
    columnHelper.accessor((row) => row.middleName, {
        id: "Middle Name",
        size: 100,
        cell: (info) => <div className={cn('px-2 whitespace-nowrap text-xs', [null,'',undefined]?.includes(info.getValue())  && '!bg-muted w-full h-full flex justify-start items-center')}>{info.getValue()}</div>,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>Middle Name</span>,
    }),
    columnHelper.accessor((row) => row.lastName, {
        id: "Last Name",
        size: 100,
        cell: (info) => <div className={cn('px-2 whitespace-nowrap text-xs', [null,'',undefined]?.includes(info.getValue())  && '!bg-muted w-full h-full flex justify-start items-center')}>{info.getValue()}</div>,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>Last Name</span>,
    }),
    columnHelper.accessor((row) => row.email, {
        id: "Email",
        size: 100,
        cell: (info) => <div className={cn('px-2 whitespace-nowrap text-xs', [null,'',undefined]?.includes(info.getValue())  && '!bg-muted w-full h-full flex justify-start items-center')}>{info.getValue()}</div>,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>Email</span>,
    }),
    columnHelper.accessor((row) => row.clientPassword, {
        id: "Client Password",
        size: 100,
        cell: (info) => <div className={cn('px-2 whitespace-nowrap text-xs', [null,'',undefined]?.includes(info.getValue())  && '!bg-muted w-full h-full flex justify-start items-center')}>{info.getValue()}</div>,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>Client Password</span>,
    }),
    columnHelper.accessor((row) => row.hasCellPhone, {
        id: "Has Cell Phone",
        size: 100,
        cell: (info) => <div className={cn('px-2 whitespace-nowrap text-xs', [null,'',undefined]?.includes(info.getValue())  && '!bg-muted w-full h-full flex justify-start items-center')}>{info.getValue()}</div>,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>Has Cell Phone</span>,
    }),
    columnHelper.accessor((row) => row.cellNumber, {
        id: "Cell Number",
        size: 100,
        cell: (info) => <div className={cn('px-2 whitespace-nowrap text-xs', [null,'',undefined]?.includes(info.getValue())  && '!bg-muted w-full h-full flex justify-start items-center')}>{info.getValue()}</div>,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>Cell Number</span>,
    }),
    columnHelper.accessor((row) => row.staffThatEnrolled, {
        id: "Staff That Enrolled",
        size: 100,
        cell: (info) => <div className={cn('px-2 whitespace-nowrap text-xs', [null,'',undefined]?.includes(info.getValue())  && '!bg-muted w-full h-full flex justify-start items-center')}>{info.getValue()}</div>,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>Staff That Enrolled</span>,
    }),
    columnHelper.accessor((row) => row.comment, {
        id: "Notes",
        size: 100,
        cell: (info) => <div className={cn('px-2 whitespace-nowrap text-xs', [null,'',undefined]?.includes(info.getValue())  && '!bg-muted w-full h-full flex justify-start items-center')}>{info.getValue()}</div>,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>Notes</span>,
    }),
    columnHelper.accessor((row) => row.ageRange, {
        id: "Age Range",
        size: 100,
        cell: (info) => <div className={cn('px-2 whitespace-nowrap text-xs', [null,'',undefined]?.includes(info.getValue())  && '!bg-muted w-full h-full flex justify-start items-center')}>{info.getValue()}</div>,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>Age Range</span>,
    }),
    columnHelper.accessor((row) => row.gender, {
        id: "Gender",
        size: 100,
        cell: (info) => <div className={cn('px-2 whitespace-nowrap text-xs', [null,'',undefined]?.includes(info.getValue())  && '!bg-muted w-full h-full flex justify-start items-center')}>{info.getValue()}</div>,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>Gender</span>,
    }),
    columnHelper.accessor((row) => row.otherGender, {
        id: "Other Gender Description",
        size: 100,
        cell: (info) => <div className={cn('px-2 whitespace-nowrap text-xs', [null,'',undefined]?.includes(info.getValue())  && '!bg-muted w-full h-full flex justify-start items-center')}>{info.getValue()}</div>,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>Other Gender Description</span>,
    }),
    columnHelper.accessor((row) => row.currentHousing, {
        id: "Current Housing",
        size: 100,
        cell: (info) => <ArrayCell value={info.getValue()} />,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>Current Housing</span>,
    }),
    columnHelper.accessor((row) => row.otherCurrentHousing, {
        id: "Other Current Housing",
        size: 100,
        cell: (info) => <div className={cn('px-2 whitespace-nowrap text-xs', [null,'',undefined]?.includes(info.getValue())  && '!bg-muted w-full h-full flex justify-start items-center')}>{info.getValue()}</div>,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>Other Current Housing</span>,
    }),
    columnHelper.accessor((row) => row.frequentShelterUse, {
        id: "Frequent Shelter Use",
        size: 100,
        cell: (info) => <div className={cn('px-2 whitespace-nowrap text-xs', [null,'',undefined]?.includes(info.getValue())  && '!bg-muted w-full h-full flex justify-start items-center')}>{info.getValue()}</div>,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>Frequent Shelter Use</span>,
    }),
    columnHelper.accessor((row) => row.miscCharacteristics, {
        id: "Personal Stories",
        size: 100,
        cell: (info) => <ArrayCell value={info.getValue()} />,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>Personal Stories</span>,
    }),
    columnHelper.accessor((row) => row.otherMiscCharacteristics, {
        id: "Other Personal Stories",
        size: 100,
        cell: (info) => <div className={cn('px-2 whitespace-nowrap text-xs', [null,'',undefined]?.includes(info.getValue())  && '!bg-muted w-full h-full flex justify-start items-center')}>{info.getValue()}</div>,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>Other Personal Stories</span>,
    }),
    columnHelper.accessor((row) => row.identity, {
        id: "Identity",
        size: 100,
        cell: (info) => <ArrayCell value={info.getValue()} />,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>Identity</span>,
    }),
    columnHelper.accessor((row) => row.hasStudentVisa, {
        id: "Has Student Visa",
        size: 100,
        cell: (info) => <div className={cn('px-2 whitespace-nowrap text-xs', [null,'',undefined]?.includes(info.getValue())  && '!bg-muted w-full h-full flex justify-start items-center')}>{info.getValue()}</div>,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>Has Student Visa</span>,
    }),
    columnHelper.accessor((row) => row.isRefugee, {
        id: "Is Refugee",
        size: 100,
        cell: (info) => <div className={cn('px-2 whitespace-nowrap text-xs', [null,'',undefined]?.includes(info.getValue())  && '!bg-muted w-full h-full flex justify-start items-center')}>{info.getValue()}</div>,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>Is Refugee</span>,
    }),
    columnHelper.accessor((row) => row.otherIdentity, {
        id: "Other Identity",
        size: 100,
        cell: (info) => <div className={cn('px-2 whitespace-nowrap text-xs', [null,'',undefined]?.includes(info.getValue())  && '!bg-muted w-full h-full flex justify-start items-center')}>{info.getValue()}</div>,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>Other Identity</span>,
    }),
    columnHelper.accessor((row) => row.hasStatusCard, {
        id: "Has Status Card",
        size: 100,
        cell: (info) => <div className={cn('px-2 whitespace-nowrap text-xs', [null,'',undefined]?.includes(info.getValue())  && '!bg-muted w-full h-full flex justify-start items-center')}>{info.getValue()}</div>,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>Has Status Card</span>,
    }),
    columnHelper.accessor((row) => row.clientGoals, {
        id: "Client Goals",
        size: 100,
        cell: (info) => <ArrayCell value={info.getValue()} />,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>Client Goals</span>,
    }),
    columnHelper.accessor((row) => row.otherGoals, {
        id: "Other Goals",
        size: 100,
        cell: (info) => <div className={cn('px-2 whitespace-nowrap text-xs', [null,'',undefined]?.includes(info.getValue())  && '!bg-muted w-full h-full flex justify-start items-center')}>{info.getValue()}</div>,
        header: (info) => <span className='whitespace-nowrap text-xs px-2'>Other Goals</span>,
    }),
]

const ArrayCell = ({value}) => {
    if ([null,'',undefined]?.includes(value) || value?.length === 0) return <div className="px-2 whitespace-nowrap text-xs flex justify-start items-center bg-muted"></div>
    return (
        <div className="px-2 flex flex-col">
            {value.map((v, i) => {
                return <div className="whitespace-nowrap text-xs" key={i}>- {v}</div>
            })}
        </div>
    )
}