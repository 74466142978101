import { Demographics } from "@/components/forms/ClientForm/Demographics";
import { Goals } from "@/components/forms/ClientForm/Goals";
import { Outcomes } from "@/components/forms/ClientForm/Outcomes";
import { ParticipantInformation } from "@/components/forms/ClientForm/ParticipantInformation";
import { Accordion } from "@radix-ui/react-accordion";

import { BackToTop } from "@/components/misc/BackToTop";
import { DateCell } from "@/components/tables/cells/DateCell";
import { Button } from "@/components/ui/button";
import { AgeRange, BasicJetDB, Gender, JetDBEnums } from "@/util/constants";
import { nullNum } from "@/util/miscUtils";
import { formatPhone } from "@/util/textUtils";
import { ComposedForm } from "@/wrappers/ui/form/ComposedForm";
import { UseQueryResult } from "@tanstack/react-query";
import { CustomUseFormReturn } from "@/hooks/useRHF";
import { Separator } from "@/components/ui/separator";
import { Circle, Dot } from "lucide-react";
import { FieldGroup } from "@/components/forms/formComponents/FieldGroup";
import { useState } from "react";
import { fieldNameSections } from "@/routes/Main/Clients/ClientDetails";

// Demo

export type DemographicsData = {
    ageRange: AgeRange;
    gender: Gender;
    // ethnicity: Ethnicity,
    // race: Set<Race>,
    currentHousing?: BasicJetDB[];
    identity: BasicJetDB[];
    frequentShelterUse: boolean;
    hasStatusCard: boolean;
    hasStudentVisa: boolean;
    isRefugee: boolean;
    miscCharacteristics: BasicJetDB[];
    indigenousType: BasicJetDB[];
    housingOutcome: BasicJetDB[];
    financialResource: BasicJetDB[];

    otherGoals: string;
    otherGoalsOutcome: string;
    otherGender: string;
    otherCurrentHousing: string;
    otherIdentity: string;
    otherMiscCharacteristics: string;
};

export type ClientFormProps = {
    form: CustomUseFormReturn;
    enumsQuery: UseQueryResult<JetDBEnums>;
    clientData: any;
    onSubmit: any;
};

export const ClientForm = ({
    form,
    enumsQuery,
    clientData,
    onSubmit,
    ...props
} : ClientFormProps) => {
    const {
        firstName,
        lastName,
        alias,
        display,
        lastMsgTime,
        recentReadMsgTime,
        readMsgs,
        unreadMsgs,
        deleted,
        dateEnrolled } = clientData || {};
    const [openSections, setOpenSections] = useState([]);
    console.log("main form values", form.getValues(), 'err',form.formState.errors);
    console.log('activeelement', document.activeElement);
    function handleSubmit(...args) {
        console.log('submitting form', args);
        onSubmit(...args);
    }
    return (
        <ComposedForm {...{ form, onSubmit:handleSubmit }} className="flex gap-4">
            <div className="bg-background rounded-xl px-4 border flex-4">
                <BackToTop />
                <Accordion value={openSections} onValueChange={setOpenSections} type="multiple" className="w-full" >
                    <Outcomes
                        form={form}
                        enumsQuery={enumsQuery}
                        clientData={clientData}
                        sectionIndex={1}
                    />
                    <ParticipantInformation
                        form={form}
                        enumsQuery={enumsQuery}
                        clientData={clientData}
                        sectionIndex={2}
                    />
                    <Demographics
                        form={form}
                        enumsQuery={enumsQuery}
                        clientData={clientData}
                        sectionIndex={3}
                    />
                    {/* <GoalsAndOutcomes enumsQuery={enumsQuery} clientData={clientData} sectionIndex={4}/> */}
                    <Goals
                        form={form}
                        enumsQuery={enumsQuery}
                        clientData={clientData}
                        sectionIndex={4}
                    />
                </Accordion>
            </div>
            <div className="bg-background flex-1 rounded-xl sticky top-5 border  h-fit min-w-[20rem]">
                <div className="p-4 flex flex-col gap-4">
                        <p className="place-self-center leading-tight text-lg  font-medium">
                            {formatPhone(alias)}
                        </p>
                        { firstName || lastName && (
                            <div className="flex flex-col  items-center">
                                <p className="text-muted-foreground text-xs leading-none">
                                    Name on CVM profile
                                </p>
                                <p className="leading-tight text-lg">
                                    {firstName} {lastName}
                                </p>
                            </div>
                        )}
                    {`${firstName} ${lastName}` !== display && (
                        <div className="mt-4 flex flex-col items-center">
                            <p className="text-muted-foreground text-xs leading-none">
                                Cisco display name
                            </p>
                            <p>{display}</p>
                        </div>
                    )}
                    <Separator className="my-3 h-0.5"/>
                    <div className="w-full grid gap-y-1 grid-cols-2">
                        <span className="text-muted-foreground text-sm border-b">
                            Date # assigned:
                        </span>
                        <span className="text-end border-b">
                            {dateEnrolled ? (new Date(dateEnrolled)).toLocaleDateString('en-CA') : 'Unknown'}
                        </span>

                        <span className="text-muted-foreground border-b">
                            Last listened:
                        </span>
                        <DateCell
                            nullPlaceholder="never"
                            className=" text-end border-b"
                            value={nullNum(recentReadMsgTime)}
                        />

                        <span className="text-muted-foreground  border-b">
                            Listened msgs:
                        </span>
                        <span className=" text-end border-b">{readMsgs}</span>

                        <span className="text-muted-foreground border-b">
                            Unlistened msgs:
                        </span>
                        <span className=" text-end border-b">{unreadMsgs}</span>
                        
                        <span className="text-muted-foreground border-b">
                            Deleted msgs:
                        </span>
                        <span className=" text-end border-b">{deleted}</span>
                    </div>
                    <div className="pt-[3rem] pb-2">  
                        <ErrorSection {...{form, openSections, setOpenSections,fieldNameSections}}/>
                    </div>
                    <Button type="submit" variant="default" className="mt-auto w-full" disabled={Object.keys(form?.formState?.errors)?.length > 1}>
                        Submit client data
                    </Button>
                </div>
            </div>
        </ComposedForm>
    );
};

type ErrorSectionProps = {
    form: CustomUseFormReturn;
    openSections: string[];
    setOpenSections: (value: string[]) => void;
    fieldNameSections: {
        [key: string]: string;
    }
}

export const ErrorSection = ({form, openSections, setOpenSections, fieldNameSections}: ErrorSectionProps) => {
    const errors = form.formState.errors;
    const hasErrors = Object.keys(errors).length > 0;
    function jumpToError(name) {
        console.log('jumping to errror', name, fieldNameSections[name], fieldNameSections);
        if (!openSections.includes(fieldNameSections[name])) {
            const targetSection = fieldNameSections[name];
            if (targetSection) {
                console.log('setting focus');
                setOpenSections([...openSections, fieldNameSections[name]]);
                setTimeout(() => {
                    form.setFocus(name);
                }, 50);
            }
        } else {
            console.log('it includes', name, fieldNameSections[name], openSections);
            form.setFocus(name);
        }
        console.log('setting form focus to', name);
    }
    return (
        <div className="border-l-4 border-error-dark bg-destructive-foreground">
            {hasErrors && (
                <div className="flex flex-col gap-1 py-4 pl-4">
                    <h3 className="text-lg text-destructive font-medium">Invalid Form</h3>
                    <span className="text-sm italic text-muted-foreground">Click on error to jump to invalid field</span>
                </div>
            )}
            {Object.entries(errors).map(([field,error], index) => (
                <FieldError key={index} form={form} name={field} message={error.message} onClick={jumpToError}/>
            ))}
        </div>
    )
}

type FieldErrorProps = {
    form: CustomUseFormReturn;
    name: string;
    message: string;
    onClick: (name: string) => void;

}
const FieldError = ({form, message, name, onClick}: FieldErrorProps) => {
    const {errors} = form.formState;
    return (
        <div className="flex hover:underline items-center text-sm w-fit  hover:cursor-pointer  py-0.5 px-2 text-destructive rounded-md" onClick={() => onClick(name)}>
            <Circle className="w-2 h-2 flex-grow mr-2 fill-current"/>
            <span className="shrink break-words">{message}</span>
        </div>
    )
}