import { Button } from "@/components/ui/button";
import { Command, CommandInput } from "@/components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { createColumnHelper, filterFns } from "@tanstack/react-table";
import { Check, Edit2, ExternalLinkIcon, Plus } from "lucide-react";
import { Link } from "react-router-dom";

import {
    CommandEmpty,
    CommandGroup,
    CommandItem,
    CommandList,
} from "@/components/ui/command";
import { cn } from "@/lib/utils";
import useAgenciesInBranch from "@/api/agencies/getAgenciesInBranch";
import { Separator } from "@/components/ui/separator";
import { Fragment } from "react/jsx-runtime";
import { useState } from "react";

const columnHelper = createColumnHelper();

const stringFilter = filterFns.includesString;
export const branchesColumnHelper = [
    columnHelper.accessor((row) => row.id, {
        id: "ID",
        size: 10,
        meta: {
            hiddenCol: true,
            hiddenFilter: true,
            hideExport: true,
        },
        filterFn: stringFilter,
        cell: (info) => <span>{info.getValue()}</span>,
        header: (info) => <span>ID</span>,
    }),
    columnHelper.accessor((row) => row.Name, {
        id: "Name",
        size: 100,
        filterFn: stringFilter,
        cell: (info) => <span>{info.getValue()}</span>,
        header: (info) => <span>Name</span>,
    }),
    // columnHelper.accessor((row) => row?.status?.Name, {
    //     id: "Status",
    //     size: 100,
    //     filterFn: stringFilter,
    //     cell: (info) => <span>{info.getValue()}</span>,
    //     header: (info) => <span>Status</span>,
    // }),
    columnHelper.accessor((row) => row?.hostAgency, {
        id: "Host Agency",
        size: 1000,
        filterFn: stringFilter,
        cell: ({ row: { original } }) => <HostAgency row={original} />,
        header: (info) => <span>Host Agency</span>,
    }),    
    columnHelper.display({
        id: "Actions",
        meta: {
            hideExport: true,
        },
        size: 100,
        cell: ({ row, table }) => <Actions {...{ row, table }} />,
        header: (info) => <span>Actions</span>,
    }),
];

const Actions = ({ row, table }) => {
    const [isOpen, setIsOpen] = useState(false);
    function close() {
        setIsOpen(false);
    }

    function open() {
        setIsOpen(true);
    }
    const { setHostAgency } = table.options?.meta || {};
    const branchAgenciesQuery = useAgenciesInBranch(row.original.id);
    function handleSelect(agency) {
        setHostAgency(row.original, agency);
        console.log('setting host agency', row.original?.Name, agency);
        close();
    }
    return (
        <div className="flex gap-2 w-fit">
            <Link to={"/branch/" + row.original.id} draggable="false">
                <Button
                    variant="outline"
                    size={"sm"}
                    className="flex gap-2 items-center text-end"
                >
                    Go to branch <ExternalLinkIcon className="w-4" />
                </Button>
            </Link>
            <Popover open={isOpen} onOpenChange={setIsOpen}>
                <PopoverTrigger asChild>
                    {row.original.hostAgency ? (
                        <Button
                            variant="outline"
                            size={"sm"}
                            className="flex gap-2 items-center text-end w-full"
                        >
                            Change host agency <Edit2 className="w-4" />
                        </Button>
                    ) : (
                        <Button
                            variant="outline"
                            size={"sm"}
                            className="flex gap-2 items-center text-end"
                        >
                            Add host Agency <Plus className="w-4" />
                        </Button>
                    )}
                </PopoverTrigger>
                <PopoverContent
                    className="w-fit flex flex-col gap-3"
                    side="bottom"
                    align="start"
                    // onBlur={close}
                >
                    <Command>
                        <CommandInput placeholder={'search agencies...'} />
                        <CommandList>
                            <CommandEmpty>No agencies found</CommandEmpty>
                            <CommandGroup>
                                {branchAgenciesQuery.data?.map?.((opt) => (
                                    <Fragment key={opt.id}>
                                        <CommandItem
                                            tabIndex={0}
                                            onSelect={() => handleSelect(opt)}
                                        >   
                                                {opt.Name}
                                        </CommandItem>
                                    </Fragment>
                                ))}
                            </CommandGroup>
                        </CommandList>
                    </Command>
                </PopoverContent>
            </Popover>
        </div>
    );
};
// TODO: get host agency in here
const HostAgency = ({ row }) => {
    return row.hostAgency ? (
        <>
            {row.hostAgencyData.Name}
        </>
    ) : (
        <span className="whitespace-nowrap text-muted-foreground">
            No host agency found
        </span>
    );
};

// const Actions = ({row, table})  => {
//     const {modBranch, goToBranch} = table.options.meta || {}
//     return (
//         <div className="float-right">
//             <DropdownMenu >
//                 <DropdownMenuTrigger asChild>
//                     <Button variant="outline" size={'iconsm'}><EllipsisIcon className="w-5"/></Button>
//                 </DropdownMenuTrigger>
//                 <DropdownMenuContent  className="w-56">
//                     <DropdownMenuLabel>Actions</DropdownMenuLabel>
//                     <DropdownMenuSeparator />
//                     <DropdownMenuGroup>
//                         <DropdownMenuItem onClick={() => modBranch(row?.original)}>
//                             <EditIcon className="mr-2 h-5 w-5"/>
//                             <span>Edit Branch</span>
//                         </DropdownMenuItem>
//                         <DropdownMenuItem onClick={() => goToBranch(row?.original)}>
//                             <UserMinus className="mr-2 h-5 w-5"/>
//                             <span>See Branch</span>
//                         </DropdownMenuItem>
//                     </DropdownMenuGroup>
//                 </DropdownMenuContent>
//             </DropdownMenu>
//         </div>
//     )
// }
