import { cn } from '@/lib/utils';
import React from 'react';


export const RankCell = ({index, className, children, ...props}) => {
    return (
        <div className={cn("flex gap-2 items-end font-semibold text-lg", className)}>
            <span>{index}.</span>
            {children}
        </div>
    )
}