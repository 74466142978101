import { ControlGroup } from '@/routes/Main/Reporting/components/controls/components/ControlGroup';
import { AttachedDataControls } from '@/routes/Main/Reporting/components/controls/subcontrols/AttachedDataControls';
import { AttachedLegendControls } from '@/routes/Main/Reporting/components/controls/subcontrols/AttachedLegendControls';
import { ColorsControls } from '@/routes/Main/Reporting/components/controls/subcontrols/ColorsControls';
import { LegendControls } from '@/routes/Main/Reporting/components/controls/subcontrols/LegendControls';
import { TitleControls } from '@/routes/Main/Reporting/components/controls/subcontrols/TitleControls';
import React from 'react';


export const BarControls = ({state, datasetEnum, handleChange, ...props}) => {
    return (
        <ControlGroup title={'Config'}>
            <TitleControls {...{...state.config,handleChange}} handleChange={handleChange}/>
            <LegendControls {...{...state.config,handleChange}} handleChange={handleChange}/>
            <AttachedDataControls {...{...state.config,handleChange}} handleChange={handleChange}/>
            <ColorsControls {...{...state.config, handleChange, datasetEnum, config:state.config}} />
        </ControlGroup>
    );
};

