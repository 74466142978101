import useAgenciesInBranch from "@/api/agencies/getAgenciesInBranch";
import useAgency from "@/api/agencies/getAgency";
import useAllBranches from "@/api/branches/getAllBranches";
import { getBranchesQueryOptions } from "@/api/branches/getBranch";
import { useNumbersInAgencies } from "@/api/numbers/getNumbersInAgency";
import useNumbersInBranch from "@/api/numbers/getNumbersInBranch";
import { getMyUserDataQueryOptions } from "@/api/user/getMyUserData";
import { BranchForm } from "@/components/forms/BranchForm";
import { Table } from "@/components/tables/Table";
import { agenciesColumnHelper } from "@/components/tables/columnHelpers/agenciesColumnHelper";
import { phoneNumbersColumnHelper } from "@/components/tables/columnHelpers/phoneNumbersColumnHelper";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { ExternalLinkIcon } from "lucide-react";
import { Link, redirect, useParams } from "react-router-dom";

export const BranchDetails = ({ ...props }) => {
    const { branchID } = useParams();
    // const branchQuery = useBranch(branchID)
    const branchQuery = useAllBranches({
        select: (branches) =>
            branches.find((branch) => {
                console.log("r", branch);
                return branch.id === branchID;
            }),
        placeholderData: {},
    });
    const agenciesQuery = useAgenciesInBranch(branchQuery.data?.id);
    const numbersQuery = useNumbersInAgencies(agenciesQuery.data, !agenciesQuery.isPending && !agenciesQuery.isError && agenciesQuery.data?.length)
    const hostAgencyQuery = useAgency(branchQuery.data?.hostAgency, branchID);

    function onBranchSubmit(data) {
        console.log("branchSubmit", data);
    }
    const branchData = branchQuery.data;

    return (
        <div>
            {/* <BranchForm onSubmit={onBranchSubmit} branchQuery={branchQuery} /> */}
            {   branchData?.hostAgency &&
                <Link to={'/agency/' + branchData?.id + '/' + branchData?.hostAgency} draggable="false" >
                    <Button  variant="outline" size={'sm'} className="flex gap-2 items-center text-end">
                        Go to host agency profile ({branchData?.hostAgencyData?.Name}) <ExternalLinkIcon className="w-4"/>
                    </Button>
            </Link>
            }
            <Card className="mt-5">
                <CardHeader>
                    <CardTitle>Agencies in {branchQuery.data?.Name}</CardTitle>
                </CardHeader>
                <CardContent>
                    <Table
                        state={{
                            columnVisibility: {
                                Branch: false,
                            },
                        }}
                        data={agenciesQuery.data ?? []}
                        exportFileName={`agencies_in_branch_${branchQuery.data?.Name}`}
                        columnHelper={agenciesColumnHelper}
                        meta={{branchID}}
                    />
                </CardContent>
            </Card>
            <Card className="mt-5">
                <CardHeader>
                    <CardTitle>Numbers in {branchQuery.data?.Name}</CardTitle>
                </CardHeader>
                <CardContent>
                <Table
                    showColumnVisibility
                    exportFileName={`phone_numbers_in_${branchData?.Name}`}
                    pdfName={'Phone Numbers'}
                    data={numbersQuery.data}
                    showLoading={numbersQuery.isPending}
                    columnHelper={phoneNumbersColumnHelper}
                    // maxHeightPixels={'100%'}
                />
                </CardContent>
            </Card>
            {/* <Card>
                <CardHeader>
                    <CardTitle>Clients in {branchQuery.data?.Name}</CardTitle>
                </CardHeader>
                <CardContent>
                    <Table
                        state={{
                            columnVisibility: {
                                    Branch: false
                                }
                        }}
                        data={branchQuery.data?.agencies ?? []}
                        exportFileName={`agencies_in_branch_${branchQuery.data?.Name}`}
                        columnHelper={agenciesColumnHelper}
                    />
                </CardContent>
            </Card> */}
        </div>
    );
};

export const BranchDetailsLoader = (queryClient) => async ({ params }) => {
        const { branchID } = params;
        // Ensure Permissions
        const myData = await queryClient.ensureQueryData(getMyUserDataQueryOptions)
        const adminLevel = Number(myData?.adminLevel)
        const isAdmin = adminLevel > 5
        const hasBranchPermissions = adminLevel > 3 && myData?.nodeids.includes(branchID)
        if (!isAdmin && !hasBranchPermissions) return redirect('/')
        // Prefetch data
        const branchData = await queryClient.ensureQueryData(
            getBranchesQueryOptions(branchID)
        );
        return { branchData };
    };
