// If this is not called on a form, react hook form does not see autofilled fields
export function checkFormForAutofill(formState, handleSubmit = ()=>{},e) {
  const fieldNames = Object.keys(formState?.defaultValues)
  // console.log('names',fieldNames);
  for (const name of fieldNames) {
    const d = document.getElementsByName(name)?.[0]
    d?.focus?.()
    d?.blur?.()
  }
  e?.preventDefault?.()
  handleSubmit(e)
}