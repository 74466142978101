import { exportTableAsCSV } from "@/components/tables/helpers/tableUtils";
import { useToast } from "@/components/ui/use-toast";
import { A4_HEIGHT, A4_MARGIN, A4_WIDTH } from "@/util/constants";
import { downloadImg, formatImageDimToFillA4 } from "@/util/fileUtils";
import { condStr } from "@/util/textUtils";
import { useAlert } from "@/wrappers/alerts/useAlert";
import { filterFns } from "@tanstack/react-table";
import { toCanvas, toPng } from "html-to-image";
import { jsPDF } from "jspdf";
import { useRef, useState } from "react";

function defaultPDFSetupFn({
    newPDF,
    title,
    isGroupedColConfig = false,
    exportOpts,
    pageHeight = A4_HEIGHT,
    pageWidth = A4_WIDTH,
    tableRef,
    table,
}) {
    newPDF.setFontSize(20);
    if (isGroupedColConfig) {
        title += ` - ${exportOpts.groupByColumn.label}=${
            exportOpts.columnUniquevalues[exportOpts.groupByColumnValueIndex]
        }`;
    }
    title && newPDF.text(title, A4_WIDTH / 2, 50, { align: "center" });
    newPDF.setFontSize(10);
    newPDF.text(
        `Generated on ${new Date().toString("MMMM d yyyy")}`,
        A4_WIDTH / 2,
        75,
        { align: "center" }
    );
    return newPDF;
}

function defaultPDFExportSaveFn({ pdf, fileName }) {
    pdf.save(fileName);
}
export const useTableExportHelper = ({
    table,
    pdfName = "",
    pdfSetupFn = defaultPDFSetupFn,
    exportFileName = "",
    tableElRef,
    pageState,
    setPageState,
    skipAlert = false,
    skipToast = false,
    onPDFExportFinish = () => {},
    pdfExportSaveFn = defaultPDFExportSaveFn,
    topMargin = 0,
}) => {
    const pdfRef = useRef(null);
    const { errorToast, successToast } = useToast();
    const { alert } = useAlert();
    const [shouldExport, setShouldExport] = useState(false);
    const [exportOptions, setExportOptions] = useState({
        allPages: false,
        showGroupByColumn: false,
        groupByColumn: null,
        columnUniquevalues: [],
        groupByColumnValueIndex: 0,
        groupByColumnOrigFilterFn: null,
    });
    function isExportGroupedColConfig() {
        return exportOptions.showGroupByColumn && exportOptions.groupByColumn;
    }
    // function toggleAllPages() {
    //     setExportOptions(prev => ({...prev, allPages: !prev.allPages}))
    // }
    function setAllPagesOption(val) {
        setExportOptions((prev) => ({ ...prev, allPages: val }));
    }
    function setGroupByColumn(col) {
        // console.log("new col", col);
        setExportOptions((prev) => ({ ...prev, groupByColumn: col }));
    }
    function handleToggleExportGroupByColumn(val) {
        if (!val) {
            setExportOptions((prev) => ({
                ...prev,
                showGroupByColumn: false,
                groupByColumn: null,
            }));
            return;
        } else {
            setExportOptions((prev) => ({ ...prev, showGroupByColumn: true }));
        }
    }
    function exportData(exportType) {
        const newVisibility = Object.fromEntries(
            table
                .getAllColumns()
                .map((col) => [
                    col.id,
                    col.getIsVisible && !col.columnDef?.meta?.hideExport,
                ])
        );
        table.setColumnVisibility(newVisibility);
        setShouldExport(exportType);
        if (exportType === "pdf") setPageState({ pageIndex: 0, pageSize: 35 });
    }

    function exportDataClicked(exportType) {
        const doSetup = () => {
            if (isExportGroupedColConfig()) {
                setExportOptions((prev) => ({
                    ...prev,
                    groupByColumnOrigFilterFn: table.getColumn(
                        exportOptions.groupByColumn.value
                    ).columnDef.filterFn,
                }));
                table.getColumn(
                    exportOptions.groupByColumn.value
                ).columnDef.filterFn = filterFns.equals;
                // Export results for every filter value of the groupByColumn
                const GroupColumnExportSetupValid = setupGroupByColumnExport();
                if (!GroupColumnExportSetupValid) {
                    return;
                }
            }
            exportData(exportType);
        };

        if (exportOptions.allPages) {
            !skipAlert &&
                alert({
                    title: "Warning: Intensive Export",
                    message: () => (
                        <span>
                            Exporting all pages in a table may take a long time
                            to complete, depending on the amount of pages. Older
                            computers may have a difficult time completing this
                            procedure.
                            <br />
                            <br />{" "}
                            <span className="font-semibold">
                                Do not close this tab or navigate away from this
                                page while the export is in progress
                            </span>{" "}
                        </span>
                    ),
                    status: "warning",
                    showConfirm: true,
                    showCancel: true,
                    confirmText: "I understand",
                    cancelText: "Cancel",
                }).then((res) => {
                    if (res.cancelled) {
                        setExportOptions((prev) => ({
                            ...prev,
                            allPages: false,
                        }));
                        return;
                    } else {
                        doSetup();
                    }
                });
        } else {
            doSetup();
        }
    }
    function setupGroupByColumnExport() {
        if (isExportGroupedColConfig()) {
            const targetCol = table.getColumn(
                exportOptions.groupByColumn.value
            );
            const uniqueValues = Array.from(
                targetCol.getFacetedUniqueValues().keys()
            );
            const firstValue = uniqueValues?.[0];
            const failedToFetchUniqueValues =
                !Array.isArray(uniqueValues) ||
                uniqueValues.length < 1 ||
                !firstValue ||
                Number.isNaN(firstValue);
            if (failedToFetchUniqueValues) {
                !skipToast &&
                    errorToast(
                        `Error: Group-by column '${exportOptions.groupByColumn.label}' has no unique values`
                    );

                setExportOptions((prev) => ({
                    ...prev,
                    showGroupByColumn: false,
                    groupByColumn: null,
                }));
                setShouldExport(null);
                return false;
            }
            setExportOptions((prev) => ({
                ...prev,
                columnUniquevalues: uniqueValues,
            }));
            table.resetColumnFilters();
            targetCol.setFilterValue(
                uniqueValues?.[exportOptions.groupByColumnValueIndex]
            );
            return true;
        }
    }
    function handlePDFSetup() {
        try {
            const newPDF = new jsPDF({
                unit: "px",
                format: "a4",
                compress: false,
                hotfixes: ["px_scaling"],
            });

            const pdfAfterSetup = pdfSetupFn({
                newPDF,
                title: pdfName,
                isGroupedColConfig: isExportGroupedColConfig(),
                exportOpts: exportOptions,
                pageHeight: A4_HEIGHT,
                pageWidth: A4_WIDTH,
                tableRef: tableElRef,
                table,
            });
            console.log(
                "almost done setting up",
                newPDF,
                pdfAfterSetup,
                pdfSetupFn
            );
            pdfRef.current = pdfAfterSetup || newPDF;
        } catch (error) {
            console.log("error in pdf setup", error);
        }
    }

    function cancelExport() {
        setShouldExport(null);
        setExportOptions((prev) => ({
            ...prev,
            groupByColumnValueIndex: 0,
            groupByColumn: null,
            columnUniquevalues: null,
        }));
        table.resetColumnVisibility(true);
        table.firstPage();
        table.resetColumnFilters(true);
    }
    async function handleExport() {
        console.log("in handle export", exportOptions);
        try {
            if (!shouldExport) return;
            if (shouldExport === "pdf" && !pdfRef.current) handlePDFSetup();
            let promise = null;
            const pageCount = table.getPageCount();
            const exportIndexString =
                pageCount > 1
                    ? `_${pageState.pageIndex + 1} of ${pageCount}`
                    : "";
            const isColGrouping = isExportGroupedColConfig();
            const currentGroupByValue = isColGrouping
                ? exportOptions.columnUniquevalues[
                      exportOptions.groupByColumnValueIndex
                  ]
                : null;
            let fileName = `${exportFileName}`;

            if (isColGrouping) {
                fileName += `_${currentGroupByValue}`;
                if (shouldExport !== "pdf") {
                    fileName += exportIndexString;
                }
            }

            switch (shouldExport) {
                case "csv":
                    promise = handleCSVExport();
                    break;
                case "jpg":
                    promise = handleImageExport(fileName, "jpg");
                    break;
                case "png":
                    promise = handleImageExport(fileName, "png");
                    break;
                case "pdf":
                    promise = handleExportToPDFPage();
                    break;
            }
            await promise;
            // If there are more pages to export for this configuration, go to next page
            const configHasMorePages =
                exportOptions.allPages && pageState.pageIndex < pageCount - 1;
            if (configHasMorePages) {
                console.log("config has more pages");
                setPageState((prev) => ({
                    ...prev,
                    pageIndex: prev.pageIndex + 1,
                }));
                // console.log("still more pages of this configuration to export");
                return;
            }
            // If there are more values to group by for this configuration
            if (isColGrouping) {
                const targetCol = table.getColumn(
                    exportOptions.groupByColumn.value
                );
                const uniqueValues = exportOptions.columnUniquevalues;
                const nextIndex = exportOptions.groupByColumnValueIndex + 1;
                if (nextIndex < uniqueValues.length) {
                    if (shouldExport === "pdf") {
                        pdfRef.current.save(
                            `${exportFileName}${condStr(
                                `_${currentGroupByValue}`
                            )}.pdf`
                        );
                        handlePDFSetup();
                    }
                    table.firstPage();
                    targetCol.setFilterValue(
                        exportOptions.columnUniquevalues[nextIndex]
                    );
                    setExportOptions((prev) => ({
                        ...prev,
                        groupByColumnValueIndex: nextIndex,
                    }));
                    return;
                }
                // console.log(
                //     "no more coluimn values to export for",
                //     nextIndex,
                //     uniqueValues.length
                // );
            }
            if (shouldExport === "pdf")
                pdfExportSaveFn({ pdf: pdfRef.current, fileName: fileName });
            // pdfRef.current = null;
            !skipToast && successToast("Export Success");
            cleanupExport();
        } catch (error) {
            cleanupExport();
            !skipToast &&
                errorToast(
                    "This was likely caused by excess memory consumption. If you are exporting a large amount of data, try lowering the amount that is exported at once.",
                    {
                        title: "Export Failed",
                        duration: 10000,
                    }
                );
        }
    }

    function cleanupExport() {
        // console.log('cleaning up export');
        if (isExportGroupedColConfig())
            table.getColumn(
                exportOptions.groupByColumn.value
            ).columnDef.filterFn = exportOptions.groupByColumnOrigFilterFn;
        setShouldExport(null);
        setExportOptions((prev) => ({
            ...prev,
            groupByColumnValueIndex: 0,
            groupByColumn: null,
            columnUniquevalues: null,
            groupByColumnOrigFilterFn: null,
        }));
        table.resetColumnVisibility(true);
        table.resetPageSize();
        table.resetColumnFilters();
        if (shouldExport === "pdf") {
            onPDFExportFinish();
        }
    }
    // console.log('tableref in export helper', tableElRef.current, table);
    function handleExportToPDFPage() {
        return new Promise((r, _) => {
            // let temptImg = new Image()
            const start = performance.now();
            toCanvas(tableElRef.current, { quality: 0.8 }).then((dataUrl) => {
                const { width, height } =
                    tableElRef.current.getBoundingClientRect();
                const isFirstPage = pageState.pageIndex === 0;
                const marginToAdd = isFirstPage ? topMargin : 0;
                pdfRef.current.addPage();
                if (table.getPageCount() > 1) {
                    pdfRef.current.setFontSize(10);
                    pdfRef.current.text(
                        `${pageState.pageIndex + 1} of ${table.getPageCount()}`,
                        A4_WIDTH - A4_MARGIN,
                        A4_MARGIN + marginToAdd,
                        { align: "right" }
                    );
                }
                const [formattedW, formattedH] = formatImageDimToFillA4(
                    width,
                    height,
                    A4_MARGIN + marginToAdd
                );
                pdfRef.current.addImage(
                    dataUrl,
                    "JPEG",
                    A4_MARGIN,
                    40 + marginToAdd,
                    formattedW,
                    formattedH,
                    "",
                    "FAST"
                );
                r();
            });
        });
    }
    function handleCSVExport() {
        setShouldExport(null);
        setPageState({ pageIndex: pageState, pageSize: 10 });
        return exportTableAsCSV(table, exportFileName);
    }

    function handleImageExport(fileName, imageSuffix) {
        return new Promise((r) => {
            toPng(tableElRef.current, { quality: 0.8 }).then((dataUrl) => {
                downloadImg(fileName + "." + imageSuffix, dataUrl);
                r();
            });
        });
    }

    function getExportProgress() {
        const pageCount = table.getPageCount();
        let pct = 20;
        let step = 5;
        let numSteps = 100;
        // return {pct, step, numSteps}
        if (!shouldExport) {
            return { pct, step, numSteps };
        }
        if (exportOptions.groupByColumn && exportOptions.showGroupByColumn) {
            step = exportOptions.groupByColumnValueIndex;
            numSteps = exportOptions.columnUniquevalues.length;
        } else {
            step = pageState.pageIndex;
            numSteps = pageCount;
        }
        pct = (step / numSteps) * 100;

        return { pct, step, numSteps };
    }

    return {
        exportDataClicked,
        handleExport,
        cancelExport,
        getExportProgress,
        handleToggleExportGroupByColumn,
        setShouldExport,
        isExportGroupedColConfig,
        shouldExport,
        exportOptions,
        setAllPagesOption,
        setGroupByColumn,
    };
};

export function setPDFBackgroundColor(pdf) {
    pdf.setFillColor(204, 204, 204, 0);
    pdf.rect(10, 10, 150, 160, "F");
}
