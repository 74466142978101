import {
    FormControl,
    FormDescription,
    FormField,
    FormHeader,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form";
import { cn } from "@/lib/utils";
import { Control } from "react-hook-form";
import { Checkbox } from "../../../components/ui/checkbox";

type ComposedFormCheckboxProps = {
    control: Control;
    name: string;
    small?: boolean;
    label?: string;
    placeholder?: string;
    description?: string;
    className?: string;
    oneLine?: boolean;
};

export const ComposedFormCheckbox = ({
    control,
    name,
    small = false,
    label = `${name}`,
    placeholder = `Enter your ${name}`,
    description,
    oneLine = false,
    className = "",
    ...props
}: ComposedFormCheckboxProps) => {
    return oneLine ? (
        <OneLineCheckbox
            small={small}
            control={control}
            name={name}
            label={label}
            description={description}
        />
    ) : (
        <FormField
            control={control}
            name={name}
            render={({ field }) => (
                <FormItem>
                    <FormHeader label={label} />
                    {description && (
                        <FormDescription className="!mt-1">
                            {description}
                        </FormDescription>
                    )}
                    <FormControl>
                        <Checkbox
                            checked={field.value}
                            onCheckedChange={field.onChange}
                        />
                    </FormControl>
                </FormItem>
            )}
        />
    );
};

const OneLineCheckbox = ({
    control,
    name,
    label,
    description,
    small,
    ...props
}) => {
    return (
        <FormField
            control={control}
            name={name}
            render={({ field }) => (
                <FormItem className="flex items-start space-x-2 space-y-0">
                    <FormControl>
                        <Checkbox
                            checked={field.value}
                            onCheckedChange={field.onChange}
                        />
                    </FormControl>
                    <div className="flex flex-col justify-start space-y-1">
                        {label && (
                            <FormLabel
                                className={cn(
                                    "leading-tight",
                                    !small && "font-medium"
                                )}
                            >
                                {label}
                            </FormLabel>
                        )}
                        {description && (
                            <FormDescription className="">
                                {description}
                            </FormDescription>
                        )}
                    </div>
                    <FormMessage />
                    {/* <FormHeader label={label}/> */}
                </FormItem>
            )}
        />
    );
};
