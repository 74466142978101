import { ComposedFormNumberText } from "@/wrappers/ui/form/ComposedFormNumberText"
import { ComposedFormText } from "../../../wrappers/ui/form/ComposedFormText"
import { FormSection } from "../../ui/FormSection"
import { FieldGroup } from "@/components/forms/formComponents/FieldGroup"

export const ContactInfo = ({form, enumsQuery, sectionIndex, agencyData, hasPrev}) => {
    const {control} = form
    return (
        <FormSection {...{sectionIndex}} title={'Communication Info'}>
         
            <div className="flex flex-col gap-6">
                <ComposedFormText
                        small
                        placeholder="1234567890"
                        label="Agency Phone"
                        name={'mainPhone'}
                        control={control}/>
                    <FieldGroup
                        title="Main Contact"
                        labelPosition="center"
                        className="w-1/2">
                        <ComposedFormText
                            variant="large"
                            placeholder="Contact Name"
                            label="Name"
                            name={'contactName'}
                            control={control}/>
                        <ComposedFormText
                            variant="large"
                            placeholder="Contact Email"
                            label="Email"
                            name={'contactEmail'}
                            control={control}/>
                        <ComposedFormText
                            variant="large"
                            placeholder="Contact Phone"
                            label="Phone"
                            name={'contactPhone'}
                            control={control}/>
                        <ComposedFormText
                            variant="large"
                            placeholder="Contact Role"
                            label="Role"
                            name={'contactRole'}
                            control={control}/>
                    </FieldGroup>
                    <FieldGroup
                        title="Executive Contact"
                        labelPosition="center"
                        className="w-1/2">
                        <ComposedFormText
                            variant="large"
                            placeholder="Executive Name"
                            label="Name"
                            name={'execContactName'}
                            control={control}/>
                        <ComposedFormText
                            variant="large"
                            placeholder="Executive Email"
                            label="Email"
                            name={'execContactEmail'}
                            control={control}/>
                        <ComposedFormText
                            variant="large"
                            placeholder="Executive Phone"
                            label="Phone"
                            name={'execContactPhone'}
                            control={control}/>
                        <ComposedFormText
                            variant="large"
                            placeholder="Executive Role"
                            label="Role"
                            name={'execContactRole'}
                            control={control}/>
                        </FieldGroup>
            </div>
        </FormSection>
    )
}