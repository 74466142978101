import { cn } from '@/lib/utils';
import CountUp, { CountUpProps } from 'react-countup';

type LiveNumberProps = CountUpProps & {
    end: number;
    className?: string;
    percent?: boolean;
};


const percentProps = {
    decimals: 1,
    suffix: '%',
    decimalPlaces: 1,
}
export const LiveNumber = ({end, className, percent, ...props}: LiveNumberProps) => {
    return (
        <CountUp className={cn(className)} {...(percent ? percentProps : {})} start={0} end={end} duration={1} separator=',' {...props} />
    );
};
