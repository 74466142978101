import { postPromise } from "@/api/promises";
import { ROUTE_IDS } from "@/api/routeIDs";
import { composeFormData } from "@/util/apiUtils";
import { MILLISEC } from "@/util/constants";
import { condenseDuplicateNumbers } from "@/util/queryUtils";
import {
    UseQueryResult,
    queryOptions,
    useQueries,
    useQuery,
} from "@tanstack/react-query";
import { clone, cloneDeep } from "lodash";
import { useCallback, useRef } from "react";

export const getNumbersInAgency = async ({ queryKey }) => {
    const [_key, { agencyid }] = queryKey;
    
    const data = await postPromise(
        import.meta.env.VITE_API_BASE_URL,
        composeFormData({ agencyid }, ROUTE_IDS.GET_NUMBERS_IN_AGENCY)
    );
    const ret = condenseDuplicateNumbers(data)
    return ret;
};

export default function useNumbersInAgency(agencyid, config = {}) {
    const query = useQuery({
        ...getNumbersInAgencyQueryOptions(agencyid),
        ...config,
    });
    return query;
}

export function useNumbersInAgencies(agencies, enabled=true) {
    const fallbackDataRef = useRef([]);
    // console.log('a',agencies);
    const queries = useQueries<any[], UseQueryResult<unknown, Error>[]>({
        queries: enabled && !!agencies
            ? agencies.map((a) => getNumbersInAgencyQueryOptions(a.id))
            : fallbackDataRef.current,
        combine: useCallback((queries) => {
            const isPending = !enabled || queries.some((q) => q.isPending);
            if (isPending || !(queries.length === agencies.length))
                return { data: null, isPending };
            const data = queries
                .map((q, idx) => {
                    const transformedNumbers = q.data.map((num) => {
                        const data = {
                            ciscoFirstName: num.firstName,
                            ciscoLastName: num.lastName,
                            ...num
                        }
                        data.agencyid = agencies[idx].id;
                        data.agencyname = agencies[idx].Name;
                        (data.branchname = agencies[idx].branchName),
                        (data.branchid = agencies[idx].branchID);
                        return data
                    })
                    return transformedNumbers;
                })
                ?.flat?.();
            return {
                data,
                isPending,
            };
        }, [agencies, enabled]),
    });
    return queries;
}

export const getNumbersInAgencyQueryOptions = (agencyid) =>
    queryOptions({
        staleTime: MILLISEC.MIN_15,
        gcTime: MILLISEC.MIN_15,
        queryKey: [ROUTE_IDS.GET_NUMBERS_IN_AGENCY, { agencyid }],
        queryFn: getNumbersInAgency,
    });
