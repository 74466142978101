import { BlockAlertIcon } from "@/components/blockalerts/BlockAlertIcon";
import { Spacer } from "@/components/misc/Spacer";
import { cn } from "@/lib/utils";
import { cva, VariantProps } from "class-variance-authority";
import { AlertCircle, TriangleAlert } from "lucide-react";
import React from "react";

const blockAlertVariants = cva("group rounded-md border-2 grid items-center", {
    variants: {
        variant: {
            info: "info border-info-dark bg-gradient-to-b from-info/30 to-info-background/40 text-info-content-header",
            error: "error border-error-dark bg-gradient-to-b from-error/30 to-error-background/40 text-error-content-header",
            warning: "success border-warning-dark bg-gradient-to-b from-warning/30 to-warning-background/50  text-warning-content-header",
        },
        size: {
            lg: "large px-4 py-4 grid-cols-[4rem_1fr] gap-x-2 gap-y-1",
            md: "medium px-3 py-4 grid-cols-[4rem_1fr] gap-x-1",
            sm: "small px-2 py-3 grid-cols-[4rem_1fr] gap-x-0.5",
        },
    },
    defaultVariants: {
        variant: "info",
        size: "lg",
    },
});

type BlockAlertProps = {
    variant: VariantProps<typeof blockAlertVariants>;
    title: string;
    message: string;
    className?: string;
    children?: React.ReactNode | React.ReactNode[];
};
export const BlockAlert = ({
    variant,
    className,
    title,
    message,
    children,
}: BlockAlertProps) => {
    return (
        <div className="rounded-md bg-white">
            <div className={cn(blockAlertVariants(variant), className)}>
                <BlockAlertIcon variant={variant} />
                {/* <AlertCircle className='w-full h-full hidden group-[.error]:block group-[.info]:block' />
                <TriangleAlert className='w-full  group-[.large]:w-6 group-[.large]:h-6 group-[.medium]:w-4 group-[.medium]:h-4 hidden group-[.warning]:block'/> */}
                <span className=" font-medium group-[.medium]:text-lg group-[.large]:text-xl group-[.large]:font-semibold">
                    {title}
                </span>
                <Spacer />
                <p className=" w-full font-light text-sm group-[.large]:text-lg group-[.large]:font-normal group-[.medium]:text-md  group-[.medium]:font-normal">
                    {message}
                </p>
                <div className="col-span-2 [&>*:first-child]:mt-4">{children}</div>
            </div>
        </div>
    );
};