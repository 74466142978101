import useNumbersInAgency from '@/api/numbers/getNumbersInAgency';
import { Table } from '@/components/tables/Table';
import { phoneNumbersColumnHelper } from '@/components/tables/columnHelpers/phoneNumbersColumnHelper';
import { selectThirtyDayInactive } from '@/util/queryUtils';
import React from 'react';
import { Outlet } from 'react-router-dom';


export const ForAgency = ({agencyData, ...props}) => {
    const numbersQuery = useNumbersInAgency(agencyData.id,{select: selectThirtyDayInactive})
    return (
        <Table
            headerClassName='text-sm'
            rowClassName='!text-xs'
            exportFileName="phone_numbers"
            pdfName={'Phone Numbers'}
            data={numbersQuery.data}
            showLoading={numbersQuery.isPending}
            columnHelper={phoneNumbersColumnHelper}/>
    );
};

