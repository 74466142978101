import * as React from "react"
import { Check, ChevronDown, ChevronLeft, ChevronRight } from "lucide-react"
import { DayPicker, MonthCaption } from "react-day-picker"

import { cn } from "@/lib/utils"
import { Button, buttonVariants } from "@/components/ui/button"
import { Select } from "@/wrappers/ui/Select"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";

export type CalendarProps = React.ComponentProps<typeof DayPicker> & {
  showYearDropdown?: Boolean,
  showMonthDropdown?: Boolean,
  minDate?: Date,
  maxDate?: Date,
}

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  showMonthDropdown=false,
  showYearDropdown=false,
  minDate,
  maxDate,
  ...props
}: CalendarProps) {
  const captionLayout = React.useMemo(() => {
    if (showMonthDropdown && showYearDropdown) 
      return "dropdown"
    if (showMonthDropdown)
      return "dropdown-months"
    if (showYearDropdown)
      return "dropdown-years"
    return "label"
  },[showMonthDropdown, showYearDropdown])
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn("p-3", className)}
      hidden={{before: minDate, after: maxDate}}
      captionLayout={captionLayout}
      labels={{  
        labelYearDropdown: (props) => "Select year",
      }}
      classNames={{
        months: "flex relative flex-col sm:flex-row space-y-4  sm:space-y-0",
        month: "space-y-4 ml-0",
        month_caption: "flex justify-center pt-1 relative pointer-events-none items-center",
        caption_label: "text-sm font-medium",
        nav: "space-x-1 h-8 pt-1 flex items-center",
        dropdown: 'bg-transparent',
        dropdowns: 'bg-transparent pointer-events-auto font-normal flex items-center [&>span]:h-fit',
        // dropdown_year:"bg-red-400",
        button_previous: cn(
          buttonVariants({ variant: "outline" }),
          "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100 absolute left-1"
        ),
        button_next: cn(
          buttonVariants({ variant: "outline" }),
          "h-7 w-7 pointer-events-auto bg-transparent p-0 opacity-50 hover:opacity-100 absolute right-1 hover:cursor-pointer"
        ),
        month_grid: "w-full border-collapse space-y-1",
        weekdays: "flex",
        years_dropdown: 'bg-blue-300',
        weekday:
          "text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]",
        week: "flex w-full mt-2",
        day: "h-9 w-9 rounded-md text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
        day_button: cn(
          buttonVariants({ variant: "ghost" }),
          "h-9 w-9 p-0 font-normal aria-selected:opacity-100"
        ),
        range_end: "day-range-end",
        selected:
          "bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground",
        today: "bg-accent text-accent-foreground",
        outside:
          "day-outside text-muted-foreground opacity-50 aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30",
        disabled: "text-muted-foreground opacity-50",
        range_middle:
          "aria-selected:bg-accent aria-selected:text-accent-foreground",
        hidden: "invisible",
        ...classNames,
      }}
      components={{
        Chevron: ({orientation, className}) => 
          ({
          left: <ChevronLeft className={cn("h-4 w-4", className)} />,
          right: <ChevronRight className={cn("h-4 w-4 pointer-events-none", className)} />,
          down: <ChevronDown className={cn("h-4 w-4", className)} />,
        }[orientation]),
        Dropdown: ({ options, className, value, onChange, components }) => {
          const [open, setOpen] = React.useState(false)
          const isMonth = React.useMemo(() => {
            return isAlphaRegex.test(options[0]?.label?.[0])
          },[options]) 

          const filteredOptions = React.useMemo(() => {
            if (!isMonth) 
              return options.filter((opt) => opt.value > minDate.getFullYear() && opt.value < maxDate.getFullYear())
            return options
          },[options, minDate, maxDate])

          function handleOptionSelected(opt) {
            onChange({target: { value: opt.value }})
            setOpen(false)
          }
          return (
            <>
              <Popover open={open} onOpenChange={setOpen}>
                <PopoverTrigger asChild>
                  <Button 
                    variant="ghost"
                    className="flex gap-1 items-center px-2 py-1 h-fit whitespace-nowrap text-md font-normal"
                    >
                    {options.find((opt) => opt.value === value)?.label}
                    <ChevronDown className="h-4 w-4" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className='w-fit'>
                <Command>
                      <CommandList>
                          {/* <CommandEmpty>{emptyMessage}</CommandEmpty> */}
                          <CommandGroup>
                              {filteredOptions?.map?.((opt) => (
                                  <CommandItem
                                      tabIndex={0}
                                      // value={valueFn(opt) || ''} 
                                      key={opt.value}
                                      onSelect={() => handleOptionSelected(opt)}
                                  >
                                      {opt.label}
                                  </CommandItem>
                              ))}
                          </CommandGroup>
                      </CommandList>
                  </Command>
                </PopoverContent>
              </Popover>
            </>
          )
      },
        // IconLeft: ({ ...props }) => <ChevronLeft className="h-4 w-4" />,
        // IconRight: ({ ...props }) => <ChevronRight className="h-4 w-4" />,
        // Dropdown: ({value, onChange, caption, }) => {
        //     return {
        //       <Select 

        //       />
        //     }
        // },
      }}
      {...props}
    />
  )
}
Calendar.displayName = "Calendar"

const isAlphaRegex = /^[a-zA-Z]+$/

export { Calendar }
