import { Handler } from "@/Handler";
import useMyUserData, {
    getMyUserDataQueryOptions,
} from "@/api/user/getMyUserData";
import { Sidebar } from "@/routes/Main/components/Sidebar/Sidebar";
import { getCookie } from "@/util/cookieUtils";
import { Suspense } from "react";
import { Outlet, redirect } from "react-router-dom";

export const MainRoute = ({ ...props }) => {
    const myDataQuery = useMyUserData();
    return (
        <Suspense fallback={<>Error!!!</>}>
            <Handler />
            <div className="grid h-full">
                <div className="flex h-full w-screen">
                    <Sidebar myDataQuery={myDataQuery} />
                    <div className="container py-7 h-full">
                        <Outlet />
                    </div>
                </div>
            </div>
        </Suspense>
    );
};

const test = async () =>
    new Promise((r) =>
        setTimeout(() => {
            r(true);
        }, 10000)
    );

export const MainRouteLoader = (queryClient) => async () => {
    try {
        const myUserData = await queryClient.ensureQueryData({
            queryKey: getMyUserDataQueryOptions.queryKey,
        })
        if (!myUserData || myUserData?.Name === "AxiosError") {
            console.log("busted");
            return redirect("/auth/login");
        } else {
            // console.log('successfully got user data');
        }
        if (!myUserData)
            console.log("mainroute loading couldn't find user data", myUserData);
    } catch (error) {
        return redirect("/auth/login")
    }
    return {}
};
