import { Check, ChevronsUpDown } from "lucide-react";
import * as React from "react";

import { Button } from "@/components/ui/button";
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/components/ui/command";
import { FormControl } from "@/components/ui/form";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { SelectVariants } from "@/wrappers/ui/Select";
import { capitalizeFirst } from "../util/textUtils";
import { ScrollArea } from "@/components/ui/scroll-area";

interface ObjectOption extends Object {
    id?: string;
}
type Option = string | number | ObjectOption;

export interface MultiSelectProps {
    options: Option[];
    selected: Option[];
    open?: boolean;
    name?: string;
    onChange: (option: Option[]) => void;
    showSearch?: boolean;
    closeOnPointerOut?: boolean;
    openOnFocus?: boolean;
    inForm?: boolean;
    placeholder?: string;
    searchLabel?: string;
    emptyMessage?: string;
    popupClassName?: string;
    buttonClassName?: string;
    valueIsID?: boolean;
    variant?: "small" | "medium" | "large" | "xs";
    side?: "left" | "top" | "right" | "bottom";
    valueFn?: (option: Option) => string;
    renderFn?: (option: Option) => string | React.ReactNode;
    displayFn?: (selected: Option[]) => string | React.ReactNode;
    IDFn?: (option: Option) => string;
    selectedFn?: (option: Option, selected: Option[]) => boolean;
}

const defaultDisplayFn = (selected) => (
    <p>
        <span className="text-subtext mr-1">({selected?.length})</span> Selected
    </p>
);

export const MultiSelect = React.forwardRef<
    React.ElementRef<typeof Button>,
    MultiSelectProps
>(
    (
        {
            options,
            selected,
            open: controlledOpen,
            onChange,
            name = "",
            showSearch = false,
            closeOnPointerOut = true,
            openOnFocus = false,
            inForm = true,
            side = "bottom",
            popupClassName = "",
            buttonClassName = "",
            variant,
            valueIsID = false,
            placeholder = `Select ${capitalizeFirst(name)}`,
            searchLabel = `Search ${capitalizeFirst(name)}`,
            emptyMessage = `No ${capitalizeFirst(name)} Found.`,
            valueFn = (opt) => (opt as ObjectOption)?.id,
            renderFn = valueFn,
            displayFn = defaultDisplayFn,
            IDFn = valueFn,
            selectedFn = (opt, sel) =>
                sel.map((s) => IDFn(s)).includes(IDFn(opt)),
            ...props
        },
        ref
    ) => {
        const [isOpen, setIsOpen] = React.useState(false);
        const hasValue = selected?.length > 0;

        React.useEffect(() => {
            controlledOpen != null && setIsOpen(controlledOpen);
        }, [controlledOpen]);

        function handleToggle(item) {
            if (valueIsID) {
                if (selected.includes(IDFn(item)))
                    onChange(selected.filter((curr) => curr !== IDFn(item)));
                else onChange([...selected, IDFn(item)]);
            } else {
                if (selectedFn(item, selected))
                    onChange(
                        selected.filter((curr) => IDFn(curr) !== IDFn(item))
                    );
                else onChange([...selected, item]);
            }
        }

        function close() {
            setIsOpen(false);
        }

        function open() {
            setIsOpen(true);
        }

        return (
            <Popover open={isOpen} onOpenChange={setIsOpen} modal>
                <PopoverTrigger asChild>
                    {inForm ? (
                        <FormControl>
                            <Button
                                onFocus={openOnFocus ? open : undefined}
                                variant="outline"
                                type="button"
                                role="combobox"
                                className={cn(
                                    SelectVariants({ variant }),
                                    !hasValue && "text-muted-foreground",
                                    buttonClassName,    
                                )}
                            >
                                {hasValue ? displayFn(selected) : placeholder}
                                <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                            </Button>
                        </FormControl>
                    ) : (
                        <Button
                            onFocus={openOnFocus ? open : undefined}
                            variant="outline"
                            type="button"
                            role="combobox"
                            className={cn(
                                SelectVariants({ variant }),
                                "w-[200px] justify-between",
                                !hasValue && "text-muted-foreground",
                                buttonClassName
                            )}
                        >
                            {hasValue ? displayFn(selected) : placeholder}
                            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                        </Button>
                    )}
                </PopoverTrigger>
                <PopoverContent
                    className={cn("w-[200px] p-0", popupClassName)}
                    side={side}
                    onPointerLeave={closeOnPointerOut ? close : undefined}
                >
                    <ScrollArea >
                        <Command>
                            {showSearch && (
                                <CommandInput placeholder={searchLabel} />
                            )}
                            <CommandList className="">
                                <CommandEmpty>{emptyMessage}</CommandEmpty>
                                <CommandGroup>
                                    {options?.map?.((opt) => (
                                        <CommandItem
                                            tabIndex={0}
                                            // value={valueFn(opt) || ''}
                                            key={IDFn(opt)}
                                            onSelect={() => handleToggle(opt)}
                                        >
                                            <Check
                                                className={cn(
                                                    "mr-2 h-4 w-4 opacity-0",
                                                    selectedFn(opt, selected) &&
                                                        "opacity-100"
                                                )}
                                            />
                                            {renderFn(opt)}
                                        </CommandItem>
                                    ))}
                                </CommandGroup>
                            </CommandList>
                        </Command>

                    </ScrollArea>
                </PopoverContent>
            </Popover>
        );
    }
);

MultiSelect.displayName = "Select";
