import { FormControl, FormDescription, FormField, FormHeader, FormItem } from '@/components/ui/form';
import { cn } from '@/lib/utils';
import { cva } from 'class-variance-authority';
import { Textarea } from '../../../components/ui/textarea';
import { camelCaseToWords } from '../../../util/textUtils';
import { Spacer } from '@/components/misc/Spacer';


export const TextAreaInputVariants = cva(
  "flex flex-col",
  { 
    variants: {
      variant: {
        large: 'w-full',
        medium: "w-[25rem]",
        small: "w-[15rem]"
      }
    },
    defaultVariants: {
      variant: "medium"
    }
  }
)

export type InputVariant = 'small' | 'medium' | 'large' | undefined

export const ComposedFormTextarea = ({
    control,
    name, 
    small=false,
    label,
    placeholder=`Enter your ${camelCaseToWords(label,false)}`,
    description=null,
    variant='medium' as InputVariant,
    hideLabel=false,
    className='',
    ...props}) => {
    
    return (
        <FormField
            control={control}
            name={name}
            render={({field}) => (
                <FormItem className='space-y-0 w-fit'>
                    {!hideLabel && <FormHeader label={label}/>}
                    <Spacer className='pt-2'/>
                    <FormControl>
                        <Textarea className={cn(TextAreaInputVariants({variant}))} placeholder={placeholder} {...field} {...props}/>
                    </FormControl>
                    {description && <FormDescription>{description}</FormDescription>}
                </FormItem>
            )}
        />
    );
};
