import { postPromise } from "@/api/promises";
import { ROUTE_IDS } from "@/api/routeIDs";
import { composeFormData } from "@/util/apiUtils";
import { MILLISEC } from "@/util/constants";
import { queryOptions, useQuery } from "@tanstack/react-query";

export const getMyUserData = async ({ queryKey }) => {
    // return new Promise((r,e) => r({
    //         id: 1,
    //         firstName: 'Dylan',
    //         lastName: 'Macleod',
    //         adminLevel: "13",
    //         branches: [1,2,3]
    // }))
    // return errorPromise()
    // ({})
    return postPromise(
        import.meta.env.VITE_API_BASE_URL,
        composeFormData({}, ROUTE_IDS.GET_MY_USER_DATA)
    );
};

export default function useMyUserData() {
    const query = useQuery(getMyUserDataQueryOptions);
    return query;
}
export const getMyUserDataQueryOptions = queryOptions({
    queryKey: ["user", "me", ROUTE_IDS.GET_MY_USER_DATA],
    queryFn: (params) => getMyUserData(params),
    staleTime: MILLISEC.MIN_15,
    select,
});

function select(data) {
    return {
        ...data,
        isAdmin: Number(data.adminLevel) > 9,
        isBranchUser: Number(data.adminLevel) < 10 && Number(data.adminLevel) >= 5,
        isAgencyUser: Number(data.adminLevel) < 5,
    };
}
