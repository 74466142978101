import { clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs) {
  return twMerge(clsx(inputs))
}

export const enumToOptions = (e) =>
  e
    ?  Object.entries(e).map((entry) => ({
          value: entry[0],
          label: entry[1],
      }))
    : []

    export const serverEnumToOptions = (e) =>
      e 
        ? Object.values(e)
        : []


export function genHex() {
  return '#' + Math.floor(Math.random()*16777215).toString(16).padStart(6, '0')
}