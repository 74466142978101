import { Table } from "@/components/tables/Table"
import { BranchDemographicRatingsCols } from "@/components/tables/columnHelpers/branchDemographicRatingsCols"

export const DemographicBranchRankings = ({tableRef, pdfRef,branches, className='', children=undefined}) => {
    return (
        <div className={className}>
            {children}
            <Table
                controlledPDF
                tableFnRef={tableRef}
                pdfRef={pdfRef}
                className="h-full"
                data={branches}
                columnHelper={BranchDemographicRatingsCols}
                showSearchBar={false}
                showPagination={false}
                showColumnVisibility={false}
                showExportButton={false}
                showFilters={false}
                headerClassName="whitespace-nowrap text-sm "
                rowClassName="py-1"
                maxHeightPixels={411.2}
            />
        </div>
    )
}