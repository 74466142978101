import React from "react"
import { useFormContext } from "react-hook-form"

type FormErrorProvider= React.FC<{
    field: string,
    children: (value: any) => React.ReactNode
}>
export const FormErrorProvider: FormErrorProvider = ({field, children }) => {
    const form = useFormContext()
    return (
        <>
            {children({error: form.getFieldState(field)?.error?.message})}
        </>
    )
}