import { postPromise } from '@/api/promises';
import { ROUTE_IDS } from '@/api/routeIDs';
import { composeFormData, convertObjFieldsToString } from '@/util/apiUtils';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const deleteUser = (params) => {
    // return new Promise(r => r({
        
    // }))
    //return errorPromise()
	return postPromise(import.meta.env.VITE_API_BASE_URL,composeFormData(params, ROUTE_IDS.DELETE_USER));
}

export default function useDeleteUser({onSuccess, ...props}={}) {
    const client = useQueryClient()
    const mutation = useMutation({
        mutationKey: ['user','branch', ROUTE_IDS.DELETE_USER],
        mutationFn: deleteUser,
        onSuccess: (data, req) => {
            if (data.status?.includes?.('not ')) {
                console.log('here bad')
                throw new Error('Failed to delete user.');} 
            else {
                console.log('here good');
            }
            client.invalidateQueries({queryKey: ['user', 'branch']})
            onSuccess && onSuccess(data,req)
        },
        ...props
    });
    return mutation;
}