import { Checkbox } from '@/components/ui/checkbox';
import { cn } from '@/lib/utils';
import { cva } from 'class-variance-authority';
import React from 'react';
const ComposedCheckboxVariants = cva(
    'flex flex-row items-center space-x-3 space-y-0 rounded-md border p-4 hover:cursor-pointer select-none',{
    variants: {
        variant: {
            default: '',
            custom: '',
        },
    },
    defaultVariants: {
        variant: 'default'
    }
})

type JCheckboxProps = React.HTMLAttributes<HTMLButtonElement> &{
    label?: string | React.ReactNode;
    description?: string;
    value: boolean;
    handleChange: (value: boolean) => void;
    variants?: {
        variant?: 'default' | 'custom';
    }
}

export const JCheckbox = ({label, description ,value, handleChange, variants, children, className, ...props}: JCheckboxProps) => (
    <label className={cn(ComposedCheckboxVariants(variants), value && 'border-primary', className)}>
        <Checkbox
            className={'w-5 h-5 stroke-[2]  data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground'}
            checked={value}
            onCheckedChange={handleChange}
            {...props}
        />
         <div className="space-y-1 leading-none">
            {label && (
                <div>
                {label}
                </div>
            )}
            {description &&(
                <div className={"text-sm text-muted-foreground"}>
                {description}
                {/* <Link href="/examples/forms">mobile settings</Link> page. */}
                </div>
            )}
        </div>
        {children}
    </label>
)
