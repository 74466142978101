
export const SplashIcon = (props) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 718.39 374.01">
        <path fill='currentColor' d="m714.41,270.73c-18.05,22.51-75.71-4.3-94.99,17.91-16.18,25.05,6.28,39.11,29.62,38.85,21.7,7.24,21.97,33.55,4.37,43.7v.33c-57.66,5.14-116.57.48-174.64,2.01-7.55-2.44-14.19-7.7-16.93-15.31-1.97-15.25-7.85-30.81-25.76-31.53-57.87-.11-115.75-.11-173.62,0-7.06.37-12.71,5.43-18.57,8.9-12.42,11.29,4.16,38.54-34.15,37.65-20.39.64-40.8.18-61.19.28-32.53-10.4-4.21-40.47-43.76-46.69-21.05-1.94-43.21,2.69-63.64-2.23-15.92-11.06-15.32-24.77-3.57-38.98,15.74-9.43,34.2-5.06,51.74-5.94,13.12-4.51,23.26-18.64,17.22-31.96-14.99-33.52-82.72,3.64-103.48-24.78-26.59-73.94,129.08-4.17,130.43-57.06,2.27-13.3-7.68-22.65-20.08-24.98-16.9,1.19-35.39-8.97-32.14-27.96,7.57-38.44,83.79-6.37,109.45-23.97,15.25-12.57,10-41.19-12.11-41.92-12.58-.51-26.95,2.41-37.13-6.62C127.64,25.21,138.86-1.87,160.63.1c62.46,0,124.92-.03,187.39.03,9.35-.36,18.34,1.21,24.44,8.95,8.65,15.07,1.98,32.87,25.1,36.96,38.5,2.61,102.13.28,134.88,1.04l-3.47-.16c41.78.29,84.47.03,126.58.12,11.85,0,22.3,9.38,23.13,20.66,1.09,36.39-45.09,24.07-68.62,26.07-30.67.38-30.15,46.22.39,46.26,10.11-.62,20.3-.08,30.46-.12,14.17,5.86,22.83,21.64,15.69,35.95-11.99,16.14-32.06,6.64-47.19,15.98v.32c-16.96,11.09-9.18,35.55,9.01,40.78,26.78,2.38,54.04.36,80.99.99,17.09,1.67,24.06,23.3,15,36.8Z"/>
    </svg>
)

export const ClientIcon = (props) => (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.25366 13.9999L16.2419 14.0011C16.3459 14.1727 16.4732 14.3321 16.6227 14.474L17.3413 15.1561C17.2557 15.4887 17.1162 15.8222 16.9207 16.1585C16.723 16.4985 16.4946 16.7956 16.2343 17.0521L15.44 16.7872C14.5057 16.4756 13.4912 16.8326 12.9326 17.6533L12.2111 18.7132C11.5824 19.637 11.6943 20.899 12.4808 21.6886L12.7083 21.9167C12.1668 21.973 11.5973 22.0011 11.0001 22.0011C8.11062 22.0011 5.87181 21.3445 4.30894 20.0008C3.48032 19.2884 3.00366 18.25 3.00366 17.1572V16.2499C3.00366 15.0073 4.01102 13.9999 5.25366 13.9999ZM17.0106 12.245L17.5139 11.0581C17.75 10.5015 18.3154 10.1987 18.8699 10.3143L18.9884 10.3455L19.6187 10.5469C20.2436 10.7466 20.7222 11.2819 20.8768 11.9542C21.2441 13.5518 20.8034 15.4969 19.5548 17.7896C18.3079 20.0789 16.9414 21.4553 15.455 21.9189C14.8779 22.099 14.258 21.9682 13.7916 21.5767L13.6684 21.4635L13.1897 20.9829C12.7749 20.5664 12.6894 19.9076 12.9676 19.3921L13.0382 19.2759L13.7597 18.2159C14.0436 17.7989 14.5292 17.6015 14.9971 17.7012L15.1241 17.7358L16.456 18.18C16.9876 17.7778 17.4307 17.2715 17.7856 16.6612C18.0897 16.138 18.2887 15.6078 18.3824 15.0705L18.4205 14.8013L17.3115 13.7487C16.9462 13.4019 16.8135 12.862 16.9628 12.376L17.0106 12.245L17.5139 11.0581L17.0106 12.245ZM11.0001 2.00462C13.7615 2.00462 16.0001 4.2432 16.0001 7.00462C16.0001 9.76605 13.7615 12.0046 11.0001 12.0046C8.2387 12.0046 6.00012 9.76605 6.00012 7.00462C6.00012 4.2432 8.2387 2.00462 11.0001 2.00462Z" fill="currentColor"/>
    </svg>
)

export const BranchIcon = (props) => (
    <svg {...props} width="24" height="24" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M240 208h-8V88a8 8 0 0 0-8-8h-64a8 8 0 0 0-8 8v40h-48V40a8 8 0 0 0-8-8H32a8 8 0 0 0-8 8v168h-8a8 8 0 0 0 0 16h224a8 8 0 0 0 0-16M168 96h48v112h-48Zm-16 48v64h-48v-64ZM40 48h48v160H40Zm32 24v16a8 8 0 0 1-16 0V72a8 8 0 0 1 16 0m0 48v16a8 8 0 0 1-16 0v-16a8 8 0 0 1 16 0m0 48v16a8 8 0 0 1-16 0v-16a8 8 0 0 1 16 0m48 16v-16a8 8 0 0 1 16 0v16a8 8 0 0 1-16 0m64 0v-16a8 8 0 0 1 16 0v16a8 8 0 0 1-16 0m0-48v-16a8 8 0 0 1 16 0v16a8 8 0 0 1-16 0"/></svg>
)
export const AgencyIcon = (props) => (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><rect width="2" height="2" x="9" y="6" fill="currentColor" rx=".5"/><rect width="2" height="2" x="13" y="6" fill="currentColor" rx=".5"/><rect width="2" height="2" x="9" y="9.5" fill="currentColor" rx=".5"/><rect width="2" height="2" x="13" y="9.5" fill="currentColor" rx=".5"/><rect width="2" height="2" x="9" y="13" fill="currentColor" rx=".5"/><rect width="2" height="2" x="13" y="13" fill="currentColor" rx=".5"/><path fill="currentColor" d="M18.25 19.25h-.5V4a.76.76 0 0 0-.75-.75H7a.76.76 0 0 0-.75.75v15.25h-.5a.75.75 0 0 0 0 1.5h12.5a.75.75 0 0 0 0-1.5m-2 0H11V17a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v2.25H7.75V4.75h8.5Z"/></svg>
)

export const PhoneIcon = (props) => (
    <svg {...props} width="24" height="24" viewBox="0 0 56 56" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M39.156 50.934c4.078 0 6.774-1.102 9.164-3.774c.188-.187.352-.398.54-.586c1.406-1.57 2.062-3.117 2.062-4.593c0-1.688-.984-3.258-3.07-4.712l-6.82-4.734c-2.11-1.453-4.571-1.617-6.54.328l-1.804 1.805c-.54.539-1.008.563-1.547.234c-1.242-.797-3.797-3.023-5.532-4.757c-1.828-1.805-3.609-3.82-4.523-5.297c-.328-.54-.281-.985.258-1.524l1.781-1.805c1.969-1.968 1.805-4.453.352-6.538l-4.758-6.82c-1.43-2.087-3-3.048-4.688-3.071c-1.476-.024-3.023.656-4.593 2.062c-.211.188-.399.352-.61.516c-2.648 2.39-3.75 5.086-3.75 9.14c0 6.704 4.125 14.86 11.696 22.43c7.523 7.524 15.703 11.696 22.382 11.696m.024-3.61c-5.977.117-13.64-4.476-19.711-10.523c-6.117-6.094-10.922-14.016-10.805-19.992c.047-2.579.938-4.805 2.79-6.399c.14-.14.28-.258.444-.375c.68-.61 1.454-.937 2.11-.937c.703 0 1.312.257 1.758.96l4.547 6.82c.492.727.539 1.548-.188 2.274l-2.062 2.063c-1.641 1.617-1.5 3.586-.328 5.156c1.335 1.805 3.656 4.43 5.437 6.211c1.805 1.805 4.64 4.336 6.445 5.695c1.57 1.172 3.563 1.29 5.18-.328l2.062-2.062c.727-.727 1.524-.68 2.25-.211l6.82 4.547c.704.468.985 1.054.985 1.758c0 .68-.328 1.43-.96 2.132a5.82 5.82 0 0 1-.352.446c-1.617 1.828-3.844 2.718-6.422 2.765"/></svg>
)

export const SearchIcon = (props) => (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5A6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5S14 7.01 14 9.5S11.99 14 9.5 14"/></svg>
)