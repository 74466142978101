import { motion } from 'framer-motion'
export const VanishingControls = ({disabled,...props}) => (
    <motion.div 
        initial={false}
        className='flex items-end space-x-2 h-full'
        // initial={{opacity:0}}
        animate={{opacity: disabled ? 0 : 1}}
        exit={{opacity:0}}
        {...props}
    />

)