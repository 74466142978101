import { SearchIcon } from "@/components/misc/icons";
import { Spacer } from "@/components/misc/Spacer";
import { Button } from "@/components/ui/button";
import { Popover, PopoverContent, PopoverDescription, PopoverHeader, PopoverTitle, PopoverTrigger } from "@/components/ui/popover";
import { useRHF } from "@/hooks/useRHF";
import { useYupValidationResolver } from "@/hooks/useYupValidationResolver";
import { ComposedForm } from "@/wrappers/ui/form/ComposedForm";
import { ComposedFormText } from "@/wrappers/ui/form/ComposedFormText";
import { FormError } from "@/wrappers/ui/form/FormError";
import { FormErrorProvider } from "@/wrappers/ui/form/FormErrorProvider";
import Yup from "@/wrappers/yup";

const validationSchema = Yup.object({
    atLeastOne: Yup.boolean().testSiblings(
        'At least one field must be filled in', 
        ({Name, city}) => Name || city
    ),
    name: Yup.string().trim().default(''),
    city: Yup.string().trim().default('')
})

export const FindAgencyPopover = ({onSubmit, children}) => {
    const resolver = useYupValidationResolver(validationSchema);
    const form
        = useRHF({resolver, defaultValues: validationSchema.getDefault(), mode: 'onBlur'});
    const {control} = form;

    return (
        <Popover >
            <PopoverTrigger asChild>
                {children}
            </PopoverTrigger>
            <PopoverContent className="flex flex-col gap-3" side="bottom" align="start">
                <ComposedForm form={form} onSubmit={onSubmit} className="pb-0 pt-0">
                    <PopoverHeader>
                        <PopoverTitle>Search Agencies</PopoverTitle>
                        <PopoverDescription>Search for agencies by name or city</PopoverDescription>
                        <FormErrorProvider field={'atLeastOne'}>
                            {FormError}
                        </FormErrorProvider>
                    </PopoverHeader>
                    <Spacer className="mt-4"/>
                    <ComposedFormText
                        {...{
                            control,
                            name: 'name',
                            label: 'Name',
                            placeholder: 'Access',
                            variant: 'large',
                        }}
                    />
                    <ComposedFormText
                        {...{
                            control,
                            name: 'city',
                            label: 'City',
                            placeholder: 'City',
                            variant: 'large',
                        }}
                    />
                    <Button 
                        type="submit"
                        variant="default" className="w-fit gap-4 place-self-end">
                        Search for Agency
                        <SearchIcon className=""/>
                    </Button>
                </ComposedForm>
            </PopoverContent>
        </Popover>
    )
}