import { FieldRow } from "@/components/forms/formComponents/FieldRow";
import { ComposedFormCheckboxGroup } from "@/wrappers/ui/form/ComposedFormCheckboxGroup";
import { ComposedFormDatepicker } from "@/wrappers/ui/form/ComposedFormDatepicker";
import { ComposedFormSwitchBox } from "@/wrappers/ui/form/ComposedFormSwitchBox";
import { ComposedFormTextarea } from "@/wrappers/ui/form/ComposedFormTextarea";
import { ShowWhenFieldSibling } from "@/wrappers/ui/form/ShowWhenFieldSibling";
import { ComposedFormText } from "../../../wrappers/ui/form/ComposedFormText";
import { FormSection } from "../../ui/FormSection";

export const AgencyInfo = ({
    form,
    enumsQuery,
    sectionIndex,
    agencyData,
    hasPrev,
}) => {
    const { control } = form;
    const { serviceTypes } = enumsQuery.data || {};
    return (
        <FormSection {...{ sectionIndex }} title={"General Agency Information"}>
            <div className="flex flex-col gap-4">
                <input
                    id="test"
                    name="agencyid"
                    tabIndex={-1}
                    className="bg-background focus:text-green-400  h-0"
                    defaultValue={"hi"}
                />
                <ComposedFormSwitchBox
                    control={control}
                    className="max-w-[48.3rem]"
                    name={"isActive"}
                    description="Is this agency participating in the distribution and administration of CVM numbers?"
                    label="Active"
                />
                <FieldRow>
                    <ComposedFormDatepicker
                        control={control}
                        name={"startDate"}
                        label="Start Date"
                        variant="medium"
                    />
                    <ComposedFormDatepicker
                        control={control}
                        name={"endDate"}
                        label="End Date"
                        variant="medium"
                    />
                </FieldRow>
                <ComposedFormText
                    small
                    placeholder="Name of Agency"
                    label="Display Name"
                    name={"displayName"}
                    control={control}
                />
                <ComposedFormText
                    small
                    placeholder="City"
                    label="City"
                    name={"city"}
                    control={control}
                />
                <FieldRow>
                    <ComposedFormText
                        small
                        placeholder="123 Fake St"
                        label="Address"
                        name={"address"}
                        control={control}
                    />
                    <ComposedFormText
                        small
                        placeholder="123 Fake St"
                        label="Address 2"
                        name={"address2"}
                        control={control}
                    />
                </FieldRow>

                <ComposedFormText
                    small
                    label="Agency Password"
                    name={"agencyPassword"}
                    control={control}
                />
                <ComposedFormTextarea
                    small
                    label="Agency Notes"
                    name={"agencyNotes"}
                    placeholder=""
                    control={control}
                />
                <ComposedFormCheckboxGroup
                    entries={serviceTypes}
                    control={control}
                    name={"serviceTypes"}
                    label="Service Types"
                    subComponentFn={(entry) =>
                        ({
                            Other: (
                                <ShowWhenFieldSibling
                                    sibling="serviceTypes"
                                    includes={entry.id}
                                    mode="wait"
                                >
                                    <div className="ml-6 pt-3 pb-1">
                                        <ComposedFormTextarea
                                            hideLabel
                                            label=""
                                            control={control}
                                            name="otherServices"
                                            placeholder="Describe other services"
                                        />
                                    </div>
                                </ShowWhenFieldSibling>
                            ),
                        }[entry.Name || entry.label])
                    }
                />
            </div>
        </FormSection>
    );
};
