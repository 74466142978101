
export const stackedBarOptions = ({
    animations=true,
    stackedX = false,
    stackedY = false,
    showTitle = false,
    maxYTick,
    title,
    showLegend = true,
    alignTitle = "center",
    titleFontSize,
    legendPos,
    legendDotSize = 20,
    titlePos,
    backgroundColor,
    showAttachedLabel = 12,
    attachedLabelFontSize,
    legendColor='black',
    borderColors,
    ...additionalConfig
}) => ({
    maintainAspectRatio: false,
    barPercentage: 0.9,
    categoryPercentage: 0.9,
    minBarLength: 0,
    offset:0,
    // borderColor: "gray",
    animations: animations,
    plugins: {
        title: {
            display: showTitle,
            text: title,
            align: alignTitle,
            position: titlePos,
            font: {
                size: titleFontSize === "" ? 20 : titleFontSize,
            },
            color: "black",
            fullSize: true,
        },
        legend: {
            display: showLegend,
            reverse: true,
            position: legendPos,
            // align: "center",
            labels: {
                padding: 10,
                font: {
                    size: 20,
                },
                color: 'white',
                boxWidth: legendDotSize,
                boxHeight: legendDotSize,
                // borderRadius:100,
                // useBorderRadius: true,
                usePointStyle: true,
                pointStyle: "rectRounded",
            },
        },
        customCanvasBackgroundColor: {
            color: backgroundColor,
        },
        datalabels: {
            anchor: "start",
            labels: {
                value: {
                    display: showAttachedLabel,
                    anchor: function (ctx) {
                        // switch (ctx.datasetIndex) {
                        //     case 0:
                        //         return 'end';
                        //     case 1:
                        //         return 'start';
                        //     default:
                        //         return 'center';
                        // }
                        // console.log('index',ctx.dataIndex);
                        return "end";
                    },
                    align: function (ctx) {
                        // console.log('index',ctx.dataIndex, ctx.dataset.label, ctx);
                        switch (ctx.datasetIndex) {
                            case 0:
                                return -35;
                            case 1:
                                return -90;
                            default:
                                return -150;
                        }
                        // return -135;
                    },
                    color: function (ctx) {
                        return "white";
                    },
                    backgroundColor: function (ctx) {
                        var color = ctx.dataset.borderColor;
                        return color;
                    },
                    formatter: function (value, ctx) {
                        // console.log('formatter', value,ctx);
                        return value;
                    },
                    font: {
                        weight: "bolder",
                        size: attachedLabelFontSize,
                    },
                    borderColor: function (ctx) {
                        return 'white'
                        // return borderColors[ctx.datasetIndex];
                        // switch (ctx.datasetIndex % 3) {
                        //     case 0:
                        //         return 'white';
                        //     case 1:
                        //         return 'white';
                        //     default:
                        //         return 'white';
                        // }
                    },
                    borderWidth: 2,
                    borderRadius: 4,
                    padding: 6,
                    offset: function (ctx) {
                        switch (ctx.datasetIndex) {
                            case 0:
                                return 12;
                            case 1:
                                return 3;
                            default:
                                return 19;
                        }
                    },
                },
            },
        },
    },
    scales: {
        x: {
            display: true,
            stacked: stackedX,
            grid: {
                display: false,
                drawBorder: false,
            },
            ticks: {
                minRotation:20,
                font: {
                    size: 15,
                },
                color: legendColor
            },
        },
        y: {
            stacked: stackedY,
            // max:5000,
            grace: '25%',
            grid: {
                // display: false,
                drawBorder: false,
            },
            ticks: {
                font: {
                    size: 18,
                },
            },
        },
    },
    ...additionalConfig,
});

export const stackedDemoReportBarOptions = ({
    stackedX,
    stackedY,
    ...additionalConfig
}) => {
    return stackedBarOptions({
        showTitle: false,
        showLegend: false,
        alignTitle: "center",
        legendPos: "top",
        backgroundColor: "transparent",
        legendDotSize: 15,
        legendFontSize: "12",
        displayTooltip: false,
        showAttachedLegend: false,
        showAttachedLabel: true,
        stackedX,
        stackedY,
        borderRadius: 10,
        spacing: 0,
        // cutout: '80%',
        hoverOffset: 0,
        layout: {
            padding: {
                left: 15,
                right: 10,
                top: 40,
            },
        },
        ...additionalConfig,
    });
};
