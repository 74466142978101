import { getPromise, postPromise } from '@/api/promises';
import { ROUTE_IDS } from '@/api/routeIDs';
import { MICROSERVICE_URL } from '@/util/constants';
import { queryOptions, useQuery } from '@tanstack/react-query';

export const listFAQs = ({queryKey}) => {
    return getPromise(`${MICROSERVICE_URL}/faq`, {})
}

export default function useListFAQs() {
    const query = useQuery(listFAQsQueryOptions());
    return query;
}
export const listFAQsQueryOptions = () => queryOptions({
    queryKey: ['faq'],
    queryFn: listFAQs,
    select
})

function select(data) {
    return data?.sort?.(compare)
}
function compare(a, b) {
    return a.index - b.index;
}