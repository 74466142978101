import { ArrowDownNarrowWide, ArrowUpNarrowWideIcon } from 'lucide-react';
import React from 'react';


export const SortControls = ({headers, table, disabled, ...props}) => {
    const filteredHeaders = 
        headers.filter(h => 
            h.column.getCanSort() &&
            h.column.columnDef?.meta?.showSortControls &&
            h.column.getIsVisible()
        )
    return (
        <div className={`flex gap-2 items-start 
         ${disabled ? 'opacity-40' : '' }`}
        >
        {
            filteredHeaders
                .map((header, idx) => (
                    <div 
                      className="relative rounded-2xl bg-base-100 border border-gray-border" key={header.id + 'container'}>
                        <Sorter disabled={disabled} key={header.id + 'filter'} column={header.column} table={table} />    
                    </div>
            ))
        }
        </div>
    )
};

const Sorter = ({table, column, ...props}) => {
    const firstValue = table
        .getPreFilteredRowModel()
        .flatRows[0]?.getValue(column.id)
    const columnFilterValue = column.getFilterValue()
    const sortedUniqueValues = React.useMemo(
      () =>
      typeof firstValue === 'number'
      ? []
      : Array.from(column.getFacetedUniqueValues().keys()).sort(),
      [column.getFacetedUniqueValues()]
    )   
    return (
        <div className='flex items-end justify-between py-2 px-3 hover: cursor-pointer' onClick={column.getToggleSortingHandler()}>
            <p className='text-sm'>{column.columnDef.id}</p>
            <div className='mb-1 pl-2'>
                {{
                    asc: <ArrowUpNarrowWideIcon className='w-6 h-6 text-[#ff6000]'/>,
                    desc: <ArrowDownNarrowWide className='w-6 h-6 text-[#4700ff]'/>
                }[String(column.getIsSorted())] ?? (
                    <ArrowUpNarrowWideIcon className='w-6 h-6 opacity-30'/>
                )}
            </div>
        </div>
    )
}

