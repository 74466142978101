import useAgenciesInBranch from '@/api/agencies/getAgenciesInBranch';
import useNumbersInBranch from '@/api/numbers/getNumbersInBranch';
import { MiniCard } from '@/components/cards/MiniCard';
import { ListSkeleton } from '@/components/skeletons/ListSkeleton';
import { Table } from '@/components/tables/Table';
import { phoneNumbersColumnHelper } from '@/components/tables/columnHelpers/phoneNumbersColumnHelper';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { ForAgency } from '@/routes/Main/Reporting/Presets/ThirtyDayInactivity/ForAgency';
import { selectThirtyDayInactive } from '@/util/queryUtils';
import React from 'react';


export const ForBranch = ({branchdata, ...props}) => {
    const [asOne, setAsOne] = React.useState(1)    
    const [expandedAgencies, setExpandedAgencies] = React.useState([])
    const agenciesInBranchQuery = useAgenciesInBranch(branchdata.id)
    const numbersQuery = useNumbersInBranch(branchdata.id,branchdata.Name,{select: selectThirtyDayInactive})
    return (
        <Tabs value={asOne} onValueChange={setAsOne} className='w-full'>
            <div className="flex justify-center w-full">
                <TabsList className='place-self-center shadow-md'>
                        <TabsTrigger value={1}>Separate by agency</TabsTrigger>
                        <TabsTrigger value={2}>Combine</TabsTrigger>
                    {/* <div className="flex justify-center w-full">
                    </div> */}
                </TabsList>

            </div>
            <TabsContent value={1} className='overflow-visible'>
                <Accordion type='multiple' value={expandedAgencies} onValueChange={setExpandedAgencies} className='space-y-2 overflow-visible'>
                    { agenciesInBranchQuery.isPending ? (
                        <ListSkeleton/>
                    ) : (
                        <>
                            {agenciesInBranchQuery.data.map((a) => (
                            <MiniCard key={a.id} className={' overflow-visible mx-1'}>
                                <AccordionItem value={a.id} key={a.id} className='border-b-0 w-full '>
                                    <AccordionTrigger className='font-bold'>{a.Name}</AccordionTrigger>
                                    <AccordionContent className='rounded-md p-1 w-full'>
                                        <MiniCard className={'bg-secondary'}>
                                            <ForAgency key={a.id} agencyData={a}/>
                                        </MiniCard>
                                    </AccordionContent>
                                </AccordionItem>
                            </MiniCard>
                            ))}
                        </>
                    )}
                </Accordion>
            </TabsContent>
            <TabsContent value={2}>
                <MiniCard className={'bg-secondary'}>
                    <Table
                        exportFileName="phone_numbers"
                        pdfName={'Phone Numbers'}
                        data={numbersQuery.data}
                        showLoading={numbersQuery.isPending}
                        columnHelper={phoneNumbersColumnHelper}/>
                </MiniCard>
            </TabsContent>
        </Tabs>
    );
};

