import { addDefaultQueryOptions } from "@/api/addDefaultQueryOptions";
import { MainScrollContainer } from "@/components/misc/MainScrollContainer";
import { ThemeProvider } from "@/components/theme/theme-provider";
import { Toaster } from "@/components/ui/toaster";
import { ScrollContainerRefProvider } from "@/context/ScrollContainerContext";
import { router } from "@/router";
import { MILLISEC } from "@/util/constants";
import { GlobalAlert } from "@/wrappers/alerts/GlobalAlert";
import {
    QueryCache,
    QueryClient,
    QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import "non.geist";
import { Suspense } from "react";
import { RouterProvider } from "react-router-dom";
import "../index.css";
import "@cyntler/react-doc-viewer/dist/index.css"

const queryCache = new QueryCache({
    onError: (error) => {
        // console.log('error from cache:', error);
    },
    onSuccess: (data) => {
        // console.log('data from cache:', data);
    },
    onSettled: (data, error) => {
        // if (error) console.log('error from cache settled:', error);
    },
});
const queryClient = new QueryClient({
    queryCache: queryCache,
    defaultOptions: {
        queries: {
            // refetchOnWindowFocus: "always",
            // refetchOnReconnect: "always",
            refetchInterval: MILLISEC.MIN_14,
            retryDelay: 60,
            retry: 1,
            staleTime: MILLISEC.MIN_15,
            gcTime: 0,
            
        },
    },
});
addDefaultQueryOptions(queryClient);
const r = router(queryClient);

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            {<ReactQueryDevtools client={queryClient} />}
            <ThemeProvider>
                <ScrollContainerRefProvider>
                    <MainScrollContainer>
                        <div className="h-screen">
                            <Suspense fallback={null}>
                                <RouterProvider router={r} />
                                <Toaster />
                                <GlobalAlert />
                            </Suspense>
                        </div>
                    </MainScrollContainer>
                </ScrollContainerRefProvider>
            </ThemeProvider>
        </QueryClientProvider>
    );
}

export default App;
