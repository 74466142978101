import { isFunc } from "@/util/miscUtils"
import { AnimateConditionalRender, animationDirection } from "@/wrappers/ui/framer/AnimateConditionalRender";
import { AnimatePresenceProps } from "framer-motion";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form"

type ShowWhenFieldSiblingProps = {
    sibling: string;
    mode?: AnimatePresenceProps['mode']
    is?: any;
    includes?: any;
    children: React.ReactNode;
    direction?: animationDirection;
    onConditionFail?: () => void;
    onConditionPass?: () => void;
    noAnimation?: boolean;
};

export const ShowWhenFieldSibling = ({ mode, sibling, is, includes, onConditionFail,direction, onConditionPass, children, noAnimation=true }: ShowWhenFieldSiblingProps) => {
    const [show, setShow] = useState(false)
    const {watch} = useFormContext()
    useEffect(shouldShow,[watch(sibling)])
    function shouldShow() {
        const sibVal = watch(sibling)
        let result = false
        if (is) {
            result = 
                isFunc(is) 
                    ? is(sibVal) 
                    : is === sibVal
        }
        if (includes) {
            result = sibVal?.includes?.(includes)
        }
        result 
            ? onConditionPass && onConditionPass() 
            : onConditionFail && onConditionFail()
        setShow(result)
    }
    return noAnimation ? (
        <>{show && children}</>
    ) : (
        <AnimateConditionalRender mode={mode} direction={direction}>
            {show && children}
        </AnimateConditionalRender>
    )
    
    // <>{children}</>
    
}