import { refreshExpiredToken } from '@/api';
import { refreshToken } from '@/api/auth/refreshToken';
import { useToast } from '@/components/ui/use-toast';
import { getCookie, setCookie } from '@/util/cookieUtils';
import { jwtDecode } from 'jwt-decode';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const Handler = ({...props}) => {
    const {toast} = useToast()
    const nav = useNavigate()
    const location = useLocation()
    
    async function handleFocus(e) {
    if (!getCookie('token')) {
        if (getCookie('refreshToken')) {
            console.log('refreshing token');
            try {
                const r = await refreshExpiredToken()
                console.log('r',r.data);
                const {token} = r.data
                if (token) {
                    const decodedToken = jwtDecode(token)
                    setCookie('token', token, decodedToken.exp * 1000)
                    console.log('successfully refreshed token');
                    return
                } else {
                    console.log('no refreshed token in response');
                }
            } catch (error) {
                console.log('error refreshing token', error);
            }
        }
        console.log('dont have cookie');
        try {
            toast({
                duration:4000, 
                title: <span className='flex items-end gap-2'>Session expired</span> ,
                description: <span className=''>Please log in again.</span> ,
                status: 'info',
            })
            console.log('pathname is ', location.pathname);
            nav('/auth/login', {state:{
                from: location.pathname
            }})
            
        } catch (error) {
            console.log('error',error);
        }
        // console.log('here');
    } else {
        console.log('got cookie');
    }
}
    useEffect(() => {
        console.log('setting focus listeners');
        if (typeof window !== 'undefined' && window.addEventListener) {
            window.addEventListener('visibilitychange', handleFocus, false)
            window.addEventListener('focus', handleFocus, false)
        }
        return () => {
            if (typeof window !== 'undefined' && window.removeEventListener) {
                window.removeEventListener('visibilitychange', handleFocus, false)
                window.removeEventListener('focus', handleFocus, false)
            }
            console.log('cleaning up focus listeners');
        }
    },[])
    return (
        <div>
        </div>
    );
};

