import { Table } from "../../../../../components/tables/Table";
import { BranchUsageRatingsCols } from "../../../../../components/tables/columnHelpers/BranchUsageRatingsCols";

export const AgencyRankings = ({
    agencies,
    className = "",
    children = undefined,
    tableFnRef,
    pdfRef,
}) => {
    return (
        <div className={className}>
            {children}
            <Table
                pdfRef={pdfRef}
                controlledPDF
                tableFnRef={tableFnRef}
                className="h-full"
                data={agencies}
                columnHelper={BranchUsageRatingsCols}
                showColumnVisibility={false}
                showExportButton={false}
                showFilters={false}
                headerClassName="whitespace-nowrap text-sm "
                rowClassName="py-1"
                maxHeightPixels={328}
            />
        </div>
    );
};
