import api from "@/api";
import axios, { AxiosError } from "axios";

export const getPromise = (url,payload,config={}, authenticated=true) => {
  const client = authenticated ? api : axios.create();
  return new Promise((resolve, reject) => {
    client.get(url,config).then(response => {
      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response);
      }
    }).catch(error => {
      reject(error);
    });
  });
}


export const postPromise = (url, payload, options = {}, meta={}) => {
  return new Promise((resolve, reject) => {
    const headers = {}
    if (options.json != true)
      headers['Content-Type'] = 'multipart/form-data'
    api.post(url, payload, { 
      headers,
      meta
    }).then(response => {
      if (response.status === 200 && response.data != null) {
        resolve(response.data);
      } else {
        reject(response);
      }
    }).catch(error => {
      reject(error);
    });
  });
}

export const putPromise = (url, payload, options = {}) => {
  return new Promise((resolve, reject) => {
    const headers = {}
    if (options.json != true)
      headers['Content-Type'] = 'multipart/form-data'
    api.put(url, payload, { 
      headers
    }).then(response => {
      if (response.status === 200 && response.data != null) {
        resolve(response.data);
      } else {
        reject(response);
      }
    }).catch(error => {
      reject(error);
    });
  });
}

export const patchPromise = (url, payload) => {
  return new Promise((resolve, reject) => {
    api.patch(url, payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => {
      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response);
      }
    }).catch(error => {
      reject(error);
    });
  });
}

export const deletePromise = (url, payload) => {
  return new Promise((resolve, reject) => {
    api.delete(url, {data: payload}, {
      headers: {
        'Content-Type': 'application/json'
      },
    }).then(response => {
      if (response.status === 200) {
        resolve(response.data);
      } else {
        reject(response);
      }
    }).catch(error => {
      reject(error);
    })
  });
}

export const errorPromise = () => {
    return new AxiosError('testing error')
}