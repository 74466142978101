import { MiniCard } from '@/components/cards/MiniCard';
import { Checkbox } from '@/components/ui/checkbox';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Separator } from '@/components/ui/separator';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { SubcontrolContainer } from '@/routes/Main/Reporting/components/controls/subcontrols/SubcontrolContainer';
import { SubcontrolTitle } from '@/routes/Main/Reporting/components/controls/subcontrols/SubcontrolTitle';
import { VanishingControls } from '@/routes/Main/Reporting/components/controls/subcontrols/VanishingControls';


export const TitleControls = ({showTitle, title, titleFontSize, alignTitle, titlePos, handleChange,...props}) => {
    return (
        <MiniCard className={'items-end shadow-none h-16'} active={showTitle}>
            <div className="flex items-center space-x-2 h-full">
                <Checkbox
                    value={showTitle}
                    onCheckedChange={(e) => handleChange('showTitle',   e)}
                    className={'w-4 h-4 stroke-[2]  data-[state=checked]:bg-candy data-[state=checked]:text-candy-foreground'}
                />
                <span className='text-lg font-medium'>Title</span>
            </div>
            <VanishingControls disabled={!showTitle}>
                <Separator orientation='vertical'/>
                <SubcontrolContainer>
                    <SubcontrolTitle>Title</SubcontrolTitle>
                        <Input
                            className='pr-2 pl-1 py-1 h-8 text-xs'
                            disabled={!showTitle}
                            placeholder='Ex. Distribution of ...'
                            value={title}
                            onChange={(e) => handleChange('title',   e.target.value)}/>
                </SubcontrolContainer>
                <Separator orientation='vertical'/>
                <SubcontrolContainer>
                    <SubcontrolTitle>Font</SubcontrolTitle>
                    <Label className="flex items-center px-1 gap-1 border rounded-md">
                    <Input
                        className='w-10 border-none text-center pr-1 pl-1 py-1 h-8 text-xs'
                        type='number'
                        min={0}
                        max={90}
                        disabled={!showTitle}
                        value={titleFontSize}
                        onChange={(e) => handleChange('titleFontSize',   e.target.value)}/>
                    </Label>
                </SubcontrolContainer>
                <Separator orientation='vertical'/>
                <SubcontrolContainer>
                    <SubcontrolTitle>Alignment</SubcontrolTitle>
                    <Tabs
                        value={alignTitle}
                        onValueChange={(v) => {console.log(v);handleChange('alignTitle',  v)}}>
                        <TabsList  className=' h-8' onChange={(e) => console.log(e)}>
                            <TabsTrigger disabled={!showTitle} className='py-0 h-5 bg-none data-[state=active]:bg-none' value='start'>Left</TabsTrigger>
                            <TabsTrigger disabled={!showTitle} className='py-0 h-5 bg-none data-[state=active]:bg-none' value='center'>Center</TabsTrigger>
                            <TabsTrigger disabled={!showTitle} className='py-0 h-5 bg-none data-[state=active]:bg-none' value='end'>Right</TabsTrigger>
                        </TabsList>
                    </Tabs>
                </SubcontrolContainer>
                <Separator orientation='vertical'/>
                <SubcontrolContainer>
                    <SubcontrolTitle>Position</SubcontrolTitle>
                    <Tabs
                        value={titlePos}
                        onValueChange={(v) => {console.log(v);handleChange('titlePos',  v)}}>
                        <TabsList  className=' h-8' onChange={(e) => console.log(e)}>
                            <TabsTrigger disabled={!showTitle} className='py-0 h-5 bg-none data-[state=active]:bg-none' value='top'>Top</TabsTrigger>
                            <TabsTrigger disabled={!showTitle} className='py-0 h-5 bg-none data-[state=active]:bg-none' value='left'>Left</TabsTrigger>
                            <TabsTrigger disabled={!showTitle} className='py-0 h-5 bg-none data-[state=active]:bg-none' value='right'>Right</TabsTrigger>
                            <TabsTrigger disabled={!showTitle} className='py-0 h-5 bg-none data-[state=active]:bg-none' value='bottom'>Bottom</TabsTrigger>
                        </TabsList>
                    </Tabs>
                </SubcontrolContainer>
            </VanishingControls>
        </MiniCard>
    );
};

