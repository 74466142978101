import { Chart, ChartType, DefaultDataPoint } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { forwardRef, useEffect } from 'react';
import { ChartProps, Pie } from 'react-chartjs-2';
import { PIE_LEGEND_FONT_FACTOR, PIE_LEGEND_RADIUS_FACTOR, PIE_RADIUS } from '../../../util/constants';

export type ChartJSOrUndefined<TType extends ChartType = ChartType, TData = DefaultDataPoint<TType>, TLabel = unknown> = Chart<TType, TData, TLabel> | undefined;
type ResponsivePieProps = Omit<ChartProps<"pie", number[], unknown>, "type">  & {
    datasetColors?: string[]
}

export const ResponsivePie = forwardRef<ChartJSOrUndefined<"pie", number[], unknown>, ResponsivePieProps>((props, ref) => {
    const {datasetColors, ...otherProps} = props || {}
    useEffect(updateChartColors,[datasetColors])
    // function onResize(chart, {width, height}) {
    //     if (isNaN(chart.legend.width) || isNaN(chart.legend.height)) return
    //     const minDimension = Math.min((width - chart.legend.width) * PIE_RADIUS / 100, height * PIE_RADIUS / 100 )
    //     const legendBoxRadius = minDimension / PIE_LEGEND_RADIUS_FACTOR
    //     const legendFont = minDimension / PIE_LEGEND_FONT_FACTOR
    //     chart.options.plugins.legend.labels = {
    //         ...chart.options.plugins.legend.labels,
    //         boxHeight: legendBoxRadius,
    //         boxWidth: legendBoxRadius,
    //         font: {
    //             size: legendFont
    //         }
        
    //     }
    //     chart?.update?.('resize')
    // }
    function updateChartColors() {
        if (!ref?.current.data?.datasets?.length) return
        ref.current.data.datasets.forEach((set, idx) => {
            set.backgroundColor = datasetColors
            set.borderColor = datasetColors})
        ref.current.update()
    }
    return (
        <Pie {...otherProps} plugins={[ChartDataLabels]} options={{...props.options}} ref={ref} />
    );
});
ResponsivePie.displayName = 'ResponsivePie';

