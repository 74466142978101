import { ComposedFormCheckboxGroup } from "@/wrappers/ui/form/ComposedFormCheckboxGroup";
import { ComposedFormTextarea } from "@/wrappers/ui/form/ComposedFormTextarea";
import { ShowWhenFieldSibling } from "@/wrappers/ui/form/ShowWhenFieldSibling";
import Yup from "@/wrappers/yup";
import { motion } from "framer-motion";
import { ReactNode } from "react";
import { cn } from "../../../lib/utils";
import { Fn } from "../../misc/Code";
import { FormSection } from "../../ui/FormSection";
import { Button } from "../../ui/button";
import { Checkbox } from "../../ui/checkbox";
import { FormControl, FormField, FormItem, FormLabel } from "../../ui/form";
import { Label } from "../../ui/label";
import { RadioGroup, RadioGroupItem } from "../../ui/radio-group";
import { Separator } from "../../ui/separator";

export type Goals = {
    clientGoals: object[];
    otherGoals: string;
};

export const Goals = ({
    form,
    enumsQuery,
    clientData,
    sectionIndex,
    ...props
}) => {
    const { register, handleSubmit, formState, setValue, reset, control } =
        form;

    const { clientGoals } = enumsQuery.data || {};
    // function loadData() {
    //     if (!clientData) return setValue('objectid', objectid)
    //     const cast = validationSchema.cast(clientData, {stripUnknown: true})
    //     console.log('cast',cast);
    //     const parsedEnums = parseServerEnums(cast,[clientGoals],['clientGoals'])
    //     reset({
    //         ...cast,
    //         ...parsedEnums,
    //         objectid
    //     })
    // }
    console.log(clientGoals);

    return (
        <FormSection sectionIndex={sectionIndex} title={"Goals"}>
            <ComposedFormCheckboxGroup
                {...{
                    name: "clientGoals",
                    entries: clientGoals,
                    control,
                    label: "Client Goals",
                }}
                subComponentFn={(entry) =>
                    ({
                        Other: (
                            <ShowWhenFieldSibling
                                sibling="clientGoals"
                                includes={entry?.id}
                                onConditionFail={() =>
                                    setValue("otherGoals", "")
                                }
                            >
                                <div className="ml-6 pt-3 pb-1">
                                    <ComposedFormTextarea
                                        hideLabel
                                        control={control}
                                        name="otherGoals"
                                        label=""
                                        placeholder="Other Goals"
                                    />
                                </div>
                            </ShowWhenFieldSibling>
                        ),
                    }[entry?.Name || entry?.label])
                }
            />
        </FormSection>
    );
};
const GoalsSection = ({
    error,
    ...props
}: {
    error: any;
    children: ReactNode;
}) => {
    return error ? (
        <div>
            <p className="text-error">{error.message}</p>
            <div className="border p-2 rounded-md border-error flex flex-col gap-4">
                {props.children}
            </div>
        </div>
    ) : (
        <>{props.children}</>
    );
};

const GoalOutcomePair = ({
    goalLabel,
    goalName,
    outcomeName,
    outcomeLabel,
    control,
    outcomes,
}) => {
    return (
        <FormField
            control={control}
            name={goalName}
            render={({ field: goalField }) => (
                <motion.div
                    variants={containerVariants}
                    initial="initial"
                    animate={goalField.value ? "selected" : "initial"}
                    className={cn(
                        "flex flex-row min-h-fit justify-between border rounded-md p-4 overflow-clip"
                    )}
                >
                    <Label className="hover:cursor-pointer flex-1">
                        <FormItem className="flex items-start text-center gap-2">
                            <FormControl>
                                <Checkbox
                                    checked={goalField.value}
                                    onCheckedChange={goalField.onChange}
                                />
                            </FormControl>
                            <div className="text-lg flex flex-row gap-2 h-4 !mt-0 items-center [&_span]:leading-none">
                                <span className="text">Goal</span>
                                <Separator
                                    orientation="vertical"
                                    className="bg-black w-[1.5px]"
                                />
                                <span>{goalLabel}</span>
                            </div>
                            {/* <FormLabel className='hover:cursor-pointer'>Goal: {goalLabel}</FormLabel> */}
                        </FormItem>
                    </Label>
                    <Separator orientation="vertical" className="mx-4" />
                    <motion.div variants={outcomeVariants} className="flex-1">
                        {/* <Form */}
                        <FormField
                            control={control}
                            name={outcomeName}
                            render={({ field: outcomeField }) => (
                                <FormItem className="">
                                    {Fn(
                                        () =>
                                            !goalField.value &&
                                            outcomeField.value &&
                                            outcomeField.onChange(null),
                                        goalField.value
                                    )}
                                    <div className="text-lg flex gap-2 h-4 font-medium items-center">
                                        <span className="text">Outcome</span>
                                        <Separator
                                            orientation="vertical"
                                            className="bg-black w-[1.5px]"
                                        />
                                        <span>{outcomeLabel}</span>
                                    </div>
                                    <FormControl>
                                        <RadioGroup
                                            onValueChange={
                                                outcomeField.onChange
                                            }
                                            defaultValue={outcomeField.value}
                                            value={outcomeField.value}
                                            className="flex flex-col ml-1"
                                        >
                                            {outcomes?.map?.((option) => (
                                                <FormItem key={option.value}>
                                                    <FormLabel className="flex items-center space-x-2 space-y-0 hover:cursor-pointer">
                                                        <FormControl>
                                                            <RadioGroupItem
                                                                value={
                                                                    option.value
                                                                }
                                                            />
                                                        </FormControl>
                                                        <FormLabel className="w-fit text-sm font-medium hover:cursor-pointer">
                                                            {option.label}
                                                        </FormLabel>
                                                    </FormLabel>
                                                </FormItem>
                                            ))}
                                        </RadioGroup>
                                    </FormControl>
                                    {/* {description && <FormDescription>{description}</FormDescription>} */}
                                </FormItem>
                            )}
                        />
                    </motion.div>
                </motion.div>
            )}
        ></FormField>
    );
};

const containerVariants = {
    initial: {
        height: "3.2rem",
        borderColor: "hsla(214.3 31.8% 91.4% / 1)",
        // backgroundColor: '#ffffff'
    },
    selected: {
        height: "8rem",
        // borderColor: 'hsla(0.0 0.0 0.0 0.0 / 0)',
        borderColor: "hsla(0 50% 0% / 1)",
        // backgroundColor: '#000000'
    },
};

const outcomeVariants = {
    initial: {
        opacity: "0%",
        y: 10,
    },
    selected: {
        opacity: "100%",
        y: 0,
    },
};
