import useListFAQs from "@/api/microservice/listFAQs";
import { BlockAlert } from "@/components/blockalerts/BlockAlert";
import { PageDescription } from "@/components/misc/PageDescription";
import { PageHeader } from "@/components/misc/PageHeader";
import { PreviewDocumentDialog } from "@/components/misc/PreviewDocumentDialog";
import { useSearchedData } from "@/hooks/wrappers/useSearchedData";
import { HelpSettingsEntriesSkeleton } from "@/routes/Main/Admin/HelpSettings/components/HelpSettingsEntriesSkeleton";
import { HelpEntries } from "@/routes/Main/Help/components/HelpEntry";
import { TextSearchBar } from "@/wrappers/ui/TextSearchBar";
import React from "react";

const d = [];
export const Help = ({ ...props }) => {
    const [previewOpen, setPreviewOpen] = React.useState(false);
    const [previewData, setPreviewData] = React.useState(null);
    const FAQsQuery = useListFAQs();
    const [searchQuery, setSearchQuery] = React.useState("");

    const filteredData = useSearchedData({
        data: FAQsQuery.data,
        search: searchQuery,
        searchKey: "title",
    });

    function previewClicked(entry) {
        setPreviewOpen(true);
        setPreviewData({ title: entry.title, documents: [{ uri: entry.url }] });
    }
    function downloadClicked(item) {}
    return (
        <div className="h-page">
            <PageHeader title="CVM Guides & Resources" />
            <PageDescription>
                Here you can find a collection of guides and resources to help
                you operate CVM and this website.
            </PageDescription>
            <div className="flex flex-col max-w-[64rem]">
                <div className="flex flex-col w-fit justify-end pt-2 relative self-end">
                    <ShownEntriesCount
                        count={filteredData?.length}
                        total={FAQsQuery.data?.length}
                    />
                    <TextSearchBar
                        onChange={setSearchQuery}
                        value={searchQuery}
                        placeholder="Search for a guide or resource"
                    />
                </div>
                <NoEntriesShown
                    filteredData={filteredData}
                    unfilteredData={FAQsQuery.data}
                />
                {FAQsQuery.isPending ? (
                    <HelpSettingsEntriesSkeleton />
                ) : (
                    <HelpEntries
                        items={filteredData}
                        previewClicked={previewClicked}
                        downloadClicked={downloadClicked}
                    />
                )}
            </div>
            <PreviewDocumentDialog
                dialogTitle={`Preview for '${previewData?.title}'`}
                dialogProps={{
                    open: previewOpen,
                    onOpenChange: setPreviewOpen,
                }}
                documents={previewData?.documents}
            />
        </div>
    );
};

const ShownEntriesCount = ({ count, total }) => {
    return (
        <span className="absolute -top-3 z-[1] right-0 text-muted-foreground text-sm font-semibold ">
            Showing {count} of {total}
        </span>
    );
};

const NoEntriesShown = ({ filteredData, unfilteredData }) => {
    const searchTooStrict =
        filteredData?.length === 0 && unfilteredData?.length > 0;
    const noEntries =
        filteredData?.length === 0 && unfilteredData?.length === 0;

    if (noEntries) {
        return (
            <div className="mt-4">
                <BlockAlert
                    variant={{
                        variant: "warning",
                    }}
                    title="No entries found."
                    message="There are no guides or resources to display. This could be due to an error or no entries existing."
                />
            </div>
        );
    }
    if (searchTooStrict) {
        return (
            <span className="text-muted-foreground">
                No entries found. Try a broader search.
            </span>
        );
    }
};
