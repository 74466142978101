import { PieChartSkeleton } from "@/components/skeletons/PieChartSkeleton";
import { useTheme } from "@/components/theme/theme-provider";
import { getManualPieConfig } from "@/routes/Main/Reporting/utils/ChartConfigs";
import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import mergeRefs from "merge-refs";
import React, { useMemo, useRef } from "react";
import { Doughnut } from "react-chartjs-2";
import CountUp from "react-countup";
import {
    customDataLabels,
    doughnutOptions,
} from "../../../utils/doughnutOptions";
import { UsageReportData } from "../useUsageReportData";

type UsageUserCountPieProps = {
    data: UsageReportData;
};
export const UsageUserCountPie = React.forwardRef<
    Chart<"doughnut", any, unknown>,
    UsageUserCountPieProps
>(({ data, ...props }, ref) => {
    const innerRef = useRef();
    const combinedRef = mergeRefs(ref, innerRef);
    const [resetTheme, setResetTheme] = React.useState(false);
    const { theme } = useTheme();
    React.useEffect(() => {
        setResetTheme((prev) => !prev);
    }, [theme]);
    const { activeCount, inactiveCount, neverRead } = data;
    const config = useMemo(() => {
        const primaryColor = getComputedStyle(
            document.documentElement
        ).getPropertyValue("--secondary-foreground");
        // console.log(theme, primaryColor);
        return getManualPieConfig(
            [activeCount, inactiveCount, neverRead],
            ["#FD5B41", "#8E9AAE", `hsl(${primaryColor})`],
            ["Active", "Inactive", "Never used number"]
        );
    }, [data, resetTheme]);
    const options = useMemo(() => {
        return doughnutOptions({
            animations: !props?.isExporting,
            showTitle: false,
            showLegend: false,
            alignTitle: "center",
            legendPos: "bottom",
            backgroundColor: "transparent",
            legendDotSize: "20",
            legendFontSize: "16",
            displayTooltip: false,
            showAttachedLegend: false,
            showAttachedLabel: false,
            displayTailedAttachedLabel: true,
            tailedAttachedLabelFontSize: 16,
            tailedAttachedLabelStrokeWidth: 2,
            tailedAttachedLabelYOffset: 25,
            tailedAttachedLabelXOffset: 15,
            borderRadius: 0,
            spacing: 0,
            cutout: "80%",
            hoverOffset: 0,
            layout: { padding: 10 },
        } as any);
    }, [data]);
    return config ? (
        <div className="w-full min-h-fit max-h-none">
            <span className="font-semibold text-xl">Client activity</span>
            <div className="relative h-auto min-h-[20rem] w-[99%]">
                <Doughnut
                    ref={combinedRef}
                    options={options as any}
                    plugins={[ChartDataLabels, customDataLabels]}
                    {...config}
                />
                <div className="absolute flex flex-col items-center left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                    {/* <span className='font-semibold'>{commaNumber(activeCount + inactiveCount)}</span> */}
                    <CountUp
                        className="font-semibold"
                        start={0}
                        end={activeCount + inactiveCount}
                        separator=","
                    />
                    <span className="font-light mt-1">Total Clients</span>
                </div>
            </div>
            <div className="flex flex-col gap-1">
                <div className="flex items-center gap-2">
                    <div className="w-4 h-4 rounded-md bg-[#FD5B41]"></div>
                    <span className="font-medium">Active</span>
                </div>
                <div className="flex items-center gap-2">
                    <div className="w-4 h-4 rounded-md bg-[#8E9AAE]"></div>
                    <span className="font-medium">Inactive</span>
                </div>
                <div className="flex items-center gap-2">
                    <div className="w-4 h-4 rounded-md bg-secondary-foreground"></div>
                    <span className="font-medium">Never used their number</span>
                </div>
            </div>
        </div>
    ) : (
        <PieChartSkeleton />
    );
});

UsageUserCountPie.displayName = "UsageUserCountPie";
