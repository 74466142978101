import useAgency, { getAgencyQueryOptions } from '@/api/agencies/getAgency';
import useNumbersInAgency, { getNumbersInAgencyQueryOptions } from '@/api/numbers/getNumbersInAgency';
import { PageHeader } from '@/components/misc/PageHeader';
import { Table } from '@/components/tables/Table';
import { phoneNumbersColumnHelper } from '@/components/tables/columnHelpers/phoneNumbersColumnHelper';
import { useLoaderData, useParams } from 'react-router-dom';

export const AgencyMailboxes = ({...props}) => {
    const loaderData = useLoaderData()
    const {agencyID} = useParams()
    const agencyQuery = useAgency(agencyID)
    // const mailboxesQuery = useInboxesInAgency({agencyid: agencyID})
    const inboxesQuery = useNumbersInAgency(agencyID)
    
    console.log('aquery',inboxesQuery.data);
    return (
        <div>
            <PageHeader title={`Mailboxes For Agency ${agencyQuery.data?.Name}`}>

            </PageHeader>
            <Table
                showLoading={inboxesQuery.isPending}
                exportFileName={`mailboxes_in_agency_${agencyQuery.data?.Name}`}
                columnHelper={phoneNumbersColumnHelper}
                data={inboxesQuery.data}
            />
        </div>
    );
};

export const AgencyMailboxesLoader = (queryClient) => async ({params}) =>  {
    const {agencyID} = params
    const usersInAgencyData = await queryClient.ensureQueryData(getNumbersInAgencyQueryOptions(agencyID))
    const agencyData = await queryClient.ensureQueryData(getAgencyQueryOptions(agencyID))
    const inboxPromises = []
    // usersInAgencyData.forEach(user => {
    //     inboxPromises.push(queryClient.ensureQueryData(getNumbersInAgencyQueryOptions(user.Name)))
    // })
    const mailboxesInAgencyData = Promise.all(inboxPromises)
    return {usersInAgencyData, agencyData}
}