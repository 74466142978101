import { FormControl, FormDescription, FormField, FormHeader, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Control } from 'react-hook-form';
import { Checkbox } from '../../../components/ui/checkbox';
import { curry } from '../../../util/miscUtils';
import { Label } from '@/components/ui/label';


// type CheckboxEntry = 

type ComposedFormCheckboxGroupProps = {
    name: string;
    control: Control;
    small?: boolean;
    label?: string;
    placeholder?: string;
    description?: string;
    className?: string;
    oneLine?: boolean;
    entries?: {label: string, value: string }[];
    valueFn?: (entry: any) => any;
    labelFn?: (entry: any) => string | JSX.Element;
    subComponentFn?: (entry: any) => JSX.Element;
};

export const ComposedFormCheckboxGroup = ({
    name,
    control,
    small = false,
    label = `${name}`,
    placeholder = `Enter your ${name}`,
    entries=[],
    description,
    oneLine=false,
    className = '',
    valueFn= (entry) => entry?.value || entry?.id,
    labelFn= (entry) => (entry?.Name || entry?.label),
    subComponentFn = (entry) => null,
    ...props
}: ComposedFormCheckboxGroupProps) => { 
    
    function handleChange(newV, field, entry) {
        const entryVal = valueFn(entry) 
        const safeArr = field.value || []
        const neww = 
            newV 
                ? [...safeArr, entryVal]
                : safeArr.filter(v => v !== entryVal)
        console.log('new',field.value, neww, entryVal);
        newV 
            ? field.onChange([...safeArr, entryVal])
            : field.onChange(safeArr.filter(v => v !== entryVal))
    }
    return (
        <FormField
            control={control}
            name={name}
            render={({field}) => 
                <FormItem className='py-3'>
                    <FormHeader label={label} className='w-fit'/>
                    {description && <FormDescription className='!mt-1'>{description}</FormDescription>}
                    { entries.map((entry, index) => (
                        <div key={valueFn(entry)} className='ml-3'>
                            <FormControl>
                                <div className='w-fit'>
                                    <Label className="flex w-fit items-center gap-2 hover:cursor-pointer">
                                        <Checkbox
                                            checked={field.value?.includes?.(valueFn(entry))}
                                            onCheckedChange={(val) => handleChange(val, field, entry)}
                                            {...field}/>
                                        <Label className='pointer-events-none'>{labelFn(entry)}</Label>
                                    </Label>
                                </div>
                            </FormControl>
                            {subComponentFn(entry)}
                        </div>
                        ))
                    }
                </FormItem>
            }
            />  
    )
}

    


const OneLineCheckbox = ({control, name, label, description, ...props}) => {
    return (
        <FormField
        control={control}
        name={name}
        render={({field}) => (
            <FormItem className='flex items-start space-x-2 space-y-0'>
                <FormControl>
                    <Checkbox
                        checked={field.value}
                        onCheckedChange={field.onChange}/>
                </FormControl>
                <div className='flex flex-col justify-start space-y-1'>
                    {label && <FormLabel className="font-medium leading-tight">{label}</FormLabel>}
                    {description && <FormDescription className=''>{description}</FormDescription>}
                </div>
                    <FormMessage/>
                {/* <FormHeader label={label}/> */}
            </FormItem>
        )}
    />
    )
}
