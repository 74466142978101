import { postPromise } from "@/api/promises"
import { ROUTE_IDS } from "@/api/routeIDs"
import { composeFormData, convertObjFieldsToString } from "@/util/apiUtils"
import { useMutation, useQueryClient } from "@tanstack/react-query"

export type SignupWithInviteParams = {
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    code: string
}

function signupWithInvite(params: SignupWithInviteParams) {
    return postPromise(import.meta.env.VITE_API_BASE_URL, convertObjFieldsToString({...params, type: ROUTE_IDS.SIGNUP_WITH_INVITE}), {json: true}, {skipToken: true})
}

export const useSignupWithInvite = ({onSuccess, ...props}) => {
    const queryClient = useQueryClient()
    const mutation = useMutation({
        mutationKey: ['signupWithInvite'],
        mutationFn: signupWithInvite,
        onSuccess: (data: any, req) => {
            if (data?.status?.includes?.('not')) throw new Error(data.status)
            onSuccess && onSuccess(data, req)
        },
        ...props
    })
    return mutation
}