import { ROUTE_IDS } from "@/api/routeIDs";
import { composeFormData } from "@/util/apiUtils";
import { getCookie } from "@/util/cookieUtils";
import axios from "axios";

export const refreshToken =  () => {
    const refreshPayload = new FormData();
      refreshPayload.append('type', ROUTE_IDS.REFRESH);
      let refreshToken = getCookie('refreshToken');
      if (refreshToken != null) {
          return axios.post(
            import.meta.env.VITE_API_BASE_URL,
            composeFormData({}, ROUTE_IDS.REFRESH),
            {
              headers: {
                Authorization: `Bearer ${refreshToken}`,
                Accept: '*/*',
              },
            }
          );
      } else {
        throw new Error('No refresh token found');
      }
}