import { MiniCard } from '@/components/cards/MiniCard';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { genHex } from '@/lib/utils';
import { rangeArray } from '@/util/miscUtils';
import { ColorPickerPopover } from '@/wrappers/ui/ColorPickerPopover';
import { RefreshCcw } from 'lucide-react';
import React from 'react';


export const ColorsControls = ({config, backgroundColor, datasetEnum, datasetColors, handleChange, ...props}) => {
    function randomizeColors(key) {
        const isArrayField = Array.isArray(config[key])
        let newColors = genHex()
        if (isArrayField) {
            const len = config[key].length
            newColors = rangeArray(0,len - 1).map(() => genHex())
        }
        handleChange(key, newColors)
    }
        // console.log('de',datasetEnum);
        // console.log(Object.values(datasetEnum));
    return (
        <MiniCard className={'flex-col items-start  h-fit gap-2'}>
            <ColorPickerPopover color={backgroundColor} label={'Background Color'} onChange={handleChange}/>
            <Separator/>
            <div className="flex flex-wrap gap-2">
                {Object.values(datasetEnum).map((d, i) => (
                        <ColorPickerPopover key={i} color={datasetColors[i]} label={d} onChange={(key, value) => {
                            const newColors = [...datasetColors]
                            newColors[i] = value
                            handleChange('datasetColors', newColors)
                        }}/>
                    ))}
                <Button
                    size={'iconsm'}
                    onClick={() => randomizeColors('datasetColors')}
                >
                    <RefreshCcw className='w-4 h-4'/>
                </Button>
            </div>
    {/*                 
            <div className="flex-1 flex space-x-2">

            </div> */}
        </MiniCard>
    );
};

