import { postPromise } from '@/api/promises';
import { ROUTE_IDS } from '@/api/routeIDs';
import { composeFormData } from '@/util/apiUtils';
import { queryOptions, useQueries, useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';

export const getAgenciesInBranch = ({queryKey}) => {
    const [_key, {branchid}] = queryKey;
	return postPromise(import.meta.env.VITE_API_BASE_URL, composeFormData({branchid}, ROUTE_IDS.GET_AGENCIES_IN_REGION));
}

export function useAgenciesInBranches({branchEntries}) {
    const queries = useQueries({
        queries: branchEntries.map(b => getAgenciesInBranchQueryOptions(b?.[0])),
        combine: useCallback((results) => {
            const isPending = results.some(({isPending}) => isPending);
            try {
                const d =  
                    isPending 
                    ? [] 
                    : results.reduce((acc, {data},idx) => 
                        [
                            ...acc, 
                            ...(data.map(a => 
                                ({...a,branchName: branchEntries?.[idx]?.[1] || ''})) )
                        ]
                        , [])
                return {
                    data: d,
                    isPending,
                }
                
            } catch (error) {
                console.log('oops', error);
                return {
                    data: [],
                    isPending: true
                }
            }
        },[branchEntries])
    });
    return queries;
}

export default function useAgenciesInBranch(branchID, config={}) {
    const query = useQuery(getAgenciesInBranchQueryOptions(branchID,config));
    return query;
}
export const getAgenciesInBranchQueryOptions = (branchid,config={}) => queryOptions({
    queryKey: [ROUTE_IDS.GET_AGENCIES_IN_REGION, {branchid}],
    enabled: branchid != undefined,
    staleTime: 1000 * 60 * 5,
    queryFn: getAgenciesInBranch,
    ...config
})