import { cva } from "class-variance-authority"
import { cn } from "../../../lib/utils"

const FieldRowVariants = cva(
    "flex gap-6 ",
    {
        variants: {
            variant: {
                large: "",
                medium: "w-full",
                small: "w-[15rem]"
            },
            width: {
                fit: 'w-fit',
                wide: 'w-full'
            },
        },
        defaultVariants: {
            variant: "small",
            width: 'fit'
        }
    }

)
export type FieldRowProps = {
    variant?: {
        variant?: 'small' | 'medium' | 'large' | undefined
        width?: 'fit' | 'wide' | undefined
    }
    children?: React.ReactNode | undefined;
    className?: string | undefined;
}

export const FieldRow = ({children, className, variant}: FieldRowProps) => children && (
    <div className={cn(FieldRowVariants(variant))}>
        {children}
    </div>
)