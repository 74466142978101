import { createContext, useContext, useRef, useState } from "react";

const ScrollContainerRefContext  = createContext(null);

export const useScrollContainerRef = () => {
    const scrollContainerRef = useContext(ScrollContainerRefContext);
    if (!scrollContainerRef) {
        throw new Error("useScrollContainerRef must be used within a ScrollContainerRefProvider");
    }
    return scrollContainerRef;
}

export const ScrollContainerRefProvider = ({ children }) => {
    const scrollContainerRef = useRef()
    return (
        <ScrollContainerRefContext.Provider value={scrollContainerRef}>
            {children}
        </ScrollContainerRefContext.Provider>
    );
}
