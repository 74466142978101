import { ErrorAlert } from "@/components/blockalerts/ErrorAlert";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import { cn } from "@/lib/utils";
import DocViewer, { DocViewerRenderers, IDocument } from "@cyntler/react-doc-viewer";
import { DialogProps } from "@radix-ui/react-dialog";
import { DocViewerProps } from "node_modules/@cyntler/react-doc-viewer/dist/DocViewer";

type PreviewDocumentDialogProps = {
    dialogTitle?: string;
    docViewerProps?: DocViewerProps;
    dialogProps?: DialogProps;
    documents?: IDocument[];
};
export const PreviewDocumentDialog = ({
    dialogTitle,
    documents,
    docViewerProps,
    dialogProps,
}: PreviewDocumentDialogProps) => {
    // console.log(documents);
    const hasValidDocuments = documents && documents?.length > 0;
    return (
        <Dialog {...dialogProps}>
            <DialogContent
                className={cn(
                    "overflow-hidden grid-rows-[auto_1fr]",
                    hasValidDocuments && "h-[90vh] max-w-4xl"
                )}
            >
                {dialogTitle && (
                    <DialogHeader className="h-fit">
                        <DialogTitle>{dialogTitle}</DialogTitle>
                    </DialogHeader>
                )}
                {hasValidDocuments ? (
                    <DocViewer
                        className="h-full overflow-scroll"
                        // style={{ height: "1056px" }}
                        config={{
                            header: { disableHeader: true },

                        }}
                        pluginRenderers={DocViewerRenderers}
                        documents={documents}
                        {...docViewerProps}
                    />
                ) : (
                    <ErrorAlert
                        message={"There was an issue displaying the document"}
                        title="Error"
                    />
                )}
            </DialogContent>
        </Dialog>
    );
};
