import React from 'react';

const HowTo = () => {
    return (
        <div>
            guide content goes here
        </div>
    );
};

export default HowTo;