import fuzzysort from "fuzzysort";
import { useEffect, useMemo, useState } from "react";

type UseSearchedDataProps<DataType> = {
    data: DataType[];
    search: string;
    searchKey: string;
}

type PreparedData<DataType> = {
    searchKey: Fuzzysort.Prepared;
} & DataType


const defaultData = []
export const useSearchedData = <DataType,>({
    data,
    search,
    searchKey
}: UseSearchedDataProps<DataType>) => {
    const safeData = data || defaultData;
    const [filteredData, setFilteredData] = useState<DataType[]>(safeData);
    const preparedData: PreparedData<DataType>[] = useMemo(() => {
        return safeData.map((item) => ({
            ...item,
            searchKey: fuzzysort.prepare(item[searchKey])
        }))
    },[data]);

    useEffect(filterData,[preparedData, search]);

    function filterData() {
        if (search === "") {
            setFilteredData(safeData)
            return 
        }
        const res = fuzzysort.go(search, preparedData, {
            key: "searchKey",
        });
        setFilteredData(res.map((r) => r.obj));
    }
    console.log('filtered',filteredData);
    return filteredData
    
}