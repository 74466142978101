import { AgencyStatus } from "../components/forms/AgencyForm/AgencyStatus";
import { AgeRange, BranchStatus, ClientMiscResponse, CurrentHousing, DependentsOrFamily, Ethnicity, Gender, IncomeSources, MiscCharacteristics, Race, SuperBool, primaryDemographics } from "./constants";

export const getMockUsers = (numUsers=100) => {
    const users:{
        ageRange: string;
        gender: string;
        ethnicity: string;
        race: string;
        currentHousing: string;
        miscCharacteristics: string;
        clientMiscResponse: string;
        superBool: string;
        incomeSources: string[];
        dependentsOrFamily: string;
        primaryDemographics: string;
        agencyStatus: string;
        branchStatus: string;
    }[] = []

    for (let i = 0; i < numUsers; i++) {

        const u = {
            ageRange: getRandomEnumValue(AgeRange),
            gender: getRandomEnumValue(Gender),
            ethnicity: getRandomEnumValue(Ethnicity),
            race: getRandomEnumValue(Race),
            currentHousing: getRandomEnumValue(CurrentHousing),
            miscCharacteristics: getRandomEnumValue(MiscCharacteristics),
            clientMiscResponse: getRandomEnumValue(ClientMiscResponse),
            superBool: getRandomEnumValue(SuperBool),
            incomeSources: [getRandomEnumValue(IncomeSources),getRandomEnumValue(IncomeSources),getRandomEnumValue(IncomeSources)],
            dependentsOrFamily: getRandomEnumValue(DependentsOrFamily),
            primaryDemographics: getRandomEnumValue(primaryDemographics),
            agencyStatus: getRandomEnumValue(AgencyStatus),
            branchStatus: getRandomEnumValue(BranchStatus),
        };   
        users.push(u);
    }
    return users
}


function getRandomEnumValue(enumObject: any): string {
  const enumValues = Object.values(enumObject);
  const randomIndex = Math.floor(Math.random() * enumValues.length);
  return enumValues[randomIndex] as string;
}