import { Skeleton } from "@/components/ui/skeleton";
import { cn } from "@/lib/utils";

export const BarChartSkeleton = ({numRows=7,className=''}) => (
    <div className={cn("flex items-end gap-2", className)}>
        {Array(numRows).fill().map((_, i) => (
            <Bar key={i}/>
        ))}
    </div>
)

const Bar = () => (
    <Skeleton className="flex-1" style={{
        height:  Math.random() * 95 + 5 + '%',
    }}/>
)