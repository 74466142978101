import { useEffect, useState } from "react";
import { useCountUp } from "react-countup";
import { UseCountUpProps } from "react-countup/build/useCountUp";

type UseTooSoonProps = Partial<UseCountUpProps> & {
    initialTooSoon?: boolean;
    cooldownDuration: number;
}
export type UseTooSoonHook = {
    startCooldown: () => void;
    tooSoon: boolean;
    timer: ReturnType<typeof useCountUp>;
}

export const useTooSoon = ({
    ref= 'timer', 
    initialTooSoon=true, 
    cooldownDuration, 
    onEnd, 
    ...timerProps
}: UseTooSoonProps) : UseTooSoonHook => {
    const [tooSoon, setTooSoon] = useState(initialTooSoon);
    const timer = useCountUp({
        ref,
        start: cooldownDuration, 
        end: 0, 
        duration: 20, 
        useEasing: false, 
        startOnMount: initialTooSoon,
        onEnd: () => {
            setTooSoon(false)
            onEnd(timer)
        },
        ...timerProps
    });

    useEffect(() => {
        timer.start()
    },[tooSoon])
        
        return {
            startCooldown: () => setTooSoon(true),
            tooSoon,
            timer
        } 
}