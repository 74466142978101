import { Button, ButtonProps, buttonVariants } from '@/components/ui/button';
import { UseTooSoonHook } from '@/hooks/wrappers/useTooSoon';
import { cn } from '@/lib/utils';
import { VariantProps } from 'class-variance-authority';
import React from 'react';
import { useCountUp } from 'react-countup';

type TooSoonButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    tooSoonHook: UseTooSoonHook,
    size?: VariantProps<typeof buttonVariants>['size'],
    render: (props: {tooSoon: boolean, timer: React.ReactNode}) => React.ReactNode,

}
export const TooSoonButton = ({
    onClick, 
    tooSoonHook, 
    size='sm',
    render, 
    className, 
    ...props
}: TooSoonButtonProps) => {
    const {tooSoon, startCooldown} = tooSoonHook
    function handleClick(e) {
        if (tooSoon) return;
        startCooldown()
        onClick(e)
    }

    const timer = <div id='timer' className='inline px-0.5'/>
    return  (
        <Button
            onClick={handleClick}
            variant='link'
            size={size}
            className={cn(
                'px-1 py-0 my-0 h-fit text-secondary-foreground font-medium', 
                tooSoon && 'cursor-not-allowed !no-underline',
                className)}
            {...props}>
                {render({tooSoon, timer})}
            </Button>
    
)
};
