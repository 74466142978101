import { Button } from "@/components/ui/button";

export const CancelExportButton = ({ isExporting, onClick, ...props }) => {
    return (
        isExporting && (
            <Button
                onClick={onClick}
                className="flex text-md justify-between items-center bg-error-background text-error-content w-36"
            >
                Cancel Export
            </Button>
        )
    );
};
