import { useEffect } from "react";
import { useRHF } from "../../../hooks/useRHF";
import { useYupValidationResolver } from "../../../hooks/useYupValidationResolver";
import { enumToOptions } from "../../../lib/utils";
import { BranchStatus } from "../../../util/constants";
import { ComposedForm } from "../../../wrappers/ui/form/ComposedForm";
import { ComposedFormSelect } from "../../../wrappers/ui/form/ComposedFormSelect";
import { ComposedFormText } from "../../../wrappers/ui/form/ComposedFormText";
import { ComposedFormTextarea } from "../../../wrappers/ui/form/ComposedFormTextarea";
import Yup from "../../../wrappers/yup";
import { FormSection } from "../../ui/FormSection";
import { FieldGroup } from "../formComponents/FieldGroup";
import { FieldRow } from "../formComponents/FieldRow";

const validationSchema = Yup.object({
    prefix: Yup.string(),
    displayName: Yup.string().smartLabel().required("required"),
    status: Yup.mixed().oneOfEnum(BranchStatus).required("required"),
    yearLaunched: Yup.number().required("required"),
    address: Yup.string().required("required"),
    city: Yup.string().required("required"),
    province: Yup.string().required("required"),
    postalCode: Yup.string().required("required"),
    mainPhone: Yup.string().required("required"),
    mainFax: Yup.string().required("required"),
    notes: Yup.string().notRequired(),
});
export const BranchStatusAndContact = ({
    onSubmit,
    hasPrev,
    branchData,
    sectionIndex,
}) => {
    const resolver = useYupValidationResolver(validationSchema);
    const form = useRHF({
        resolver,
        defaultValues: validationSchema.getDefault(),
    });
    const { register, handleSubmit, formState, setValue, reset, control } =
        form;
    useEffect(loadData, [branchData]);
    function loadData() {
        if (!branchData) return;
        reset(branchData);
    }
    return (
        <FormSection {...{ sectionIndex, title: "Branch Status and Contact" }}>
            <ComposedForm {...{ form, onSubmit }}>
                <FieldGroup>
                    <ComposedFormText
                        disabled
                        small
                        placeholder=""
                        label="Prefix"
                        name={"prefix"}
                        control={control}
                    />
                    <ComposedFormText
                        small
                        placeholder="Name of branch"
                        label="Display Name"
                        name={"displayName"}
                        control={control}
                    />
                    <ComposedFormSelect
                        control={control}
                        name={"status"}
                        label="Branch Status"
                        valueFn={(value) => value?.value}
                        renderFn={(value) => value?.label}
                        options={enumToOptions(BranchStatus)}
                    />
                    <ComposedFormText
                        small
                        placeholder="2021"
                        label="Year Launched"
                        name={"yearLaunched"}
                        control={control}
                    />
                </FieldGroup>
                <FieldGroup>
                    <FieldRow>
                        <ComposedFormText
                            small
                            placeholder="British Columbia"
                            label="Province"
                            name={"province"}
                            control={control}
                        />
                        <ComposedFormText
                            small
                            placeholder="Vancouver"
                            label="City"
                            name={"city"}
                            control={control}
                        />
                    </FieldRow>
                    <FieldRow>
                        <ComposedFormText
                            small
                            placeholder="123 Fake St"
                            label="Address"
                            name={"address"}
                            control={control}
                        />
                        <ComposedFormText
                            small
                            placeholder="Ex. VJS 3G3"
                            label="Postal Code"
                            name={"postalCode"}
                            control={control}
                        />
                    </FieldRow>
                </FieldGroup>
                <FieldGroup title="Contact Info">
                    <ComposedFormText
                        small
                        placeholder="123-456-7890"
                        label="Main Phone"
                        name={"mainPhone"}
                        control={control}
                    />
                    <ComposedFormText
                        small
                        placeholder="123-456-7890"
                        label="Main Fax"
                        name={"mainFax"}
                        control={control}
                    />
                </FieldGroup>
                <ComposedFormTextarea
                    label="Branch Notes"
                    placeholder=""
                    name={"notes"}
                    control={control}
                />
            </ComposedForm>
        </FormSection>
    );
};
