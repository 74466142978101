export const LegendEntry = ({color, label, value}) => (
    <div className="flex items-center gap-2">
        <div className={`w-4 h-4 rounded-md`} style={{backgroundColor: color}}></div>
        <span className='font-medium'>
            {label}
        </span>
        {value != null && (
            <span className="text-muted-foreground">
                ({value})
            </span>
        )}
    </div>
)