import { postPromise, putPromise } from "@/api/promises"
import { ROUTE_IDS } from "@/api/routeIDs"
import { composeFormData, convertObjFieldsToString } from "@/util/apiUtils"
import { MICROSERVICE_URL } from "@/util/constants"
import { deleteAllCookies } from "@/util/cookieUtils"
import { consumePresignedUrl } from "@/util/queryUtils"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import axios from "axios"

type modFAQParams = {
    id: string,
    title: string,
    file: File,
    changeFile: boolean
    changeTitle: boolean
}
export const modFAQ = async (params: modFAQParams) => {
    const {id, title, file, changeFile, changeTitle} = params
    const modResult = await putPromise(`${MICROSERVICE_URL}/faq`, {id,title, changeFile, changeTitle}, {json:true})
    if (!params.changeFile) 
        return
    console.log(modResult);
    const {presignedUrl} = modResult
    const tempApi = axios.create()
    return consumePresignedUrl(tempApi, file, file.type, presignedUrl)
    // return {token: 'token', refreshToken:'refreshToken'}
}

export const useModFAQ = ({onSuccess, ...otherProps}) => {
    const client = useQueryClient();
    const mutation = useMutation({
        mutationKey: ['faq'],
        mutationFn: modFAQ,
        onSuccess: (data, req) => {
            client.invalidateQueries({queryKey: ['faq']})
            onSuccess && onSuccess(data, req)
        },
    ...otherProps})
    return mutation;
}