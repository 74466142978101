import { MILLISEC } from "@/util/constants";
import { getDateString } from "@/util/textUtils";
import { intersection } from "lodash";

export function selectThirtyDayInactive(data) {
    try {
        const thirtyDaysAgo = new Date().getTime() - MILLISEC.DAY * 30
        return data.filter(d => !d.recentReadMsgTime || Number(d.recentReadMsgTime) < thirtyDaysAgo)
        
    } catch (error) {
        console.log('errir', error);
    }
}

export function parseBooleans(dataObj) {
    return Object.fromEntries(Object.entries(dataObj).map(([k,v]) => {
        if (v === 'true') return [k, true]
        if (v === 'false') return [k, false]
        return [k,v]
    }))
}

export function parseServerEnums(dataObj, enums, fields) {
    if (!fields) throw new Error('Fields param must be an array.')
    if (!Array.isArray(fields))  throw new Error('Fields param must be an array.')
    const enumsIsArray = Array.isArray(enums)   
    if (enumsIsArray && !(fields?.length === enums.length)) throw new Error('Fields length must match enums length.')
    const enumEntries = 
        fields.map((f,idx) => {
            try {
                const dataEnumArr = dataObj[f] || []
                const ids = enumsIsArray
                    ? dataEnumArr.map(enumName => 
                        parseInt(enumName) 
                            ? enumName 
                            : enums[idx].find(e => {console.log(e, enumName); return (e.Name === enumName || e.id === enumName)}).id)
                    : dataEnumArr.map(enumName => enums[f].find(e => e.Name === enumName || e.id === enumName).id)
                return [f, ids]
            } catch (error) {
                console.log('error', error, f, dataObj[f]);
            }
        })
        return {
            ...dataObj,
            ...Object.fromEntries(enumEntries)
        }
        

}

export function consumePresignedUrl(api, uploadFile, type, url, fileName=undefined) {
    const safeFileName = fileName || uploadFile.name
    console.log('uploadedFile', uploadFile);
    const options = { 
        headers: { 
            "Content-Type": uploadFile.type ,
            "Content-Disposition": `attachment; filename=${safeFileName}`,
        } 
    };
    return api.put(url, uploadFile, options);
}

export function invalidateQueryKeys(queryClient, keys) {
    return Promise.all(
        keys.map(key => queryClient.invalidateQueries({queryKey: key}))
    )
}

export function condenseDuplicateNumbers(numArray) {
    const numMap = new Map();
    const sortedData = numArray.sort((a, b) => Number(b.timestamp) - Number(a.timestamp));
    sortedData.forEach((num) => {
        const number = Number(num.alias);
        if (numMap.has(number)) {
            numMap.get(number).pastClients.push(num)
        }
        else {
            num.pastClients = []
            numMap.set(number, num);
        }
    });
    return [...numMap.values()]
}

// 1581008179000 
// 1714712752990 
//  133 704 573 990