import React from "react";
export const Background = ({ ...props }) => {
    return (
        <>
            <div className="fixed z-[2] top-0 right-0 w-screen h-screen bg-black bg-opacity-30"></div>
            <figure className="h-screen w-screen fixed top-0 left-0">
                <img
                    src="https://main.d5e6lrcr2bdyr.amplifyapp.com/auth-bg.jpg"
                    className="h-full w-full z-[1] object-cover"
                />
            </figure>
        </>
    );
};