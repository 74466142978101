import { Select } from '@/wrappers/ui/Select';
import React from 'react';


export const PageSizeDropdown = ({table}) => {
    const {pageIndex, pageSize } = table?.getState?.()?.pagination || {}
    return (
        <Select
            closeOnItemSelect={false}
            closeOnPointerOut={true}
            inForm={false}
            buttonClassName='w-[110px]'
            popupClassName='w-[110px]'
            options={[10,20,50]}
            value={pageSize}
            valueFn={(item) => item}
            renderFn={RowEntry}
            handleChange={(e) => table?.setPageSize(e)}

        />
    );
};



function RowEntry(val) {
    return <p className='flex justify-justify-between w-[80px]'>{val} <span className='opacity-40 ml-2 place-self-end'>rows</span></p>
}

