import { postPromise } from "@/api/promises";
import { ROUTE_IDS } from "@/api/routeIDs";
import { convertObjFieldsToString } from "@/util/apiUtils";
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const modBranchAdmin = (params) => {
    // const {_key, params} = queryKey;
    // return new Promise(r => r({
        
    // }))
// return errorPromise()
    return postPromise(import.meta.env.VITE_API_BASE_URL, convertObjFieldsToString({...params, type:ROUTE_IDS.MOD_USER}), {json:true})
}

export default function useModBranchAdmin({onSuccess,...props}={}) {
    const client = useQueryClient()
    const mutation = useMutation({
        mutationKey: ['user','branch', ROUTE_IDS.MOD_USER],
        mutationFn: modBranchAdmin,
        onSuccess: (data, req) => {
            client.invalidateQueries({queryKey: ['user', 'branch']})
                .then(() => 
                    onSuccess && onSuccess(data,req))
        },
        ...props
    });
    return mutation;
}