import { getChartColors, getManualPieConfig } from '@/routes/Main/Reporting/utils/ChartConfigs';
import React, { useMemo, useRef } from 'react';
import { Doughnut, Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart } from 'chart.js';
import CountUp from 'react-countup';
import { DemographicsData } from '@/components/forms/ClientForm/Demographics';
import { customDataLabels, doughnutOptions } from '@/routes/Main/Reporting/utils/doughnutOptions';
import { mergeUnknownAndNoData } from '@/util/miscUtils';
import { DemographicsReportData } from '@/routes/Main/Reporting/Presets/DemographicsReport/useDemographicsReportData';
import mergeRefs from 'merge-refs';
import { LegendEntry } from '@/components/charting/LegendEntry';
import { PieChartSkeleton } from '@/components/skeletons/PieChartSkeleton';

type GenderPieProps = {
    data: DemographicsReportData,
    hideUnknown: boolean
};

export const GenderPie = React.forwardRef<Chart<"doughnut", any, unknown>, GenderPieProps>(({ data, hideUnknown,...props }, ref) => {
    const internalRef = useRef()
    const [labels, values, colors] = useMemo(() => {
        if (!data.gender) return [[],[]];
        const withMergedUnknown = mergeUnknownAndNoData(data.gender)
        withMergedUnknown.Unknown += data.withoutProfileCount
        const [l, v] = Object
            .entries(withMergedUnknown)
            .reduce(
                (acc, [key, value]) => {
                    if (hideUnknown && key === 'Unknown') return acc;
                    acc[0].push(key);
                    acc[1].push(value);
                    return acc;
                },
                [[],[]])
        const c = getChartColors(l.length)
        return [l, v, c]
    },[data, hideUnknown])
    const config = useMemo(() => {
        if (!data.ageRange) return null;
        return getManualPieConfig(
            values,
            colors,
            labels,
        );
    }, [data, labels, values]);
    const options = useMemo(() => {
        return doughnutOptions({
            animations: !props?.isExporting,
            showTitle: false,
            showLegend: false,
            alignTitle: 'center',
            legendPos: 'bottom',
            backgroundColor: 'transparent',
            legendDotSize: '20',
            legendFontSize: '16',
            displayTooltip: false,
            showAttachedLegend: false,
            showAttachedLabel: false,
            displayTailedAttachedLabel: false,
            tailedAttachedLabelFontSize: 16,
            tailedAttachedLabelStrokeWidth: 2,
            tailedAttachedLabelYOffset: 25,
            tailedAttachedLabelXOffset: 15,
            borderRadius: 0,
            spacing: 0,
            // cutout: '80%',
            hoverOffset: 0,
            layout: { padding: {
                left: 15,
                right: 10
            } },
        } as any);
    }, []);
    
    console.log(internalRef.current);
    return config ? (
        <div className='w-full h-full'>
            <span className='font-semibold text-xl'>Gender</span>
            <div className='h-[20rem] relative -mb-2 '>
                <Pie
                    ref={mergeRefs(ref, internalRef)}
                    options={options as any}
                    plugins={[ChartDataLabels, customDataLabels]}
                    {...config}
                />
                {/* <div className="absolute flex flex-col items-center left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                    <CountUp className='font-semibold' start={0} end={data.withProfileCount} separator=','/>
                    <span className='font-light mt-1'>Total Messages</span>
                </div> */}
            </div>
            <div className='flex flex-col gap-1 justify-center'>
                {labels.map((label, index) => (
                    <LegendEntry key={label} value={values[index]} color={colors[index]} label={label}/>
                ))}
            </div>
        </div>
    ) : (
        <PieChartSkeleton/>
    );
});


GenderPie.displayName = 'AgeDoughnut';