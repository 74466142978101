import React, { useEffect } from 'react';
import Yup from '../../../wrappers/yup';
import { RadioGroup } from '../../ui/radio-group';
import { useRHF } from '../../../hooks/useRHF';
import { useYupValidationResolver } from '../../../hooks/useYupValidationResolver';
import { checkFormForAutofill } from '../../../util/formUtils';
import { ComposedFormRadio } from '../../../wrappers/ui/form/ComposedFormRadio';
import { Form, FormControl, FormDescription, FormField, FormHeader, FormItem } from '../../ui/form';
import { SuperBool } from '../../../util/constants';
import { cn, enumToOptions } from '../../../lib/utils';
import { ComposedFormDatepicker } from '../../../wrappers/ui/form/ComposedFormDatepicker';
import { FormSection } from '../../ui/FormSection';
import { Separator } from '../../ui/separator';
import { ComposedFormSelect } from '../../../wrappers/ui/form/ComposedFormSelect';
import { ComposedFormText } from '../../../wrappers/ui/form/ComposedFormText';
import { useParams, useSearchParams } from 'react-router-dom';
import useModClient from '@/api/numbers/modClient';
import { Button } from '@/components/ui/button';
import { useQueryResultToasts } from '@/wrappers/toasts';
import { ShowWhenFieldSibling } from '@/wrappers/ui/form/ShowWhenFieldSibling';
import { Input } from '@/components/ui/input';
import { Toggle } from '@/components/ui/toggle';
import { FieldGroup } from '@/components/forms/formComponents/FieldGroup';


export type ParticipantInformationData = {
    agency: object,
    phoneNumber?: string,
    dateEnrolled: string,
    firstName: string,
    middleName?: string,
    lastName: string,
    language: string | 'en',
    email?: string,
    clientPassword?: string,
    hasCellPhone?: boolean,
    existingCellNumber?: string,
    staffThatEnrolled?: object,
    comment?: string,
    hasStatusCard?: boolean,
}

export const ParticipantInformation = ({form, enumsQuery, clientData, sectionIndex, ...props}) => {
    const {register, handleSubmit, formState, setValue, reset, control} = form

    return (
        <FormSection sectionIndex={sectionIndex} title={'Participant Information'}>
            {/* Client info column */}
            <input className='h-0' name='objectid' />
            <div className="flex flex-col w-fit gap-6">
                <FieldGroup labelPosition='center' title='Client'>
                    <span className='font-medium leading-tight '>Name</span>
                    <div className="flex gap-2 items-end"> 
                        <FormField
                            control={control}
                            name={'firstName'}
                            render={({field}) => (
                                <FormItem className=''>
                                    {/* <FormDescription className='!mt-0 '>First</FormDescription> */}
                                    <FormControl className='flex  '>
                                        <Input className={cn('w-[10rem] !mt-0 h-10 py-5')} placeholder={'First'} {...field} value={field.value || ''} {...props}/>      
                                    </FormControl>
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={control}
                            name={'middleName'}
                            render={({field}) => (
                                <FormItem className=''>
                                    {/* <FormDescription className='!mt-0 '>Middle</FormDescription> */}
                                    <FormControl className='flex  '>
                                        <Input className={cn('w-[10rem] !mt-0 h-10 py-5')} placeholder={'Middle'} {...field} value={field.value || ''} {...props}/>      
                                    </FormControl>
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={control}
                            name={'lastName'}
                            render={({field}) => (
                                <FormItem className=''>
                                    {/* <FormDescription className='!mt-0 '>Last</FormDescription> */}
                                    <FormControl className='flex  '>
                                        <Input className={cn('w-[10rem] !mt-0 h-10 py-5')} placeholder={'Last'} {...field} value={field.value || ''} {...props}/>      
                                    </FormControl>
                                </FormItem>
                            )}
                        />
                    </div>
                    <ComposedFormText
                        variant='large'
                        control={control}
                        placeholder='Ex. johnsmith@gmail.com'
                        name="email"
                        label="Email address"/>
                    <ComposedFormText
                        variant='large'
                        control={control}
                        placeholder='Ex. 9023'
                        name="clientPassword"
                        label="Client password"/>
                    <ComposedFormRadio
                        control={control}
                        name="hasCellPhone"
                        label="Has cell phone"
                        options={[
                            {label: 'Yes', value: true},
                            {label: 'No', value: false},
                        ]}
                    />
                    <ShowWhenFieldSibling
                        sibling='hasCellPhone'
                        is={true}
                    >
                        <div className="pt-2">
                            <ComposedFormText
                                control={control}
                                placeholder=''
                                name="cellNumber"
                                label="Cell Phone Number"/>

                        </div>
                    </ShowWhenFieldSibling>
                </FieldGroup>
                {/* Misc info column */}
                <FieldGroup labelPosition='center' title='Misc'>
                    <ComposedFormDatepicker
                        variant='large'
                        control={control}
                        name={'dateEnrolled'}
                        label='Usage start date'
                        />    
                    <ComposedFormText
                        variant='large'
                        control={control}
                        placeholder=''
                        name="staffThatEnrolled"
                        label="Staff assisting with enrolment"/>

                    <ComposedFormText
                        variant='large'
                        control={control}
                        placeholder=''
                        name="comment"
                        label="Notes"/>
                </FieldGroup>
            </div>
        </FormSection>
    );
};
