import useAllNumbers, {
    getAllNumbersQueryOptions,
} from "@/api/numbers/getAllNumbers";
import { getMyUserDataQueryOptions } from "@/api/user/getMyUserData";
import { PageHeader } from "@/components/misc/PageHeader";
import { Table } from "@/components/tables/Table";
import { phoneNumbersColumnHelper } from "@/components/tables/columnHelpers/phoneNumbersColumnHelper";
import { ShowActiveNumbersControls } from "@/components/tables/helpers/ShowActiveNumbersControls";
import { timeStampToFilter } from "@/components/tables/helpers/tableUtils";
import { JCheckbox } from "@/wrappers/ui/JCheckbox";
import { useEffect, useState } from "react";
import { redirect } from "react-router-dom";

export const AllPhoneNumbers = ({ ...props }) => {
    const allNumbersQuery = useAllNumbers();
    const [minLastReadTime, setMinLastReadTime] = useState(null);
    const [showNiceDates, setShowNiceDates] = useState(false);
    // useEffect(() => {
    //     console.log("finding numbers with more than one client");
    //     if (!allNumbersQuery.data) return;
    //     const duplicates = allNumbersQuery.data?.filter((number) => {
    //         return number.pastClients?.length > 0;
    //     });
    //     console.log(
    //         duplicates.find(d => d.alias === '7783312508')
    //     );
    //     console.log("numbers with more than one client", duplicates.map(d => d.alias));
        
    // }, [allNumbersQuery.data]);
    return (
        <div className="h-full flex  flex-col">
            <PageHeader title={"All Phone Numbers"}></PageHeader>
            <Table
                retrieveSearchParamFilters
                showColumnVisibility
                exportFileName="phone_numbers"
                pdfName={"Phone Numbers"}
                data={allNumbersQuery.data}
                showLoading={allNumbersQuery.isPending}
                columnHelper={phoneNumbersColumnHelper}
                maxHeightPixels={"100%"}
                extraFilters={timeStampToFilter(minLastReadTime, "Last Read")}
                meta={{
                    showNiceDates
                }}
            >
                <ShowActiveNumbersControls
                    minLastReadTime={minLastReadTime}
                    setMinLastReadTime={setMinLastReadTime}
                />
                <JCheckbox 
                    className="py-1 h-10 bg-background"
                    value={showNiceDates}
                    handleChange={setShowNiceDates}
                    label="Show Nice Dates"
                />
            </Table>
        </div>
    );
};

export const allPhoneNumbersLoader = (queryClient) => async () => {
    const myData = await queryClient.ensureQueryData(getMyUserDataQueryOptions);
    if (!Number(myData.adminLevel) > 10) redirect("/");
    const allNumbersData = queryClient.ensureQueryData(
        getAllNumbersQueryOptions
    );
    console.log("all numbers loader data", allNumbersData);
    return { allNumbersData };
};
