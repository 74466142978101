import {create} from "zustand"

interface AlertProps {
    title?: string;
    message?: string;
    showConfirm?: boolean;
    confirmText?: string;
    showCancel?: boolean;
    cancelText?: string;
    status?: "info" | "success" | "warning" | "error" | 'default' ;
}

interface AlertOutcome {
    confirmed: boolean;
    cancelled: boolean;
}

interface AlertState {
    isOpen: boolean;
    open: () => void;
    close: () => void;
    outcome: AlertOutcome;
    onClose: () => void;
    confirm: () => void;
    cancel: () => void; 
    alertProps: AlertProps;
    alert: (alertProps: Partial<AlertProps>) => Promise<AlertOutcome>;
}

const initialOutcome = {
    confirmed: false,
    cancelled: false
}

const initialAlertProps = {
    title: "Are you Sure?",
    message: "",
    showConfirm: true,
    confirmText: "Confirm",
    showCancel: true,
    cancelText: "Cancel",
    status: "default" as const
}   

export const useAlert = create<AlertState>((set,get) => ({
    isOpen: false,
    open: () => set({ isOpen: true }),
    close: () => {set({ isOpen: false, outcome: initialOutcome }), get().onClose() },
    onClose: ()=>{},
    outcome: initialOutcome,
    alertProps: initialAlertProps,
    confirm: () => {
        set({ isOpen:false, outcome: { confirmed: true, cancelled: false } })
        get().onClose()
    },
    cancel: () => {
        set({ isOpen:false, outcome: { confirmed: false, cancelled: true } })
        get().onClose()
    },
    alert: (alertProps) => {
        // console.log('alertProps', alertProps, get().alertProps);
        return new Promise((resolve) => {
            set(state => ({...state, alertProps: {...state.alertProps, ...alertProps}, isOpen: true, onClose: () => resolve(get().outcome) }))
        })
    },
}) )