import { AgencyCell } from "@/components/tables/cells/AgencyCell";
import { BranchCell } from "@/components/tables/cells/BranchCell";
import { DateCell } from "@/components/tables/cells/DateCell";
import { LinkedClientCell } from "@/components/tables/cells/LinkedClientCell";
import { dateFilterFn, maxDateFilterFn, minDateFilterFn } from "@/components/tables/helpers/filterFns";
import { Badge } from "@/components/ui/badge";
import { cn } from "@/lib/utils";
import { MILLISEC } from "@/util/constants";
import { nullNum } from "@/util/miscUtils";
import { formatPhoneNumber, getDateString, getNiceDate } from "@/util/textUtils";
import { createColumnHelper, filterFns } from "@tanstack/react-table";
import { useMemo } from "react";

const columnHelper = createColumnHelper();

const stringFilter = filterFns.includesString;
const numRangeFilter = filterFns.inNumberRange

export const phoneNumbersColumnHelper = [
    columnHelper.accessor((row) => row.Name, {
        id: "objectid",
        size: 10,
        meta: {
            hiddenFilter:true,
            hiddenCol:true,
            hideExport:true
        },
        filterFn: stringFilter,
        cell: (info) => <span>{info.getValue()}</span>,
        header: (info) => <span>ID</span>,
    }),
    columnHelper.accessor((row) => row.id, {
        id: "ID",
        size: 10,
        meta: {
            hiddenFilter:true,
            hiddenCol:true,
            hideExport:true
        },
        filterFn: stringFilter,
        cell: (info) => <span>{info.getValue()}</span>,
        header: (info) => <span>ID</span>,
    }),
    columnHelper.accessor((row) => row.alias, {
        id: "alias",
        size: 10,
        filterFn: stringFilter,
        meta: {
            columnLabel: 'Phone Number',
        },
        cell: (info) => <span className="whitespace-nowrap text-md font-semibold">{formatPhoneNumber(info.getValue())}</span>,
        header: (info) => <span>Phone Number</span>,
    }),
    columnHelper.accessor((row) => row.branchname, {
        id: "Branch",
        size: 100,
        filterFn: stringFilter,
        cell: ({row: {original: row}}) => <BranchCell name={row.branchname} id={row.branchid}/>,
        header: (info) => <span>Branch</span>,
    }),
    columnHelper.accessor((row) => row.agencyname, {
        id: "Agency",
        size: 10,
        filterFn: stringFilter,
        cell: ({row: {original: row}}) => <AgencyCell name={row.agencyname} id={row.agencyid} branchid={row.branchid}/>,
        header: (info) => <span>Agency</span>,
    }),
    columnHelper.accessor((row) => `${row?.firstName || ''} ${row?.lastName ||''}`, {
        id: "Cisco Name",
        size: 10,
        filterFn: stringFilter,
        meta: {
            hiddenCol: true,
            hiddenFilter: true,
            hideExport: true
        },
        cell: ({getValue, row}) => <span>{getValue()}</span>,
        header: (info) => <span className="whiteno">Cisco Name</span>,
    }),
    // columnHelper.accessor((row) => `${row?.clientdata?.firstName || ''} ${row?.clientdata?.lastName ||''}`, {
    //     id: "Name",
    //     meta: {
    //         // hiddenCol: true,
    //         // hiddenFilter: true,
    //         hideExport: true
    //     },
    // }),
    // columnHelper.display({
    //     id: "Msg Times",
    //     size: 200,
    //     meta: {
    //         hideExport: true,
    //     },
    //     cell: ({row}) => <MessageTimesCell row={row}/>,
    //     header: (info) => <span>Message Times</span>,
    // }),
    columnHelper.accessor((row) => nullNum(row?.recentReadMsgTime), {
        id: "Last Read",
        size: 100,
        filterFn: minDateFilterFn,
        sortUndefined: 'last',
        cell: ({row, getValue, table}) => <LastActiveCell {...{row, getValue, table}}/>,
        header: (info) => <span>Last Listened Msg</span>,
    }),
    columnHelper.accessor((row) => nullNum(row?.timestamp), {
        id: "Date Reset",
        size: 100,
        filterFn: minDateFilterFn,
        sortUndefined: 'last',
        cell: ({row, getValue, table}) => <DateResetCell {...{row, getValue, table}}/>,
        header: (info) => <span>Last Reset</span>,
    }),

    // The field names are backwards
    // columnHelper.accessor((row) => nullNum(row.firstMsgTime) , {
    //     id: "First received",
    //     size: 100,
    //     filterFn: dateFilterFn,
    //     meta: {
    //         filterType: 'date',
    //         hiddenCol: true,

    //     },
    //     cell: ({row, getValue}) => <span className='whitespace-nowrap'>{<DateCell concise value={getValue()}/>}</span>,
    //     header: (info) => <span>First Received Msg</span>,
    // }),
    // The field names are backwards
    // columnHelper.accessor((row) => nullNum(row.lastMsgTime) , {
    //     id: "Last Received",
    //     size: 100,
    //     filterFn: dateFilterFn,
    //     meta: {
    //         filterType: 'date',
    //         hiddenCol: true,
    //     },
    //     cell: ({row, getValue}) => <span className='whitespace-nowrap'>{<DateCell concise value={getValue()}/>}</span>,
    //     header: (info) => <span>Last Received Msg</span>,
    // }),
    // columnHelper.display({
    //     id: "Msg Counts",
    //     // size: 200,
    //     meta: {
    //         hideExport: true,
    //     },
    //     cell: ({row}) => <MessagesCell row={row}/>,
    //     header: (info) => <span>Msg Counts</span>,
    // }),
    columnHelper.accessor((row) => Number((row.unreadMsgs) || 0), {
        id: "Unread",
        size: 100,
        filterFn: numRangeFilter,
        meta: {
            // hiddenCol: true
        },
        cell: (info) => <span className=''>{info.getValue()}</span>,
        header: (info) => <span>Unheard Msgs</span>,
    }),
    columnHelper.accessor((row) => Number((row.readMsgs) || 0), {
        id: "Read",
        size: 100,
        filterFn: numRangeFilter,
        meta: {
            // hiddenCol: true
        },
        cell: (info) => <span >{info.getValue()}</span>,
        header: (info) => <span>Listened Msgs</span>,
    }),
    columnHelper.accessor((row) => Number((row.totalMsgs) || 0), {
        id: "Total",
        size: 100,
        filterFn: numRangeFilter,
        meta: {
            // hiddenCol: true
        },
        cell: (info) => <span className=''>{info.getValue()}</span>,
        header: (info) => <span>Total Msgs</span>,
    }),
    columnHelper.accessor((row) => numberIsActive(row) , {
        id: 'Active',
        filterFn: 'equals',
        size: 100,
        meta: {
            hiddenFilter: true,
        },
        cell: (info) => <ActiveCell row={info.row}/>,
        header: (info) => <span>Active</span>,
    }),
    columnHelper.accessor((row) => `${row.clientdata?.firstName || ''} ${row.clientdata?.lastName ||''}`, {
        id: "Client",
        size: 10,
        filterFn: stringFilter,
        cell: ({row: {original}}) => <LinkedClientCell row={original}/>,
        header: (info) => <span>Client Profile</span>,
    }),
]

function numberIsActive(row) {
    const safe = nullNum(row?.recentReadMsgTime)
    const thirtyDaysAgo = new Date().getTime() - MILLISEC.DAY * 30;
    return safe != null && safe > thirtyDaysAgo
}

function ActiveCell({row}) {
    const isActive = useMemo(() => numberIsActive(row.original),[row])
    return (
        <Badge 
            className="w-18"
            variant={isActive ? 'success' : 'secondary'}>
            {isActive ? 'Active' : 'Inactive'}
        </Badge>
    )
}

const LastActiveCell = ({row,getValue, table}) => {   
    return (
        <DateCell 
            mode={table.options.meta?.showNiceDates ? 'days' : 'date'}
            className={cn(
                "whitespace-nowrap  text-md font-semibold text-muted-foreground",
                row.getValue('Active') && 'text-success'
                )}
            concise 
            value={getValue()}/>
    )
}


const MessageTimesCell = ({row}) => {
    const lastRead = nullNum(row.getValue('Last Read'));
    const firstMsg = row.getValue('First received');
    const lastMsg = row.getValue('Last Received');

    // console.log(row);

    return (
        <div className="grid grid-cols-2 whitespace-nowrap flex-grow gap-x-2">
                <p>
                    Oldest
                </p>
                <DateCell concise value={firstMsg}/>

                <p>
                    Newest
                </p>
                <DateCell concise value={lastMsg}/>

                <p>
                    Last Listened
                </p>
                <DateCell concise value={lastRead}/>
        </div>
    )   
}

const DateResetCell = ({getValue, table}) => (
    <DateCell
        mode={table.options.meta?.showNiceDates ? 'days' : 'date'}
        concise
        value={getValue() * 1000}/>
)

const MessagesCell = ({row}) => {
    const unread = row.getValue('Unread');
    const read = row.getValue('Read');
    const total = row.getValue('Total');

    return (
        <div className="grid grid-cols-2 whitespace-nowrap flex-grow gap-x-1">
                <p>
                    Unread
                </p>
                <span>{unread}</span>

                <p>
                    Read
                </p>
                <span>{read}</span>

                <p>
                    Total
                </p>
                <span>{total}</span>
        </div>
    )   
}