import { cn } from '@/lib/utils';
import { forwardRef, HTMLAttributes } from 'react';

type SimpleCardProps = HTMLAttributes<HTMLDivElement> & {
    className?: string;
};

export const SimpleCard = forwardRef<HTMLDivElement, SimpleCardProps>(({ className, ...props }, ref) => {
    return (
        <div ref={ref} className={cn('bg-background border rounded-2xl px-6 py-4', className)} {...props} />
    );
});
SimpleCard.displayName = 'SimpleCard';